<template>
  <div id="table_list_panel" class="upperrow" style="width:20%;overflow: hidden;" :style="{'height':containerHeight}" ref="table_list_panel_ref" @mouseover="onMainDivMouseOver" @mouseleave="onMainDivMouseLeave">
    <div id="table_list_header" style="height:40px;background-color:#E0E0E0;position:relative;top:0px;" ref="table_list_header_ref">
      <v-row class="EDC-Row">
        <v-col cols="10" class="EDC-Col" style="padding-left:8px !important;">
          <v-text-field id="tbl_searchbox" dense hide-details  v-on:keyup="perform_tablelist_serach" v-model="table_search" ref="tbl_searchbox_ref"
          @click.enter.native="makeServerCallForTable(table_search)" :disabled="readonly" autocomplete="off"></v-text-field>
        </v-col>
        <v-col cols="2" class="EDC-Col" style="padding-top:8px !important;">
          <v-row class="EDC-Row">
            <v-col class="EDC-Col">
              <v-menu  bottom absolute transition="scale-transition" 
              style="display:block" v-model="showMoreActions" :close-on-content-click="false">
              <template v-slot:activator="{ on }">
                <svgicon style="cursor:pointer;" v-on="on" class="svg-icon-mini svg-fill-mini" name="More" slot="activator"  :original="true" title="Show More Options"></svgicon>
              </template>
              <v-card v-bind:class="{'minwidth':steptablelist,'minwidthwithoutstep':!steptablelist}">
                <v-card-text xs12>
                  <v-flex xs12>
                    <v-layout row wrap style="margin-left:5%;">
                      <v-flex xs8>
                        <v-checkbox :disabled="readonly" class="customCheckbox" v-model="showDescription"
                        label="Show Description" @change="showDescriptionChange"></v-checkbox>
                      </v-flex>
                    </v-layout>
                  </v-flex>
                  <v-flex xs12>
                    <v-layout row wrap>
                      <v-flex v-bind:class="{'xs2':steptablelist,'xs3':!steptablelist}">
                      Show
                      </v-flex>
                      <v-flex v-bind:class="{'xs2':steptablelist,'xs3':!steptablelist}">
                       <v-checkbox class="customCheckbox" v-model="searchForTable"
                       label="Tables" :disabled="readonly"></v-checkbox>
                     </v-flex>
                     <v-flex v-bind:class="{'xs2':steptablelist,'xs3':!steptablelist}">
                       <v-checkbox class="customCheckbox" v-model="searchForView"
                       label="Views" :disabled="readonly"></v-checkbox>
                     </v-flex>
                     <v-flex v-bind:class="{'xs2':steptablelist,'xs3':!steptablelist}">
                       <v-checkbox class="customCheckbox" v-model="searchForAlias"
                       label="Synonyms" :disabled="readonly"></v-checkbox>
                     </v-flex>
                     <v-flex xs4 v-if="steptablelist">
                       <v-checkbox class="customCheckbox" v-model="searchForWorkTable"
                       label="Work Tables" :disabled="readonly"></v-checkbox>
                     </v-flex>
                    </v-layout>
                  </v-flex>
                  <v-flex v-bind:class="{'xs8':steptablelist,'xs6':!steptablelist}"></v-flex>
                  <v-flex v-bind:class="{'xs4':steptablelist,'xs6':!steptablelist}"></v-flex>
                    <v-layout>
                      <vc-button :disabled="readonly" type="button" @click.native="showMoreActions = false" itemText="Cancel" style="float:left"></vc-button>
                      <vc-button :disabled="readonly" type="button" @click.native="makeServerCallForTable(table_search)" itemText="Search" style="float:right"></vc-button>
                    </v-layout>
                  </v-card-text>
                </v-card>
              </v-menu>
            </v-col>
            <v-col class="EDC-Col" cols="4"  align="left">
              <svgicon @click="makeServerCallForTable(table_search)" class="svg-icon-mini svg-fill-mini" name="Search" :original="true" ref="tbl_searchicon_ref"></svgicon>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
</div>
<div style="height:90%;overflow:auto;justify:left !important;">
  <v-progress-linear v-if="indeterminate" :indeterminate="indeterminate" style="margin:0px;"></v-progress-linear>
  <v-list :disabled="readonly" id="list_of_tables" ref="list_of_tables_ref"  dense expand>
    <draggable v-if="!tableListLoader" v-model="table_list" :sort="false" @start="startDrag" :options="dragOptions" :move="onMove">
      <v-list-item  hide-details dense v-for="item in table_list" :key="item.title" class="list_items tableListItem"
      ref="list_items_ref" :data-tabletitle='item.title'>
      <v-list-item-action v-if="item.type=='T'" class="EDC-ListItemMargin">
        <!-- <v-icon color="blue" title="table">fa-table</v-icon> -->
        <svgicon class="svg-icon" name="Database_Table" :original="true" title="table"></svgicon>
      </v-list-item-action>

      <v-list-item-action v-else-if="item.type=='V'" class="EDC-ListItemMargin">
        <!-- <v-icon color="blue" title="view">fas fa-binoculars</v-icon> -->
        <svgicon class="svg-icon" name="Database_View" :original="true" title="view"></svgicon>
      </v-list-item-action>

      <v-list-item-action v-else-if="item.type=='A'" class="EDC-ListItemMargin">
        <!-- <v-icon color="blue" title="synonyms">far fa-clone</v-icon> -->
        <svgicon class="svg-icon" name="Database_Synonym" :original="true" title="synonyms"></svgicon>
      </v-list-item-action>

      <v-list-item-action v-else class="EDC-ListItemMargin">
        <!-- <v-icon color="blue" title="step">fa-book</v-icon> -->
        <svgicon class="svg-icon" name="Database_Worktable" :original="true" title="step"></svgicon>
      </v-list-item-action>
      <div style="width:35%;">
      <v-list-item-content>
        <v-list-item-title class="tabletitle EDC-ListItem" v-text="getItemTitle(item)" :title="item.title"></v-list-item-title>
      </v-list-item-content>
    </div>
      <v-list-item-content  style="text-align:left;" v-if="showDescription">
        <v-list-item-title class="EDC-ListItem" v-text="getTrimDescription(item.description)" :title="item.description"></v-list-item-title>
      </v-list-item-content>
    </v-list-item>
  </draggable>
  <v-progress-circular v-if="tableListLoader"  :width="3" :size="20" color="blue" indeterminate></v-progress-circular>

</v-list>

</div>
</div> 
</template>
<script>
const cloneDeep = require('clone-deep');
import {getTableList} from "./../methods/agent_api_call.js"
import draggable from 'vuedraggable'
import _ from 'lodash'
import vcButton from '@/views/button.vue'

export default {
 components:{
  draggable,
  'vc-button':vcButton,
},
computed:{
  dragOptions () {
    return  {
      animation: 0,
      group: 'description',
    };
  },
},
name: 'TableList',
props: ['tablelistobj','containerHeight','tableListLoader','steptablelist','readonly','datasourceDetails','datasource_id','productName','isDataviewerProduct'],
data () {
  return {
    isDragging:false,
    indeterminate:false,
    height:"",
    table_list:this.tablelistobj.table_list,
    default_table_list:this.tablelistobj.default_table_list,
    table_search:'',
    env_id:'',
    schema:'',
    connection_str:'',
    db_type: '',
    tbl_object_type:[],
    showMoreActions:false,
    showDescription:true,
    checkDesciption:false,
    searchForTable:true,
    searchForView:true,
    searchForAlias:true,
    searchForWorkTable:true,
    defaultListOffsetWidth:0,
    resizeListWidth:"500px",
    serverCallForTable:null,
  } 
},
watch: {
  tableheight(newValue, o){
      // debugger
      height = newValue;
    },
    tablelistobj(newvalue,o){
      this.table_list = newvalue.table_list
      this.connection_str= newvalue.connection_str
      this.db_type = newvalue.db_type
      this.schema= newvalue.schema
      this.default_table_list = newvalue.default_table_list
      this.env_id = newvalue.env_id,
      this.table_search = ''
      if(newvalue.tbl_object_type)
        this.tbl_object_type = newvalue.tbl_object_type
      this.perform_tablelist_serach()
    },
    readonly(newvalue){

    },
    datasourceDetails(newvalue){

    },
    datasource_id(newvalue){

    },
    productName(newvalue){

    },
    isDataviewerProduct(newvalue){

    }
  },
  mounted() {
    if(this.$refs.table_list_header_ref.$el)
      var header = this.$refs.table_list_header_ref.$el 
    else
      var header = this.$refs.table_list_header_ref
    if(this.$refs.table_list_panel_ref.$el)
      var table_list_panel = this.$refs.table_list_panel_ref.$el
    else 
      var table_list_panel = this.$refs.table_list_panel_ref
    table_list_panel.addEventListener('scroll',function(e){});
    this.defaultListOffsetWidth = "300px"
    // alert(this.defaultListOffsetWidth)
    this.managetableListWidth(this.resizeListWidth)
    
  },
  methods:{
    managetableListWidth(width){
      if(this.$refs.list_of_tables_ref.$el){
      this.$refs.list_of_tables_ref.$el.style.width=width;
      // this.$refs.list_of_tables_ref.$el.style.clientWidth = width
      }
    else 
      {
        this.$refs.list_of_tables_ref.style.width=width;
        // this.$refs.list_of_tables_ref.style.clientWidth = width;
      }
    },
    showDescriptionChange(show){
      // if(show)
        this.managetableListWidth(this.resizeListWidth)
      // else
        // this.managetableListWidth(this.defaultListOffsetWidth)
    },
    getTrimDescription(description=""){
      var trimSize = 30
      if(!description)
        return ""
      // if(description.length <=trimSize)
      //     return description
      // var sub_description = description.substr(0,trimSize-3)+"..."
      return description
    },
    startDrag(event){      
      // debugger;
      if(this.readonly)
        return
      let title = event.item.getElementsByClassName("tabletitle")[0].innerText

      if(title){
        // title = title.split("\n")[0]
        title = title.trim()
      }
      let _this = this;
      let obj = _.cloneDeep(_.find(_this.table_list, ['title',title]));
      if(!obj){
        if(event.item.dataset && event.item.dataset.tabletitle)
          obj = _.cloneDeep(_.find(_this.table_list, ['title',event.item.dataset.tabletitle]))
        if(!obj)
        return
      }
      if(obj.type == 'T')
        obj.type = 'table';
      else if(obj.type == 'A')
       obj.type = 'alias';
     else if(obj.type == 'V')
       obj.type = 'view';
     else 
       obj.type = 'step';   

     obj.table_description = obj.title
     if(obj.description)
      obj.table_description = obj.description.trim()
     this.$emit('dragged-object', obj);
   },
   onMove ({relatedContext, draggedContext}) {
      // this.$emit('dragged-object',draggedContext.element);
    },
    perform_tablelist_serach(event,loop){
      var input = this.table_search
      if(this.$refs.list_of_tables_ref.$el)
        var table_list = this.$refs.list_of_tables_ref.$el
      else 
        var table_list = this.$refs.list_of_tables_ref
      var list_items = table_list.getElementsByClassName('list_items')
      var filter = input.toUpperCase();
      var key_found = false
      for (var i = 0; i < list_items.length; i++) {
        if (list_items[i].innerText.trim().toUpperCase().indexOf(filter) > -1) 
        {
          list_items[i].style.display = "";
          key_found = true
        }
        else
          list_items[i].style.display = "none";
      }
      if(input.length == 0){
        clearInterval(this.serverCallForTable)
        this.table_list = cloneDeep(this.default_table_list)
      }
      else{
        this.makeServerCallForTable(input)
      }
    },

    makeServerCallForTable(input){
      if(!input)
        return
      let _this = this
      clearInterval(_this.serverCallForTable)
      _this.serverCallForTable = setTimeout(function(){
        _this.perform_serverside_search(input)
      },800)
    },
    perform_serverside_search(input){

      // this page has option to show descriptions. So you can set it as true. Even if showDescription is false.Because showDescription is only for client hide/show activity
      var description_needed = true
      var check_in_description = this.checkDesciption
      var search_types = []
      if(this.searchForTable)
        search_types.push("table")
      if(this.searchForView)
        search_types.push("view")
      if(this.searchForAlias)
        search_types.push("alias")


      // if user not selected proper value, then search for all
      if (search_types.length === 0){
        // user only ask for step list
        if(this.searchForWorkTable && this.steptablelist){
          this.table_list = []
          for (var i = this.steptablelist.length - 1; i >= 0; i--) {
            this.table_list.unshift(this.steptablelist[i])
          }
          this.showMoreActions = false
          return
        }

        this.searchForTable = this.searchForView =this.searchForAlias = true
        search_types = ["table","view","alias"]
      }

      // dictionary parameters

      // If showDescription is true then only we are going to check for description

      var kwargs = {"description_needed":description_needed,
                    "check_in_description":this.showDescription,
                    "object_type":search_types}
      // currently I assumed, in dataviewer table list panel only used by businss object
      if(this.isDataviewerProduct){
        kwargs['requestForBO'] = true
        kwargs['datasource_id'] = this.datasource_id
        kwargs['available_datasource_list'] = [this.datasourceDetails]
        kwargs['isDataviewerProduct'] = true
      }

      this.indeterminate = true;
      getTableList(this,this.env_id,this.connection_str, this.schema, this.db_type, input,this.tbl_object_type,kwargs).then(tableResponse =>{
        this.table_list = tableResponse.table_view_list;
        this.indeterminate = false;
        if(this.searchForWorkTable && this.steptablelist){
          for (var i = this.steptablelist.length - 1; i >= 0; i--) {
            this.table_list.unshift(this.steptablelist[i])
          }
        }
      }).catch(errorResponse => {
        this.indeterminate = false;
      });
      this.showMoreActions = false
    },
    dragitem(event){
      var innerhtml = event.target.innerHTML
      var parser = new DOMParser()
      var doc = parser.parseFromString(innerhtml, "text/xml");
      var table_type = doc.getElementsByTagName('i')[0].getAttribute("title")
      event.dataTransfer.setData("table_name", event.target.innerText);
      event.dataTransfer.setData("table_type", table_type);
    },
    onMainDivMouseOver(){
      this.$emit('onMainDivMouseOver')
    },
    onMainDivMouseLeave(){
      this.$emit('onMainDivMouseLeave')
    },
    getItemTitle(item){
      if(!item.seqNo)
        return item.title
      return "["+item.seqNo+"] "+item.title
    }
  }
}
</script>
<style scoped>

.customCheckbox >>> label{
  top: 5px !important;
}

.v-input--selection-controls {
  margin-top:0px;
  padding-top:0px;
}

.tabletitle{
  
}
.minwidth{
  min-width:700px !important;
}

.minwidthwithoutstep{
  min-width:500px !important;
}

.EDC-ListItemMargin{
  margin-right:10px !important;
}

.tableListItem{
  min-height:32px !important;
  cursor: pointer;
}
</style>