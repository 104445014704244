<template>
    <v-container class="EDC-Row">
        <v-row class="EDC-Row">
            <v-col class="EDC-Col">
                <v-row class="EDC-Row">
                    <v-col class="EDC-Col">
                        <svgicon v-if="showAddNewOption"  class="svg-icon-mini svg-fill-mini" name="add_multiple_v2"
                        :original="true" :title="'Add New '+header.headerFor" @click="showPopUp">

                        </svgicon>
                        <svgicon v-if="showEditOption" class="svg-icon-mini svg-fill-mini" name="document_edit_v2"
                        :original="true" :title="'Edit '+header.headerFor" @click="showPopUp">
                            
                        </svgicon>
                        <v-menu v-if="showDeleteOption" slot="activator">
                            <template v-slot:activator="{ on }">
                                <svgicon  class="svg-icon-mini svg-fill-mini" v-on="on" name="delete_v2" 
                                    :original="true" :title="'Delete '+header.headerFor" >
                                </svgicon>
                            </template>
                            <confirmation-panel heading="Confirm Action" label="Are you sure want to delete?"
                            lblSuccess="Delete" @onSuccess="deleteData">
                            </confirmation-panel>
                        </v-menu>
                    </v-col>
                </v-row>
                <v-dialog v-model="showFilterDialog" width="1000">
                    <v-card>
                        <v-row class="EDC-Row">
                            <v-col class="EDC-Col">
                                <v-toolbar dense dark>
                                <v-app-bar-nav-icon></v-app-bar-nav-icon>
                                <v-toolbar-title>{{ headerText }}</v-toolbar-title>
                                <v-spacer></v-spacer>
                                <v-btn icon>
                                    <v-icon title="close" @click="closeOption">mdi-close</v-icon>
                                </v-btn>
                            </v-toolbar>
                            </v-col>
                        </v-row>
                        <v-row class="EDC-Row" >
                            <v-col v-if="isFilterOption">
                                <new-edc-criteria :columns_for_filter="definedFilters"  
                                :column_name_list="columnList" @savedata="manageCriteria" 
                                :new_columns_for_filter="main_filterUI" :secondaryfilter="secondary_filterUI"
                                @new="addNewFilter">
                                </new-edc-criteria>
                            </v-col>
                        </v-row>
                        <v-row class="EDC-Row">
                            <v-col class="EDC-Col">
                                <vc-button itemText="Save" @click.native="saveFilter">

                                </vc-button>
                                <vc-button itemText="Cancel" @click.native="closeOption">

                                </vc-button>
                            </v-col>
                        </v-row>
                    </v-card>
                </v-dialog>
            </v-col>
        </v-row>
    </v-container>
</template>
<style scoped>

</style>
<script>
    import edccriteria from './../newcriteria.vue'
    import confirmationPanel from './Confarmation.vue'
    export default {
        name:"EdcAdvanceInlineFilter",
        components:{
            'new-edc-criteria':edccriteria,
            'confirmation-panel':confirmationPanel
        },
        props:{
            header:{
                type:Object,
                default:()=>{
                    return{
                        value:"",
                        label:"",
                        headerFor:'',
                        option:[]
                    }
                }
            },
            filterColumns:{
                type:Array,
                default:()=>{
                    return []
                }
            },
            valueObject:{
                type:Object,
                default:()=>{
                    return {

                    }
                }
            },
            disabledOptions:{
                type:Boolean,
                default:false
            },
            itemObj:{
                type:Object
            }
        },
        data(){
            return{
                showFilterDialog:false,
                main_filterUI:[],
                secondary_filterUI:{groupList:[]},
                definedFilters: [],
                columnList:[],
                saved_main_filterUI:[],
                saved_secondary_filterUI:{groupList:[]},
                savedDefinedFilter:[]
            }
        },
        computed:{
            filterObj(){
                return {
                    's_parentheses': '',
                    'column_name': '',
                    'operator': '',
                    'v_type1': 'Value',
                    'v_type2': 'Value',
                    'value1': '',
                    'value2':'',
                    'e_parentheses': '',
                    'is_drv_table': false,
                    'ref_row_id':'',
                    'ref_step_id':''

                }
            },
            isFilterOption(){
                return this.header.headerFor === 'Filter'
            },
            isFilterApplied(){
                if(_.isEmpty(this.valueObject))
                    return false
                return this.valueObject.filter.length >0
            },
            showAddNewOption(){
                if(this.isFilterOption){
                    if(!this.isFilterApplied)
                        return true
                    return false
                }
                return false
            },
            showEditOption(){
                if(this.isFilterOption){
                    return !this.showAddNewOption
                }
            },
            showDeleteOption(){
                if(this.isFilterOption){
                    // if its edit, it means it has value so you can delete it.
                    return this.showEditOption
                }
            },
            headerText(){
                if(this.isFilterOption)
                    return "Define Filter"
                return "Title"
            },
        },
        watch:{
            'header':{
                handler(newvalue){

                },
                deep:true
            },
            'header.option':{
                handler(newValue){
                    if(this.isFilterOption)
                        this.columnList = _.cloneDeep(newValue)
                }
            },
            'valueObject':{
                handler(newValue){

                }
            }
        },
        mounted() {

        },
        methods:{
            showPopUp(){
                var _this = this
                if(!_.isEmpty(this.itemObj)){
                    this.$emit('advancePopupInitiating',this.itemObj, this.header)
                }
                if(this.isFilterOption){
                    if(this.header.option)
                        this.columnList = _.cloneDeep(this.header.option)
                    this.showFilterDialog = true
                    if(this.isFilterApplied){
                        this.definedFilters = _.cloneDeep(this.valueObject.filter)
                        this.main_filterUI = _.cloneDeep(this.valueObject.main_filterUI)
                        this.secondary_filterUI = _.cloneDeep(this.valueObject.secondary_filterUI)
                    }
                    else{
                        setTimeout(()=>{
                            _this.addNewFilter(true)
                        },500)
                    }
                }
            },
            addNewFilter(isDefault){
                var obj = _.cloneDeep(this.filterObj);
                if(!isDefault){

                }
                else{
                    this.main_filterUI = [obj]
                    return
                }
                var current_filter_length = this.definedFilters.length
                if(current_filter_length > 0){
                    if(!this.definedFilters[current_filter_length-1]['operation'])
                    this.definedFilters[current_filter_length-1]['operation'] = false   
                }
                this.definedFilters.push(obj);
            },
            delete_filter_row(idx){
                var current_filter_length = this.columns_for_filter.length
                if(current_filter_length > 1 && current_filter_length-1 ===  idx)
                    delete this.columns_for_filter[idx-1]['operation']
                this.columns_for_filter.splice(idx, 1)
                if(this.columns_for_filter.length === 0){
                    var obj = _.cloneDeep(this.filter_column_tbl_obj);
                    this.columns_for_filter.push(obj);
                }
            },
            manageCriteria(definedFilter,main_filterUI,secondary_filterUI){
                this.savedDefinedFilter = _.cloneDeep(definedFilter)
                this.saved_main_filterUI = _.cloneDeep(main_filterUI)
                this.saved_secondary_filterUI=_.cloneDeep(secondary_filterUI)
            },
            saveFilter(){
                let valueObj = {"filterObj":{
                        "filter":_.cloneDeep(this.savedDefinedFilter),
                        "main_filterUI":_.cloneDeep(this.saved_main_filterUI),
                        "secondary_filterUI":_.cloneDeep(this.saved_secondary_filterUI)
                    }
                }
                this.$emit('saveAdvanceRowOption',valueObj)
                this.closeOption()
            },
            deleteData(){
                if(this.isFilterOption){
                    let valueObj = {"filterObj":{}}
                    this.$emit('saveAdvanceRowOption',valueObj)
                }
            },
            closeOption(){
                this.showFilterDialog = false
            },
        }
    }
</script>