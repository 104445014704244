<template>
	<div>
		<v-flex xs12 style="margin-left:15px;margin-right:15px;margin-top:5px;">
			<v-card v-if="dataList.headers.length > 0" pl-1 pr-1>
				<v-container class="EDC-Container EDC-GridHeaderContainer" v-if="!dataList.hideActions || !dataList.hideFilter || !dataList.hideExport || !dataList.hideShowColumns">
					<v-row no-gutters>
						<v-col cols="4" v-if="!dataList.hideActions">
							<v-row no-gutters>
								<svgicon v-if="showAddNew && !selected.length" title="Add New" class="EDC-GridIcon svg-icon-grid svg-fill-grid" name="add_v2" :original="true" @click="createEvent('record', 'onNew')"></svgicon>
								<!--  -->
								<span v-if="dataList.moreInformatonDetails">
									<v-menu slot="activator" right v-model="showMoreInfoModal" :close-on-content-click="false"
									:nudge-width="400" max-width="480">
									<template v-slot:activator="{ on }">
										<svgicon v-on="on" v-if="dataList.moreInformatonDetails.showMoreInfo" :title="dataList.moreInformatonDetails.showMoreInfoTitle||'Show More Info'" class="EDC-GridIcon svg-icon-grid svg-fill-grid" name="job_details_v2" :original="true"></svgicon>
									</template>
									<v-card elevation="0" style="overflow-y:hidden !important;padding-bottom:16px !important;">
										<v-row class="EDC-Row">
											<v-col class="EDC-Col">
												<v-toolbar dense flat tile class="EDC-Toolbar"> 
													<v-toolbar-title  style="width:100% !important;">
														<v-row class="EDC-Row">
															<v-col class="EDC-Col text-truncate" align="start" :title="dataList.moreInformatonDetails.header || 'More Information'">
																{{dataList.moreInformatonDetails.header || 'More Information'}}
															</v-col>
															<v-col class="EDC-Col" cols="1" align="end">
																<svgicon class="svg-icon-grid svg-fill-grid EDC-GridIcon" slot="activator" 
																name="cancel_v2" title="Close" @click="showMoreInfoModal=false" style="color:white !important;fill:white !important;"></svgicon>
															</v-col>
														</v-row>
													</v-toolbar-title>
												</v-toolbar>
											</v-col>
										</v-row>
										<v-row class="EDC-Row">
											<v-col class="EDC-Col style-2scroll" style="max-height:300px !important;overflow-y:auto;">
												<v-row class="EDC-Row">
													<v-col class="EDC-Col" style="padding:8px !important;"  align="start" cols="3">
														<b>What Step Does:</b>
													</v-col>
													<v-col class="EDC-Col" style="padding:8px !important;" align="start">
														{{ dataList.moreInformatonDetails.what_step_does }}
													</v-col>
												</v-row>
												<v-divider class="EDC-Divider"></v-divider>
												<v-row class="EDC-Row" v-if="dataList.moreInformatonDetails.used_tables">
													<v-col class="EDC-Col" style="padding:8px !important;"  align="start" cols="3"><b>Used Tables:</b></v-col>
													<v-col class="EDC-Col" style="padding:8px !important;" align="start">
														<table style="border:1px solid;width:100%;">
															<tr>
																<th class="moreInfoTD">Table Name</th>
																<th class="moreInfoTD">Table Description</th>
															</tr>
															<template v-for="(item,idx) in dataList.moreInformatonDetails.used_tables">
															<tr :key="'used_table'+idx" >
																<td class="moreInfoTD">
																	{{item.database_table_name}}
																</td>
																<td class="moreInfoTD">
																	{{item.description}}
																</td>
															</tr>
															</template>
														</table>
													</v-col>
												</v-row>
												<v-divider class="EDC-Divider"></v-divider>
												<v-row class="EDC-Row">
													<v-col class="EDC-Col" style="padding:8px !important;"  align="start" cols="3">
														<b>Note:</b>
													</v-col>
													<v-col class="EDC-Col" style="padding:8px !important;" align="start">
														<span v-html="dataList.moreInformatonDetails.step_description"></span>
													</v-col>
												</v-row>
												<v-divider class="EDC-Divider"></v-divider>
												<v-row class="EDC-Row" v-if="dataList.moreInformatonDetails.filters">
													<v-col class="EDC-Col" style="padding:8px !important;"  align="start" cols="3"><b>Filters:</b></v-col>
													<v-col class="EDC-Col" style="padding:8px !important;" align="start" v-if="dataList.moreInformatonDetails.filters.length">
														<template v-for="(item,indx) in dataList.moreInformatonDetails.filters">
															<v-row class="EDC-Row" :key="('filters'+indx)">
																<v-col class="EDC-Col">
																	{{item}}
																</v-col>
															</v-row>
														</template>
													</v-col>
													<v-col v-else class="EDC-Col" style="padding:8px !important;" align="start">
														N/A
													</v-col>
												</v-row>
												<v-divider class="EDC-Divider"></v-divider>
												
												<v-row class="EDC-Row">
													<v-col class="EDC-Col">
														<vc-button itemText="SQL" @click.native="copyDataToClipboard(dataList.moreInformatonDetails.select_query)"></vc-button>
														<vc-button itemText="Close" @click.native="showMoreInfoModal=false"></vc-button>
													</v-col>
												</v-row>
											</v-col>
										</v-row>
									</v-card>
									</v-menu>
								</span>
								<v-menu  bottom absolute transition="scale-transition" v-if=" getNewObjWidOpt && newWidOption && newWidOption.role && !selected.length" close-on-content-click close-on-click>
									<template v-slot:activator="{ on }">
										<svgicon style="display: inline-flex !important;" class="svg-icon-grid svg-fill-grid EDC-GridIcon" name="add_v2" :original="true" v-on="on"></svgicon>
									</template>
									<v-list>
										<v-list-item  dense v-for="(item, index) in newMenu"
										:key="index" @click="createEvent('record', item.event)" style="cursor:pointer">
										<v-list-item-subtitle class="EDC-ListItem" style="text-align:left !important;">{{item.title}}</v-list-item-subtitle>
									</v-list-item>
								</v-list>
							</v-menu>

							<action-panel :key="ActionPanelremountTime" :selectedArray="selected" @createEvent="createEvent" :data="gridActionObject" @onCancelEditing="onCancelEditing"></action-panel>
						</v-row>
					</v-col>
					<v-col cols="7" align="end">
						<v-chip v-if="!dataList.hideFilter" v-for="(item,index) in selectChips" :key="item" color="primary" style="margin-top: 3px !important; margin-right: 3px !important" @click:close="chipDeleted(item,index)" small close>{{item}}</v-chip>
					</v-col>
					<v-col cols="1" align="end" v-if="!dataList.hideFilter || !dataList.hideExport || !dataList.hideShowColumns">

						<!-- <template v-slot:activator="{ on }"> -->
							<!--<svgicon class="svg-icon-grid svg-fill-grid EDC-GridIcon" name="search_v2" slot="activator" :original="true" @click="displayCriteriaPopUp()" v-if="!dataList.hideFilter" title="Apply Filter"></svgicon>-->

							<svgicon class="svg-icon-grid svg-fill-grid EDC-GridIcon" slot="activator" name="document_report_view_v2" v-if="dataList.showDataviewerRedirection" title="View Data in Dataviewer" @click="$emit('redirectToDataviewer')"></svgicon>
						<!-- </template> -->

						<action-panel @onDownload="onDownload" :showDownload="!dataList.hideExport" v-if="!dataList.hideExport">

						</action-panel>


						<v-menu offset-y class="EDC-GridColumnHide" close-on-click :close-on-content-click='false'>
							<template v-slot:activator="{ on }">
								<!-- <v-icon  v-on="on" class="EDC-GridIcon" title="Show / Hide Columns" id="smallicon" v-if="!dataList.hideShowColumns">settings_ethernet</v-icon> -->
								<svgicon class="svg-icon-grid svg-fill-grid EDC-GridIcon" v-on="on" name="columns_show_hide_v2" slot="activator" :original="true"  v-if="!dataList.hideShowColumns"title="Hide/Show Columns"></svgicon>

							</template>
							<v-card class="EDC-VCardColumn">
								<v-combobox :items="allHeaders" multiple :menu-props="{ maxHeight: '300' }" hide-details v-model="visibleCols" item-text="text" item-value="text" @change="hideDataGridColumns" class="EDC-ControlPadding">
									<template v-slot:selection="{ item, index }">
										<span v-if="index === 0">{{ item.text }}</span>
										<span v-if="index === 1">(+{{ visibleCols.length - 1 }} Cols)</span>
									</template>
								</v-combobox>
							</v-card>
						</v-menu>
					</v-col>
				</v-row>
			</v-container>


			<!--NOTE: SELECT ALL Not work for the even vuetify grid for different pages-->
			<v-data-table dense v-model="selected" :multi-sort="!dataList.singleSort"
			:headers="dataList.headers"
			:items="rowsToDisplay"
			:item-key="dataList.itemkey"
			:page="page"
			:items-per-page="perPage"
			:server-items-length="dataList.total_count"
			:show-select="!dataList.hideSelect"
			class="elevation-1"
			hide-default-footer
			:loading="dataList.tableLoading"
			@page-count="pageCount = $event" @update:options="updateTableOptions" id="edcGrid">

			<template v-for="(h,i) in dataList.headers" v-slot:[`header.${h.value}`]="{ header }">
				<v-tooltip bottom :key="'h'+i">
					<template v-slot:activator="{ on }">
						<span v-on="on" @click="test($event)">{{h.displayHeader || h.text}}</span>
					</template>
					<span>{{getColTooltips(h.text)}}</span>
				</v-tooltip>
			</template>
			<template v-slot:body="{ items }">
				<tbody style="cursor:pointer;">
					<tr  v-if="showInlineFilter && !dataList.hideFilter && (dataList.rows.length > 0 || updatedFilter.length > 0 || inlineFilterList.length>0)">
						<td v-if="!dataList.hideSelect" class="text-align:left;">

						</td>
						<td v-for="(h,i) in dataList.headers" :key="i+'fitlerheader'" style="text-align:left" :class="{'hideCol':h.align===hideColProp}">
							<span v-if="!h.hideInlineFilter">
								<v-autocomplete clearable v-if="h.useDropdownForSearch" :items="h.searchOptions" hide-details dense :item-text="h.searchItemText"  :item-value="h.searchItemValue" v-model="h.filtertext" @change="applyInlineFilter($event,h, h.filtertext)" @click:clear="clearInlineFilter($event,h,h.filtertext)" :multiple="h.searchMultiple||false" :title="h.filtertext">
								</v-autocomplete>
								<v-text-field v-else clearable rounded id="inlineFilter" type="text" v-model="h.filtertext" @keypress.enter.prevent="applyInlineFilter($event,h, h.filtertext)" hide-details dense @click:clear="clearInlineFilter($event,h,h.filtertext)" @keyup.prevent="keyUpInlineFilter($event,h, h.filtertext)" autocomplete="off" @blur="applyInlineFilter($event,h, h.filtertext,true)"></v-text-field>
							</span>
							<!-- <input  v-if="!h.hideInlineFilter" style="border-radius:5px !important;border:1px solid; padding-left: 5px;" type="text" v-model="h.filtertext" @keypress.enter.prevent="applyInlineFilter($event,h, h.filtertext)" v-focus width="25px"/> -->
							<!-- <v-span v-else></v-span> -->
						</td>
					</tr>
					<tr v-if="dataList.rows.length === 0">
						<td :colspan="dataList.headers.length" class="EDC-NoRecordText">{{NoDataFound}}</td>
					</tr>
					<tr v-if="dataList.rows.length > 0" v-for="(item,index) in items" :key="'item'+index" @dblclick="rowDoubleClick(item,index)" @click="manageHighLight($event,index,item)" :class="{'EDC-HighlightGridRow': highlightedRows.indexOf(index)>-1}">
						<td v-if="!dataList.hideSelect">
							<v-checkbox v-model="selected" :value="item" style="margin:0px;padding:0px" hide-details color="primary-lighten2" @change="rowSelectionChanged(item,index)"></v-checkbox>
						</td>
							<td v-for="(header,headerindex) in dataList.headers" :style="{'text-align':header.align|| 'left'}" :key="'header'+headerindex" :title="item[header.display_text_key] || item[header.value]" :class="{'hideCol':header.align===hideColProp}">

								<!-- v-progress-circular in td -->
								<v-progress-circular v-if="header.useProgress"
								:rotate="-90"
								:width="2"
								:size="24"
								color="primary"
								:value="item[header.value]"
								><span style="font-size:10px !important;">{{item[header.value]}}</span>
							</v-progress-circular>

							<!-- v-chip in td -->
							<v-chip style="margin-top:1px;margin-bottom:1px;" v-else-if="header.useChip" :color="getColor(header.chipFor,item[header.value])" small dark>{{getFormatedData(header,item[header.value])}}</v-chip>

							<!-- v-checkbox in td -->
							<v-checkbox v-else-if="header.useCheckbox && item.is_row_editable" v-model="item[header.value]" :value="item[header.value]" style="margin:0px;padding:0px" hide-details color="primary-lighten2" :indeterminate="item.useIndeterminateState" @change="checkChanged($event,header,item)" :disabled="setDisabled(header,item)"></v-checkbox>

							<!-- v-autocomplete in td . It should not be 'clearable'.If you keep clearable it will cause the problem in add policy-->
							<v-autocomplete v-else-if="header.useAutoComplete && item.is_row_editable" :multiple="header.useMultiple || false" :return-object="header.returnObject || false" hide-details="auto" v-model="item[header.value]"  :items="header.option" dense :item-text="header.item_text" :label="header.label || 'select'" :item-value="header.item_value" @input="changeAutoCompleteSelection($event,item,header,headerindex,index)"></v-autocomplete>


							<edc-advance-autocomplete v-else-if="header.useAdvanceAutoComplete && item.is_row_editable" 
							:header="header" :key="header.value+index" :selectedValue="item[header.value]" 
							:isAllSelected="item[header.selectAllKey] || false"
							@changeAutoCompleteSelection="changeAdvanceItemSelection($event,item,header,headerindex,index)" 
							@performSearch="performSearchInAdanceAutoComplete($event,header, item, headerindex, index)">

							</edc-advance-autocomplete>

							<edc-advance-combobox v-else-if="header.useAdvanceCombobox && item.is_row_editable" 
							:header="header" :item="item" :key="header.value+index+'combo'" :selectedValue="item[header.value]" 
							:isAllSelected="item[header.selectAllKey] || false"
							@changeAutoCompleteSelection="changeAdvanceItemSelection($event,item,header,headerindex,index)">

							</edc-advance-combobox>

							<!--Edc calender in td. Keep this above the textbox. Because on policy date if user select actual date policy value type then this should get displayed-->
							<edc-calender :input="item[header.value]" @update="setDate(item, header.value, ...arguments)"
							v-else-if="item.is_row_editable && ((item.value_type === 'Actual Date' && header.useTextField) || header.useCalender)" :desne="true"> </edc-calender>


							<!-- v-textbox in td -->
							<v-text-field v-else-if="header.useTextField && item.is_row_editable" hide-details="auto" v-model="item[header.value]" dense class="EDC-TextField" @keypress="validateInput($event,header.inputType)" :disabled="header.disabled" @focus="AddRowForGridEditing(item)" @blur="AddRowForGridEditing(item,header,index)" :label="header.label"></v-text-field>

							<edc-advance-row-options :key="header.value+index+'advancerow'" 
							v-else-if="header.useAdvanceRowOption && isAdvanceOptionShow(item)" 
							:header="header" :itemObj="item" :valueObject="item[header.value]" 
							@advancePopupInitiating="advancePopupInitiating" @saveAdvanceRowOption="saveAdvanceRowOption($event,item,header)">

							</edc-advance-row-options>
							<v-radio-group v-model="single_selected_row"  hide-details dense v-else-if="header.useRadio" @change="radioSelectionChange(item)">
                            	<v-radio class="radioClass" :color="colorCode" :value="item" hide-details dense>

                            	</v-radio>
                        	</v-radio-group>
							<!-- normal td -->
							<span v-else-if='header.avoidTrim' @click="copyToClipboard($event,header)" class='keep-spaces'>{{getFormatedData(header,item[header.value],item,header.is_key_json)}}</span>

							<span v-else-if='isRowFullfillLinkCriteria(header,item)' @click="linkCellClicked($event,header,item)" class="grid-cell-link"> {{getFormatedData(header,item[header.value],item,header.is_key_json)}} </span>

							<span v-else-if='header.gridDisplayTextKey' :title="item[header.gridDisplayTextTitleKey] || item[header.gridDisplayTextKey]"> {{item[header.gridDisplayTextKey] || item[header.value]}} </span>

							<span v-else @click="copyToClipboard($event,header)">{{getFormatedData(header,item[header.value],item,header.is_key_json)}}</span>
						</td>
					</tr>
				</tbody>
			</template>
		</v-data-table>

		<v-container style="max-height:50px;padding:0px;" v-if="showTableFooter">
			<v-row no-gutters dense xs12>
				<v-col cols="4" class="EDC-DashboardCol">
					<v-row no-gutters v-if="!dataList.hideItemPerPage">
						<v-col cols="8" style="margin-left:5%;">
							<v-select :items="perPageArray" hide-details v-model="perPage" style="width:50px;padding:0px;" @input="onPerPageChange"></v-select>
							<span style="position: absolute;left:60px;margin-top:-22px;" class="EDCTableFooter">Rows</span>
						</v-col>
						<v-col cols="3">
							<!-- <span style="position: absolute;left: 0%;margin-top:5%;" class="EDCTableFooter">Rows</span> -->
						</v-col>
					</v-row>
				</v-col>
				<v-col cols="4" class="EDC-DashboardCol" align-self="center">
					<span class="EDCTableFooter" v-if="!dataList.hideRowInfo && rowsToDisplay.length > 0">
					{{ (page - 1) * perPage + 1 }} - {{ (page - 1) * perPage + rowsToDisplay.length }} of {{ dataList.actual_total_count || dataList.total_count }}</span>
					<span class="EDCTableFooter" v-else-if="!dataList.hideRowInfo">
						0 - 0 of 0
					</span>
				</v-col>
				<v-col cols="4" class="EDC-DashboardCol" justify="end" align="end" align-self="end" v-if="!dataList.hidePagination">

					<v-pagination style="justify-content:flex-end !important;float:right;"  v-model="page" :length="pageCount" :total-visible="pageNumberTotalVisible" @input="onPageChange"></v-pagination>

				</v-col>
			</v-row>
		</v-container>
	</v-card>
</v-flex>
<v-layout>

	<v-dialog v-model="showCriteria"  eager style="overflow-y:none" persistent>
		<v-col>
			<v-card class="rounded-card" style="height: 325px;">
				<v-toolbar dark dense>
					<v-col class="text-md-center">Filters
						<v-icon class="text-lg-left" @click="showCriteria = false" style="color:#dedede;height:22px;float:right">fa-times-circle</v-icon>
					</v-col>
				</v-toolbar>
				<v-col pa-2 xs12 style="max-height: 225px; overflow-y: scroll;" id="step_detail_table">
					<new-edc-criteria :columns_for_filter="updatedFilter"  :column_name_list="columnList" @savedata="manageCriteria" :new_columns_for_filter="main_filterUI"></new-edc-criteria>
				</v-col>
				<vc-button outline itemText="Apply"  @click.native="saveCriteria">
				</vc-button>
			</v-card>
		</v-col>
	</v-dialog>
</v-layout>
</div>
</template>
<script>
import edccriteria from './../newcriteria.vue'
import{CLIENT_SIDE,SERVER_SIDE} from '@/data/macros.js';

import {FLITER_JOIN_OPERATOR_ARRAY_JSON,GRID_STATUS_COLOR_CODE,No_Data_Found,OEPRATOR_TO_EDC_OPERATOR} from '../../constants/constants.js'
import {
	COLOR_CODE,BTN_COLOR
} from "@/data/macros.js";
import vcButton from './../button.vue'
import moment from 'moment'
import ActionPanel from "./ActionPanel.vue"
import {manageScreenDisplay} from '@/methods/special.js'
import {isValidNumber} from '@/methods/edcdatavalidation.js'
import {POLICY_VALUE_TYPE,POLICY_VALUE_TYPE_WITH_RETENTION} from '@/data/metaData.js'
import * as commonFuncObj from "../../methods/commonFunc.js"
import {getJDEDate, convertToUserNativeDate, getTodaysDate} from '@/methods/DateFormat.js'
import * as JDEFormatterObj from "../../methods/JDEFormatter.js"
import edcAdvanceAutocomplete from "./edcAdvanceAutocomplete.vue"
import edcAdvanceCombobox from "./edcAdvanceCombobox.vue"
import edcAdvanceRowOptions from "./edcAdvanceRowOptions.vue"
// import DateFormat from '../../methods/DateFormat';
export default {
	name:'edcdatagrid',
	components:{
		'new-edc-criteria':edccriteria,
		'vc-button':vcButton,
		'action-panel':ActionPanel,
		'edc-advance-autocomplete':edcAdvanceAutocomplete,
		'edc-advance-combobox':edcAdvanceCombobox,
		'edc-advance-row-options':edcAdvanceRowOptions
	},
	props:{
		dataList:Object,
		predefinedInlineFilter:Array,
		gridFor:{
			type:String,
			default:'dataDisplay'
		}
	},
	data(){
		return {
			main_filterUI:[{
        's_parentheses': '',
        'column_name': '',
        'operator': '',
        'v_type1': 'Value',
        'v_type2': 'Value',
        'value1': '',
        'value2':'',
        'e_parentheses': '',
        'is_drv_table': false,
        'ref_row_id':'',
        'ref_step_id':''

      }],
		showInGridFilter:false,
		ActionPanelremountTime:1,
		hideColProp:' d-none',
		rowsToDisplay:[],
  		clickOnSort:false, // this variable is used to avoid sort call on first grid load.In case of server side grid updateOptions method get called. That method make getData col 2-3 time unnecessary.To avoid this overhead added this clickOnSort variable.
  		outlineColor:BTN_COLOR,
  		pageNumberTotalVisible:7,
  		page: 1,
  		pageCount: 0,
  		perPage: 10,
  		perPageArray:[5,10,15,20,25,50,100],
  		search:'',
  		selected:[],
  		defaultCaption:'Caption Not Available',
  		showCriteria:false,
  		columnList:[],
  		updatedFilter:[],
  		inlineFilterList:[],
		filter_operators:FLITER_JOIN_OPERATOR_ARRAY_JSON,
		operator_to_edc_operator:OEPRATOR_TO_EDC_OPERATOR,
  		NoDataFound: No_Data_Found,
  		selectChips:[],
  		oldSelectedChips:[],
  		allHeaders:[],
  		visibleCols:[],
  		visibleChilds:[],
  		downloadOption:false,
  		downloadParams:{
  			recordType:"all",
  			minRange:"",
  			maxRange:"",
  			fileType:"xlsx",
  			topN:0,
  		},
  		gridActionObject:{},
  		newWidOption:{},
  		showAddNew:false,
  		showAddNewWithOption:false,
  		newMenu:[
  		{title:'Create Business Object', event:'createBusinessDoc'},
		  {title:'Create Process Definition', event:'createProcessDoc'}],
  		single_selected_row:{},
  		colorCode:COLOR_CODE,
  		allPagesRowSelectionList:[],
  		highlightedRows:[],
		compressVeritcalSpace:false,
		showMoreInfoModal:false
  	}
  },
	computed:{
		isCompressionApplicable(){
			return this.dataList.isCompressionApplicable
		},
		showInlineFilter(){
			if(!this.isCompressionApplicable)
				return true
  			if(this.compressVeritcalSpace)
  				return false
  			return !this.dataList.hideInlineFilter && (this.dataList.total_count > 5 || this.inlineFilterList.length>0)
  		},
  		showTableFooter(){
			if(!this.isCompressionApplicable)
				return true
  			if(this.compressVeritcalSpace)
  				return false
  			return this.dataList.total_count > 7
  		},
		isGridForWorkTable(){
			return this.gridFor === 'worktable'
		}
	
	},
  watch:{
  	'dataList':{
  		handler(newValue){
			this.showMoreInfoModal = false
  			this.actualDataList = []
  			var obj = _.find(newValue.actions,["text","new"])
  			this.showAddNew = true
  			if(!obj)
  				this.showAddNew = false

  			this.getNewObjWidOpt()
  		},
  		deep:true
  	},

  	'dataList.selected_rows':{
  		handler(newValue){
  			if(!newValue.length || !this.dataList.persist_selected_rows)
  				return
  			// need right logic for job plan job moving
  			// newValue.forEach(function(obj){
  			// 	this.selected.push(_.cloneDeep(obj))
  			// })
  			this.$nextTick(function () {
				this.selected = newValue
			})
  			this.dataList.persist_selected_rows = false
  		},
  	},
  	'dataList.rows':{
  		handler(newValue){
  			if(newValue && newValue.length >0 && (newValue[0].updatinglivestatus || this.dataList.is_grid_inline_edit) && this.selected.length > 0){
  			}
  			else
  				if(this.dataList.DefaultSelectAll)
  					this.selected = newValue
  				else
  					this.selected = []

  			if(this.dataList.paginationType!=SERVER_SIDE)
  			{
  				//code added for manish sir change.mail date: 11/march/2020
  				if(this.dataList.is_grid_inline_edit && this.inlineFilterList.length > 0 && this.selected.length > 0)
  					return
  				this.dataList.total_count = newValue.length
  				this.performClientSidePagination()
  			}
  			else{

  				// if(newValue.length > this.perPage)
  				// 	this.rowsToDisplay = newValue.slice(0,this.perPage)
  				// else
  					this.rowsToDisplay = newValue
  			}
  			this.manageSelected(this.selected)

  		},
  		deep:true
  	},
  	'dataList.rowRemoved':{
  		// To fix this bug => terminated one of two jobs and when we selected the remaining job the options were inverted from what you would expect. For example, the job is not selected and you see the resume button. If you select the job the selections disappear.
  		handler(newValue){
  			if(newValue)
  				this.selected = []
  		}
  	},
  	'rowsToDisplay':{
  		handler(newValue){
  			let _this = this
  			if(newValue && newValue.length >0 && (newValue[0].updatinglivestatus || this.dataList.is_grid_inline_edit) && this.selected.length > 0){
  			}
  			else
  				{
  				if(this.dataList.DefaultSelectAll)
  					this.selected = newValue
  				else if(this.dataList.allPagesRowSelection && this.dataList.allPagesRowSelectionList && this.dataList.allPagesRowSelectionList.length){
  					// now add item as a selected if that new row present in the this.dataList.allPagesRowSelectionList
					let selectedItems = [] // defining diffrent variable just to avoid emit on every add item
					for(let i=0;i<newValue.length;i++){
						if(this.dataList.allPagesRowSelectionList.indexOf(newValue[i])>-1)
							selectedItems.push(newValue[i])
					}
					this.$nextTick(()=>{
						_this.selected = selectedItems
					})
  				}
  				else{
							if(!this.compressVeritcalSpace)
								this.selected = []
						}
  				}
  			this.manageSelected(this.selected)
  		}
  	},
  	'selectChips':{
  		handler(newValue){
				// if newValue
			}
		},
		'selected':{
			handler(newValue){
				this.manageSelected(newValue)
				this.manageCompression()
			}
		},
		'perPage':{
			handler(newValue){
				this.selected = []
			}
		},
		'page':{
			handler(newValue){
				this.selected = []
			}
		},
		'predefinedInlineFilter':{
			handler(newValue){
				if(_.isEmpty(newValue) || _.isEmpty(this.dataList) || _.isEmpty(this.dataList.headers))
					return
				for(let i=0;i<newValue.length;i++){
					let currentFilter = newValue[i]
					let isLastFilter = newValue.length-1 != i // on last inline filter we have to call the function
					for(let j=0;j<this.dataList.headers.length;j++){
						let currentHeader = this.dataList.headers[j]
						if(currentFilter.column_name === currentHeader.value){
								currentHeader.filtertext =  currentFilter.value1
								this.applyInlineFilter({},currentHeader,currentFilter.value1,isLastFilter)
								break;
						}
					}
				}
			}
		},
		'dataList.isCompressionApplicable':{
			handler(newValue){
				// this.compressVeritcalSpace = newValue
			}
		},
		'gridFor':{
			handler(newval){

			}
		}
	},

	mounted(){
		// if(this.dataList.perPage)
		// 	this.perPage = this.dataList.perPage
		// else{
		// 	this.perPage = manageScreenDisplay()
		// 	if(this.dataList.is_in_tab)
		// 		this.perPage =  this.perPage - 5
		// }
		this.allHeaders = _.cloneDeep(this.dataList.headers)
		this.visibleCols = _.cloneDeep(this.dataList.headers)
	},
	methods:{
		manageSelected(newValue){
			this.dataList.selected_rows = newValue
			let selectedForEmit = _.cloneDeep(newValue)
			if(this.dataList.allPagesRowSelection)
				selectedForEmit = _.cloneDeep(this.dataList.allPagesRowSelectionList)
			this.$emit('onRowSelected',selectedForEmit,this.dataList.unique_id)
			this.gridActionObject = _.cloneDeep(this.dataList)
      // logic for show icons properly.
      this.gridActionObject.actions = []
      if(newValue.length === 0)
      	return false

			var actions = _.cloneDeep(this.dataList.actions)

			// every action should have the index
			if(actions.length > 0 && !actions[0].index)
			return

			actions = _.sortBy(_.cloneDeep(this.dataList.actions),"index")


			var row = _.cloneDeep(newValue[0])
			// Every action json should contain the 'key' key.Cheack the same.
			var action_with_key = _.every(actions,'key')

			// if not every action json contain the 'key' key then return
			if(!action_with_key)
			return false

			// logic for single selected row
			if(newValue.length === 1){
			// now we have to show only that icons which has 'key' and cmp value match (if it has cmp value)
		  var valid_actions = []
		  for (var i = 0; i < actions.length; i++) {
		  	var current_action = actions[i]

	      // continue new action
	      if(current_action.text === 'new' || current_action.text === 'newWidOptions')
	      	continue

	      // grid download icon
	      if(current_action.text === 'GridDownload'){
	          // valid_actions.push(current_action)
	          continue
	        }

		    // now check key has cmpValue column
	      var key = current_action.key
	      if(row[key]){

	          // now check is that action has cmp value key
	          if(current_action.cmpValue){
	          	if(current_action['cmpValue'] === row[key])
	          		valid_actions.push(current_action)
	          	continue
	          }
	          valid_actions.push(current_action)
	        }
	      }
	      this.ActionPanelremountTime++
	      this.gridActionObject.actions = _.cloneDeep(valid_actions)
	    }
	    else{
	    	this.gridActionObject.actions = []
	    	var multiple_actions = []
	    	for (var i = 0; i < actions.length; i++) {
	    		var current_action = actions[i]
	    		if (current_action.selectType  === 'multiple'){
	    			if(current_action['cmpValue']){
	    				let key = current_action['key']
	    				let cmpValueMatchedArray = _.filter(newValue,function(obj){
	    					return obj[key] === current_action['cmpValue']
	    				})
	    				if(cmpValueMatchedArray.length!=newValue.length)
	    					continue
	    			}
	    			multiple_actions.push(current_action)
	    		}
	    	}
	    	this.ActionPanelremountTime++
	    	this.gridActionObject.actions = _.cloneDeep(multiple_actions)
	    }
		},

		displayCriteriaPopUp(onlyAddFilters){
			var _this = this
			if(this.columnList.length === 0 && this.dataList.headers.length > 0){
				_.forEach(_this.dataList.headers,function(obj){
					_this.columnList.push({'column_name':obj.text,"column_actual_name":obj.value,"edcdataType":obj.dataType})
				})
			}
			// if(_this.oldSelectedChips.length === _this.selectChips.length)
			if(!onlyAddFilters)
				this.showCriteria = true
		},
		getNewObjWidOpt: function(){
			this.newWidOption = _.find(this.dataList.actions, ['text', 'newWidOptions']);
			if(this.newWidOption){
				if(this.newWidOption.isJobPlan){
					this.newMenu=[
  							{title:'Add Job', event:'onAddJob'},
		  					{title:'Add Job Plan', event:'addJobPlan'}]
				}
				else{
					this.newMenu=[
  							{title:'Create Business Object', event:'createBusinessDoc'},
		  					{title:'Create Process Definition', event:'createProcessDoc'}]
				}
				return this.newWidOption
			}
		},
		manageCriteria(updated_criteria){
			this.updatedFilter = updated_criteria
		},
		saveCriteria(){
			this.search = ''
			var _this = this
			var search_string = ''
			_this.selectChips = []
			_this.oldSelectedChips = []

			// reshuffle the filters set by user.For example all same name columns will come as toghether with OR and different name will come as AND.
			var updatedFilter_length = _this.updatedFilter.length
			if( updatedFilter_length > 0){
				// first findout all same name columns

				// var updatedFilterCopy = _this.getRevisedFilter()


				// _this.updatedFilter = updatedFilterCopy

				_.forEach(_this.updatedFilter,function(obj,index){

					// need to add operation, incase of multiple filter
					// if(updatedFilter_length > 1 && index!=(updatedFilter_length-1)){
					// 	obj['operation'] = false // autobind and condtion.
					// }

					if(obj.column_name && obj.operator){
						search_string = _this.selectChips.push(obj.column_name + " "+ _this.getOperatorNameById(obj.operator,obj.value1,obj.value2))

						// // in case of no last row , obj.operator will be undefined
						// if(obj.operation === true || obj.operation === false)
						// {
						// 	var operation = obj.operation?" OR ": " AND "
						// 	search_string = search_string + operation
						// }

					}
				})
			}
			_this.oldSelectedChips = _this.selectChips
			_this.search = search_string
			_this.showCriteria = false

			let new_merged_filter_list = _this.getRevisedFilter()
			// emit updateFilter
			_this.onUpdateFilter(new_merged_filter_list)

		},
		getOperatorNameById(operator,value1,value2)
		{
			if(operator === '_eq_')
				return "= "+ value1
			if(operator === '_not_eq_')
				return "!= "+value1
			if(operator === '_lt_')
				return "< "+value1
			if(operator === '_gt_')
				return "> "+value1
			if(operator === '_lt_eq_')
				return "<= "+value1
			if(operator === '_gt_eq_')
				return ">= "+value1
			if(operator === '_is_n_')
				return "Is null"
			if(operator === '_is_nt_n_')
				return "In not Null"
			if(operator === '_sl_')
				return "like "+value1+"%"
			if(operator === '_el_')
				return "like %"+value1
			if(operator === '_cl_')
				return "like %"+value1+"%"
			if(operator === '_bet_')
				return "between "+value1+" AND "+value2
			if(operator === '_in_')
				return "in ("+value1+")"
			if(operator === '_n_in_')
				return "not in ("+value1+")"

		},
		onUpdateFilter(filter_list){
			if(!filter_list)
				filter_list = this.updatedFilter
			if(this.dataList.filterType === SERVER_SIDE){
				this.$emit('UpdateFilter', _.cloneDeep(filter_list),1)
				this.page = 1
			}
			else
				this.onClientFilter(_.cloneDeep(filter_list))

			// console.log(this.updatedFilter)
		},
		setDate(obj, key, param){
			obj[key] = param;
		},
		onClientFilter(filterData){
			var new_rows = this.dataList.rows
			_.forEach(filterData,function(filterObj,index){
				let column_name = filterObj.column_name
				let operator = filterObj.operator
				let value1 = filterObj.value1
				if(!value1)
					value1 = ''
				let operation = filterObj.operation // true means OR , false means AND
				let temp_rows = _.filter(new_rows,function(rowobj){
					if(operator === '_eq_')
						return rowobj[column_name] == value1 || rowobj[column_name].toString().toLowerCase() == value1.toString().toLowerCase()
					else if(operator === '_not_eq_')
						return rowobj[column_name] != value1
					else if(operator === '_lt_')
						return rowobj[column_name] < value1
					else if(operator === '_gt_')
						return rowobj[column_name] > value1
					else if(operator === '_lt_eq_')
						return rowobj[column_name] <= value1
					else if(operator === '_gt_eq_')
						return rowobj[column_name] >= value1
					else if(operator === '_is_n_')
						return rowobj[column_name] == 'N/A'
					else if(operator === '_is_nt_n_')
						return rowobj[column_name] != 'N/A'
					else if(operator === '_sl_')
						return rowobj[column_name] && (rowobj[column_name].startsWith(value1) || rowobj[column_name].toLowerCase().startsWith(value1.toLowerCase()))
					else if(operator === '_el_')
						return rowobj[column_name] && (rowobj[column_name].endsWith(value1) || rowobj[column_name].toLowerCase().endsWith(value1.toLowerCase()))
					else if(operator === '_cl_')
						return rowobj[column_name] && (rowobj[column_name].includes(value1) || rowobj[column_name].toLowerCase().includes(value1.toLowerCase()))
				})
				new_rows = temp_rows
			})
			this.dataList.total_count = new_rows.length
			this.dataList.clientFilteredRows = new_rows
			this.page = 1
			this.performClientSidePagination(new_rows)
		},
		onPageChange(number){
			this.highlightedRows = []
			if(this.dataList.paginationType === SERVER_SIDE)
				this.$emit('onPageChange', number,this.perPage)
			else
				this.performClientSidePagination()
		},
		onPerPageChange(number){
			this.page = 1
			if(this.dataList.paginationType === SERVER_SIDE)
				this.$emit('onPerPageChange', number,this.page)
			else
				this.performClientSidePagination()
		},
		onSort(sortJson){
			if(!sortJson)
				sortJson = []
			if(this.dataList.sorting_type === SERVER_SIDE){
				this.$emit('onSort', sortJson)
			}
			else {
				let sort_cols=[]
				let sort_types = []
				let sorteddatarows = []
				for (var i = 0; i < sortJson.length; i++) {
					// in case of some numeric values, we are doing formatting. But for user, still its an
					// numeric field. So in that case we are passing extra paramter in the header json
					// as a sortColumn. If sort column exists add that column into sort cols else add actual
					// column as it is.
					let columnHeader = _.find(this.dataList.headers,["value",sortJson[i].column_name])
					if(columnHeader && columnHeader.sortColumn)
						sort_cols.push(columnHeader.sortColumn)
					else
						sort_cols.push(sortJson[i].column_name)
					sort_types.push(sortJson[i].type)
				}
				if(sort_cols.length > 0){
					let displayed_rows = this.dataList.rows
					if(this.dataList.clientFilteredRows)
						displayed_rows = this.dataList.clientFilteredRows
					sorteddatarows = _.orderBy(displayed_rows,sort_cols,sort_types)
					this.performClientSidePagination(sorteddatarows)
				}
				else
					this.performClientSidePagination()
			}
		},
		performClientSidePagination(rows){
			if(!rows)
			{
				if(this.dataList.clientFilteredRows)
					rows = this.dataList.clientFilteredRows
				else
					rows = this.dataList.rows
			}
			if(this.page <= 1 || rows.length < this.perPage)
				this.rowsToDisplay= rows.slice(0,this.perPage)
			else{
				let data_from = (this.page - 1)* this.perPage
				let data_to = this.page * this.perPage
				this.rowsToDisplay = rows.slice(data_from,data_to)
			}
		},
		chipDeleted(item,index){
			var _this = this
	  		/* item deleted from selection chip.
				find out index of deleted item and remove same item from the _this.updatedFilter.
				Then emit the UpdateFilter function
				*/
  				// delete from _this.updatedFilter
  				_this.updatedFilter.splice(index,1)
  				_this.selectChips.splice(index,1)
  				// remove operation key from last filter row
  				if(_this.updatedFilter.length > 0)
  					delete _this.updatedFilter[_this.updatedFilter.length - 1].operation

  				// reasign selectedChips to oldChips

  				let new_merged_filter_list = _this.getRevisedFilter()

  				// emit updateFilter
  				_this.onUpdateFilter(new_merged_filter_list)

  			},
  			getRevisedFilter(){
  				var _this = this
  				var updatedFilterCopy = []

  				var new_merged_filter_list = _.cloneDeep(_this.updatedFilter)
  				new_merged_filter_list = new_merged_filter_list.concat(_.cloneDeep(_this.inlineFilterList))

  				if(new_merged_filter_list.length === 0)
  					return updatedFilterCopy
  				var columns = _.uniq(_.map(new_merged_filter_list,"column_name"))
  				for(let i=0;i<columns.length;i++){
					var columnHeaderObj = _.find(_this.dataList.headers,['column_name',columns[i]])
					var column_other_details = _.find(this.columnList,["column_name",columns[i]])
					if(new_merged_filter_list[i].isInlineFilter){
						//get operator from filter value
						var is_op = commonFuncObj.separate_operator(new_merged_filter_list[i].actual_value1, true)
						//logic for null. If user put only = in the inline filter it means he/she want to fetch null value
						if(new_merged_filter_list[i].actual_value1 === '='){
							new_merged_filter_list[i]['operator'] =  '_is_n_'
							new_merged_filter_list[i]['value1'] = ''
						}

						//logic for not null. If user put only != or <> in the inline filter it means he/she want to fetch not null value
						else if(new_merged_filter_list[i].actual_value1 === '<>' || new_merged_filter_list[i].actual_value1 === '!='){
							new_merged_filter_list[i]['operator'] =  '_is_nt_n_'
							new_merged_filter_list[i]['value1'] = ''
						}
						//logic for contains , start with and end with
						else if (new_merged_filter_list[i].actual_value1.includes('%'))
						{
							let indx_percent = new_merged_filter_list[i].value1.indexOf('%')
							let operator_count = (new_merged_filter_list[i].value1.split("%").length - 1)
							//when count is more than 1 for % apply conatains
							if(operator_count > 1){
								new_merged_filter_list[i]['operator'] =  this.operator_to_edc_operator['%xx%']
							}
							//when index of %  is o apply ends with
							else if(indx_percent == 0){
								new_merged_filter_list[i]['operator'] =  this.operator_to_edc_operator['%xx']
							}
							//when % is last character in filter apply starts with
							else if(indx_percent === new_merged_filter_list[i]['value1'].length-1){
								new_merged_filter_list[i]['operator'] =  this.operator_to_edc_operator['xx%']
							}

							new_merged_filter_list[i]['value1'] = ""+new_merged_filter_list[i].value1.replace('%', '')
						}
						//logic for anyother operator except %
						else if (this.operator_to_edc_operator[is_op]){
							new_merged_filter_list[i]['operator']=  this.operator_to_edc_operator[is_op]
							new_merged_filter_list[i]['value1'] = new_merged_filter_list[i]['value1'].replace(is_op, '')
						}


						// here we have to write code to manage worktable filters -> date and number
						if(this.isGridForWorkTable){
							if(columnHeaderObj){
								if(JDEFormatterObj.isJDEDate(columnHeaderObj.column_type)){
									new_merged_filter_list[i]['value1'] = getJDEDate(new_merged_filter_list[i]['value1'], this.$session.get('UI_date_format'))
								}
								else if(commonFuncObj.isNumericDataType(columnHeaderObj.data_type)){
									new_merged_filter_list[i]['value1'] =commonFuncObj.replaceText(new_merged_filter_list[i]['value1'])
								}
							}
						}
					}
  					var dataType = ''
  					var column_actual_name = ''
  					if(column_other_details){
  						column_actual_name = column_other_details.column_actual_name
  						dataType = column_other_details.edcdataType
  					}
  					else
  						column_actual_name = columns[i]
						var firstFilter = true // to add parenthesis on first same column filter
						for(let j=0;j<new_merged_filter_list.length;j++){
							var currentFilter = _.cloneDeep(new_merged_filter_list[j])
							currentFilter['s_parentheses'] = ''
							currentFilter['e_parentheses'] = ''
							delete currentFilter.operation
							if(currentFilter.column_name === columns[i]){
								if(firstFilter){
									currentFilter['s_parentheses'] = '('
									firstFilter =false
								}
								currentFilter['operation'] = true // autobind OR between same column
								currentFilter['column_name'] = column_actual_name

								if(dataType === 'date'){
									if(currentFilter['value1'] && !currentFilter['isInlineFilter']){
										currentFilter['datedetails1']={
											format:this.$session.get('date_format'),
											value:currentFilter['value1']
										}
									}
									if(currentFilter['value1'] && is_op){
										currentFilter['datedetails1']={
											format:this.$session.get('date_format'),
											value:currentFilter['value1']
										}
									}
									if(currentFilter['value2']){
										currentFilter['datedetails2']={
											format:this.$session.get('date_format'),
											value:currentFilter['value2']
										}
									}
								}
								updatedFilterCopy.push(currentFilter)
							}
						}
						if(updatedFilterCopy.length > 0){
						updatedFilterCopy[updatedFilterCopy.length - 1]['operation'] = false // autobind AND between two column
						updatedFilterCopy[updatedFilterCopy.length - 1]['e_parentheses'] = ')'
					}
				}
			// remove operation from last filter
			delete updatedFilterCopy[updatedFilterCopy.length - 1].operation
			return updatedFilterCopy
		},
		updateTableOptions(options){
			var sortByJson = []
			if(options.sortBy.length > 0 && options.sortDesc.length > 0){
				this.clickOnSort = true
				for (var i = 0; i<options.sortBy.length; i++) {
					let data = {}
					data['column_name'] = options.sortBy[i]
					data['type'] =options.sortDesc[i]? "desc":"asc"
					sortByJson.push(data)
				}
			}
			if(this.clickOnSort)
				this.onSort(sortByJson)
		},
		hideDataGridColumns(){
			var dnone = this.hideColProp
    	// this.dataList.headers = []
    	for(let i=0;i<this.dataList.headers.length;i++){
    		if(this.dataList.headers[i]['align'] != dnone)
    			this.dataList.headers[i]['default_align'] = this.dataList.headers[i]['align']? this.dataList.headers[i]['align'] : 'left'
    		let obj = _.find(this.visibleCols,["text",this.dataList.headers[i].text])
    		if(!obj){
    			this.dataList.headers[i]['align'] = dnone
    		}
    		else{
    			if(this.dataList.headers[i]['default_align'])
    				this.dataList.headers[i]['align'] = this.dataList.headers[i]['default_align']
    		}
    	}
    },
    getFormatedData(header,text,item,is_key_json){
    	if(!text && text != 0){
    		if(is_key_json){
    			let actual_key = header.value
    			let keys = actual_key.split('.')
    			/* Here I am considering only two keys for timebieing*/
    			text = item[keys[0]][keys[1]]
    		}
    		else
    			return 'N/A'
    	}
    	// this specific validation is to remove transaction id from row count column in sql grid
    	if(header.validate === 'number'){
    		if(isNaN(text))
    			return ''
    	}
    	if(!this.dataList.useWrapping){
			if(header.display_text_key)
				return item[header.display_text_key] 
    		return text
		}
    	let new_text = text
    	let isDate = false
    	if(!isNaN(text)){
    		if(header.column_type === 'DATEW' || header.column_type === 'DATEG')
    		{
    			// its juliean date. Now need to convert into normal date

    			let days = (text%1000) - 1
    			let years = parseInt(text/1000) + 1900
    			let convertedDate = moment(years+"01-01","YYYY-MM-DD")
    			new_text = convertedDate.add('days',days).format("YYYY-MM-DD")
    			header['align'] = 'left'
    		}
    		return new_text
    	}
    	let header_text = header.text
    	new_text = new_text.replace(/\n/g, '').trim()
    	if((header_text.length+2) < new_text.length){
    		let string_to_return = new_text.substring(0,header_text.length-1)
    		return string_to_return+'..'
    	}

    	// if((new_text.length+2) > 12){
    	// 	let string_to_return = new_text.substring(0,11)
    	// 	return string_to_return+'..'
    	// }
    	else
    		return new_text
    },
    getColTooltips(headertext){
    	let _this = this
    	let description = ''
    	let obj = _.find(_this.dataList.headers,["text",headertext])
    	if(obj && obj.title)
    		return obj.title
    	return headertext
    },
    onDownload(downloadParams){
    	this.$emit('onDownload',downloadParams,_.cloneDeep(this.updatedFilter))
    },
    createEvent(recordType,eventName, defaultVal){
			var _this = this
    	let record=(recordType == 'single') ? _.cloneDeep(this.selected[0]):this.selected;
    	if(eventName == 'onEdit' && this.dataList.is_grid_inline_edit){
    		let key = this.dataList.itemkey
    		let obj = _.find(this.dataList.rows,[key,record[key]])
    		if(obj){
    			// let index = _.indexOf(this.dataList.rows,obj)
    			obj.is_row_editable = true
    			this.AddRowForGridEditing(obj)
    			// this.$emit('onValueUpdate',record)
    		}

    	}
    	else {
				if(this.isCompressionApplicable){
					_this.compressVeritcalSpace = true
					_this.rowsToDisplay = _this.selected
				}
    		this.$emit(eventName, record, defaultVal);
    		if(this.dataList.is_grid_inline_edit) // to overcome select all rows default.Please check if condition in datalist.row and rowTodisplay watch
    			this.selected = []
    	}
    },
    onCancelEditing(record){
    	this.$emit('onCancel', record);
    	this.selected = []
    },
    getColor(chipFor,value){
    	if(!chipFor || !value)
    		return 'grey'
    	if(chipFor === 'status'){
    		return GRID_STATUS_COLOR_CODE[value.toLowerCase()]
    	}
    },
    validateInput(event,inputType){
    	if(!inputType)
    		return true
    	var keycode = event.which
    	if(inputType === 'number'){
    		if(isValidNumber(keycode))
    			return true

    	}

    	event.preventDefault()
    },
    radioSelectionChange(record){
		this.highlightedRows = []
    	this.$emit('radioSelectionChange', record);
    },
    copyToClipboard(event,header){
		if(!header.copyToClipboard)
			return
		var source = event.srcElement
		var textArea = document.createElement("textarea");
		textArea.value = source.textContent;
    	document.body.appendChild(textArea);
    	textArea.select();
    	document.execCommand("Copy");
    	textArea.remove();
    },
	copyDataToClipboard(copiedString){
		var _this = this
		_this.$copyText(copiedString).then(function (e) {
			_this.$emit('copyDataToClipboard','success')
			console.log(e)
		}, function (e) {
			_this.$emit('copyDataToClipboard','error')
			console.log(e)
		})
	},
    changeAutoCompleteSelection(event_value,item,header,headerindex,itemindex){
    	this.AddRowForGridEditing(item)
    	
		this.$emit('onValueUpdate',event_value,item,header,headerindex,itemindex)
			
	},
	changeAdvanceItemSelection(itemValue,item,header,headerindex,itemindex){
		Object.assign(item,itemValue)
		this.AddRowForGridEditing(item)
		this.$emit('onValueUpdate',itemValue[header.value],item,header,headerindex,itemindex)
	},
		AddRowForGridEditing(item,header,itemindex){
			if(this.dataList.hideSelect)
				return
			this.$nextTick(function () {
				this.$set(this.selected, 0, item);
			})
			if(header && header.isPolicy){
				this.$emit('calculatePolicy',this.rowsToDisplay[itemindex],itemindex)
			}
		},
		rowDoubleClick(record,recordIndex){
			if(!this.dataList.is_grid_inline_edit)
				this.$emit('ondblClick',record,recordIndex)
			else
			{
				// check any table already in edit or not. If then do not do nothing
				let isInEdit = _.find(this.dataList.rows,['is_row_editable',true])
				if(!isInEdit){
					this.selected = [record]
					this.createEvent('single','onEdit')
				}
				else{
					this.AddRowForGridEditing(isInEdit)
				}
			}
		},
		test(event){
			event.preventDefault()
			// alert('you clicked me')
		},
		getIndeterminateState(header,item){
			// if(!hea)
		},
		setDisabled(header,item){
			if(!this.dataList.caption)
				return false
			if(this.dataList.caption ==="adminRole" || this.dataList.caption ==="envRole" || this.dataList.caption ==="notificationGroup"){
				let service_actions = item.service_actions
				if(header.text === 'All')
					return false
				if(service_actions.indexOf(header.value)>-1)
					return false
			}
			return true
		},
		checkChanged(event_value,header,item){
			if(!header.toggleRow || !this.dataList.caption)
				return
			if(this.dataList.caption ==="adminRole"||this.dataList.caption ==="envRole" || this.dataList.caption ==="notificationGroup"){
					let service_actions = item.service_actions
				if(header.text === 'All'){
					if(!event_value) // this event_value come as a null.make it proper boolean
						event_value = false
					service_actions.forEach(function(obj){
						item[obj] = event_value
					})
					return
				}
				else{
					let atleastOneChecked=false
					let atleastOneUnchecked=false
					// let obj = _.find(this.dataList.rows,["text","All"])
					// if(!obj)
					// 	return
					service_actions.forEach(function(obj){
						if(!atleastOneChecked){
							if(item[obj])
								atleastOneChecked = true
						}
						if(!atleastOneUnchecked){
							if(!item[obj])
									atleastOneUnchecked = true
						}
					})
					// if(!atleastOneChecked && !atleastOneUnchecked){
					// 	item.useIndeterminateState = false
					// }
					// if(atleastOneChecked && atleastOneUnchecked)
					// {
					// 	item.useIndeterminateState = true
					// }
					item.all = false
					if(atleastOneChecked)
					{
						let default_actions = item.default_actions
						default_actions.forEach(function(obj){
							item[obj] = true
						})
					}
					if(atleastOneChecked && !atleastOneUnchecked){
						item.all = true
					}

				}
			}
		},
		applyInlineFilter(event,header,filtertext,fromLostFocus){
			if(!filtertext)
				return
			let column_name = header.value

			let removed_obj = _.remove(this.inlineFilterList,function(obj){
				return obj.column_actual_name === column_name
			})

			let filterjson= {"column_name":header.text,"column_actual_name":header.value,"value1":filtertext,"operator":"_cl_","hideKey":true,'isInlineFilter':true}
			if(header.dataType === 'int' || header.dataType === 'date' || header.dataType === 'boolean' || header.align === 'right')
				filterjson['operator'] = "_eq_"
			if(header.dataType === 'date'){
				filterjson['datedetails1']={
					format:this.$session.get('date_format'),
					value:filtertext
				}
			}
			filterjson['actual_value1'] = header.filtertext
			this.displayCriteriaPopUp(true)
			// this.updatedFilter.push(_.cloneDeep(filterjson))
			this.inlineFilterList.push(_.cloneDeep(filterjson))
			// header.filtertext = ""
			// On enter, reapply all the inline filters value. For example, a user can type inline filters for 3 different columns but he will press Enter on the last filter text box. Expected behaviour is all 3 filters will get applied to the grid.
			if(fromLostFocus)
				return
			this.saveCriteria()
		},
		clearInlineFilter(event,header,filtertext){
			let column_name = header.value
			let value1 = filtertext
			// let removed_obj = _.remove(this.updatedFilter,function(obj){
			// 	return obj.column_name === column_name && obj.value1 === value1
			// })
			let removed_obj = _.remove(this.inlineFilterList,function(obj){
				return obj.column_actual_name === column_name && obj.value1 === value1
			})
			header.filtertext = ''
			this.saveCriteria()
		},
		keyUpInlineFilter(event,header,filtertext){
			// 8 backspace, 46 delete
			if((event.keyCode === 8 || event.keyCode===46) && !filtertext){
				let column_name = header.value
				let removed_obj = _.remove(this.inlineFilterList,function(obj){
				return obj.column_actual_name === column_name
			})
			this.saveCriteria()
			}
		},
		isChipShow(index){
			if(this.updatedFilter.length < index || this.updatedFilter[index].hideKey)
				return false
			return true
		},
		rowSelectionChanged(row,index){
			if(this.isCompressionApplicable)
				return this.manageCompression();
			if(!this.dataList.itemkey || !this.dataList.allPagesRowSelection || !row[this.dataList.itemkey])
				return

			// here logic is if row's item key present in the allPagesRowSelectionList then remove it else add it
			if(this.dataList.allPagesRowSelectionList.indexOf(row)>-1)
				this.dataList.allPagesRowSelectionList.splice(row,1)
			else
				this.dataList.allPagesRowSelectionList.push(row)
		},
		manageCompression(){
			if(!this.isCompressionApplicable)
				return ;
			if(this.selected.length === 0){
				this.compressVeritcalSpace = false
				this.rowsToDisplay = this.dataList.rows
				this.highlightedRows = []
				this.$emit('clearDrillDown')
				return
			}
		},
		manageHighLight(event, index, item){
			if(item.is_row_editable)
				return
			if(this.highlightedRows.indexOf(index)===-1){
				// if(event.ctrlKey)
					this.highlightedRows.push(index)
				// else
					// this.highlightedRows = [index]
			}
			else
				this.highlightedRows.splice(this.highlightedRows.indexOf(index),1)
		},
		isRowFullfillLinkCriteria(header,record){
			if(!header.isLinkCell ||_.isEmpty(header.linkEvaluationObj) || _.isEmpty(record))
					return false;
			else{
					let linkEvaluationObj = header.linkEvaluationObj
					if(linkEvaluationObj.isConditional)
						// not all cells going to have a link
						return linkEvaluationObj.expectedValues.indexOf(record[linkEvaluationObj.cmpheader])>-1
					// all cells are going to have a link
					return true;
			}
		},
		linkCellClicked(event,header,record){
			this.$emit("linkCellClicked",event,header,record)
		},
		autoCompleteFocused(header){
			alert(header.value)
		},
		performSearchInAdanceAutoComplete(searchValue, header, item, headerindex, itemindex){
			this.$emit('performSearchInHeader',searchValue, header, item, headerindex, itemindex)
		},
		isAdvanceOptionShow(item){
			if(this.dataList.isGridForCreateView){
				if(item.isAllSelected || item.selectedTables.length !=1)
					return false
				return true		
			}
			return false
		},
		advancePopupInitiating(item,header){
			this.$emit('advancePopupInitiating', item,header)
		},
		saveAdvanceRowOption(itemValue,item, header){
			Object.assign(item,itemValue)
			if(header.emitSaveOnSaveAdvanceOption)
				this.$emit('onSave',item)
		}
	}
}
</script>
<style scoped>
.moreInfoTD{
	padding:8px !important;
}
</style>
<style>
th{
	white-space: nowrap;
	overflow: hidden;
	text-overflow:ellipsis;
	font-size: 12px !important;
}

td{
	white-space: nowrap;
	overflow: hidden;
	text-overflow:ellipsis;
	font-size: 12px !important;
}

tbody tr:nth-of-type(even) {
	background-color:var(--v-datatablealternate-base) !important;
}

.v-pagination__item{
	font-size: 12px !important;
}

.EDCTableFooter {
	font-size: 12px !important;
}

.v-select__selection{
	font-size: 12px !important;
}
td {
	border:none !important;
}

.EDC-VCardColumn{
	height: 60px !important;
	padding: 0px !important;
	margin: 0px !important;
	width: 250px !important;
	overflow: none !important;
}

.EDC-VCombobox{
	display: inline-block !important;
	padding: 0px !important;
	margin: 0px !important;
	width: 250px !important;
}

.v-input--selection-controls{
    margin-top:0px !important;
    padding-top: 0px !important;
}

/*.v-text-field {
	padding-top: 0px !important;
	margin-top: 0px !important;
}*/

table {
	border-top:1px var(--v-secondary-lighten3) solid;
}

table {
	color:var(--v-apptext-base) !important;
}
table .theme--light.v-icon{
	color:var(--v-primary-base) !important;
}

.hideCol {
	display:none;
}

.keep-spaces { white-space: pre-wrap; }

/*
*  STYLE 2
*/

#style-2::-webkit-scrollbar-track
{
	-webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.1) !important;
	border-radius: 10px !important;
	background-color: white !important;
}

#style-2::-webkit-scrollbar
{
	width: 5px !important;
	background-color: white !important;
}

#style-2::-webkit-scrollbar-thumb
{
	border-radius: 10px !important;
	background-color: #DCDCDC !important;

}

.scrollbar
{
	background: white !important;
	overflow-y: hidden !important;
	margin-bottom: 25px !important;
	overflow-x:  scroll !important;
}
.v-sheet {
	border-radius: 5px !important;
}

.v-text-field--rounded > .v-input__control > .v-input__slot{
	padding-left:3px !important;
	padding-top:0px !important;
	padding-right:3px !important;
	padding-bottom:0px !important;
	height:20px !important;
}

.v-text-field--rounded {
	border: 2px solid;
    border-radius: 5px;
    border-color: var(--v-primary-base) !important;
    margin-left: 0px !important;
    margin-right: 0px !important;
    margin-top: 1px !important;
    margin-bottom: 1px !important;
}

.v-text-field--rounded > .v-input__control > .v-input__slot > .v-text-field__slot {
	min-width:16px !important;
}
.v-text-field--rounded > .v-input__control > .v-input__slot > .v-input__append-inner{
	margin-top:0px !important;
}

.v-text-field--rounded > .v-input__control > .v-input__slot > .v-input__append-inner> .v-input__icon{
	height:20px !important;
}
.v-text-field--rounded > .v-input__control > .v-input__slot > .v-input__append-inner> .v-input__icon > .v-icon.v-icon{
	font-size:16px !important;
}

.grid-cell-link{
	text-decoration: underline !important;
	cursor:pointer !important;
}

</style>
