<template>
    <v-container class="EDC-Row">
        <v-card class="EDC-expression-card">
            <v-row class="EDC-Row">
                <!-- <v-col class="EDC-Padding-4">
                    <v-row class="EDC-Row">
                        <v-col class="EDC-Padding-4" cols="3">
                            <v-text-field class="EDC-Control-Vertical-Padding" dense label="Expression Name" v-model="expressionName"
                             hide-details="auto" style="margin-top:0px !important;"></v-text-field>
                        </v-col>
                        <v-col class="EDC-Padding-4" cols="3">
                            <v-autocomplete class="EDC-Control-Vertical-Padding" :items="expressionForOptions" label="Expression For" 
                            item-text="name" item-value="id" v-model="expressionFor" dense hide-details="auto"></v-autocomplete>
                        </v-col>
                    </v-row>
                    <v-row class="EDC-Row" v-if="isExpressionForDate">
                        <v-col class="EDC-Col">
                            <date-expression :columnList="availableColumns" key="dateliteral"></date-expression>
                        </v-col>
                    </v-row>
                    <v-row class="EDC-Row">
                        <v-col class="EDC-Col">
                            <vc-button itemText="Save Expression" :disabled="!expressionName || !expressionFor || readonly" @click.native="saveExpression()"></vc-button>
                            <vc-button itemText="Cancel" @click.native="$emit('closeExpression')"></vc-button>
                        </v-col>
                    </v-row>
                </v-col> -->
                <v-col class="EDC-Padding-4">
                    <v-row class="EDC-Row">
                        <v-col class="EDC-Padding-4">
                            <v-textarea outlined v-model="expresionDesigner" dense hide-details="auto" @keyup="onKeyUp" ref="expressionBulder">
                                
                            </v-textarea>
                        </v-col>
                    </v-row>
                    <v-row class="EDC-Row">
                        <v-col class="EDC-Padding-4">
                            <v-menu v-model="showFunc" :position-x="positionX" :position-y="positionY" absoluteoffset-y>
                                <v-list two-lines>
                                    <v-list-item dense
                                    v-for="(item, index) in getFunctionsList"
                                    :key="index" style="pointer:cursor;" @click="setExprssion(item,false)"
                                    >
                                    <v-list-item-content>
                                        <v-list-item-title>{{ item.id }}</v-list-item-title>
                                        <v-list-item-title>{{ item.id }}({{item.expression}})</v-list-item-title>
                                        <v-list-item-subtitle>{{ item.name }}</v-list-item-subtitle>
                                    </v-list-item-content>
                                    </v-list-item>
                                </v-list>
                            </v-menu>
                        </v-col>
                    </v-row>
                    <v-row class="EDC-Row">
                        <v-col class="EDC-Padding-4" cols="3">
                            <v-autocomplete class="EDC-Control-Vertical-Padding" :items="expressionForOptions" label="Expression For" 
                            item-text="name" item-value="id" v-model="expressionFor" dense hide-details="auto"></v-autocomplete>
                        </v-col>
                        <v-col class="EDC-Padding-4" cols="3">
                            <v-autocomplete class="EDC-Control-Vertical-Padding" :items="getExpressionListByExpressionType" label="Expression For" 
                            item-text="name" item-value="id" v-model="selectedExpressionFunc" @input="setExprssion(selectedExpressionFunc)" dense hide-details="auto" return-object></v-autocomplete>
                        </v-col>
                        <v-col class="EDC-Padding-4" cols="3">
                            <v-text-field class="EDC-Control-Vertical-Padding" dense label="Expression Name" v-model="expressionName"
                             hide-details="auto" style="margin-top:0px !important;"></v-text-field>
                        </v-col>
                    </v-row>
                    <v-row class="EDC-Row">
                        <v-col class="EDC-Padding-4" align="end">
                            <vc-button itemText="Save Expression" :disabled="!expressionName || !expressionFor || readonly" @click.native="saveExpression()"></vc-button>
                            <vc-button itemText="Cancel" @click.native="$emit('closeExpression')"></vc-button>
                        </v-col>
                    </v-row>
                </v-col>
            </v-row>
        </v-card>
    </v-container>
</template>
<style scoped>
    .EDC-expression-card{
        min-height: 48vh;
        margin: 8px !important;
        
    }
</style>
<script>
    // import dateExpression from './dateExpression.vue'
    import {expressionObjFormat} from './../../constants/constants.js'
    export default{
        name:'EDCExpressionBuilder',
        components:{
            // 'date-expression':dateExpression
        },
        props:{
            availableColumns:{
                type:Array,
                default:()=>{
                    return []
                }
            },
            expressionObj:{
                type:Object,
                default:()=>{
                    return {}
                }
            },
            newDraggedObj:{
                type:Object,
                default:()=>{
                    return {}
                }
            }
        },
        data(){
            return{
                expressionName:'',
                expressionForOptions:[
                    {"name":"Date & Time",'id':'datetime'},
                    {"name":"Arthmatic Operations",'id':'arthmatic'},
                    {"name":"String",'id':'string'}
                ],
                expressionFor:'',
                readonly:false,
                showFunc:false,
                positionX:0,
                positionY:0,
                expresionDesigner:'',
                selectedExpressionFunc:{},
                currentExpressionObj:_.cloneDeep(expressionObjFormat)
            }
        },
        watch:{
            'availableColumns':{
                handler(newValue){

                }
            },
            'newDraggedObj':{
                handler(newValue){
                    this.manageDraggedObject(_.cloneDeep(newValue))
                }
            }
        },
        computed: {
            isExpressionForDate(){
                return this.expressionFor === 'datetime'
            },
            isExpressionForArtmatic(){
                return this.expressionFor === 'arthmatic'
            },
            getExpressionListByExpressionType(){
                if(this.isExpressionForDate)
                    return this.getAvailableDateFunctions
                else
                    return []
            },
            getAvailableDateFunctions(){
                return [
                    {'name':'Convert JDE Date To Standard Date','id':'JdeDateToNormalDate','expression_for':'datetime',  'expression':"<<jdedate>>", 'showdateformatoption':true},
                    {'name':'Convert Standard Date To JDE Date','id':'NormalDateToJDEDate','expression_for':'datetime',  'expression':"<<normaldate>>",'showdatepicker':true},
                    {'name':'Add Day To JDE Date','id':'AddDayToJDEDate','expression_for':'datetime',  'expression':"<<jdedate>>,interval",'needrightsideval':true,'showdateformatoption':true},
                    {'name':'Add Month To JDE Date','id':'AddMonthToJDEDate','expression_for':'datetime',  'expression':"<<jdedate>>,interval",'needrightsideval':true,'showdateformatoption':true},
                    {'name':'Add Year To JDE Date','id':'AddYearToJDEDate','expression_for':'datetime',  'expression':"<<jdedate>>,interval",'needrightsideval':true,'showdateformatoption':true},
                    {'name':'Substract Day From JDE Date','id':'SubtractDayFromJDEDate','expression_for':'datetime',  'expression':"<<jdedate>>,interval",'needrightsideval':true,'showdateformatoption':true},
                    {'name':'Substract Month From JDE Date','id':'SubtractMonthFromJDEDate','expression_for':'datetime',  'expression':"<<jdedate>>,interval",'needrightsideval':true,'showdateformatoption':true},
                    {'name':'Substract Year From JDE Date','id':'SubtractYearFromJDEDate','expression_for':'datetime',  'expression':"<<jdedate>>,interval",'needrightsideval':true,'showdateformatoption':true},
                    {'name':'Get Day From The JDE Date','id':'GetDayFromJDEDate','expression_for':'datetime',  'expression':"<<jdedate>>"},
                    {'name':'Get Month From The JDE Date','id':'GetMonthFromJDEDate','expression_for':'datetime',  'expression':"<<jdedate>>"},
                    {'name':'Get Year From The JDE Date','id':'GetYearFromJDEDate','expression_for':'datetime',  'expression':"<<jdedate>>"},
                    {'name':'Get Century From The JDE Date','id':'getCenturyFromJDEDate','expression_for':'datetime',  'expression':"<<jdedate>>"},
                    {'name':'Add Day To Normal Date','id':'AddDayToNormalDate','expression_for':'datetime',  'expression':"<<normaldate>>,interval",'needrightsideval':true,'showdateformatoption':true},
                    {'name':'Add Month To Normal Date','id':'AddMonthToNormalDate','expression_for':'datetime',  'expression':"<<normaldate>>,interval",'needrightsideval':true,'showdateformatoption':true},
                    {'name':'Add Year To Normal Date','id':'AddYearToNormalDate','expression_for':'datetime',  'expression':"<<normaldate>>,interval",'needrightsideval':true,'showdateformatoption':true},
                    {'name':'Substract Day From Normal Date','id':'SubtractDayFromNormalDate','expression_for':'datetime',  'expression':"<<normaldate>>,interval",'needrightsideval':true,'showdateformatoption':true},
                    {'name':'Substract Month From Normal Date','id':'SubtractMonthFromNormalDate','expression_for':'datetime',  'expression':"<<normaldate>>,interval",'needrightsideval':true,'showdateformatoption':true},
                    {'name':'Substract Year From Normal Date','id':'SubtractYearFromNormalDate','expression_for':'datetime',  'expression':"<<normaldate>>,interval",'needrightsideval':true,'showdateformatoption':true},
                    {'name':'Get Day From The Normal Date','id':'GetDayFromNormalDate','expression_for':'datetime',  'expression':"<<normaldate>>"},
                    {'name':'Get Month From The Normal Date','id':'GetMonthFromNormalDate','expression_for':'datetime',  'expression':"<<normaldate>>"},
                    {'name':'Get Year From The Normal Date','id':'GetYearFromNormalDate','expression_for':'datetime',  'expression':"<<normaldate>>"},
                    {'name':'Get Century From The Normal Date','id':'getCenturyFromNormalDate','expression_for':'datetime',  'expression':"<<normaldate>>"},
                    {'name':'Get DDYYY','id':'GetDateInDDYYY','expression_for':'datetime',  'expression':"<<normaldate>>"},
                    {'name':'Calculate JDE Date Difference In Days','id':'JDEDatediff','expression_for':'datetime',  'expression':"<<jdedate>>,<<jdedate>>",'is2nddatereq':true},
                    {'name':'Calculate Normal Date Difference In Days','id':'NormalDatediff','expression_for':'datetime',  'expression':"<<normaldate>>,<<normaldate>>",'is2nddatereq':true},
                    {'name':'Convert JDE Time To Standard Time','id':'JDETimeToNormalTime','expression_for':'datetime',  'expression':"<<jdetime>>",'showdateformatoption':true},
                    {'name':'Convert Standard Time To JDE Time','id':'NormalTimeToJDETime','expression_for':'datetime',  'expression':"<<normaltime>>",'showdatepicker':true}
                ]
            },
            getFunctionsList(){
                var _this = this
                let availableFunctions = this.getAvailableDateFunctions
                let filtered = _.filter(availableFunctions,(obj)=>{
                    return !_this.expresionDesigner || obj.id.toLowerCase().includes(_this.expresionDesigner.toLowerCase()) || obj.name.toLowerCase().includes(_this.expresionDesigner.toLowerCase())
                })
                return filtered.slice(0,5)
            }
        },
        mounted(){
            console.log('expression builder position', this.$refs.expressionBulder.position)
        },
        methods:{
            setExprssion(funcObj){
                if(_.isEmpty(funcObj))
                    return
                this.expresionDesigner=funcObj.id+'('+funcObj.expression+')'
                this.$refs.expressionBulder.focus()
            },
            saveExpression(){
                debugger;
                var expressionObj = {
                    "expression_name":this.expressionName,
                    "table_name":"Expression",
                    'is_drv_table':false,
                    "expressionFor":this.expressionFor
                }
                this.$emit('saveExpression',expressionObj)
            },
            onKeyUp(event){
                this.positionX = event.target.getBoundingClientRect().x+(this.expresionDesigner.length*2)
                this.positionY = event.target.getBoundingClientRect().y+24
                this.showFunc = true
            },
            newColumnDragged(colObj){

            },
            getCurrentExpressionObjThroughText(){
                /* In this function we will design the expression object based on the user selection
                    Assumptions while writtening the below code
                                        

                */
            },
            manageDraggedObject(colObj){
                this.expresionDesigner = this.expresionDesigner.replace('<<jdedate>>',colObj.column_name)
                
            }
        }
    }
</script>