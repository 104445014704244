import edcGraph from '@/views/edcGraph/edcGraph.vue'
import edcGraphToolbar from '@/views/edcGraph/edcGraphToolbar.vue'
import { post as postToServer } from './../methods/serverCall.js';
import { get as getToServer } from './../methods/serverCall.js';
import config from './../config.json'

export const mixinEdcGraph={
	components:{
		'edc-graph':edcGraph,
		'edc-graph-toolbar':edcGraphToolbar
	},
	data(){
		return{
			isAutoSave:true
		}
	},
	mounted(){
		this.getUserPreferences()
	},
	methods:{
		getUserPreferences(){
			var _this = this
			let data = {
				'product_name':'EDC',
				'user_name':_this.$session.get('email')
			}
			postToServer(_this,config.USER_PROVISION_URL+'/get_user_preference_by_username',data).then(
			response=>{
				this.isAutoSave = response.auto_save_object
			}).catch(error=>{

			})
		},
		setUserPreferences(){
			var _this = this
			let data = {
				'product_name':'EDC',
				'user_name':_this.$session.get('email'),
				'client_id':_this.$session.get('client_id'),
				'auto_save_object':_this.isAutoSave
			}
			postToServer(_this,config.USER_PROVISION_URL+'/add_user_preference',data).then(
			response=>{

			}).catch(error=>{

			})
		}
	}

}