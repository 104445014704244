export function getF0911SummarizationStepData(process_definition_name){
	let filters =[
		{
			"s_parentheses":"","column_name":"F0911.GLDGJ","operator":"_lt_","v_type1":"Date","v_type2":"Value",
			"value1":"JUL["+process_definition_name+".Summarization policy]","value2":"","e_parentheses":"","is_drv_table":false,"id":1,
			"datedetails1": {
			        "format": "JUL",
			        "is_policy": true,
			        "policy_name": process_definition_name,
			        "value": "JUL["+process_definition_name+".Summarization policy]",
			        "policy_sub_type_name": "Summarization policy",
			        "bo_id": "jdesumm001",
			        "bo_name": process_definition_name,
			        "policy_value": process_definition_name+".Summarization policy",
			        "policy_type": "Policy"
			    },
		    operation:false,
		    isEditable:false,
		    isDeletable:false
		},
		{"s_parentheses":"","column_name":"F0911.GLDCT","operator":"_not_eq_","v_type1":"Value","v_type2":"Value","value1":"BF","value2":"","e_parentheses":"","is_drv_table":false,"id":1,"operation":false},
		{"s_parentheses":"","column_name":"F0911.GLSUMM","operator":"_in_","v_type1":"Value","v_type2":"Value","value1":[" ","1","0","N"],"value2":"","e_parentheses":"","is_drv_table":false,"id":302,"operation":false},
		{"s_parentheses":"","column_name":"F0911.GLPOST","operator":"_eq_","v_type1":"Value","v_type2":"Value","value1":"P","value2":"","e_parentheses":"","is_drv_table":false,"id":303}
	]
	return {
		"selection":[
			{"id":1,"column_name":"GLCO","table_name":"F0911","alias_name":"GLCO","is_drv_table":false,"report_alias_name":"Company","data_type_details":{"data_type":"NCHAR","name":"GLCO"},"isCompulsory":true},
			{"id":2,"column_name":"GLKCO","table_name":"F0911","alias_name":"GLKCO","is_drv_table":false,"report_alias_name":"Document Company","data_type_details":{"data_type":"NCHAR","name":"GLKCO"},"isCompulsory":true},
			{"id":3,"column_name":"GLANI","table_name":"F0911","alias_name":"GLANI","is_drv_table":false,"report_alias_name":"Account Number","data_type_details":{"data_type":"NCHAR","name":"GLANI"},"isCompulsory":true},
			{"id":4,"column_name":"GLAID","table_name":"F0911","alias_name":"GLAID","is_drv_table":false,"report_alias_name":"Account ID","data_type_details":{"data_type":"NCHAR","name":"GLAID"},"isCompulsory":true},
			{"id":5,"column_name":"GLMCU","table_name":"F0911","alias_name":"GLMCU","is_drv_table":false,"report_alias_name":"Business Unit","data_type_details":{"data_type":"NCHAR","name":"GLMCU"},"isCompulsory":true},
			{"id":6,"column_name":"GLOBJ","table_name":"F0911","alias_name":"GLOBJ","is_drv_table":false,"report_alias_name":"Object Account","data_type_details":{"data_type":"NCHAR","name":"GLOBJ"},"isCompulsory":true},
			{"id":7,"column_name":"GLSUB","table_name":"F0911","alias_name":"GLSUB","is_drv_table":false,"report_alias_name":"Subsidiary","data_type_details":{"data_type":"NCHAR","name":"GLSUB"},"isCompulsory":true},
			{"id":8,"column_name":"GLSBL","table_name":"F0911","alias_name":"GLSBL","is_drv_table":false,"report_alias_name":"Subledger","data_type_details":{"data_type":"NCHAR","name":"GLSBL"},"isCompulsory":true},
			{"id":9,"column_name":"GLSBLT","table_name":"F0911","alias_name":"GLSBLT","is_drv_table":false,"report_alias_name":"Subledger Type","data_type_details":{"data_type":"NCHAR","name":"GLSBLT"},"isCompulsory":true},
			{"id":10,"column_name":"GLLT","table_name":"F0911","alias_name":"GLLT","is_drv_table":false,"report_alias_name":"Ledger Type","data_type_details":{"data_type":"NCHAR","name":"GLLT"},"isCompulsory":true},
			{"id":11,"column_name":"GLCTRY","table_name":"F0911","alias_name":"GLCTRY","is_drv_table":false,"report_alias_name":"Century","data_type_details":{"data_type":"NUMBER","name":"GLCTRY"},"isCompulsory":true},
			{"id":12,"column_name":"GLFY","table_name":"F0911","alias_name":"GLFY","is_drv_table":false,"report_alias_name":"Fiscal Year","data_type_details":{"data_type":"NUMBER","name":"GLFY"},"isCompulsory":true},
			{"id":13,"column_name":"GLPN","table_name":"F0911","alias_name":"GLPN","is_drv_table":false,"report_alias_name":"G/L Period Number","data_type_details":{"data_type":"NUMBER","name":"GLPN"},"isCompulsory":true},
			{"id":14,"column_name":"GLCRCD","table_name":"F0911","alias_name":"GLCRCD","is_drv_table":false,"report_alias_name":"Currency Code","data_type_details":{"data_type":"NCHAR","name":"GLCRCD"},"isCompulsory":true},
			{"id":15,"column_name":"GLUM","table_name":"F0911","alias_name":"GLUM","is_drv_table":false,"report_alias_name":"Unit of Measure","data_type_details":{"data_type":"NCHAR","name":"GLUM"},"isCompulsory":true},
			{"id":16,"column_name":"GLAA","table_name":"F0911","alias_name":"GLAA","is_drv_table":false,"report_alias_name":"Amount","data_type_details":{"data_type":"NUMBER","name":"GLAA"},
			"aggregate":"sum","isCompulsory":true,"hide":true},
			{"id":17,"column_name":"GLU","table_name":"F0911","alias_name":"GLU","is_drv_table":false,"report_alias_name":"Units","data_type_details":{"data_type":"NUMBER","name":"GLU"},
			"aggregate":"sum","isCompulsory":true,"hide":true}
		],

		"filter":filters,
		"main_filterUI":filters,
		"secondary_filterUI":{"groupList":[],"mainfilteroperation":false},
		"select_table_list":["F0911"],
		"new_select_table_list":[{"table_name":"F0911","table_type":"table"}],
		"order_by":[
			{"column_name":"GLCTRY","type":'asc'},
			{"column_name":"GLFY","type":'asc'},
			{"column_name":"GLPN","type":'asc'},
			{"column_name":"GLCO","type":'asc'},
			{"column_name":"GLAID","type":'asc'},
			{"column_name":"GLLT","type":'asc'},
			{"column_name":"GLSBL","type":'asc'},
			{"column_name":"GLSBLT","type":'asc'},
			{"column_name":"GLCRCD","type":'asc'}
		],
		"summarizationFor":"F0911"
	}
}


export function getF4111SummarizationStepData(process_definition_name){
	// In the Filter 'isEditable' & 'isDeletable'  keys added to keep permanent filter as discussed with Nirav on 17th March 2022

	let defaultFilter = [
		{
			"s_parentheses":"","column_name":"F4111.ILDGL","operator":"_lt_","v_type1":"Date","v_type2":"Value",
			"value1":"JUL["+process_definition_name+".Summarization policy]","value2":"","e_parentheses":"","is_drv_table":false,"id":1,
			"datedetails1": {
			        "format": "JUL",
			        "is_policy": true,
			        "policy_name": process_definition_name,
			        "value": "JUL["+process_definition_name+".Summarization policy]",
			        "policy_sub_type_name": "Summarization policy",
			        "bo_id": "jdesumm001",
			        "bo_name": process_definition_name,
			        "policy_value": process_definition_name+".Summarization policy",
			        "policy_type": "Policy"
			    },
		    operation:false,
		    isEditable:false,
		    isDeletable:false
		},
		{
			"s_parentheses":"","column_name":"F4111.ILTRDJ","operator":"_lt_","v_type1":"Date","v_type2":"Value",
			"value1":"JUL["+process_definition_name+".Summarization policy]","value2":"","e_parentheses":"","is_drv_table":false,"id":1,
			"datedetails1": {
		        "format": "JUL",
		        "is_policy": true,
		        "policy_name": process_definition_name,
		        "value": "JUL["+process_definition_name+".Summarization policy]",
		        "policy_sub_type_name": "Summarization policy",
		        "bo_id": "jdesumm001",
		        "bo_name": process_definition_name,
		        "policy_value": process_definition_name+".Summarization policy",
		        "policy_type": "Policy"
		    },
		    isEditable:false,
		    isDeletable:false
		}
	]
	return {
		"selection":[
			{"id":1,"column_name":"ILKCO","table_name":"F4111","alias_name":"ILKCO","is_drv_table":false,"report_alias_name":"Document Company","data_type_details":{"data_type":"NCHAR","name":"ILKCO"},"isCompulsory":true},
			{"id":2,"column_name":"ILMCU","table_name":"F4111","alias_name":"ILMCU","is_drv_table":false,"report_alias_name":"Branch/Plant","data_type_details":{"data_type":"NCHAR","name":"ILMCU"},"isCompulsory":true},
			{"id":3,"column_name":"ILITM","table_name":"F4111","alias_name":"ILITM","is_drv_table":false,"report_alias_name":"Item Number - sht","data_type_details":{"data_type":"NUMBER","name":"ILITM"},"isCompulsory":true},
			{"id":4,"column_name":"ILLITM","table_name":"F4111","alias_name":"ILLITM","is_drv_table":false,"report_alias_name":"Product No","data_type_details":{"data_type":"NCHAR","name":"ILLITM"},"isCompulsory":true},
			{"id":5,"column_name":"ILAITM","table_name":"F4111","alias_name":"ILAITM","is_drv_table":false,"report_alias_name":"Catalog No","data_type_details":{"data_type":"NCHAR","name":"ILAITM"},"isCompulsory":true},
			{"id":6,"column_name":"ILLOCN","table_name":"F4111","alias_name":"ILLOCN","is_drv_table":false,"report_alias_name":"Location","data_type_details":{"data_type":"NCHAR","name":"ILLOCN"},"isCompulsory":true},
			{"id":7,"column_name":"ILLOTN","table_name":"F4111","alias_name":"ILLOTN","is_drv_table":false,"report_alias_name":"Lot/SN","data_type_details":{"data_type":"NCHAR","name":"ILLOTN"},"isCompulsory":true},
			{"id":8,"column_name":"ILGLPT","table_name":"F4111","alias_name":"ILGLPT","is_drv_table":false,"report_alias_name":"G/L Class","data_type_details":{"data_type":"NCHAR","name":"ILGLPT"},"isCompulsory":true},
			{"id":9,"column_name":"ILTRUM","table_name":"F4111","alias_name":"ILTRUM","is_drv_table":false,"report_alias_name":"Trans U/M","data_type_details":{"data_type":"NCHAR","name":"ILTRUM"},"isCompulsory":true},
			{"id":10,"column_name":"ILIPCD","table_name":"F4111","alias_name":"ILIPCD","is_drv_table":false,"report_alias_name":"As Of Posted Code","data_type_details":{"data_type":"NCHAR","name":"ILIPCD"},"isCompulsory":true},
			{"id":11,"column_name":"ILTRQT","table_name":"F4111","alias_name":"ILTRQT","is_drv_table":false,"report_alias_name":"Transaction Quantity","data_type_details":{"data_type":"NUMBER","name":"ILTRQT"},
			"aggregate":"sum","isCompulsory":true,"hide":true},
			{"id":12,"column_name":"ILPAID","table_name":"F4111","alias_name":"ILPAID","is_drv_table":false,"report_alias_name":"Extended Cost/Price","data_type_details":{"data_type":"NUMBER","name":"ILPAID"},
			"aggregate":"sum","isCompulsory":true,"hide":true}
		],
		"filter":defaultFilter,
		"main_filterUI":defaultFilter,
		"secondary_filterUI":{"groupList":[],"mainfilteroperation":false},
		"select_table_list":["F4111"],
		"new_select_table_list":[{"table_name":"F4111","table_type":"table"}],
		"order_by":[
			{"column_name":"ILMCU","type":'asc'},
			{"column_name":"ILITM","type":'asc'},
			{"column_name":"ILLOCN","type":'asc'},
			{"column_name":"ILLOTN","type":'asc'}
		],
		"summarizationFor":"F4111",
		"having":[
			{"column_name":"ILTRQT","operator":"_not_eq_","operand":"true","value":"0","aggregate":"sum"},
			{"column_name":"ILPAID","operator":"_not_eq_","operand":"","value":"0","aggregate":"sum"}
		],
		"write_bf_record_if_quantity_0":false
	}
}
// For F4111 , order by used Index number 6 from jtetables.com website.Nirav's suggestion-Date -11th March 2022
