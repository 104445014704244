/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'business_object_v2': {
    width: 24,
    height: 24,
    viewBox: '0 0 6.35 6.35',
    data: '<path pid="0" d="M2.117.53a.264.264 0 00-.265.264v1.058c0 .147.118.265.265.265h2.116a.264.264 0 00.265-.265V.794a.264.264 0 00-.265-.265H2.117zm.926 1.588v.925H1.588a.132.132 0 00-.133.131v1.059h.266v-.926h2.91v.926h.263V3.174a.132.132 0 00-.133-.13H3.307v-.926h-.264zM.926 4.233a.4.4 0 00-.397.399v.792c0 .218.179.397.397.397h1.321a.4.4 0 00.399-.397v-.792a.402.402 0 00-.399-.399H.926zm3.175 0a.4.4 0 00-.397.399v.792c0 .218.179.397.397.397h1.321a.4.4 0 00.399-.397v-.792a.402.402 0 00-.399-.399H4.101zm-3.308.67H2.38v.521a.13.13 0 01-.134.133H.926a.13.13 0 01-.133-.133v-.52zm3.175 0h1.588v.521a.13.13 0 01-.134.133H4.101a.13.13 0 01-.133-.133v-.52z"/>'
  }
})
