<template>
    <v-btn :loading="loading" outlined :disabled="disabled" 
                    color="apptext" class="border" elevation="1" :small="small" :x-small="xSmall">{{itemText}}</v-btn>
</template>
<script>
import { BTN_COLOR } from '@/data/macros.js';
export default {
    name:'vcButton',
    data() {
      return {
          buttonColor:BTN_COLOR
      }
    },
    props: {
      itemText: String,
      loading: Boolean,
      disabled:Boolean,
      small:{
        type:Boolean,
        default:true
      },
      xSmall:{
        type:Boolean,
        default:false
      }
    },
    watch:{
        xSmall(newValue){
            if(newValue)
                this.small = false
        }
    },
    mounted(){
        if(this.xSmall)
            this.small = false
    }
}
</script>
<style scoped>
.border{
    border-radius:2px;
    margin:4px !important;
}
</style>
