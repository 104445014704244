<template>
    <v-flex id="mainflex">
        <v-card class="rounded-card EDC-Designer-Row">
            <v-container class="EDC-Container">
                <v-row class="EDC-Row" justify="start">
                    <v-col class="EDC-Col text-truncate" cols="10" align="left">
                        <ul class="breadcrumb" style="display:inline-flex;">
                        <li><a @click="savedata(true)"> Data Item Search </a></li>
                        <li>[{{stepSeqNo}}] <input type="text" v-model="stepname" :title="stepname" @blur="StepNameChanged()" :readonly="readonly" @keydown="preventKeyEvent" :size="nameTextboxSize"/></li>
                        </ul>

                        <span v-if='!readonly' style="font-weight:400;font-size:13px;margin-left:5px;" v-bind:class="{errorcls:is_error_occured , successcls:!is_error_occured}">{{'('+savedstatus + ')'}}</span>

                        <span v-else style="color:rgba(0,0,0,0.54);font-weight:400;font-size:13px;margin-left:5px;">
                        (Readonly Mode)</span>

                        <span style="font-weight:400;font-size:13px;margin-left:5px;" :style="{'color': colorCode}">{{datasource_error||redoundoMsg}}</span>
                    </v-col>
                    <v-col cols="2" class="EDC-Col">

                        <v-icon style="font-size:20px;float:right;padding-left:12px !important;" class="v-align" @click="stepper=1;savedata(true)" title='close'>far fa-times-circle</v-icon>

                        <span class="" style="font-size:13px;float:right;margin-right:5px;" :style="{'color': colorCode}">
                            <v-progress-circular :width="3" v-if="validateProgress" :size="20" color="blue" indeterminate></v-progress-circular>
                            <showerrorlisttemplate :validation_response="validation_response" :stepType="stepType"
                                @set-error="setError" @totalCount="totalCount" :errorListCount="errorListCount" :showprocess="validateProgress ? 'Y':'N'" style="margin-top:2px !important;margin-left:4px !important;" :showErrorPopUp="showErrorPopUp">
                            </showerrorlisttemplate>
                        </span>

                        <span style="float:right;" class="v-align" :style="{'color': colorCode}">
                            <svgicon class="svg-icon-grid svg-fill-grid" name="validate"  style="float:right;margin-top:-20%;"  title="Validate Step" @click="validatestepdata" ></svgicon>
                        </span>
                    </v-col>
                </v-row>
                <v-row class="EDC-Row">
                    <v-col class="EDC-Col" align="left">
                        <edc-graph-toolbar style="padding-left:4px !important;" :redoCount="undoStackCount" :undoCount="redoStackCount" key="selection-desinger" @performUndoRedo="$emit('keyupHandler',$event)" @showDescription="descriptionPanel = true" @onSave="$emit('saveProcessDesigner')" @onCancel="onCancel" :showNewVersion="false"></edc-graph-toolbar>
                    </v-col>
                </v-row>
            </v-container>
            <v-container class="EDC-Container">
                <v-row class="EDC-Row" style="height:60vh !important">
                    <v-col cols="12" class="EDC-Col">
                        <v-stepper v-model="stepper" class="EDC-Stepper" non-linear @change="changeStepper">
                            <v-stepper-header class="EDC-StepperHeader">
                                <v-stepper-step :step="1" class="EDC-StepperStep" editable>
                                    Options
                                </v-stepper-step>
                                <v-divider class="EDC-StepperDivider"></v-divider>
                                <v-stepper-step :step="2" class="EDC-StepperStep" editable>
                                    Rules
                                </v-stepper-step>
                            </v-stepper-header>
                            <v-stepper-items>
                                <v-stepper-content class="EDC-Stepper-Content" :step="1" elevation="0">
                                    <v-row class="EDC-Row">
                                        <v-col class="EDC-Col">
                                            <v-row class="EDC-Row">
                                                <v-col cols="4">
                                                    <v-card class="optionCard">
                                                        <v-row class="EDC-Row">
                                                            <v-col class="cardHeader" align="left">
                                                                Database Option
                                                            </v-col>
                                                        </v-row>
                                                        <v-row class="EDC-Row">
                                                            <v-col  class="EDC-Col controlPadding" align="left" cols="4">
                                                                <v-label>Create View In</v-label>
                                                            </v-col>
                                                            <v-col  class="EDC-Col controlPadding" align="left" cols="7">
                                                                <v-autocomplete dense label="Select Datasource" 
                                                                :items="allDatasourcesForView" item-text="datasource_name" 
                                                                item-value="key_to_pass" v-model="selectedDatasourceOn">
                                                                </v-autocomplete>
                                                            </v-col>
                                                        </v-row>
                                                    </v-card>
                                                </v-col>
                                                <v-col cols="4">
                                                    <v-card class="optionCard style-2scroll">
                                                        <v-row class="EDC-Row">
                                                            <v-col class="cardHeader" align="left">
                                                                Runtime Options
                                                            </v-col>
                                                        </v-row>
                                                        <v-row class="EDC-Row">
                                                            <v-col  class="EDC-Col controlPadding" align="left">
                                                                <v-label>If error occur during view creation</v-label>
                                                            </v-col>
                                                        </v-row>
                                                        <v-row class="EDC-Row" style="padding-left:32px !important;">
                                                            <v-col class="EDC-Col">
                                                                <v-radio-group v-model="runtimeException" hide-details dense> 
                                                                    <v-radio class="customCheckbox" hide-details label="Log the error & continue" value="continue" /> 
                                                                    <v-radio class="customCheckbox" label="Log the error & stop processing" value="throwexception" /> 
                                                                </v-radio-group>
                                                            </v-col>
                                                        </v-row>
                                                        <v-divider class="EDC-Divider"></v-divider>
                                                        <v-row class="EDC-Row">
                                                            <v-col  class="EDC-Col controlPadding" align="left">
                                                                <v-checkbox v-model="dropAlreadyExitsView" dense class="customCheckbox" label="Replace existing views" />
                                                            </v-col>
                                                        </v-row>
                                                    </v-card>
                                                </v-col>
                                                <v-col cols="4">

                                                </v-col>
                                            </v-row>
                                        </v-col>
                                    </v-row>
                                </v-stepper-content>
                                <v-stepper-content class="EDC-Stepper-Content" :step="2" elevation="0">
                                    <v-row class="EDC-Row">
                                        <v-col>
                                            <v-card class="gridCard style-2scroll">
                                                <v-row class="EDC-Row">
                                                    <v-col class="EDC-Col">
                                                        <edc-data-grid-edit key="grid" :dataList="tableList"
                                                        @onNew="onNew" @onValueUpdate="onValueUpdate" @onSave="onSave" @onCancel="onCancelEditing"
                                                        @onDelete="onDelete" @performSearchInHeader="performSearchInHeader"
                                                        @advancePopupInitiating="getColumnList">
                                                        </edc-data-grid-edit>
                                                    </v-col>
                                                </v-row>
                                            </v-card>
                                        </v-col>
                                    </v-row>
                                </v-stepper-content>
                            </v-stepper-items>
                        </v-stepper>
                    </v-col>
                </v-row>
                <v-row class="EDC-Row">
                    <v-col cols="12"  align="end">
                        <vc-button itemText="Previous" @click.native="stepper > 1?stepper--:stepper=6"></vc-button>
                        <vc-button itemText="Next" @click.native="stepper === 6?stepper=1:stepper++"></vc-button>
                    </v-col>
                </v-row>
            </v-container>
        </v-card>

        <v-dialog v-model="descriptionPanel" width="1000" eager>
            <v-card>
                <rich-text-box style="backgroud:white" key="selectionRichTextBox" @DescriptionChange="DescriptionChange" :vueEditorObj="vueEditorObj">

                </rich-text-box>
                <v-card-actions>
                <v-spacer></v-spacer>
                <vc-button  itemText= "Close" @click.native="descriptionPanel = false">

                </vc-button>
                </v-card-actions>
            </v-card>
        </v-dialog>
        <loading-panel :loader="loader"></loading-panel>
    </v-flex>
</template>
<style scoped>
    .customCheckbox >>> label{
        top: 4px !important;
    }
    .optionCard{
        height:32vh !important;
        overflow-y: auto !important;
        overflow-x: hidden !important;
    }
    .cardHeader{
        font-weight: bold;
        font-size: 14px !important;
    }

    .controlPadding{
        padding-left: 16px !important;
        padding-bottom: 8px !important;
    }

    .gridCard{
        height: 48vh !important;
        overflow-y: auto !important;
        overflow-x: hidden !important;
    }
</style>
<script>
    import showerrorlisttemplate from './../../views/showerrorlisttemplate.vue'
    import RichTextBox from "@/views/richTextEditor/index.vue";
    import edcGraphToolbar from '@/views/edcGraph/edcGraphToolbar.vue'
    import edcDraggableLists from '@/views/draggableLists.vue'
    import * as edcConstantObj from '../../methods/edcGraph/constants.js'
    import { post as postToServer,get as getToServer } from '@/methods/serverCall.js';
    import {COLOR_CODE,BTN_COLOR} from '@/data/macros.js'
    import config from '../../config.json'
    import Criteria from '@/views/newcriteria.vue';
    import {validate_process_def_json} from "../../methods/call_validation.js"
    import {getColumnsWithDescription} from "../../methods/agent_api_call.js"
    import edcDataGridForEdit from "./../../views/dataTable/edcdatagridForEdit.vue"
    import { v4 as EDCuuidv4 } from 'uuid';
    
    export default {
        name:"edcCreateView",
        components:{
            'edc-graph-toolbar':edcGraphToolbar,
            'edc-dragable-list':edcDraggableLists,
            'rich-text-box':RichTextBox,
            showerrorlisttemplate,
            'new-edc-criteria':Criteria,
            'edc-data-grid-edit':edcDataGridForEdit
        },
        props:{
            parentdigramobj:{
                type:Object,
                default:{"name":"create_view"}
            },
            savedatastatus:{
                type:String,
                default:''
            },
            iserroroccured:{
                type:Boolean,
                default:false
            },
            datasourceerror:{
                type:String,
                default:''
            },
            acceleratorObj:{
                type:Object,
                default:{}
            },
            readonly:{
                type:Boolean,
                default:false
            },
            redoundoCommandObj:{
                type:Object
            },
            redoundoMsg:{
                type:String,
            },
            undoStack:{
                type:Array
            },
            redoStack:{
                type:Array
            },
            environmentDetails:{
                type:Object
            }
        },
        data(){
            return{
                loader:false,
                colorCode:COLOR_CODE,
                stepType:edcConstantObj.CREATE_VIEW,
                stepSeqNo:1,
                stepname:'CREATE VIEW',
                nameTextboxSize:20,
                savedstatus:'edited',
                datasource_error:'',
                is_error_occured:false,
                showErrorPopUp:false,
                undoStackCount:0,
                redoStackCount:0,
                descriptionPanel:false,
                stepper:1,
                allDatasourcesForView:[],
                loading:false,
                tableLoading:false,
                validation_response:{},
                clientId:this.$session.get('client_id'),
                env_id:this.$session.get("selected_env").id,
                descriptionPanel:false,
                stepDescription:'',
                vueEditorObj:{},
                columns_for_filter:[],
                column_name_list:[],
                main_filterUI:[],
                secondary_filterUI:{groupList:[]},
                filter_column_tbl_obj: {
                    's_parentheses': '',
                    'column_name': '',
                    'operator': '',
                    'v_type1': 'Value',
                    'v_type2': 'Value',
                    'value1': '',
                    'value2':'',
                    'e_parentheses': '',
                    'is_drv_table': false,
                    'ref_row_id':'',
                    'ref_step_id':''
                },
                auto_increment_id:0,
                validateProgress:false,
                errorListCount:0,
                autoValidateTimer:0,
                table_column_list:[],
                policy_list:[],
                runtimeException:"continue",
                dropAlreadyExitsView:true,
                tableList: {
                    headers: [
                        {
                            text: 'Seq.No',
                            align: 'end',
                            value: "exe_seq",
                            label: "Runtime Sequence Number ,In which order views will get created",
                        },
                        {
                            text: 'View For',
                            align: 'start',
                            value: "viewFor",
                            useAutoComplete:true,
                            sortable:false,
                            label: "View For",
                            option:[],
                            'item_text':'display_text',
                            'item_value':'display_text',
                        },
                        {
                            text: 'View Type',
                            value: "viewType",
                            useAutoComplete:true,
                            sortable:false,
                            label: "View Type",
                            'item_text':'display_text',
                            'item_value':'display_text',
                            option:[]
                        },
                        { 
                            text: 'Tables For View', 
                            value: "selectedTables", 
                            'item_text':'display_text',
                            'item_value':'name', 
                            useAdvanceAutoComplete:true,  
                            useMultiple:true,
                            option:[], 
                            sortable:false,
                            label: "Tables For View",
                            show_select_all:true,
                            headerFor:'Tables',
                            gridDisplayTextKey:"selectedTablesText",
                            gridDisplayTextTitleKey:"selectedTablesTextTitle",
                            selectAllKey:'isAllTablesSelected',
                            performTableSearch:true
                        },
                        { 
                            text: 'Except These Tables', 
                            value: "excludedTables",
                            'item_text':'display_text',
                            'item_value':'name', 
                            useAdvanceAutoComplete:true, 
                            sortable:false,
                            useMultiple:true,
                            option:[], 
                            label: "Except These Tables",
                            headerFor:'Tables',
                            gridDisplayTextKey:"excludedTablesText",
                            gridDisplayTextTitleKey:"excludedTablesTextTitle",
                            show_select_all:false,
                            performTableSearch:true
                        },
                        { 
                            text: 'Select Users', 
                            value: "selectedUsers", 
                            useAdvanceCombobox:true, 
                            sortable:false,
                            option:["PUBLIC"], 
                            label: "Select Users",
                            useMultiple:true,
                            headerFor:'Users',
                            gridDisplayTextKey:"selectedUsersText",
                            gridDisplayTextTitleKey:"selectedUsersTextTitle"
                        },
                        { 
                            text: 'Permissions', 
                            value: "selectedPermission", 
                            useAutoComplete:true, 
                            sortable:false,
                            'item_text':'display_text',
                            'item_value':'display_text',
                            option:[], 
                            label: "Permissions"
                        },
                        { 
                            text: 'Filters', 
                            value: "filterObj", 
                            useAdvanceRowOption:true,
                            headerFor:'Filter',
                            sortable:false,
                            useButton:true,
                            option:[],
                            gridDisplayTextKey:"filterText",
                            gridDisplayTextTitleKey:"filterTextTitle",
                            emitSaveOnSaveAdvanceOption:true
                        },
                    ],
                    actions: [],
                    rows: [],
                    itemkey: "id",
                    hideSelect: false,
                    hideExport: true,
                    hideFilter:true,
                    hideShowColumns:true,
                    is_grid_inline_edit:true,
                    selected:[],
                    perPage:10,
                    isGridForCreateView:true
                },
                rowObject:{
                    "exe_seq":"",
                    "viewFor":"",
                    "viewType":"",
                    "selectedTables":[],
                    "excludedTables":[],
                    "selectedUsers":["PUBLIC"],
                    "selectedPermission":"Grant Readonly",
                    "filterObj":{
                        "filter":[],
                        "main_filterUI":[],
                        "secondary_filterUI":{}
                    },
                    "hasFilter":'',
                    "is_row_editable":true,
                    "id":"",
                    "selectedTablesText":"",
                    "selectedTablesTextTitle":"",
                    "excludedTablesText":"",
                    "excludedTablesTextTitle":"",
                    "selectedUsersText":"",
                    "selectedUsersTextTitle":"",
                    "isAllTablesSelected":false,
                    "filterText":"N/A",
                    "filterTextTitle":"No Filter Applied"

                },
                selectedDatasourceOn:'',
                backupRows:[],
                table_column_list:[],
            }
        },
        computed:{
            isShowFilter(){
                //return this.dataItemSearchType && this.dataItemSearchType.value === 'filtered'
                return false
            },
            viewTypeOptions(){
                return [
                    {"display_text":"Combined","value":"combined","valueForAPI":"source_data"},
                    {"display_text":"Source Only","value":"source","valueForAPI":"source_data"},
                    {"display_text":"Archive Only","value":"archive","valueForAPI":"target_data"}
                ]
            },
            viewPermissionOptions(){
                return [
                    {"display_text":"Grant Readonly","value":"readonly"},
                    {"display_text":"Deny All","value":"denied"},
                    {"display_text":"Grant Add,Update,Delete","value":"update"}
                ]
            },
            selectedDatasourceObj(){
                if(!this.selectedDatasourceOn || _.isEmpty(this.environmentDetails))
                    return {}
                return this.environmentDetails[this.selectedDatasourceOn]
            },
            throwExceptionOnError(){
                return this.runtimeException === 'throwexception'
            }
        },
        watch:{
            'stepname':{
                handler(newValue){
                    if(newValue){
                        this.nameTextboxSize=Math.max(20,newValue.length>60?60:newValue.length)
                    }
                    else
                        this.nameTextboxSize = 20
                }
            },
            'parentdigramobj':{
                handler(newValue){
                    var _this =this
                    let parentObjVal = _.cloneDeep(newValue)
                    this.resetData()
                    if(parentObjVal.stepname)
                        this.stepname  =  parentObjVal.stepname
                    this.description = parentObjVal.description || {}
                    let stepData = parentObjVal.step_data || {}
                    if(stepData.allRulesForUI){

                        this.backupRows = _.sortBy(stepData.allRulesForUI,'exe_seq')
                        this.tableList.rows = _.cloneDeep(this.backupRows)
                    }
                    if(stepData.view_create_on_ds){
                        this.selectedDatasourceOn = stepData.view_create_on_ds
                    }
                    if(stepData.throw_exception_on_error){
                        this.runtimeException = 'throwexception'
                    }
                    if(stepData.drop_view_already_exists === false){
                        this.dropAlreadyExitsView = false
                    }

                }
            },
            'acceleratorObj':{
                handler(newValue){

                }
            },
            'stepper':{
                handler(newValue){
                    
                }
            },
            policies(newvalue){
                this.policy_list = newvalue;
            },
            'environmentDetails':{
                handler(newvalue){
                    
                }
            }
        },
        mounted(){
            this.tableList.actions =[
                { "text": "new", role: true, index: 1, "key":"id", selectType: "single"},
                { "text": "edit", role: true, index: 2, "key":"id", selectType: "single", },
                { "text": "delete", role: true, index: 3, selectType: "multiple", "key": "id" },
                {'text':"save","key":"is_row_editable",selectType:"single",index:3,role: true},
                {'text':"cancel","key":"is_row_editable",selectType:"single",index:4,role: true},
            ]
            this.tableList.headers[2].option = this.viewTypeOptions
            this.tableList.headers[6].option = this.viewPermissionOptions
            this.GetDatasourceList()
            this.GetTableTypes()
        },
        methods:{
            GetDatasourceList () {
                var _this = this
                var get_datasource_by_env_url = config.ENVIRONMENT_API_URL + '/get_combine_ds_list_by_environment'
                var data = {"env_id": _this.env_id}
                postToServer(_this, get_datasource_by_env_url, data, true).then(response => {
                    _this.allDatasourcesForView = response
                    _this.selectedDatasourceOn = 'create_view_ds_1_data'
                    setTimeout(()=>{
                        _this.GetUserList()
                    })
                }).catch(error_response => {
                    // alert('Error while fetching datasource details ',error_response)
                });
            },  
            GetTableTypes(){
                var _this = this
                var get_datasource_by_env_url = config.JDE_FORMATER_URL + '/get_jde_table_type_list'
                getToServer(_this, get_datasource_by_env_url, true).then(response => {
                    _this.tableList.headers[1].option = response
                }).catch(error_response => {
                    // alert('Error while fetching table type details ',error_response)
                });
            },
            GetUserList(){
                var _this = this
                var getUsersList =  config.AGENT_API_URL +'/get_all_database_user_list'
                let data = {"client_id":_this.clientId, "env_id":_this.env_id}
                data['data_source_details'] = _this.selectedDatasourceObj
                postToServer(_this, getUsersList, data).then(response=>{
                    let users = _.map(response,"username")
                    users.splice(0,0,"PUBLIC")
                    _this.tableList.headers[5].option = users
                }).catch(error=>{

                })
            },
            GetTableList(currentRow, searchText, initiatedHeader){
                var _this = this
                if(!currentRow.viewFor || !currentRow.viewType)
                    return
                let viewForCode = this.findValueFromList(this.tableList.headers[1].option,currentRow.viewFor,'display_text','code')
                let fetchTableFrom = this.findValueFromList(this.tableList.headers[2].option,currentRow.viewType,'display_text','valueForAPI')
                let data = {
                    "table_code":viewForCode,
                    "client_id":this.clientId,
                    "env_id":this.env_id,
                    "fetch_table_from":fetchTableFrom,
                    "data_source_details":this.environmentDetails,
                    "table_name":searchText || ''
                }
                if(!searchText && initiatedHeader){
                    // it means call came from the header edit, so here we have to auto select atleast first table
                    if(currentRow[initiatedHeader.value] && !currentRow.isAllTablesSelected && currentRow[initiatedHeader.value].length){
                        data['table_name'] = currentRow[initiatedHeader.value][0].name
                    }
                }
                _this.loader = true
                postToServer(_this, config.JDE_FORMATER_URL+'/get_jde_table_list_by_code', data).then(response=>{
                    _this.loader = false
                    let allObj = _this.getAllTableJsonOnCode(viewForCode, currentRow.viewFor)
                    if(!initiatedHeader){
                        _this.tableList.headers[3].option = response
                        _this.tableList.headers[3].selectAllText = allObj.display_text
                        _this.tableList.headers[4].option = response
                    }
                    else
                        initiatedHeader.option = response
                }).catch(error=>{
                    _this.loader = false
                    alert("error in fetching table list", error)
                })
            },
            performSearchInHeader(searchValue, header, item, headerindex, itemindex){
                if(!header.performTableSearch)
                    return
                this.GetTableList(item, searchValue,header)
            },
            getAllTableJsonOnCode(code,viewFor){
                let allObj = {"title":"all", "table_name":"all", "code":code}
                if(code == "0")
                    allObj['display_text'] ="All Tables"
                else
                    allObj['display_text'] = "All "+viewFor 
                return allObj
            },
            getColumnList(currentRow,header){
                var _this = this
                if(currentRow.isAllTablesSelected || currentRow.selectedTables.length != 1){
                    return
                }
                _this.column_name_list = []
                let fetchTableFrom = this.findValueFromList(this.tableList.headers[2].option,currentRow.viewType,'display_text','valueForAPI')
                let selectedDatasource = this.environmentDetails[fetchTableFrom]
                let tableName = currentRow.selectedTables[0]

                if(this.table_column_list.length && this.table_column_list[0].table_name === tableName){
                    header.option = _.cloneDeep(this.table_column_list)
                    return
                }
                this.table_column_list = []
                var kwargs = {"table_column_list":this.table_column_list, "env_id":this.env_id,
                "connection_str":selectedDatasource.connection_string,"schema":selectedDatasource.schema_name, 
                "table_name":tableName, "table_type": 'table', "need_column_db_details":true, 
                "db_type":selectedDatasource.database_type}
                getColumnsWithDescription(this,kwargs).then(tableColumnResponse => {
                    let filterColumns = []
                    if(tableColumnResponse && tableColumnResponse.column_list){
                        for(let i=0;i<tableColumnResponse.column_list.length;i++){
                            filterColumns.push({"column_name":tableColumnResponse.column_list[i],'table_name':tableName})
                        }
                        _this.column_name_list = filterColumns
                        header.option = _.cloneDeep(_this.column_name_list)
                     }
                }).catch(error=>{
                    // alert(error)
                })
            },
            onNew(isInitial){
                // before adding new row, first check last rows has been filled or not
                let isRowEditable = _.find(this.tableList.rows,['is_row_editable',true])
                if(isRowEditable){
                    alert('Please fill up the all required values from the editmode row')
                    return
                }
                this.tableList.rows.splice(0, 0, _.cloneDeep(this.rowObject));
            },
            onValueUpdate(event_value, item, header, headerindex, itemindex){
                if(!header)
                    return
                if(header.value === 'viewFor' || header.value === "viewType"){
                    this.GetTableList(item)
                }
            },
            isCallRelatedToTable(item, header){
                if(header.value === 'viewFor' || header.value === "viewType"){
                    if(item.viewFor && item.viewType){
                        this.getTableList(item)
                    }   
                }
            },
            onSave(record){
                var _this = this
                if(!this.validRecord(record))
                    return
                delete record.is_row_editable
                let newRecord = _.cloneDeep(record)
                if(newRecord.id){
                    for(let i=0;i<this.backupRows.length;i++){
                        if(record.id === this.backupRows[i].id){
                            this.backupRows[i] = record
                            break;
                        }
                    }
                }
                else{
                    newRecord['id'] = EDCuuidv4()
                    this.backupRows.splice(0,0,newRecord)
                }
                this.createExecutionSequenceNumber()
                // this.savedata()
            },
            validRecord(record){
                /*
                    Record show have
                        viewFor
                        viewviewType
                        allSelectedTables or something select
                    if not select all
                        then remove all exclude tables and related keys
                    if not user
                        add public user
                        add proper text & title property for single user incase if no record.selectedUsersText available

                */

                if(!record.viewFor || !record.viewType || 
                ((!record.selectedTables || !record.selectedTables.length) && !record.isAllTablesSelected)){
                    alert('View For , View Type & Select Tables For View are compulsory fields')
                    return false
                }
                if(!record.isAllTablesSelected && record.excludedTables.length){
                    record.excludedTables = []
                    record.excludedTablesText = 'N/A'
                    record.excludedTablesTextTitle = 'Not Applicable If user select specific tables for the view creation'
                }
                else if(!record.excludedTables.length){
                    record.excludedTablesText = 'N/A'
                    record.excludedTablesTextTitle = 'Tables not selected'

                }
                if(!record.selectedUsers.length){
                    record.selectedUsers = ["PUBLIC"]
                }
                if(!record.selectedUsersText) {
                    record.selectedUsersText = record.selectedUsers[0]
                    record.selectedUsersTextTitle = record.selectedUsers[0]
                }
                return true
            },
            onDelete(records){
                for(let i=0;i<records.length;i++){
                    this.backupRows = _.filter(this.backupRows,(obj)=>{
                        return obj.id != records[i].id
                    })
                }
                return this.createExecutionSequenceNumber()                
            },
            onCancelEditing(){
                this.tableList.rows = _.cloneDeep(this.backupRows)
            },
            onCancel(){
                
            },
            createExecutionSequenceNumber(){
                var _this = this
                if(_this.backupRows.length <=1)
                {   
                    if(this.backupRows.length === 1)
                        _this.backupRows[0]['exe_seq'] = 1
                    _this.$nextTick(()=>{
                        _this.tableList.rows = _.cloneDeep(_this.backupRows)
                    })
                    return
                }
                let data = {"rule_list":_this.backupRows}
                _this.loader = true
                postToServer(_this, config.JDE_FORMATER_URL +'/get_view_rules_by_execution_sequence', data).then(response=>{
                    _this.loader = false
                    _this.backupRows = response
                    _this.$nextTick(()=>{
                        _this.tableList.rows = _.cloneDeep(_this.backupRows)
                    })
                    _this.savedata()
                }).catch(error=>{
                    _this.loader = false
                    _this.$nextTick(()=>{
                        _this.tableList.rows = _.cloneDeep(_this.backupRows)
                    })
                })
            },
            changeStepper(){

            },
            savedata(is_close){
                if(is_close){
                    this.datasource_error = ''
                }
                let data = this.getStepData()
                // console.log("data selection data =>",data)
                this.$emit('update-object',data, is_close);
            },
            performUndoRedo(){

            },
            getStepData(){
                var _this = this
                if(!_this.stepname)
                    _this.stepname = "Create View"
                return {
                    'stepname':_this.stepname,'step_type':_this.stepType,"description":"",
                    "stepId":this.parentdigramobj.stepId,
                    'step_data':{
                        'step_type':_this.stepType,
                        "view_create_on_ds":this.selectedDatasourceOn,
                        "throw_exception_on_error":this.throwExceptionOnError,
                        "drop_view_already_exists":this.dropAlreadyExitsView,
                        "all_rules_list":this.getAllRules(),
                        "allRulesForUI":this.backupRows
                    },
                }
            },
            getAllRules(){
                let allRules = []
                for(let i=0;i<this.backupRows.length;i++){
                    let currentRow = _.cloneDeep(this.backupRows[i])
                    if(currentRow.is_row_editable)
                        continue                 
                    let viewFor = this.findValueFromList(this.tableList.headers[1].option,currentRow.viewFor,'display_text','name')
                    let  viewType = this.findValueFromList(this.tableList.headers[2].option,currentRow.viewType)
                    let permission = this.findValueFromList(this.viewPermissionOptions,currentRow.selectedPermission)
                    let filter = []
                    if(!currentRow.isAllTablesSelected && currentRow.selectedTables.length===1){
                        if(currentRow.filterObj && currentRow.filterObj.filter && currentRow.filterObj.filter.length){
                            // check first the table in the filter is same as currentRow.selectedTables[0]
                            // if(currentRow.filterObj.filter[0].table_name === currentRow.selectedTables[0]){
                                filter = currentRow.filterObj.filter
                            // }

                        }
                    }
                    allRules.push({
                        "id":currentRow.exe_seq,
                        "view_for":viewFor,
                        "view_type":viewType,
                        "tables_for_view":currentRow.isAllTablesSelected?['all']:currentRow.selectedTables,
                        "exclude_table_list": currentRow.excludedTables || [],
                        "permission":permission,
                        "user_list_for_view":currentRow.selectedUsers,
                        "filter":filter
                    })
                }
                return allRules
            },
            findValueFromList(listToSearch,valueToSearch, keyToSearch='display_text',keyToReturn="value"){
                let obj = _.find(listToSearch,[keyToSearch,valueToSearch])
                if(obj)
                    return obj[keyToReturn]
                return ""
            },
            preventKeyEvent(event){
                if (event.ctrlKey && (event.code === 'KeyZ'||event.code === 'KeyY'))
                    event.preventDefault()
            },
            resetData(){
                this.stepper = 1
                this.stepname = 'Create View'
                this.nameTextboxSize = 20
                this.stepDescription = ''
                this.vueEditorObj = {'description':''}
                this.tableList.rows = []
                this.backupRows = []
                this.runtimeException = "continue"
                this.dropAlreadyExitsView = true
                this.selectedDatasourceOn = 'create_view_ds_1_data'
            },
            StepNameChanged(){

            },
            DescriptionChange(stepDescription){
                this.stepDescription = stepDescription
                this.savedata()
            },
            validateStepOnUserAction(){
                // we are going to call validate object after curtain time after action get performed because graph take sometime to update its json.
                var _this = this
                _this.showErrorPopUp = false
                clearInterval(_this.autoValidateTimer)
                _this.autoValidateTimer = setTimeout(()=>{
                _this.showErrorPopUp = true
                _this.validatestepdata()
                _this.$emit('validatePD')
                },edcConstantObj.AUTO_VALIDATOR_TIMER)
            },
            totalCount(count){
                this.errorListCount = count;
            },
            setError(errorList){
                let _this = this;
                _this.Perform_Validation(errorList);
            },
            validatestepdata(){
                var _this = this
                if(this.readonly)
                    return
                if(_this.validateProgress){
                return false
                }
                _this.validateProgress=true;
                var data = this.getStepData()
                data['env_id'] = this.env_id
                _this.validation_response={}
                // _this.Remove_Error_List();
                validate_process_def_json(_this, data).then(validation_response => {
                _this.validateProgress=false;
                var error_data = {"step_data":validation_response}
                _this.validation_response = error_data
                }).catch(error=>{
                    _this.validateProgress = false;
                })
            },
            Perform_Validation(error_data){
                var _this = this
                _this.Remove_Error_List();
                if (typeof error_data === 'object'){
                    if(error_data.all_rules_list && error_data.all_rules_list.duplicate_row){

                    }
                }
            },
            Remove_Error_List(){
                this.Remove_From_Normal_List(this.columns_for_filter)
            },
            Remove_From_Normal_List(list){
                for(var i=0;i<list.length;i++){
                    list[i].error_list = {}
                }
            },
            Perform_Validation_On_Filter(filter_error_data){
                // var _this = this;
                // var rows = Object.keys(filter_error_data)
                // rows.map(row=>{ // it will provide rowindex like 0,1,2
                //     let columnArray = Object.keys(filter_error_data[row]);
                //     columnArray.map(column=>{ // it will provide key in that object like alies_name, column_name
                //         _this.columns_for_filter[row].error_list[column] = filter_error_data[row][column];
                //     })
                // })
            },
            initiateValidation(newValue){
                return
                this.selectDataItemRule = []
                this.ruleForSelectTables = []
                if(newValue!=2 && (_.isEmpty(this.aliasName) || !this.runOnDS))
                    this.selectDataItemRule = [() => false]
                if(newValue!=4 && !this.selectedTables.length)
                    this.ruleForSelectTables = [() => false]
            },
            changedDataItemSearch(){
                if(this.disabledPrimaryKeyOption){
                    this.additionalInformation = 'noadditionalinformation'
                }
                this.savedata()
            },
        }
    }
</script>
