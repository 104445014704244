<template>
    <v-container class="EDC-Row">
        <v-row class="EDC-Row">
            <v-col class="EDC-Col">
                <v-combobox :multiple="header.useMultiple || false"
                hide-details="auto" v-model="selectedItems"  :items="header.option" dense
                :label="header.label || 'select'" @input="changeAutoCompleteSelection">
                    <template v-slot:selection="{ item, index }">
                        <span v-if="index === 0" class="labelCls"> {{ item }} </span>
                        <span v-if="index === 1" class="labelCls">(+{{ selectedLength - 1 }} {{ header.headerFor }})</span>
                    </template>
                </v-combobox>
            </v-col>
        </v-row>
    </v-container>
</template>
<style scoped>
    .labelCls{
        font-size: 12px !important;
    }
</style>
<script>
    export default {
        name:'EdcTableSearchComponent',
        props:{
            header:{
                type:Object,
                default:()=>{
                    return {
                        useMultiple:false,
                        value:"",
                        option:[],
                        label:"",
                        headerFor:''
                    }
                }
            },
            selectedValue:{
                type:Array,
                default:()=>{
                    return []
                }
            },
            isAllSelected:{
                type:Boolean,
                default:false
            }
        },
        data(){
            return{
                selectAll:false,
                selectedItems:[]
            }
        },
        computed:{
            selectedLength(){
                return this.selectedItems.length
            },
            gridDisplayText(){
                let displayText = ''
                if(this.selectedItems.length){
                    for(let i=0;i<this.selectedItems.length;i++){
                        if(i=== 0)
                            displayText = this.selectedItems[0]
                        if(i===1)
                            displayText  = displayText + " (+" + (this.selectedItems.length-1) + this.header.headerFor+")"
                    }
                }
                return displayText
            },
            gridDisplayTextTitle(){
                let displayTextTitle = ''
                if(this.selectedItems.length){
                    for(let i=0;i<this.selectedItems.length;i++){
                        if(displayTextTitle)
                            displayTextTitle = displayTextTitle+", " 
                        displayTextTitle = displayTextTitle + this.selectedItems[i]
                    }
                }
                return displayTextTitle
            
            },
        },
        watch:{
            headers:{
                handler(newvalue){

                },
                deep:true
            },
            selectedValue:{
                handler(newvalue){
                    this.selectedItems = _.cloneDeep(newvalue)
                }
            }
        },
        mounted(){
            if(this.selectedValue){
                this.selectedItems = _.cloneDeep(this.selectedValue)
            }
        },
        methods:{
            changeAutoCompleteSelection(event){
                let valueObj = {[this.header.value]:this.selectedItems}
                if(this.header.useMultiple){
                    if(this.header.gridDisplayTextKey)
                        valueObj[this.header.gridDisplayTextKey] = this.gridDisplayText
                    if(this.header.gridDisplayTextTitleKey)
                        valueObj[this.header.gridDisplayTextTitleKey] = this.gridDisplayTextTitle
                }
                this.$emit("changeAutoCompleteSelection",valueObj)
            },
        }
    }
</script>