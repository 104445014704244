const joint = require('jointjs')
import * as shapeIcons from './icons.js'
import * as constantObj from './constants.js'
import * as anonymousObj from './anonymousFunctions.js'

var edcBorderImage = new joint.shapes.standard.BorderedImage({
	attrs : {
		root:{
			magnet:false
		},
        image : {
            width : 16,
            height : 16,
			title:''
        },
        border:{

        },
        label:{
        	text:{
        		textWrap:{
        			width: -10, // reference width minus 10
        			ellipsis: true // could also be a custom string, e.g. '...!?'
        		},
				'title':"test"
        	},
			'title':"test"
        }
    }
});

var edcWorkflowBorderImage = edcBorderImage.clone()
edcWorkflowBorderImage.attr('border/stroke','transparent')

var edcWrapperRect  = new joint.shapes.standard.Rectangle({
	attrs:{
		body: {
    		fill: 'transparent',
    		stroke:{
	           	width:0
	        },
		},
		 border:{
		 	"fill":"transparent"
        },
		label: {
    
		}
	}
});

var edcRect  = new joint.shapes.standard.Rectangle({
	attrs:{
		body: {
    		fill: '#B0B0B0',
    		stroke:{
	           	width:1
	        },
		},
		label: {
    
		}
	}
});


var edcPolygon = new joint.shapes.standard.Polygon({

})

var edcEclipse = new joint.shapes.standard.Ellipse({
        
}); 

var edcSimpleRect = new joint.shapes.standard.Rectangle({

})

var getShapeForWorkflow = {'Start':edcEclipse,
							'Condition':edcPolygon,
							'End':edcEclipse,
							'Review':edcSimpleRect,
							'Form Mapping':edcSimpleRect,
							'Wait For Form Submit':edcSimpleRect,
							'Form Selection':edcSimpleRect,
							'email_request':edcSimpleRect}

export function getRubberBandRect(){
	var rect = new joint.shapes.standard.Rectangle();
	rect.prop('is_rubber',true)
	rect.attr({
    	body: {
        	fill: 'transparent',
    	},
    	label: {

		}
	});
	rect.attr("rect/strokeDasharray", "5,5");
	return rect
}

export function getEdcTableShapeWithWrapper(tablesProps){
	if(!tablesProps.has_filter)
		tablesProps.has_filter = false
		if(!tablesProps.has_media)
		tablesProps.has_media = false
	console.log('tablesProps => ',tablesProps)
	let edcTableShape = edcBorderImage.clone()
	let snappedX = anonymousObj.getNearestGridSnapPosition(tablesProps.positionX)
	let snappedY = anonymousObj.getNearestGridSnapPosition(tablesProps.positionY)
	edcTableShape.resize(constantObj.BUSINESS_OBJECT_TABLE_WIDTH, constantObj.BUSINESS_OBJECT_TABLE_HEIGHT);
	edcTableShape.position(snappedX,snappedY)
	edcTableShape.attr('border/rx', constantObj.BUSINESS_OBJECT_TABLE_BORDER_RX);
	edcTableShape.attr('rect/magnet',true);
	edcTableShape.attr('label/title', tablesProps.table_name);
	let fontsize = anonymousObj.getSuitableFontSize(tablesProps.table_name)
	edcTableShape.attr('label/font-size',fontsize)
	edcTableShape.attr('label/text',  anonymousObj.getWrappedText(tablesProps.table_name,60,joint,2,fontsize));
	// edcTableShape.attr('label/text',  tablesProps.table_name);
	edcTableShape.attr('image/title', tablesProps.table_name);
	edcTableShape.attr('label/fill', '#FFFFFF');
	edcTableShape.attr('label/refY', constantObj.BUSINESS_OBJECT_TABLE_NAME_REFY);
	edcTableShape.attr('image/xlinkHref',  'data:image/svg+xml;utf8,' +
	 encodeURIComponent(shapeIcons.getTableWithoutFilter()));

	edcTableShape.prop('table_name',tablesProps.table_name)
	edcTableShape.prop('table_type',tablesProps.table_type)
	edcTableShape.prop('table_description',tablesProps.table_description)
	edcTableShape.prop('edc_cell_type',constantObj.BUSINESS_EDC_CELL_TYPE)
	edcTableShape.prop('is_embed',true)
	edcTableShape.prop('has_filter',tablesProps.has_filter)
	edcTableShape.prop('has_media',tablesProps.has_media)
	
	edcTableShape.prop('applicable_for_REDOUNDO',tablesProps.applicable_for_REDOUNDO)
	if(tablesProps.object_id)
		edcTableShape.prop('object_id',tablesProps.object_id)
	edcTableShape.prop('datasource_index',tablesProps.datasource_index)
	if(tablesProps.datasource_index>=0){
		edcTableShape.attr('label/text',  anonymousObj.getWrappedText((tablesProps.datasource_index+1)+" : "+tablesProps.table_name,60,joint,2,fontsize));
	}
	edcTableShape.prop('datasource_id',tablesProps.datasource_id)
	if(tablesProps.is_merged && tablesProps.merged_tables){
		edcTableShape.prop('is_merged',true)
		edcTableShape.prop('merged_tables',tablesProps.merged_tables)
		tablesProps.merged_label_text = tablesProps.merged_label_text.replace(/\n/g, '')
		applyTextProp(tablesProps.merged_label_text,edcTableShape)
		console.log('tablesProps.merged_label_text',edcTableShape.attr('label/text'))
	}
	if(tablesProps.addDrvProp){
		// edcTableShape.attr('label/text',anonymousObj.getWrappedText(tablesProps.table_description,
		// constantObj.BUSINESS_OBJECT_TABLE_WIDTH,joint,2))
		if(tablesProps.table_type === 'step')
			edcTableShape.prop('is_drv_table', true)
		else
			edcTableShape.prop('is_drv_table',false)
	}
	if(tablesProps.id)
		edcTableShape.set('id',tablesProps.id)
	manageTableIcons(edcTableShape)
	// if(tablesProps.has_filter)
	// 	edcTableShape.attr('image/xlinkHref',  'data:image/svg+xml;utf8,' 
	// 		+encodeURIComponent(shapeIcons.getTableWithFilter()));

	let wrapperRect = edcWrapperRect.clone()
	// -5 for more magnet space, if still the complaint about magnet drawing, +(5/2) value into with 
	// position values so then text will get place at center.

	wrapperRect.resize(constantObj.BUSINESS_OBJECT_TABLE_WIDTH, constantObj.BUSINESS_OBJECT_TABLE_HEIGHT);
	wrapperRect.position(snappedX,snappedY)
	wrapperRect.attr('label/text',anonymousObj.getWrappedText(tablesProps.table_description,
		constantObj.BUSINESS_OBJECT_TABLE_WIDTH,joint,2))
	wrapperRect.attr('label/title',tablesProps.table_description)
	wrapperRect.attr("rect/title", tablesProps.table_name);
	wrapperRect.prop('table_name',tablesProps.table_name)
	wrapperRect.prop('table_type',tablesProps.table_type)
	wrapperRect.prop('table_description',tablesProps.table_description)
	wrapperRect.prop('is_wrapper',true)
	// wrapperRect.attr('label/text',tablesProps.table_description)
	wrapperRect.attr('label/refY', constantObj.BUSINESS_OBJECT_TABLE_DESCRIPTION_REFY);
	wrapperRect.attr("rect/rx", constantObj.BUSINESS_OBJECT_TABLE_BORDER_RX);
	wrapperRect.prop('edc_cell_type',constantObj.BUSINESS_OBJECT_KEYS_CONTAINER_TYPE);
	wrapperRect.attr("root/magnet", false);
	wrapperRect.attr('label/font-size', 10);
	wrapperRect.embed(edcTableShape)
	return [edcTableShape,wrapperRect]
}

export function getBusinessObjectKeysCell(tablesProps){
	if(!tablesProps)
		tablesProps = {
			"table_name":constantObj.BUSINESS_OBJECT_KEYS_NAME,
			"table_type":constantObj.BUSINESS_EDC_CELL_KEY_TYPE,
			"table_description":constantObj.BUSINESS_OBJECT_KEYS_NAME,
			"positionX":10,
			"positionY":10
		}
	let edcTableShape = edcBorderImage.clone()
	edcTableShape.resize(constantObj.BUSINESS_OBJECT_TABLE_WIDTH, constantObj.BUSINESS_OBJECT_TABLE_HEIGHT);
	edcTableShape.position(tablesProps.positionX,tablesProps.positionY)
	edcTableShape.attr('border/rx', constantObj.BUSINESS_OBJECT_TABLE_BORDER_RX);
	edcTableShape.attr('rect/magnet',true);
	edcTableShape.attr('label/text', tablesProps.table_name);
	edcTableShape.attr('label/title', tablesProps.table_name);
	edcTableShape.attr('label/fill', '#FFFFFF');
	edcTableShape.attr('label/refY', constantObj.BUSINESS_OBJECT_TABLE_NAME_REFY);
	edcTableShape.attr('label/font-size',10)
	edcTableShape.attr('image/xlinkHref',  'data:image/svg+xml;utf8,' +
	 encodeURIComponent(shapeIcons.getBusinessIconWithoutKeys()));
	edcTableShape.attr('image/width', 116);
	edcTableShape.attr('image/height', 56);
	edcTableShape.prop('table_name',tablesProps.table_name)
	edcTableShape.prop('table_type',tablesProps.table_type)
	edcTableShape.prop('table_description',tablesProps.table_description)
	edcTableShape.prop('is_special_cell',true)
	edcTableShape.prop('edc_cell_type',constantObj.BUSINESS_EDC_CELL_KEY_TYPE)
	edcTableShape.prop('is_embed',true)
	edcTableShape.attr('./visibility', 'hidden');

	let wrapperRect = edcWrapperRect.clone()
	// -5 for more magnet space, if still the complaint about magnet drawing, +(5/2) value into with 
	// position values so then text will get place at center.

	wrapperRect.resize(constantObj.BUSINESS_OBJECT_TABLE_WIDTH, constantObj.BUSINESS_OBJECT_TABLE_HEIGHT);
	wrapperRect.position(tablesProps.positionX,tablesProps.positionY)
	wrapperRect.attr('label/text',tablesProps.table_description)
	wrapperRect.attr('label/title',tablesProps.table_description)
	// wrapperRect.attr('label/text',tablesProps.table_description)
	wrapperRect.attr('label/refY', constantObj.BUSINESS_OBJECT_TABLE_DESCRIPTION_REFY);
	wrapperRect.attr("rect/rx", constantObj.BUSINESS_OBJECT_TABLE_BORDER_RX);
	wrapperRect.prop('edc_cell_type',constantObj.BUSINESS_EDC_CELL_CONTAINER_TYPE);
	wrapperRect.attr("root/magnet", false);
	wrapperRect.prop('table_name',tablesProps.table_name)
	wrapperRect.prop('table_type',tablesProps.table_type)
	wrapperRect.prop('table_description',tablesProps.table_description)
	wrapperRect.prop('is_special_cell',true)
	wrapperRect.attr('label/font-size', 10);
	wrapperRect.attr('./visibility', 'hidden');
	if(tablesProps.id){
		edcTableShape.set('id',tablesProps.id)
		edcTableShape.attr('./visibility', 'visible');
		wrapperRect.attr('./visibility', 'visible');
	}
	wrapperRect.embed(edcTableShape)

	return [edcTableShape,wrapperRect]

}

export function addIconPropsToShape(edcTableShape,key){
	if(key === 'filter')
		edcTableShape.prop('has_filter',true)
	else if (key === 'media')
		edcTableShape.prop('has_media',true)
	return manageTableIcons(edcTableShape)
}

export function removeIconPropsToShape(edcTableShape,key){
	if(key === 'filter')
		edcTableShape.prop('has_filter',false)
	else if (key === 'media')
		edcTableShape.prop('has_media',false)
	return manageTableIcons(edcTableShape)
}



function manageTableIcons(edcTableShape){
	if(edcTableShape.prop('has_filter') && edcTableShape.prop('has_media'))
		edcTableShape.attr('image/xlinkHref',  'data:image/svg+xml;utf8,' +
		encodeURIComponent(shapeIcons.getTableWithFilterWithMedia()))
	else if(edcTableShape.prop('has_filter'))
		edcTableShape.attr('image/xlinkHref',  'data:image/svg+xml;utf8,' +
		encodeURIComponent(shapeIcons.getTableWithFilter()))
	else if(edcTableShape.prop('has_media'))
		edcTableShape.attr('image/xlinkHref',  'data:image/svg+xml;utf8,' +
		encodeURIComponent(shapeIcons.getTableWithMedia()))
	else
		edcTableShape.attr('image/xlinkHref',  'data:image/svg+xml;utf8,' +
		encodeURIComponent(shapeIcons.getTableWithoutFilter()));
	return true
}

export function getDataSelectionShape(){
	let dataSelection = getProcessDesingnerShape(constantObj.SELECTION_STEP,{"positionX":10,"positionY":10})
	return dataSelection
}

export function getMinusShape(){
	let minusStep = getProcessDesingnerShape(constantObj.MINUS_STEP,{"positionX":100,"positionY":10})
	return minusStep
}

export function getMergeShape(){
	let mergeStep = getProcessDesingnerShape(constantObj.MERGE_STEP,{"positionX":190,"positionY":10})
	return mergeStep
}

export function getArchiveShape(){
	let archiveStep = getProcessDesingnerShape(constantObj.ARCHIVE_STEP,{"positionX":10,"positionY":110})
	return archiveStep
}

export function getCopyShape(){
	let copyStep = getProcessDesingnerShape(constantObj.COPY_STEP,{"positionX":100,"positionY":110})
	return copyStep
}

export function getPurgeShape(){
	let purgeStep = getProcessDesingnerShape(constantObj.PURGE_STEP,{"positionX":190,"positionY":110})
	return purgeStep
}

export function getProcedureShape(){
	let procedureStep = getProcessDesingnerShape(constantObj.PROCEDURE_STEP,{"positionX":10,"positionY":210})
	return procedureStep
}

export function getDuplicateShape(){
	let duplicateStep = getProcessDesingnerShape(constantObj.DUPLICATE_STEP,{"positionX":100,"positionY":210})
	return duplicateStep
}

export function getCreateViewShape(){
	let createViewStep =  getProcessDesingnerShape(constantObj.CREATE_VIEW,{"positionX":190,"positionY":210})
	return createViewStep
}

export function getJDESumm0911Shape(){
	let jdeSummf0911Step = getProcessDesingnerShape(constantObj.JDE_SUMM_F0911,{"positionX":10,"positionY":10})
	return jdeSummf0911Step
}

export function getJDESumm4111Shape(){
	let jdeSummf4111Step = getProcessDesingnerShape(constantObj.JDE_SUMM_F4111,{"positionX":100,"positionY":10})
	return jdeSummf4111Step
}

export function getDataItemSearchShape(){
	let dataItemSearchStep =  getProcessDesingnerShape(constantObj.DATA_ITEM_SEARCH,{"positionX":190,"positionY":10})
	return dataItemSearchStep
}


export function getProcessDesingnerShape(type,position,stepProperties={}){
	let processDesingerShape =  edcBorderImage.clone() 
	if(stepProperties.id)
		processDesingerShape.set('id',stepProperties.id)
	processDesingerShape.attr('label/text', '');
	processDesingerShape.attr('label/title', '');
	processDesingerShape.attr('border/rx', constantObj.PROCESS_DESIGNER_SHAPE_BORDER_RX);
	processDesingerShape.position(position.positionX,position.positionY);
	processDesingerShape.resize(constantObj.PROCESS_DESIGNER_SHAPE_WIDTH, 
		constantObj.PROCESS_DESIGNER_SHAPE_HEIGHT);

	processDesingerShape.prop('steptype',type)
	processDesingerShape.prop('bus_id','')
	if(stepProperties.bus_id)
		processDesingerShape.prop('bus_id',stepProperties.bus_id)
	processDesingerShape.prop('policy_id','')
	if(stepProperties.policy_id)
		processDesingerShape.prop('policy_id',stepProperties.policy_id)
	processDesingerShape.prop('bussinessObjectName','')
	if(stepProperties.bussinessObjectName)
		processDesingerShape.prop('bussinessObjectName',stepProperties.bussinessObjectName)	
	processDesingerShape.prop('step_data',{})
	if(stepProperties.step_data)
		processDesingerShape.prop('step_data',stepProperties.step_data)
	processDesingerShape.prop('stepname',type)
	if(stepProperties.stepname)
		processDesingerShape.prop('stepname',stepProperties.stepname)

	processDesingerShape.prop('edc_cell_type',constantObj.PROCESS_DESIGNER_EDC_CELL_TYPE)
	processDesingerShape.prop('is_embed',true)
	manageShapeIcon(processDesingerShape,false)
	return processDesingerShape
}

export function getDesignerShapes(){
	return [getDataSelectionShape(),getMinusShape(),getMergeShape(),getArchiveShape(),getCopyShape(),
	getPurgeShape(),getProcedureShape(),getDuplicateShape(),getCreateViewShape()]
}

export function getJdeDesignerSteps(){
	return [getJDESumm0911Shape(),getJDESumm4111Shape(),getDataItemSearchShape()]
}

export function getProcessDesignerWrapper(text,position,wrapperFor){
	let wrapperRect = edcWrapperRect.clone()

	// -5 for more magnet space, if still the complaint about magnet drawing, +(5/2) value into with 
	// position values so then text will get place at center.
	if(wrapperFor === 'edcWorkflow'){
		wrapperRect.resize(constantObj.WORKFLOW_SHAPE_WIDTH, constantObj.WORKFLOW_SHAPE_HEIGHT);
		wrapperRect.attr('border/stroke','transparent')
		wrapperRect.attr('label/refY', constantObj.WORKFLOW_DESIGNER_DESCRIPTION_RFEY);
	}
	else{
		wrapperRect.resize(constantObj.PROCESS_DESIGNER_SHAPE_WIDTH, constantObj.PROCESS_DESIGNER_SHAPE_HEIGHT);
		wrapperRect.attr('label/refY', constantObj.PROCESS_DESIGNER_DESCRIPTION_REFY);
	}
	
	wrapperRect.position(position.positionX,position.positionY)
	wrapperRect.attr('label/title',text)
	wrapperRect.attr('rect/title',text)
	let wrappedText = anonymousObj.getWrappedText(text,wrapperRect.size().width,joint,2,10)
	wrapperRect.attr('label/text',wrappedText)
	
	// wrapperRect.attr('label/text',tablesProps.table_description)
	
	wrapperRect.attr("rect/rx", constantObj.PROCESS_DESIGNER_SHAPE_BORDER_RX);
	wrapperRect.prop('edc_cell_type',constantObj.PROCESS_DESIGNER_EDC_CELL_CONTAINER_TYPE);
	wrapperRect.attr("root/magnet", false);
	wrapperRect.prop('step_name',text)
	wrapperRect.attr('label/font-size', 10);
	wrapperRect.attr('label/font-weight',"normal")
	return wrapperRect
}

export function manageShapeIcon(element,glow=false){
	if(!element)
		return
	let element_type = element.prop('steptype')
	switch(element_type){
		case constantObj.SELECTION_STEP:
			element.attr('image/xlinkHref',  'data:image/svg+xml;utf8,' +
	 			encodeURIComponent(shapeIcons.getDataSelectionShape(glow)));
			break;
		case constantObj.MINUS_STEP:
			element.attr('image/xlinkHref',  'data:image/svg+xml;utf8,' +
	 			encodeURIComponent(shapeIcons.getMinusShape(glow)));
			break;
		case constantObj.MERGE_STEP:
			element.attr('image/xlinkHref',  'data:image/svg+xml;utf8,' +
	 			encodeURIComponent(shapeIcons.getMergeShape(glow)));
			break;
		case constantObj.ARCHIVE_STEP:
			element.attr('image/xlinkHref',  'data:image/svg+xml;utf8,' +
	 			encodeURIComponent(shapeIcons.getArchiveShape(glow)));
			break;
		case constantObj.COPY_STEP:
			element.attr('image/xlinkHref',  'data:image/svg+xml;utf8,' +
	 			encodeURIComponent(shapeIcons.getCopyShape(glow)));
			break;
		case constantObj.PURGE_STEP:
			element.attr('image/xlinkHref',  'data:image/svg+xml;utf8,' +
	 			encodeURIComponent(shapeIcons.getPurgeShape(glow)));
			break;
		case constantObj.PROCEDURE_STEP:
			element.attr('image/xlinkHref',  'data:image/svg+xml;utf8,' +
	 			encodeURIComponent(shapeIcons.getProcedureShape(glow)));
			break;
		case constantObj.DUPLICATE_STEP:
			element.attr('image/xlinkHref',  'data:image/svg+xml;utf8,' +
	 			encodeURIComponent(shapeIcons.getDuplicateShape(glow)));
			break;
		case constantObj.JDE_SUMM_F0911:
			element.attr('image/xlinkHref',  'data:image/svg+xml;utf8,' +
	 			encodeURIComponent(shapeIcons.getJDESumm0911Shape(glow)));
			break;
		case constantObj.JDE_SUMM_F4111:
			element.attr('image/xlinkHref',  'data:image/svg+xml;utf8,' +
	 			encodeURIComponent(shapeIcons.getJDESumm4111Shape(glow)));
			break;
		case constantObj.DATA_ITEM_SEARCH:
			element.attr('image/xlinkHref',  'data:image/svg+xml;utf8,' +
					encodeURIComponent(shapeIcons.getDataItemSearchStep(glow)));
			break;
		case constantObj.CREATE_VIEW:
			element.attr('image/xlinkHref',  'data:image/svg+xml;utf8,' +
					encodeURIComponent(shapeIcons.getCreateViewStep(glow)));
			break;
		case constantObj.SEND_EMAIL_STEP:
			element.attr('label/text',  'Send Email');
			element.attr('label/title',  'Send Email');
			// element.attr('label/dy',"100")
			element.attr('text',  { "text": "Send Email", 'font-weight': 'bold' });
			break;
		case constantObj.WAIT_FOR_FORM_DATA_STEP:
			element.attr('label/text',  'Wait \n For \n Form');
			element.attr('label/title',  'Wait \n For \n Form');
			// element.attr('label/dy',"100")
			element.attr('text',  { "text": "Wait For Form", 'font-weight': 'bold' });
			break;
		case constantObj.FORM_MAPPING_STEP:
			element.attr('label/text',  'Form \n Data \n Transfer');
			element.attr('label/title',  'FormData Transfer');
			// element.attr('label/dy',"100")
			element.attr('text',  { "text": "FormData Transfer", 'font-weight': 'bold' });
			break;
		case constantObj.REVIEW_STEP:
			element.attr('label/text',  'Form \n Review');
			element.attr('label/title',  'Form \n Review');
			element.attr('text',  { "text": "From Review", 'font-weight': 'bold' });
		break;
		case constantObj.START_STEP:
			element.attr('label/text',  'Start');
			element.attr('label/title',  'Start');
			element.attr('text',  { "text": "Start", 'font-weight': 'bold' });
		break;
		case constantObj.CONDITION_STEP:
			element.attr('label/text',  'Decision');
			element.attr('label/title',  'Decision');
			element.attr('text',  { "text": "Decision", 'font-weight': 'bold' });
		break;
		case constantObj.FORM_SELECTION_STEP:
			element.attr('label/text',  'Form \n Selection');
			element.attr('label/title',  'Form Selection');
			element.attr('text',  { "text": "Form Selection", 'font-weight': 'bold' });
		break;
		case constantObj.END_STEP:
			element.attr('label/text',  'End');
			element.attr('label/title',  'End');
			element.attr('text',  { "text": "End", 'font-weight': 'bold' });
		break;
		default:
			console.log('not handled element_type',element_type)
	}
	return element
}

var space_size = 10

// function Calculate_responsive_size() {
//     let stencil_paper_width = $('.card-body').width()
//     var one_tenth_part = stencil_paper_width / 10
//     space_size = (one_tenth_part * 1.5) / 3
//     // that.cell_width = (one_tenth_part * 8.5) / 3
// }

export function getEdcBOsForFlowchart(business_obj_list) {
    var cell_list = []
    let cell_width = 60
    var start_point = 10
    var current_space_size = start_point
    var y_on_stencil = 10
    for (var i = 0; i < business_obj_list.length; i++) {
        var formated_bus_name = business_obj_list[i].display_name.replace(/(?!$|\n)([^\n]{8}(?!\n))/g, '$1\n');
        // var cell = getRectangle(current_space_size, y_on_stencil, cell_width, 65, formated_bus_name)
        var cell = edcRect.clone()
        cell.resize(80,60)
        cell.position(current_space_size, y_on_stencil)
        cell.attr('label/text',formated_bus_name)
        cell.attr('label/title', business_obj_list[i].display_name)
        cell.prop('attrs/rect/fill',"#B0B0B0")
        // cell.prop('attrs/text/fill',"#FFFFFF")
        cell.prop('is_bus_obj', true)
        cell.prop('bus_id', business_obj_list[i].object_id)
        cell.prop('bus_name', business_obj_list[i].object_name)
        cell.prop('policy_id', business_obj_list[i].policy_name)
        cell.prop('bus_version', business_obj_list[i].object_version)
        cell.attr('label/font-size', 10);
        current_space_size = current_space_size + cell_width + 30
        if (i > 0 && (i + 1) % 3 === 0) {
            current_space_size = start_point
            y_on_stencil = y_on_stencil + 90
        }
        cell_list.push(cell)
    }
    return cell_list
}



// code for the workflow steps




export function getWorkflowShapes(){
	return [getStartShape(),getSendEmailShape(),getWaitForFormShape(),getReviewShape(),getConditionShape(),getEndShape(),getFormMappingShape(),getFormSelectionhape()]
}

export function getStartShape(){
	let start = getWorkflowShape(constantObj.START_STEP,{"positionX":10,"positionY":10})
	return start
}

export function getSendEmailShape(){
	let send_email = getWorkflowShape(constantObj.SEND_EMAIL_STEP,{"positionX":115,"positionY":10})
	return send_email
}

export function getWaitForFormShape(){
	let wait_for_form = getWorkflowShape(constantObj.WAIT_FOR_FORM_DATA_STEP,{"positionX":230,"positionY":10})
	return wait_for_form
}

export function getFormMappingShape(){
	let form_mapping = getWorkflowShape(constantObj.FORM_MAPPING_STEP,{"positionX":10,"positionY":110})
	return form_mapping
}

export function getReviewShape(){
	let review = getWorkflowShape(constantObj.REVIEW_STEP,{"positionX":115,"positionY":110})
	return review
}

export function getConditionShape(){
	let condition = getWorkflowShape(constantObj.CONDITION_STEP,{"positionX":230,"positionY":110})
	return condition
}

export function getFormSelectionhape(){
	let form_selection = getWorkflowShape(constantObj.FORM_SELECTION_STEP,{"positionX":10,"positionY":210})
	return form_selection
}

export function getEndShape(){
	let end = getWorkflowShape(constantObj.END_STEP,{"positionX":115,"positionY":210})
	return end
}



export function getWorkflowShape(type,position,stepProperties={}){
	let workflowDesingerShape = getShapeForWorkflow[type].clone()
	if(stepProperties.id)
	workflowDesingerShape.set('id',stepProperties.id)
	if(type == constantObj.CONDITION_STEP)
		workflowDesingerShape.attr('body/refPoints', '0,10 10,0 20,10 10,20');
	workflowDesingerShape.attr('label/text', '');
	workflowDesingerShape.attr('border/rx', constantObj.PROCESS_DESIGNER_SHAPE_BORDER_RX);
	workflowDesingerShape.position(position.positionX,position.positionY);
	workflowDesingerShape.resize(constantObj.WORKFLOW_DESIGNER_SHAPE_WIDTH, 
		constantObj.WORKFLOW_DESIGNER_SHAPE_HEIGHT);	
	workflowDesingerShape.prop('steptype',type)	
	workflowDesingerShape.prop('shape_type',type)	
	workflowDesingerShape.prop('step_data',{})
	if(stepProperties.step_data)
		workflowDesingerShape.prop('step_data',stepProperties.step_data)
	if(stepProperties.input_data)
		workflowDesingerShape.prop('input_data',stepProperties.input_data)
	workflowDesingerShape.prop('stepname',type)
	if(type === constantObj.SEND_EMAIL_STEP)
		workflowDesingerShape.prop('stepname',"Send Email Request")
	if(stepProperties.stepname){
		workflowDesingerShape.prop('stepname',stepProperties.stepname)
	}

	workflowDesingerShape.prop('edc_cell_type',constantObj.WORK_FLOW_EDC_CELL_TYPE)
	workflowDesingerShape.prop('is_embed',true)
	// workflowDesingerShape.prop('withoutWrapper',true)
	workflowDesingerShape.prop('isMagnetAtBody',true)
	manageShapeIcon(workflowDesingerShape,false)
	return workflowDesingerShape
}


export function getEdcWorkflowSteps(){
	return [getWorkFlowShapes(constantObj.EDC_BEGIN,constantObj.row0col0Location),
			getWorkFlowShapes(constantObj.EDC_CONDITION,constantObj.row0col1Location),
			getWorkFlowShapes(constantObj.EDC_SEND_EMAIL,constantObj.row0col2Location),
			getWorkFlowShapes(constantObj.EDC_END,constantObj.row1col0Location)]
}



export function formBasedShapes(){
	return [getWorkFlowShapes(constantObj.EDC_SEND_FORM_LINK,constantObj.row0col0Location),
			getWorkFlowShapes(constantObj.EDC_FORM_REVIEW,constantObj.row0col1Location),
			getWorkFlowShapes(constantObj.EDC_FORM_RESPONSE_WAIT,constantObj.row0col2Location),
			getWorkFlowShapes(constantObj.EDC_FORM_DATA_TRANSFER,constantObj.row1col0Location),
			getWorkFlowShapes(constantObj.EDC_APPROVAL,constantObj.row1col1Location)
			]
}




export function jdeBasedShapes(){
	return [getWorkFlowShapes(constantObj.JDE_SUPPLIER,constantObj.row0col0Location),
			getWorkFlowShapes(constantObj.JDE_CUSTOMER,constantObj.row0col1Location),
			getWorkFlowShapes(constantObj.JDE_ITEM,constantObj.row0col2Location)]
}


export function getWorkFlowShapes(shapeProperties,position,otherProps){
	let wfShape =  edcWorkflowBorderImage.clone()
	if(shapeProperties.id)
		wfShape.set('id',shapeProperties.id)
	wfShape.attr('image/title',shapeProperties.name)
	wfShape.prop('steptype',shapeProperties.value)
	wfShape.prop('stepname',shapeProperties.name)
	if(shapeProperties.outputType)
		wfShape.prop('outputType',shapeProperties.outputType)
	wfShape.prop('is_for_status',shapeProperties.is_for_status)
	wfShape.prop('shape_type',shapeProperties.value)
	wfShape.prop('withoutWrapper',shapeProperties.withoutWrapper)
	wfShape.prop('input_data',{})
	if(shapeProperties && shapeProperties.input_data)
		wfShape.prop('input_data',shapeProperties.input_data)
	else if(otherProps && otherProps.input_data)
		wfShape.prop('input_data',otherProps.input_data)
	if(!wfShape.prop('withoutWrapper'))
		wfShape.prop('is_embed',true)
	wfShape.position(position.positionX,position.positionY)
	wfShape.resize(constantObj.WORKFLOW_SHAPE_WIDTH, constantObj.WORKFLOW_SHAPE_HEIGHT);
	wfShape.attr('label/text', '');
	wfShape.attr('border/magnet',true)
	wfShape.attr('border/strokeWidth',24)
	wfShape.attr('border/rx', constantObj.PROCESS_DESIGNER_SHAPE_BORDER_RX);
	manageWorkflowShapesIcon(wfShape)
	return wfShape

}

function manageWorkflowShapesIcon(shape,glow=false) {
	if(!shape)
		return
	let shape_type = shape.prop('steptype')
	switch(shape_type){
		case constantObj.EDC_BEGIN.value:
			shape.attr('image/xlinkHref',  'data:image/svg+xml;utf8,' +
	 			encodeURIComponent(shapeIcons.getEdcWFBeginIcon(glow)));
			break;
		case constantObj.EDC_END.value:
			shape.attr('image/xlinkHref',  'data:image/svg+xml;utf8,' +
	 			encodeURIComponent(shapeIcons.getEndIcon(glow)));
			break;
		case constantObj.EDC_CONDITION.value:
			shape.attr('image/xlinkHref',  'data:image/svg+xml;utf8,' +
	 			encodeURIComponent(shapeIcons.getEdcDecisionIcon(glow)));
			break;
		case constantObj.EDC_SEND_EMAIL.value:
			shape.attr('image/xlinkHref',  'data:image/svg+xml;utf8,' +
	 			encodeURIComponent(shapeIcons.getEmailSendIcon(glow)));
			break;
		case constantObj.EDC_SEND_FORM_LINK.value:
			shape.attr('image/xlinkHref',  'data:image/svg+xml;utf8,' +
	 			encodeURIComponent(shapeIcons.getFormLinkIcon(glow)));
			break;
		case constantObj.EDC_FORM_REVIEW.value:
			shape.attr('image/xlinkHref',  'data:image/svg+xml;utf8,' +
	 			encodeURIComponent(shapeIcons.getEdcReviewIcon(glow)));
			break;
		case constantObj.EDC_FORM_RESPONSE_WAIT.value:
			shape.attr('image/xlinkHref',  'data:image/svg+xml;utf8,' +
	 			encodeURIComponent(shapeIcons.getFormResponseWaitIcon(glow)));
			break;
		case constantObj.EDC_FORM_DATA_TRANSFER.value:
			shape.attr('image/xlinkHref',  'data:image/svg+xml;utf8,' +
	 			encodeURIComponent(shapeIcons.getFormTransferIcon(glow)));
			break;
		case constantObj.EDC_APPROVAL.value:
			shape.attr('image/xlinkHref',  'data:image/svg+xml;utf8,' +
	 			encodeURIComponent(shapeIcons.getFormApprovalIcon(glow)));
			break;
		case constantObj.JDE_SUPPLIER.value:
			shape.attr('image/xlinkHref',  'data:image/svg+xml;utf8,' +
	 			encodeURIComponent(shapeIcons.getJDESupplierIcon(glow)));
			break;
		case constantObj.JDE_CUSTOMER.value:
			shape.attr('image/xlinkHref',  'data:image/svg+xml;utf8,' +
	 			encodeURIComponent(shapeIcons.getJDECustomerIcon(glow)));
			break;
		case constantObj.JDE_ITEM.value:
			shape.attr('image/xlinkHref',  'data:image/svg+xml;utf8,' +
	 			encodeURIComponent(shapeIcons.getJDEItemIcon(glow)));
			break;
		default:
			console.log('not handled shape_type', shape_type)
		return shape
	}
}

export function modifyCellProps(cell,text,props,parent){
	applyTextProp(text,cell)
	for(let i=0;i<props.length;i++){
		cell.prop(props[i].key,props[i].value)
		if(parent){
			if(props[i].key === 'table_description')
				parent.attr('label/text', anonymousObj.getWrappedText(props[i].value,parent.size().width,joint,2));
			parent.prop(props[i].key,props[i].value)
		}
	}
}

function applyTextProp(text,cell){
	if(!text)
		return
	let fontsize = anonymousObj.getSuitableFontSize(text)
	cell.attr('label/font-size',fontsize)
	cell.attr('label/text',  anonymousObj.getWrappedText(text,60,joint,2,fontsize));
}