<template>
    <v-flex id="mainflex">
        <v-card class="rounded-card EDC-Designer-Row">
            <v-container class="EDC-Container">
                <v-row class="EDC-Row" justify="start">
                <v-col class="EDC-Col text-truncate" cols="10" align="left">
                    <ul class="breadcrumb" style="display:inline-flex;">
                    <li><a @click="savedata(true)"> Data Item Search </a></li>
                    <li>[{{stepSeqNo}}] <input type="text" v-model="stepname" :title="stepname" @blur="StepNameChanged()" :readonly="readonly" @keydown="preventKeyEvent" :size="nameTextboxSize"/></li>
                    </ul>

                    <span v-if='!readonly' style="font-weight:400;font-size:13px;margin-left:5px;" v-bind:class="{errorcls:is_error_occured , successcls:!is_error_occured}">{{'('+savedstatus + ')'}}</span>

                    <span v-else style="color:rgba(0,0,0,0.54);font-weight:400;font-size:13px;margin-left:5px;">
                    (Readonly Mode)</span>

                    <span style="font-weight:400;font-size:13px;margin-left:5px;" :style="{'color': colorCode}">{{datasource_error||redoundoMsg}}</span>
                </v-col>
                <v-col cols="2" class="EDC-Col">

                    <v-icon style="font-size:20px;float:right;padding-left:12px !important;" class="v-align" @click="stepper=1;savedata(true)" title='close'>far fa-times-circle</v-icon>

                    <span class="" style="font-size:13px;float:right;margin-right:5px;" :style="{'color': colorCode}">
                    <v-progress-circular :width="3" v-if="validateProgress" :size="20" color="blue" indeterminate></v-progress-circular>
                    <showerrorlisttemplate :validation_response="validation_response" :stepType="stepType"
                        @set-error="setError" @totalCount="totalCount" :errorListCount="errorListCount" :showprocess="validateProgress ? 'Y':'N'" style="margin-top:2px !important;margin-left:4px !important;" :showErrorPopUp="showErrorPopUp"></showerrorlisttemplate></span>

                        <span style="float:right;" class="v-align" :style="{'color': colorCode}">
                    <svgicon class="svg-icon-grid svg-fill-grid" name="validate"  style="float:right;margin-top:-20%;"  title="Validate Step" @click="validatestepdata" ></svgicon>
                    </span>
                </v-col>
                </v-row>
                <v-row class="EDC-Row">
                    <v-col class="EDC-Col" align="left">
                        <edc-graph-toolbar style="padding-left:4px !important;" :redoCount="undoStackCount" :undoCount="redoStackCount" key="selection-desinger" @performUndoRedo="$emit('keyupHandler',$event)" @showDescription="descriptionPanel = true" @onSave="$emit('saveProcessDesigner')" :showNewVersion="false"></edc-graph-toolbar>
                    </v-col>
                </v-row>
            </v-container>
            <v-container class="EDC-Container">
                <v-row class="EDC-Row" style="height:60vh !important">
                    <v-col cols="12" class="EDC-Col">
                        <v-stepper v-model="stepper" class="EDC-Stepper" non-linear @change="changeStepper">
                            <v-stepper-header class="EDC-StepperHeader">
                                <v-stepper-step :step="1" class="EDC-StepperStep" editable>
                                    Data Item Search For
                                </v-stepper-step>
                                <v-divider class="EDC-StepperDivider"></v-divider>
                                <v-stepper-step :step="2" :rules="selectDataItemRule" class="EDC-StepperStep" editable>
                                    Select Data Item & Search On
                                </v-stepper-step>
                                <v-divider class="EDC-StepperDivider"></v-divider>
                                <v-stepper-step :step="3"  class="EDC-StepperStep" editable>
                                    Selection Options
                                </v-stepper-step>
                                <v-divider class="EDC-StepperDivider"></v-divider>
                                <v-stepper-step :step="4" :rules="ruleForSelectTables" class="EDC-StepperStep" editable>
                                    Search In
                                </v-stepper-step>
                                <v-divider class="EDC-StepperDivider"></v-divider>
                                <v-stepper-step :step="5"  class="EDC-StepperStep" editable>
                                    Exclude From
                                </v-stepper-step>
                                <v-divider class="EDC-StepperDivider"></v-divider>
                                <v-stepper-step :step="6" class="EDC-StepperStep" :editable="isShowFilter">
                                    Filters
                                </v-stepper-step>
                            </v-stepper-header>
                            <v-stepper-items>
                                <v-stepper-content class="EDC-Stepper-Content" :step="1" elevation="0">
                                    <v-row>
                                        <v-col cols="4">
                                            <template v-for="(item,index) in dataItemSearchTypeList">
                                                <v-row class="EDC-Row" :key="index+'dataItemSearchTypeList'">
                                                    <v-col class="EDC-Col">
                                                        <v-radio-group hide-details v-model="dataItemSearchType" dense @change="changedDataItemSearch();validateStepOnUserAction()">
                                                            <v-radio :disabled="readonly" class="customCheckbox"
                                                            :color="colorCode" :label="item.displayName"  :value="item" :title="item.description + '\n' +item.example">
                                                            </v-radio>
                                                        </v-radio-group>
                                                    </v-col>
                                                </v-row>
                                            </template>
                                        </v-col>
                                        <!-- <v-col cols="1" class="EDC-Col">
                                          <v-divider style="margin:4px !important;" vertical></v-divider>
                                        </v-col>
                                        <v-col cols="4" align="left"><span style="font-size:13px;">{{dataItemSearchType.description}}</span>
                                        <br/><br/> <span style="font-size:13px;">{{dataItemSearchType.example}}</span>
                                         </v-col> -->
                                    </v-row>
                                </v-stepper-content>
                                <v-stepper-content class="EDC-Stepper-Content" :step="2" elevation="0">
                                    <v-row>
                                        <v-col cols="6">
                                            <v-row class="EDC-Row">
                                                <v-col class="EDC-Col" cols="6">
                                                    <v-autocomplete v-model="aliasName" :disabled="readonly" label="Search Alias" cache-items hide-no-data dense
                                                    :loading="loading" :items="aliasList" @input="savedata();resetFilters();validateStepOnUserAction()" :search-input.sync="aliasSearch" item-text="display_name" return-object
                                                    ></v-autocomplete>
                                                </v-col>
                                                <v-col class="EDC-Col" cols="5" style="padding-left:4px !important;">
                                                    <v-checkbox :disabled="readonly || isMultikeyAlias" :color="colorCode" label="Search Related Aliases" v-model="addRelatedAliases"
                                                    class="customCheckbox" hide-details @change="savedata();validateStepOnUserAction()" title="Search All related aliases in database. \n For example CCCO (Company Name), you can select data for KCOO, KCO."></v-checkbox>
                                                </v-col>
                                            </v-row>
                                            <v-row class="EDC-Row">
                                                <v-col class="EDC-Col" cols="6">
                                                    <v-autocomplete v-model="runOnDS" :disabled="readonly" label="Search Data Item On" hide-no-data dense
                                                    :items="dsList" item-text="displayName" item-value="dsKeyName"
                                                    @input="selectedTableList=[];tableExcludeList=[];GetTables('',1);savedata();validateStepOnUserAction()">
                                                    </v-autocomplete>
                                                </v-col>
                                            </v-row>
                                        </v-col>
                                    </v-row>
                                </v-stepper-content>
                                <v-stepper-content class="EDC-Stepper-Content" :step="3" elevation="0">
                                    <v-row class="EDC-Row">
                                        <v-col cols="6">
                                            <v-row>
                                                <v-col cols="12">
                                                    <v-row class="EDC-Row">
                                                        <v-col class="EDC-Col">
                                                            <v-row class="EDC-Row">
                                                                <v-col class="EDC-Col">
                                                                    <v-checkbox :disabled="readonly" :color="colorCode" label="Add Table Name In Work Table" v-model="addTableNameInWorkTbl"
                                                                    class="customCheckbox" hide-details @change="savedata()" title="Add table name in worktable against the value for easy troubleshoting"></v-checkbox>
                                                                </v-col>
                                                            </v-row>
                                                            <v-row class="EDC-Row" v-if="addTableNameInWorkTbl">
                                                                <v-col cols="1" class="EDC-Col"></v-col>
                                                                <v-col cols="10" class="EDC-Col">
                                                                    <v-radio-group hide-details v-model="includeTableDescription" dense @change="savedata()">
                                                                        <v-radio :disabled="readonly" class="customCheckbox" :color="colorCode" label="Add Table Description"  :value="true" title="Add table description into the worktable"></v-radio>
                                                                    </v-radio-group>
                                                                </v-col>
                                                            </v-row>
                                                            <v-row class="EDC-Row" v-if="addTableNameInWorkTbl">
                                                                <v-col cols="1" class="EDC-Col"></v-col>
                                                                <v-col cols="10" class="EDC-Col">
                                                                    <v-radio-group hide-details v-model="includeTableDescription" dense @change="savedata()">
                                                                        <v-radio :disabled="readonly" class="customCheckbox" :color="colorCode" label="Exlcude Table Description"  :value="false" title="Do not add table description into the worktable"></v-radio>
                                                                    </v-radio-group>
                                                                </v-col>
                                                            </v-row>
                                                        </v-col>
                                                    </v-row>
                                                </v-col>
                                            </v-row>
                                            <v-divider class="EDC-Divider"></v-divider>
                                            <v-row class="EDC-Row">
                                                <v-col class="EDC-Col">
                                                    <v-checkbox :disabled="readonly" :color="colorCode" label="Drop Work Table Once Job Finished" v-model="dropTableOnJobFinish"
                                                    class="customCheckbox" hide-details @change="savedata()" title="Drop work table from databse as part of job cleaning process"></v-checkbox>
                                                </v-col>
                                            </v-row>
                                            <v-divider class="EDC-Divider"></v-divider>
                                            <v-row class="EDC-Row" style="padding-top:8px !important;">
                                                <v-col class="EDC-Col" align="left" cols="12">
                                                    <v-row class="EDC-Row">
                                                        <v-col class="EDC-Col" align="left">
                                                            <v-label>Action For Missing Business Object</v-label>
                                                        </v-col>
                                                    </v-row>
                                                    <v-row class="EDC-Row">
                                                        <v-col cols="1" class="EDC-Col"></v-col>
                                                        <v-col cols="10" class="EDC-Col">
                                                            <v-radio-group hide-details v-model="missingBusinessObjectAction" dense @change="savedata()">
                                                                <v-radio :disabled="readonly" class="customCheckbox"
                                                                :color="colorCode" label="Skip Missing Objects"
                                                                value="skip" title="Skip the selected object if object present in the environment or in the database"></v-radio>
                                                            </v-radio-group>
                                                        </v-col>
                                                    </v-row>
                                                    <v-row class="EDC-Row">
                                                        <v-col cols="1" class="EDC-Col"></v-col>
                                                        <v-col cols="10" class="EDC-Col">
                                                            <v-radio-group hide-details v-model="missingBusinessObjectAction" dense @change="savedata()">
                                                                <v-radio :disabled="readonly" class="customCheckbox"
                                                                :color="colorCode" label="Throw An Error"
                                                                value="error" title="Throw an error if selected object not present in the environment or in the database"></v-radio>
                                                            </v-radio-group>
                                                        </v-col>
                                                    </v-row>
                                                </v-col>
                                            </v-row>
                                            <v-divider class="EDC-Divider"></v-divider>
                                            <!--<v-row class="EDC-Row">
                                                <v-col class="EDC-Col" align="left">
                                                    <v-checkbox :disabled="readonly" :color="colorCode" label="Add Data Item Count In Worktable" v-model="isSelectCountRequired"
                                                    class="customCheckbox" hide-details @change="savedata()" title="Add Data Item Count In Worktable"></v-checkbox>
                                                </v-col>
                                            </v-row>-->
                                            <v-row class="EDC-Row">
                                                <v-col class="EDC-Col" align="start" cols="12">
                                                    <v-row class="EDC-Row">
                                                        <v-col class="EDC-Col" align="left">
                                                            <v-label>Additional Information</v-label>
                                                        </v-col>
                                                    </v-row>
                                                    <v-row class="EDC-Row">
                                                        <v-col cols="1" class="EDC-Col"></v-col>
                                                        <v-col cols="10" class="EDC-Col" align="left">
                                                            <v-radio-group hide-details v-model="additionalInformation" dense @change="savedata()">
                                                                <v-radio :disabled="readonly" class="customCheckbox"
                                                                :color="colorCode" label="No Additional Information Required"
                                                                value="noadditionalinformation" title="No Additional Information Required"></v-radio>
                                                            </v-radio-group>
                                                        </v-col>
                                                    </v-row>
                                                    <v-row class="EDC-Row">
                                                        <v-col cols="1" class="EDC-Col"></v-col>
                                                        <v-col cols="10" class="EDC-Col">
                                                            <v-radio-group hide-details v-model="additionalInformation" dense @change="savedata()">
                                                                <v-radio :disabled="readonly" class="customCheckbox"
                                                                :color="colorCode" label="Add Data Item Count In Worktable"
                                                                value="addcountdata" title="Add Data Item Count In Worktable"></v-radio>
                                                            </v-radio-group>
                                                        </v-col>
                                                    </v-row>
                                                    <v-row class="EDC-Row">
                                                        <v-col cols="1" class="EDC-Col"></v-col>
                                                        <v-col cols="10" class="EDC-Col">
                                                            <v-radio-group hide-details v-model="additionalInformation" dense @change="savedata()">
                                                                <v-radio :disabled="disabledPrimaryKeyOption || readonly" class="customCheckbox"
                                                                :color="colorCode" label="Add Related Primary Key Information"
                                                                value="addprimarykeydata" title="Add Related Primary Key Information"></v-radio>
                                                            </v-radio-group>
                                                        </v-col>
                                                    </v-row>
                                                </v-col>
                                            </v-row>
                                        </v-col>
                                        <v-divider vertical></v-divider>
                                        <v-col cols="6">
                                            <v-row>
                                                <v-col align="left">
                                                    <v-checkbox :disabled="readonly" :color="colorCode" label="Only Include JDE Tables" v-model="onlyIncludeJDETables"
                                                    class="customCheckbox" hide-details @change="savedata();" title="Only consider JD Edward tables in the item search"></v-checkbox>
                                                </v-col>
                                            </v-row>
                                        </v-col>
                                    </v-row>
                                    
                                </v-stepper-content>
                                <v-stepper-content class="EDC-Stepper-Content" :step="4" elevation="0">
                                    <edc-dragable-list  :key="'include'+parentdigramobj.stepId" sourceListLabel="Search In Availables"
                                    targetListLabel="Search In Selected" :sourceItemInputList="selectItemInputList"
                                    :sourceColDimention="sourceColDimention" @searchInSource="selectTableSearch = $event"
                                    :targetColDimention="targetColDimention" sourceListId="selectList"
                                    selectListId="selectTargetList" @sendOutputList="validateStepOnUserAction();selectedTables=$event"
                                    :defaultTargetSelection="defaultSelectTargetSelection"
                                    keyForSort="itemIndex" keyForUnique="display_name"></edc-dragable-list>
                                </v-stepper-content>
                                <v-stepper-content class="EDC-Stepper-Content" :step="5" elevation="0">
                                    <edc-dragable-list :key="'include'+parentdigramobj.stepId" sourceListLabel="Search In Availables"
                                    targetListLabel="Search In Selected" :sourceItemInputList="excludeItemInputList"
                                    :sourceColDimention="sourceColDimention" @searchInSource="validateStepOnUserAction();excludeTableSearch = $event"
                                    :targetColDimention="targetColDimention" sourceListId="excludeList"
                                    targetListId="excludeTargetList"  @sendOutputList="excludedTables=$event"
                                    :defaultTargetSelection="defaultExcludeTargetSelection"
                                    keyForSort="itemIndex" keyForUnique="display_name"></edc-dragable-list>
                                </v-stepper-content>
                                <v-stepper-content class="EDC-Stepper-Content" :step="6" elevation="0">
                                     <v-row class="EDC-Row">
                                        <v-col class="EDC-Col" cols="12">
                                            <new-edc-criteria key="criteria" :columns_for_filter="columns_for_filter"
                                            :column_name_list="column_name_list"
                                            @savedata="saveCriteria" @new="add_new_filter_row"
                                            @delete="delete_filter_row" :acceleratorObj="acceleratorObj"
                                            :new_columns_for_filter="main_filterUI" :secondaryfilter="secondary_filterUI"
                                            :readonly='readonly' :policy_list="policy_list" 
                                            :showpolicies="true"></new-edc-criteria>
                                        </v-col>
                                    </v-row>
                                </v-stepper-content>
                            </v-stepper-items>
                        </v-stepper>
                    </v-col>
                </v-row>
                <v-row class="EDC-Row">
                    <v-col cols="12"  align="end">
                        <vc-button itemText="Previous" @click.native="stepper > 1?stepper--:stepper=6"></vc-button>
                        <vc-button itemText="Next" @click.native="stepper === 6?stepper=1:stepper++"></vc-button>
                    </v-col>
                </v-row>
            </v-container>
        </v-card>

        <v-dialog v-model="descriptionPanel" width="1000" eager>
            <v-card>
                <rich-text-box style="backgroud:white" key="selectionRichTextBox" @DescriptionChange="DescriptionChange" :vueEditorObj="vueEditorObj">

                </rich-text-box>
                <v-card-actions>
                <v-spacer></v-spacer>
                <vc-button  itemText= "Close" @click.native="descriptionPanel = false">

                </vc-button>
                </v-card-actions>
            </v-card>
        </v-dialog>

    </v-flex>
</template>
<style scoped>
    .customCheckbox >>> label{
        top: 5px !important;
        cursor: help;
    }

</style>
<script>
    import showerrorlisttemplate from './../../views/showerrorlisttemplate.vue'
    import RichTextBox from "@/views/richTextEditor/index.vue";
    import edcGraphToolbar from '@/views/edcGraph/edcGraphToolbar.vue'
    import edcDraggableLists from '@/views/draggableLists.vue'
    import * as edcConstantObj from '../../methods/edcGraph/constants.js'
    import { post as postToServer } from '@/methods/serverCall.js';
    import { get as getToServer } from '@/methods/serverCall.js';
    import {COLOR_CODE,BTN_COLOR} from '@/data/macros.js'
    import config from '../../config.json'
    import Criteria from '@/views/newcriteria.vue';
    import {validate_process_def_json} from "../../methods/call_validation.js"
    import {getColumnsWithDescription} from "../../methods/agent_api_call.js"

    export default {
        name:"edcDataItemSearch",
        components:{
            'edc-graph-toolbar':edcGraphToolbar,
            'edc-dragable-list':edcDraggableLists,
            'rich-text-box':RichTextBox,
            showerrorlisttemplate,
            'new-edc-criteria':Criteria,
        },
        props:{
            parentdigramobj:{
                type:Object,
                default:{"name":"dataselection"}
            },
            savedatastatus:{
                type:String,
                default:''
            },
            iserroroccured:{
                type:Boolean,
                default:false
            },
            datasourceerror:{
                type:String,
                default:''
            },
            acceleratorObj:{
                type:Object,
                default:{}
            },
            readonly:{
                type:Boolean,
                default:false
            },
            redoundoCommandObj:{
                type:Object
            },
            redoundoMsg:{
                type:String,
            },
            undoStack:{
                type:Array
            },
            redoStack:{
                type:Array
            },
            environmentDetails:{
                type:Object,
                default:()=>{
                    return {}
                }
            },
            tableList:{
                type:Array,
                default:()=>{
                    return []
                }
            },
            businessObjectList:{
                type:Array,
                default:()=>{
                    return []
                }
            },
            policies:{
                type:Array,
                default:[]
            },
        },
        data(){
            return{
                dataItemSearchTypeList:[
                                        {"displayName":"All Unique Value","value":"all_unique",
                                        'description':'Find all unique values for a data item from tables (objects) in scope.',
                                        'example':'For example, find all Sales Order Numbers from ALL Sales Order (F4201, F42019, F4211, F42119, etc) tables.'},

                                        {"displayName":"A Specific Value","value":"filtered",
                                        'description':'Look for a specific value in tables (objects) in scope.',
                                        'example':'For example, find all tables and records containing records related to a specific Company, Address Book or Item Number'},

                                        {"displayName":"Missing Master Data","value":"missing_master",
                                        'description':'Find data from tables (objects) in scope where the related master data record is missing.',
                                        'example':'For example, search through Sales Order tables where the Address Book numbers do not exist in Address Book Master (F0101).'},

                                        {"displayName":"Unused Master Data","value":"not_used_master",
                                        'description':'Find master data which does not have related data in tables (objects) in scope.',
                                        'example':'For example, look for Item Master records which have no related Sales Orders or Purchase Orders.'},

                                        {"displayName":"Master Data Referenced In Other Tables","value":"used_master",
                                        'description':'Find master data which does have related data in tables (objects) in scope.',
                                        'example':'For example, find all Sales Orders for a given Item Number.'}
                                    ],
                dataItemSearchType:{},
                colorCode:COLOR_CODE,
                stepType:edcConstantObj.DATA_ITEM_SEARCH,
                stepSeqNo:1,
                stepname:'Data Item Search',
                nameTextboxSize:20,
                savedstatus:'edited',
                datasource_error:'',
                is_error_occured:false,
                showErrorPopUp:false,
                undoStackCount:0,
                redoStackCount:0,
                descriptionPanel:false,
                stepper:1,
                runOnDS:'',
                datasourceKeys : [{"key":'source_data',"display":"Source",'hasBusinessObj':true},
                {"key":'target_data',"display":"Target",'hasBusinessObj':true},
                {"key":'catalog_data',"display":"Catalog"},
                {"key":'control_data',"display":"Control"},
                {"key":'dictionary_data',"display":"Dictionary",'isForFetchAliases':true},
                {"key":'object_librarian_data',"display":"Object Librarian"}],
                dsList:[],
                dsKeyName:'',
                loading:false,
                aliasSearch:'',
                aliasList:[],
                aliasName:{},
                addRelatedAliases:true,
                selectedTables:[],
                tableLoading:false,
                selectedTableList:[],
                selectTableSearch:'',
                excludeTableSearch:'',
                excludedTables:[],
                tableExcludeList:[],
                addTableNameInWorkTbl:true,
                includeTableDescription:true,
                dropTableOnJobFinish:true,
                validation_response:{},
                selectDataItemRule:[],
                dataItemObj:{},
                fetchAliasesFrom:{},
                clientId:this.$session.get('client_id'),
                aliasSearchTimer:null,
                tableListTimer:null,
                selectItemInputList:[],
                excludeItemInputList:[],
                sourceColDimention:[{cols:"12","itemKey":"display_name", "columnFor":"Object Name"}],
                targetColDimention:[{cols:"12","itemKey":"display_name", "columnFor":"Object Name"}],
                currentTableSearch:'',
                defaultSelectTargetSelection:[],
                defaultExcludeTargetSelection:[],
                env_id:0,
                descriptionPanel:false,
                stepDescription:'',
                vueEditorObj:{},
                columns_for_filter:[],
                column_name_list:[],
                main_filterUI:[],
                secondary_filterUI:{groupList:[]},
                filter_column_tbl_obj: {
                    's_parentheses': '',
                    'column_name': '',
                    'operator': '',
                    'v_type1': 'Value',
                    'v_type2': 'Value',
                    'value1': '',
                    'value2':'',
                    'e_parentheses': '',
                    'is_drv_table': false,
                    'ref_row_id':'',
                    'ref_step_id':''
                },
                auto_increment_id:0,
                missingBusinessObjectAction:'skip',
                ruleForSelectTables:[],
                validateProgress:false,
                errorListCount:0,
                autoValidateTimer:0,
                additionalInformation:'noadditionalinformation',
                table_column_list:[],
                policy_list:[],
                onlyIncludeJDETables:true
            }
        },
        computed:{
            isDataBaseAliasSelected(){
                return this.aliasName && this.aliasName.alias_type === 'D'
            },
            isCustomAlias(){
                return this.aliasName && this.aliasName.alias_type === 'C'
            },
            isShowFilter(){
                //return this.dataItemSearchType && this.dataItemSearchType.value === 'filtered'
                return this.dataItemSearchType && true
            },
            isMultikeyAlias(){
                return this.isCustomAlias && this.aliasName.alias && this.aliasName.alias.split(',').length>1
            },
            getSelectedDS(){
                if(this.environmentDetails && this.runOnDS)
                    return this.environmentDetails[this.runOnDS]
                return {}
            },
            disabledPrimaryKeyOption(){
                return this.dataItemSearchType && this.dataItemSearchType.value==='not_used_master'
            }
        },
        watch:{
            'environmentDetails':{
                handler(newValue){
                    if(!_.isEmpty(newValue))
                        this.manageEnvironmentDetails(_.cloneDeep(newValue))
                }
            },
            'aliasSearch':{
                handler(newValue){
                    var _this = this
                    clearInterval(this.aliasSearchTimer)
                    _this.aliasSearchTimer = setTimeout(function(){
                        _this.fetchAliases()
                    },800)
                }
            },
            'tableList':{
                handler(newValue){
                    if(newValue)
                        this.manageObjectList(_.cloneDeep(newValue))
                }
            },
            'selectTableSearch':{
                handler(newValue){
                    this.currentTableSearch = 'select'
                    this.GetTables(newValue)
                }
            },
            'excludeTableSearch':{
                handler(newValue){
                    this.currentTableSearch = 'exclude'
                    this.GetTables(newValue)
                }
            },
            'businessObjectList':{
                handler(newValue){
                    this.manageObjectList()
                }
            },
            'stepname':{
                handler(newValue){
                    if(newValue){
                        this.nameTextboxSize=Math.max(20,newValue.length>60?60:newValue.length)
                    }
                    else
                        this.nameTextboxSize = 20
                }
            },
            'parentdigramobj':{
                handler(newValue){
                    var _this =this
                    let parentObjVal = _.cloneDeep(newValue)
                    this.resetData()
                    this.env_id = parentObjVal.env_id
                    this.stepSeqNo = parentObjVal.stepSeqNo
                    if(parentObjVal.cell_name)
                        this.stepname = parentObjVal.cell_name
                    let stepData = parentObjVal.step_data?parentObjVal.step_data:{}
                    if(stepData.search_type)
                        this.dataItemSearchType = _.find(_this.dataItemSearchTypeList,['value',stepData.search_type.value])

                    if(stepData.run_on_datasource){
                        this.runOnDS = stepData.run_on_datasource
                        this.GetTables('',1)
                    }
                    if(parentObjVal.environmentDetails)
                        this.environmentDetails = parentObjVal.environmentDetails
                    if(stepData.search_alias)
                        this.aliasName = stepData.search_alias
                    setTimeout(()=>{
                        if(stepData.include_list)
                            _this.defaultSelectTargetSelection = stepData.include_list
                        if(stepData.exclude_list)
                            _this.defaultExcludeTargetSelection = stepData.exclude_list
                    },100)

                    if(stepData.description){
                        this.stepDescription = stepData.description
                        this.vueEditorObj = {'description':stepData.description}
                    }

                    if(stepData.is_add_table_name === false)
                        this.addTableNameInWorkTbl = stepData.is_add_table_name
                    if(stepData.drop_wrk_tbl_on_job_finish === false)
                        this.dropTableOnJobFinish = stepData.drop_wrk_tbl_on_job_finish
                    if(stepData.is_add_table_desc === false)
                        this.includeTableDescription = stepData.is_add_table_desc
                    if(stepData.include_all_alias === false)
                        this.addRelatedAliases = stepData.include_all_alias
                    if(stepData.additionalInformation)
                      this.additionalInformation = stepData.additionalInformation

                    if(stepData.filter){
                        this.columns_for_filter = stepData.filter
                    }
                    if(stepData.main_filterUI){
                        this.main_filterUI = stepData.main_filterUI

                        if(stepData.secondary_filterUI){
                            this.secondary_filterUI = stepData.secondary_filterUI
                        }
                    }
                    if(stepData.missing_business_object_action)
                        this.missingBusinessObjectAction = stepData.missing_business_object_action
                    if(stepData.only_include_jde_tables === false)
                        _this.onlyIncludeJDETables = false

                    _this.validateStepOnUserAction()
                }
            },
            'acceleratorObj':{
                handler(newValue){

                }
            },
            'stepper':{
                handler(newValue){
                    this.initiateValidation(newValue)
                }
            },
            'aliasName':{
                handler(newValue){
                    if(this.isMultikeyAlias)
                        this.addRelatedAliases = false
                    // else
                    //     this.addRelatedAliases = true
                }
            },
            policies(newvalue){
                this.policy_list = newvalue;
            },
        },
        mounted(){
            if(!_.isEmpty(this.environmentDetails))
                this.manageEnvironmentDetails()
        },
        methods:{
            manageEnvironmentDetails(environmentDetails){
                let datasourceKeys = this.datasourceKeys
                this.dsList = []
                for(let i=0;i<datasourceKeys.length;i++){
                    let dsObj = environmentDetails[datasourceKeys[i].key]
                    if(!dsObj)
                        continue
                    dsObj['dsKeyName'] = datasourceKeys[i].key
                    dsObj['displayName'] = dsObj['datasource_name']+' ('+datasourceKeys[i].display+')'
                    this.dsList.push(dsObj)
                    if(datasourceKeys[i].isForFetchAliases){
                        this.fetchAliasesFrom = dsObj
                        this.fetchAliases()
                    }
                }
            },
            fetchAliases(){
                var _this = this
                if(_.isEmpty(this.fetchAliasesFrom))
                    return
                let dataToSend = {"client_id":this.clientId,'search_alias':this.aliasSearch}
                dataToSend['data_source_details'] = this.fetchAliasesFrom
                _this.aliasList = []
                let url = config.JDE_FORMATER_URL +'/fetch_data_items_for_alias'
                postToServer(_this, url, dataToSend).then(response=>{
                    let customList = _.filter(response,(obj)=>{
                        return obj.alias_type === 'C'
                    })

                    let databaseList = _.filter(response,(obj)=>{
                        return obj.alias_type === 'D'
                    })

                    _this.aliasList = customList
                    if(customList.length && databaseList.length)
                        _this.aliasList.push({"title":"","alias_type":"","divider":true,"no-filter":true,
                        'display_name':'----------------------'})
                    _this.aliasList = _this.aliasList.concat(databaseList)

                    if(this.isDataBaseAliasSelected){
                        let obj = _.find(_this.aliasList,['display_name',_this.aliasName.display_name])
                        if(!obj)
                            _this.aliasList.push(_this.aliasName)
                    }
                }).catch(error=>{
                    // alert('Error in alias fetching')
                })
            },
            GetTables(search='',timer=800){
                var _this = this
                // _this.selectedTableList= []
                // _this.tableExcludeList = []
                let selectedDatasource = this.getSelectedDS
                if(!selectedDatasource)
                    return
                clearInterval(this.tableListTimer)
                this.tableListTimer = setTimeout(function(){
                    _this.$emit('GetTables',selectedDatasource.connection_string,selectedDatasource.schema_name,
                    selectedDatasource.database_type,search,["table","view","alias"],true,selectedDatasource.mapped_agent_id)
                },timer)

            },
            manageObjectList(tableList){
                if(!this.currentTableSearch || this.currentTableSearch === 'select')
                    this.selectItemInputList =[]
                if(!this.currentTableSearch || this.currentTableSearch === 'exclude')
                    this.excludeItemInputList = []
                if(!this.runOnDS)
                    return
                let runOnObj = _.find(this.datasourceKeys,['key',this.runOnDS])
                var IncludeListToPass = []
                let isTableAdded = false
                if(this.businessObjectList && this.businessObjectList.length && runOnObj.hasBusinessObj){
                    IncludeListToPass =[{"title":"AllBusinessObjects",
                    "type":"BO", "display_name":"ALL BUSINESS OBJECTS","object_id":"AllBusinessObjects",'itemIndex':IncludeListToPass.length}]

                    if(tableList){
                        IncludeListToPass.push({"title":"AllTables","type":"T","display_name":"ALL TABLES",
                        "object_id":"AllTables", 'itemIndex':IncludeListToPass.length})
                        IncludeListToPass.push({"type":"divider", "isDivider":true,"itemIndex":IncludeListToPass.length})
                        isTableAdded = true
                    }
                    _.forEach(_.cloneDeep(this.businessObjectList), (obj,indx)=>{
                        obj.type = "BO"
                        obj.itemIndex = IncludeListToPass.length + 1
                        obj.display_name = obj.object_name + ' ('+obj.object_id+')'
                        IncludeListToPass.push(obj)
                    })
                }
                if(IncludeListToPass.length && tableList.length){
                    IncludeListToPass.push({"type":"divider", "isDivider":true,"itemIndex":IncludeListToPass.length})
                }
                if(tableList){
                    if(!isTableAdded)
                        this.selectedTableList = [{"title":"AllTables","type":"T","display_name":"ALL TABLES",
                        "object_id":"AllTables", 'itemIndex':IncludeListToPass.length}]
                    _.forEach(tableList, (obj,indx)=>{
                        obj.itemIndex = indx + 1 + IncludeListToPass.length
                        obj.object_id = obj.title
                        obj.display_name = obj.title
                        if(obj.description)
                            obj.display_name = obj.description.trim()+' ('+obj.title+')'
                    })
                    this.selectedTableList = this.selectedTableList.concat(_.cloneDeep(tableList))
                    this.tableExcludeList = _.cloneDeep(tableList)
                }
                if(!this.currentTableSearch || this.currentTableSearch === 'select')
                    this.selectItemInputList = IncludeListToPass.concat(this.selectedTableList)
                if(isTableAdded){
                    IncludeListToPass.splice(2,1) // removed divider
                     IncludeListToPass.splice(1,1) // removed all tables
                }
                if(!this.currentTableSearch || this.currentTableSearch === 'exclude')
                    this.excludeItemInputList = IncludeListToPass.concat(this.tableExcludeList)
            },
            changeStepper(){
                this.getColumnsForFilter()
            },
            savedata(is_close){
                if(is_close){
                    this.datasource_error = ''
                }
                let data = this.getDataItemSearchData()
                console.log("data selection data =>",data)
                this.$emit('update-object',data, is_close);
            },
            performUndoRedo(){

            },
            getDataItemSearchData(){
                var _this = this
                let selection = _this.getSelectionJson()
                let addRelatedAliases = this.addRelatedAliases
                if(this.isMultikeyAlias)
                    addRelatedAliases= false
                var filter_data = _.cloneDeep(this.columns_for_filter)
                if(filter_data.length === 1 && (!filter_data[0].column_name && !filter_data[0].operator))
                    filter_data = []
                return {
                    'stepname':_this.stepname,'step_type':_this.stepType,"description":"",
                    "stepId":this.parentdigramobj.stepId,
                    'step_data':{
                        "search_type":this.dataItemSearchType,
                        "selection":selection,"search_alias":this.aliasName,"include_all_alias":addRelatedAliases,
                        "run_on_datasource":this.runOnDS,"include_list":this.selectedTables,
                        "exclude_list":this.excludedTables,"is_add_table_name":this.addTableNameInWorkTbl,
                        "is_add_table_desc":this.addTableNameInWorkTbl?this.includeTableDescription:false,
                        "drop_wrk_tbl_on_job_finish":this.dropTableOnJobFinish,
                        'description':this.stepDescription,
                        'filter':filter_data, 'main_filterUI':this.main_filterUI,
                        'secondary_filterUI':this.secondary_filterUI,
                        "missing_business_object_action":this.missingBusinessObjectAction,
                        "additionalInformation":this.additionalInformation,
                        "only_include_jde_tables":_this.onlyIncludeJDETables
                    },
                }
            },
            getSelectionJson(){
                let selectionList = []
                if(_.isEmpty(this.aliasName))
                    return selectionList
                let selectionJson = {"data_type_details":{},
                "column_name":"","alias_name":"",
                "report_alias_name":"",
                "is_drv_table":false,
                "table_name":"",
                "ref_step_id":''}
                let aliasList = this.getAliasInList()
                for(let i=0;i<aliasList.length;i++){
                    selectionJson['column_name']= selectionJson['alias_name'] = selectionJson['report_alias_name'] = aliasList[i]
                    selectionList.push(_.cloneDeep(selectionJson))
                }
                if(this.addTableNameInWorkTbl){
                    selectionJson['column_name']= selectionJson['alias_name'] = selectionJson['report_alias_name'] = "TABLE_NAME"
                    selectionList.push(_.cloneDeep(selectionJson))
                }
                if(this.includeTableDescription){
                    selectionJson['column_name']= selectionJson['alias_name'] = selectionJson['report_alias_name'] = "DESCRIPTION"
                    selectionList.push(_.cloneDeep(selectionJson))
                }
                if(this.additionalInformation === 'addcountdata'){
                    selectionJson['column_name']= selectionJson['alias_name'] = selectionJson['report_alias_name'] = "RECORD_COUNT"
                    selectionList.push(_.cloneDeep(selectionJson))
                }
                if(this.additionalInformation === 'addprimarykeydata'){
                    selectionJson['column_name']= selectionJson['alias_name'] = selectionJson['report_alias_name'] = "PRIMARY_KEYS_VALUE"
                    selectionList.push(_.cloneDeep(selectionJson))
                }
                if(this.addRelatedAliases && this.isDataBaseAliasSelected && this.aliasName && this.aliasName.alias){
                    selectionJson['column_name']= selectionJson['alias_name'] = selectionJson['report_alias_name'] = this.aliasName.alias+"_COLUMN_NAME"
                    selectionList.push(_.cloneDeep(selectionJson))
                }
                return selectionList
            },
            getAliasInList(){
                if(_.isEmpty(this.aliasName))
                    return []
                return this.aliasName.alias.split(',')
            },
            preventKeyEvent(event){
                if (event.ctrlKey && (event.code === 'KeyZ'||event.code === 'KeyY'))
                    event.preventDefault()
            },
            resetData(){
                this.dataItemSearchType = this.dataItemSearchTypeList[0]
                this.runOnDS = ''
                this.stepper = 1
                this.aliasName = {}
                this.selectedTables = []
                this.stepname = 'Data Item Search'
                this.excludedTables = []
                this.addTableNameInWorkTbl = true
                this.dropTableOnJobFinish = true
                this.includeTableDescription = true
                this.addRelatedAliases = true
                this.currentTableSearch = ''
                this.selectTableSearch =''
                this.excludeTableSearch = ''
                this.missingBusinessObjectAction='skip'
                this.nameTextboxSize = 20
                this.defaultSelectTargetSelection = []
                this.defaultExcludeTargetSelection = []
                this.stepDescription = ''
                this.additionalInformation = 'noadditionalinformation'
                this.vueEditorObj = {'description':''}
                this.resetFilters()
                this.onlyIncludeJDETables = true
            },

            StepNameChanged(){

            },
            manageOutputList(outputList,targetList){
                targetList = outputList
                this.savedata()
            },
            DescriptionChange(stepDescription){
                this.stepDescription = stepDescription
                this.savedata()
            },
            getColumnsForFilter(){
                if(!this.isShowFilter || _.isEmpty(this.aliasName))
                    return
                
                if(this.isDataBaseAliasSelected || this.dataItemSearchType.value ==='missing_master'){
                    let columns = this.getAliasInList()
                    var filterColumns= []

                    for(let i=0;i<columns.length;i++){
                        filterColumns.push({"column_name":columns[i]})
                    }
                    this.column_name_list = filterColumns
                }
                else{
                    this.getMasterTableColumns()
                }
            },
            getMasterTableColumns(){
                var _this = this
                this.table_column_list = []
                let selectedDatasource = this.getSelectedDS
                if(!selectedDatasource)
                    return
                let tableName = this.aliasName.master_table_names.split(',')[0]
                var kwargs = {"table_column_list":this.table_column_list, "env_id":this.env_id,
                "connection_str":selectedDatasource.connection_string,"schema":selectedDatasource.schema_name, "table_name":tableName, "table_type": 'table', "need_column_db_details":true, "db_type":selectedDatasource.database_type}
                getColumnsWithDescription(this,kwargs).then(tableColumnResponse => {
                    let filterColumns = []
                    if(tableColumnResponse && tableColumnResponse.column_list){
                        for(let i=0;i<tableColumnResponse.column_list.length;i++){
                            filterColumns.push({"column_name":tableColumnResponse.column_list[i],'table_name':tableName})
                        }
                        _this.column_name_list = filterColumns
                    }
                }).catch(error=>{
                    alert(error)
                })
            },
            resetFilters(){
                this.main_filterUI = []
                this.secondary_filterUI = {groupList:[]}
                this.columns_for_filter = []
                this.column_name_list = []
                this.add_new_filter_row()
            },
            saveCriteria(columns_for_filter,main_filter,secondary_filter){
                this.columns_for_filter = columns_for_filter;
                this.main_filterUI = main_filter
                this.secondary_filterUI=secondary_filter
                this.savedata();
            },
            add_new_filter_row(){
                var current_filter_length = this.columns_for_filter.length
                if(current_filter_length > 0){
                    if(!this.columns_for_filter[current_filter_length-1]['operation'])
                    this.columns_for_filter[current_filter_length-1]['operation'] = false
                }
                var obj = _.cloneDeep(this.filter_column_tbl_obj);
                obj['id'] = this.get_new_id()
                obj['error_list'] = ''
                this.columns_for_filter.push(obj);
            },
            delete_filter_row(idx){
                var current_filter_length = this.columns_for_filter.length
                if(current_filter_length > 1 && current_filter_length-1 ===  idx)
                    delete this.columns_for_filter[idx-1]['operation']
                this.columns_for_filter.splice(idx, 1)
                if(this.columns_for_filter.length === 0){
                    var obj = _.cloneDeep(this.filter_column_tbl_obj);
                    this.columns_for_filter.push(obj);
                }
            },
            get_new_id(){
                return ++this.auto_increment_id;
            },
            validateStepOnUserAction(){
                // we are going to call validate object after curtain time after action get performed because graph take sometime to update its json.
                var _this = this
                _this.showErrorPopUp = false
                clearInterval(_this.autoValidateTimer)
                _this.autoValidateTimer = setTimeout(()=>{
                _this.showErrorPopUp = true
                _this.validatestepdata()
                _this.$emit('validatePD')
                },edcConstantObj.AUTO_VALIDATOR_TIMER)
            },
            totalCount(count){
                this.errorListCount = count;
            },
            setError(errorList){
                let _this = this;
                _this.Perform_Validation(errorList);
            },
            validatestepdata(){
                var _this = this
                if(this.readonly)
                    return
                if(_this.validateProgress){
                return false
                }
                _this.validateProgress=true;
                var data = this.getDataItemSearchData()
                data['env_id'] = this.env_id
                _this.validation_response={}
                // _this.Remove_Error_List();
                validate_process_def_json(_this, data).then(validation_response => {
                _this.validateProgress=false;
                var error_data = {"step_data":validation_response}
                _this.validation_response = error_data
                _this.initiateValidation()
                _this.$store.state.errorList[_this.parentdigramobj.stepId] = error_data
                    //first clear all errors list
                    // _this.Perform_Validation(_this.validation_response)
                }).catch(error=>{
                    _this.validateProgress = false;
                })
            },
            Perform_Validation(error_data){
                var _this = this
                _this.Remove_Error_List();
                if (typeof error_data === 'object'){

                }
            },
            Remove_Error_List(){
                this.Remove_From_Normal_List(this.columns_for_filter)
            },
            Remove_From_Normal_List(list){
                for(var i=0;i<list.length;i++){
                    list[i].error_list = {}
                }
            },
            Perform_Validation_On_Filter(filter_error_data){
                var _this = this;
                var rows = Object.keys(filter_error_data)
                rows.map(row=>{ // it will provide rowindex like 0,1,2
                    let columnArray = Object.keys(filter_error_data[row]);
                    columnArray.map(column=>{ // it will provide key in that object like alies_name, column_name
                        _this.columns_for_filter[row].error_list[column] = filter_error_data[row][column];
                    })
                })
            },
            initiateValidation(newValue){
                this.selectDataItemRule = []
                this.ruleForSelectTables = []
                if(newValue!=2 && (_.isEmpty(this.aliasName) || !this.runOnDS))
                    this.selectDataItemRule = [() => false]
                if(newValue!=4 && !this.selectedTables.length)
                    this.ruleForSelectTables = [() => false]
            },
            changedDataItemSearch(){
                if(this.disabledPrimaryKeyOption){
                    this.additionalInformation = 'noadditionalinformation'
                }
                this.savedata()
            }
        }
    }
</script>
