/* point to consider while mergeing/unmerging 
        1) we have to modify cell props -> table_name, table_description,merged_tables
        2) we have to change table name reference in the tablelist, dragged table list, related join, 
        filters, link filters, media object details ,copyJoinJson
        3) we have to modify logic to fetch columns details as well as the filter column
        4) we have to make provisioning in the zip /unzip cell
        5) In merge process we have to remove the newly added cell
    */

import * as commonFuncObj from './../commonFunc.js'

export function performMergeProcess(that,env_id,mergingDetails,fromConfirmed=false){
    // first validate the data
    console.log('mergingDetails ',mergingDetails)
    let baseElement = mergingDetails['base_object']
    let originalBaseElement =_.cloneDeep(baseElement)
    let topElement = mergingDetails['top_object']
    if(!baseElement || !topElement)
        return false
    let baseTable = baseElement.prop('table_name')
    let topTable = topElement.prop('table_name')
    if(mergingDetails.fromConfirmed)
        fromConfirmed = mergingDetails.fromConfirmed

    if(!fromConfirmed){
        let isValidForMerge = validateMergingProcess(that,env_id,mergingDetails,baseTable,topTable)
        if(isValidForMerge)
            return showMergePopUp(that,env_id,mergingDetails)
        else
            return false
    }
    let baseTableDescription = baseElement.prop('table_description') + ' / ' 
    + topElement.prop('table_description')
    let baseTableName = baseElement.attr('label/text') + ' / '+topElement.attr('label/text')
    let alreadyMergedData = []
    if(baseElement.prop('merged_tables'))
        alreadyMergedData = baseElement.prop('merged_tables')
    
    alreadyMergedData.push({"table_name":topTable,"table_description":topElement.prop('table_description')})

    that.cellToModify= {
        "cell_id":baseElement.id,
        'modification_for':"merging",
        "textToChange":baseTableName,
        "propsToChange":[   
                            {"key":"table_description","value":baseTableDescription},
                            {"key":"merged_tables","value":alreadyMergedData},
                            {"key":"is_merged","value":true}
                        ]
    }
    

    if(!mergingDetails.is_undo_redo){
        // create undo data for unmerge
        
        that.cellToRemove={
            'cell_id':topElement.id
        }

        let unMergeData = {
            "element":baseElement,
            "relatedLinks":[],
            "table_to_unmerge":{"table_name":topTable,"table_description":topElement.prop('table_description')},
            "is_undo_redo":true,
            "messageToDisplay":"Table " + topTable + " unmerged from "+baseTable
        }

        mergingDetails['base_object'] = originalBaseElement
        mergingDetails['fromConfirmed'] = true
        mergingDetails['is_undo_redo'] = true
        mergingDetails["messageToDisplay"] = "Table " + topTable + " merged with "+baseTable

        let data = {'unMergeData':unMergeData,
            "mergingDetails":mergingDetails,
            "action_to_take":"unmerge_table",
            "user_action":"merge_table"
        }
        that.pushForUndo('unmerge_table', data,true)
    }
}


function validateMergingProcess(that,env_id,mergingDetails,baseTable,topTable) {
    /* validate merging details , followings are the checkpoints
        1) both table have same number of columns
        2) both table have same column names

        Process:
            1) First we have to columns for both table from the cache 
            2) Then we have to match the check points
    */
        
    let baseColumns = getTableColumnsDetails(that,env_id,baseTable)
    let topColumns  = getTableColumnsDetails(that,env_id,topTable)
    return !baseColumns || !topColumns || !baseColumns.table_column_list || !topColumns.table_column_list 
        ||commonFuncObj.isListIdenticals(baseColumns.table_column_list,topColumns.table_column_list)
}

function getTableColumnsDetails(that,env_id,table_name){
    let key = env_id+'_table_column_list'
    if(!that.$store.state.cacheData || !that.$store.state.cacheData[key])
        return {}
    return _.find(that.$store.state.cacheData[key],['table_name',table_name])

}

function showMergePopUp(that,env_id,mergingDetails){
    let confirmFn = function () {
        performMergeProcess(that,env_id,mergingDetails,true)
    }
    let obj = {
      title: 'Merge Objects',
      message: 'Are you sure you want to merge this 2 tables?',
      type: 'info',
      useConfirmBtn: true,
      onConfirm: confirmFn,
      customCloseBtnText:'Cancel',
      customConfirmBtnText:'Merge'
    }
    that.$refs.simplert.openSimplert(obj)
}

export function unMergeTable(that,unMergeData){
    let element = unMergeData.element
    let copyOfelement = _.cloneDeep(element)
    let mergedTables = element.prop('merged_tables')
    let tableToUnMerge = unMergeData.table_to_unmerge
    let baseTableName = element.prop('table_name')
    let baseTableDescription =  element.prop('table_description')
    let remainingTables = []
    let isTablesAvailableForMerge = false
    for(let i=0;i<mergedTables.length;i++){
        if(mergedTables[i].table_name === tableToUnMerge.table_name){
            baseTableDescription = baseTableDescription.replace('/ '+tableToUnMerge.table_description,'')
            continue
        }
        baseTableName = baseTableName +' / '+mergedTables[i].table_name
        remainingTables.push(mergedTables[i])
        isTablesAvailableForMerge = true
    }
    that.cellToModify= {
        "cell_id":element.id,
        'modification_for':"merging",
        "textToChange":baseTableName,
        "propsToChange":[{"key":"table_description","value":baseTableDescription},
                            {"key":"merged_tables","value":remainingTables},
                            {"key":"is_merged","value":isTablesAvailableForMerge}]
    }
    if(!unMergeData.is_undo_redo){
        let base_object = _.cloneDeep(element)
        base_object.prop('merged_tables',remainingTables)
        base_object.prop('table_description', baseTableDescription.trim())
        base_object.attr('label/text',baseTableName)
        base_object.attr('is_merged',isTablesAvailableForMerge)

        let top_object = _.cloneDeep(element)
        top_object.prop('merged_tables',[])
        top_object.prop('table_description', tableToUnMerge.table_description)
        top_object.attr('label/text',tableToUnMerge.table_name)
        top_object.prop('table_name',tableToUnMerge.table_name)
        let mergingDetails = {"base_object":base_object,
        "top_object":top_object,
        'is_undo_redo':true,
        'fromConfirmed':true,
        "messageToDisplay":"Table " + tableToUnMerge.table_name+ " merged with "+base_object.prop('table_name') 
        }
        unMergeData['element'] = copyOfelement
        unMergeData['is_undo_redo'] = true
        unMergeData['messageToDisplay'] = "Table " + tableToUnMerge.table_name+ " unmerged from "+base_object.prop('table_name')
        let data = {'unMergeData':unMergeData,
            "mergingDetails":mergingDetails,
            "user_action":"unmerge_table",
            "action_to_take":"merge_table"
        }
        that.pushForUndo('merge_table', data,true)
    }
}


export function getMergeDetails(data){
    let mergedList = []
    if(!data || !data.cells)
        return mergedList
    for(let i=0;i<data.cells.length;i++){
        if(!data.cells[i].is_merged)
            continue
        mergedList.push({
            "base_table_id":data.cells[i].id,
            "base_table_name":data.cells[i].table_name,
            "merged_tables":data.cells[i].merged_tables
        })
    }
    return mergedList
}
