import * as menuObj from './contextmenu.js'
import * as anonymousObj from './anonymousFunctions.js'
import * as shapesObj from './shapes.js'
import * as commonFuncObj from './commonFunctions.js'
import * as constObj from './constants.js'
var highlighterRubberbandCell =constObj.CELL_HIGHLIGHTER

var highlighter_prop = constObj.CELL_HIGHLIGHTER

var highlight_running_prop = constObj.RUNNING_CELL_HIGHLIGHTER

var highlight_completed_prop = constObj.COMPLETED_CELL_HIGHLIGHTER
const joint = require('jointjs')

export function getStencilPaper(el, model,heightVal) {
    if(!heightVal)
        heightVal = 400
    return new joint.dia.Paper({
        el: $(el),
        height: heightVal,
        width: $('.card-body').width(),
        model: model,
        interactive: false,
    });
}


export function MoveLens(e, lens, paperSmallContainer, main_container, paperSmall_scale) {
    var pos, x, y;
    /*prevent any other actions that may occur when moving over the image*/
    e.preventDefault();
    /*get the cursor's x and y positions:*/
    pos = getCursorPos(e, paperSmallContainer);
    /*calculate the position of the lens:*/
    x = pos.x - (lens.offsetWidth / 2);
    y = pos.y - (lens.offsetHeight / 2);
    /*prevent the lens from being positioned outside the image:*/
    if (x > paperSmallContainer.clientWidth - lens.offsetWidth) { x = paperSmallContainer.clientWidth - lens.offsetWidth; }
    if (x < 0) { x = 0; }
    if (y > paperSmallContainer.clientHeight - lens.offsetHeight) { y = paperSmallContainer.clientHeight - lens.offsetHeight; }
    if (y < 0) { y = 0; }
    /*set the position of the lens:*/
    lens.style.left = x + "px";
    lens.style.top = y + "px";
    main_container.scrollLeft = (x / paperSmall_scale)
    main_container.scrollTop = (y / paperSmall_scale)
}

export function MoveLensOnScroll(e, lens, main_container, paperSmall_scale) {
    e.preventDefault()
    lens.style.left = (main_container.scrollLeft * paperSmall_scale) + "px";
    lens.style.top = (main_container.scrollTop * paperSmall_scale) + "px";
}


export function isLeftMouseButtonDown(evt) {
    evt = evt || window.event;
    if ("buttons" in evt) {
        return evt.buttons == 1;
    }
    var button = evt.which || evt.button;
    return button == 1;
}


export function handleKeyevents(e, that,graph,graphFor) {
    let _this = that
    if(_this.isReadOnly || _this.readonly)
        return
    let allowedKeys = [17,46,88,67,86,77]
    var key = e.which || e.keyCode;
    if(allowedKeys.indexOf(key)<0)
        return
    //you can not delete/copy/cut/paste unique identifier
    if (_this.unique_identifier_cell && _this.selected_el && (_this.unique_identifier_cell.id === _this.selected_el.model.id))
        return false
    if (_this.selectedElementForContextMenu.id && _this.selectedElementForContextMenu.prop('is_businesskeys_cell'))
        return false
    var ctrl = e.ctrlKey ? e.ctrlKey : ((key === 17) ? true : false);
    if (key === 46 && _this.isCellSelected) {
        menuObj.contextMenuItemClick(that, 'delete',graph,graphFor)
    } else if (key == 88 && ctrl && _this.is_selected) {
        menuObj.contextMenuItemClick(that, 'cut',graph,graphFor)
    } else if (key == 67 && ctrl && _this.is_selected) {
        menuObj.contextMenuItemClick(that, 'copy',graph,graphFor)
    } else if (key == 86 && ctrl && _this.is_cut_or_copied) {
        menuObj.contextMenuItemClick(that, 'paste',graph,graphFor)
    } else if (key == 77 && ctrl) {
        _this.AddNewCell()
    }
}

export function SnapToGrid(cell_y,graph_grid_size){
    /* requirement is cell need to get snap to the nearest y axis line to
        draw straight line.
        LOGIC:
        perform % (modulo) on y axis value. y % graph_grid_size. 
        If modulo comes greater than 0 then element is in between the 
        grid squire. 
        Now if y axis value < graph_grid_size then y axis value = graph_grid_size
        else y axis value = cell_y - (y % graph_grid_size) so we will 
        get exact point on grid line
    */
    var y_axis_value = cell_y
    if(cell_y < graph_grid_size)
        y_axis_value = graph_grid_size
    else{
        var gap_between_line_and_cell = cell_y % graph_grid_size
        if(gap_between_line_and_cell != 0){
            y_axis_value = cell_y - gap_between_line_and_cell
        }
    }
    return y_axis_value
}

function getCursorPos(e, paperSmallContainer) {
    var a, x = 0,
    y = 0;
    e = e || window.event;
    /*get the x and y positions of the image:*/
    a = paperSmallContainer.getBoundingClientRect();
    /*calculate the cursor's x and y coordinates, relative to the image:*/
    x = e.pageX - a.left;
    y = e.pageY - a.top;
    /*consider any page scrolling:*/
    x = x - window.pageXOffset;
    y = y - window.pageYOffset;
    return { x: x, y: y };
}


export function dragDropOnMainPaper(newObject, paper,graph,that,elementFor) {
    let element = newObject.element
    let e = newObject.e
    let x = newObject.x
    let y = newObject.y

    // let _this = that
    $('body').append('<div id="flyPaper" style="position:fixed;z-index:100;opacity:.9;pointer-event:none;"></div>');
    var flyGraph = new joint.dia.Graph,
    flyPaper = new joint.dia.Paper({
        el: $('#flyPaper'),
        model: flyGraph,
        interactive: false,
        width: 80,
        height: 80
    }),
    flyShape = element.clone(),
    pos = element.position(),
    offset = {
        x: x - pos.x,
        y: y - pos.y
    };
    var validcellslist = dragnewcell(element, graph, paper)
    flyShape.position(0, 0);
    flyGraph.addCell(flyShape);
    $("#flyPaper").offset({
        left: e.pageX - offset.x,
        top: e.pageY - offset.y
    });
    $('body').on('mousemove.fly', function(e) {
        $("#flyPaper").offset({
            left: e.pageX - offset.x,
            top: e.pageY - offset.y
        });
    });
    $('body').on('mouseup.fly', function(e) {
        var x = e.pageX,
        y = e.pageY,
        target = paper.$el.offset();

        // Dropped over paper ?
        if (x > target.left && x < target.left + paper.$el.width() && y > target.top && y < target.top + paper.$el.height()) {
            var cell_x = x - target.left - offset.x
            var cell_y = y - target.top - offset.y
            var newShape = flyShape.clone();
            var valid_drop_location = dropcelloncell(newShape, paper, validcellslist, cell_x, cell_y,graph)
            if (!newShape.prop('is_bus_obj')) {

            //     var y_axis_snap_value = SnapToGrid(cell_y)
                let positionX = anonymousObj.getNearestGridSnapPosition(cell_x)
                let positionY = anonymousObj.getNearestGridSnapPosition(cell_y)
                newShape.position(positionX,positionY);
                //     // s.addTo(graph);
                if(newShape.prop('isMagnetAtBody'))
                    newShape.attr('body/magnet',true);
                else
                    newShape.attr('rect/magnet',true);

                let uniqueName = commonFuncObj.getUniqueName(newShape.prop('steptype'),newShape,graph)
                if(elementFor === 'edcWorkflow')
                    uniqueName = commonFuncObj.getUniqueName(newShape.prop('stepname'),newShape,graph)
                if(!newShape.prop('withoutWrapper')){
                    let wrapperNewShape = shapesObj.getProcessDesignerWrapper(uniqueName,{'positionX':positionX,'positionY':positionY},elementFor)
                    newShape.prop('stepname',uniqueName)
                    wrapperNewShape.embed(newShape)
                    graph.addCells([newShape,wrapperNewShape]);
                }
                else
                    graph.addCells([newShape])
                
                // this used for ReDo-UnDo
                that.dragDroppedCell = _.cloneDeep(newShape)
            } 
        }
        $('body').off('mousemove.fly').off('mouseup.fly');
        flyShape.remove();
        $('#flyPaper').remove();
    });
    
}
export function unhighlight_all(graph,paper){
    _.each(graph.getElements(), function(element) {
        if (element.prop('steptype') && (element.prop('steptype') == "Archival" 
                || element.prop('steptype') == "Copy" || element.prop('steptype') == "Purge"))
        paper.findViewByModel(element).unhighlight(null, highlighter_prop)

    });
}

export function dragnewcell(cell, graph, paper) {
    var validcellslist = []
    //Get all elements from graph
    _.each(graph.getElements(), function(element) {
        paper.findViewByModel(element).unhighlight(null, highlighter_prop)
        if (cell.prop('is_bus_obj'))
        {
            if (element.prop('steptype') && (element.prop('steptype') == "Archival" 
                || element.prop('steptype') == "Copy" || element.prop('steptype') == "Purge")) {
                paper.findViewByModel(element).highlight(null, highlighter_prop)
                validcellslist.push(element)
            }
        }
    });
    return validcellslist
}

export function dropcelloncell(cell, paper, validcellslist, cell_x, cell_y,graph) {
    if (validcellslist.length === 0)
        return false
    for (var i = 0; i < validcellslist.length; i++) {
        var element = validcellslist[i]
        paper.findViewByModel(element).unhighlight(null, highlighter_prop)
            //10 size for cover highlighted part && 30 for cover highlight with step name section
        var lower_limit_x = element.attributes.position.x - 10 //left side highlighted part
        var lower_limit_y = element.attributes.position.y - 10 //top side highlitedd part
        var higher_limit_x = element.attributes.position.x + element.attributes.size.width + 10
        var higher_limit_y = element.attributes.position.x + element.attributes.size.height + 30
        if (cell_x >= lower_limit_x && cell_x <= higher_limit_x &&
            cell_y >= lower_limit_y && cell_y <= higher_limit_y) {
            // valid cell drop point
        if(cell.prop('is_bus_obj')){
            element.prop('stepname', cell.prop('bus_name'));
            element.prop('bussinessObjectName', cell.prop('bus_name'));
            element.prop('policy_id',cell.prop('policy_id'))
            element.prop('bus_id',cell.prop('bus_id'))
            anonymousObj.updateParentWrapperText(element,cell.prop('bus_name'))
            unhighlight_all(graph,paper)
            return true
        }
    }
}
return false
}


export function resetPaper(paper_name,height,items_length,extra_space=0){
    if(items_length <=0)
        return false
    //few machine work with .$el and few machine directly works with $ref
    if(paper_name.$el)
        paper_name = paper_name.$el
    var new_height = height * (items_length/3) + 20+extra_space
    if(new_height < 400)
        return false
    paper_name.style.height =new_height + "px"
}

export function highlightRunningCell(cell_id, graph, paper) {
    var validcellslist = []
    //Get all elements from graph
    _.each(graph.getElements(), function(element) {
        if(element.id == cell_id)
        paper.findViewByModel(element).highlight(null, highlight_running_prop)
    });
    return
}

export function highlightCompletedCell(cell_id_list, graph, paper) {
    var validcellslist = []
    //Get all elements from graph
    _.each(graph.getElements(), function(element) {
        if(cell_id_list.includes(element.id))
        paper.findViewByModel(element).highlight(null, highlight_completed_prop)
    });
    return
}