<template>
    <v-container class="EDC-Container">
        <v-row class="EDC-Row">
            <v-col class="EDC-Col" cols="12">
                <v-row class="EDC-Row">
                    <v-col cols="1" class="EDC-Col"></v-col>
                    <v-col cols="5" class="EDC-Col" id="sourceSection" style="padding-right:8px !important;">
                        <v-card class="EDC-VCards" elevation="0">
                            <v-row class="EDC-Row">
                                <v-col class="EDC-Col" cols="12">
                                    <edc-toolbar class="EDC-Custom-Toolbar" key="source_column_header" :customLabel="sourceListLabel" 
                                    usedFor="list" :bindWithElement="sourceListId" @perform-serach="performSearch"></edc-toolbar>

                                    <v-list dense :id="sourceListId" class="EDC-list overflow-y-auto style-2scroll" max-height="400" data-parent-type="listItem">
                                        <draggable v-model="sourceItemList" :sort="sortableList" 
                                        @start="startDrag($event,'list')" @end="sourceDragEnd"
                                        :options="dragOptions" :move="onMove" 
                                        :group="{name:'edc',put:true,filter:'hr'}" id="toDrag">
                                            <template v-for="(item, i) in sourceItemList">
                                                <v-divider v-if="item.isDivider" class="EDC-Divider" dense :key="i+'sourceDivider'" ></v-divider>
                                                <v-list-item v-else dense selectable  :key="i+'sourceList'" class="custom-list-item" data-parent-type="listItem">
                                                    <template v-for="(col,colIndex) in sourceColDimention">
                                                        <v-col class="EDC-Col" :class="col.class || 'EDC-Wrap-List-Item'" :cols="col.cols" :key="colIndex+'soureccol'+i" 
                                                        :align="col.align || 'left'" :title="col.columnfor || 'Item'">
                                                            <span v-if="col.useIcon">
                                                                <template v-for="(icon,iconIndex) in col.icons">
                                                                    <svgicon class="svg-icon-mini svg-fill-mini" :name="icon.name"  
                                                                    :original="true" :title="icon.iconFor" @click="iconEvent(icon.emitEvent,i,item)" :key="colIndex+'soureccol'+i+'icon'+iconIndex"></svgicon>
                                                                </template>
                                                            </span>
                                                            
                                                            <span v-else>{{item[col.itemKey]}}</span>
                                                        </v-col>
                                                    </template>
                                                </v-list-item>
                                            </template>
                                        </draggable>
                                    </v-list>
                                </v-col>
                            </v-row>
                             <v-row class="EDC-Row">
                                <v-col class="EDC-Col list-Footer" cols="12" align="end"> 
                                    Total Available Items:{{sourceItemList.length}}
                                </v-col>
                            </v-row>

						</v-card>
                    </v-col>
                    <v-col cols="5" class="EDC-Col" id="targetSection" style="padding-left:8px !important;">
                        <v-card class="EDC-VCards" elevation="0">
                            <v-row class="EDC-Row">
                                <v-col class="EDC-Col" cols="12">
                                    <edc-toolbar class="EDC-Custom-Toolbar" key="target_column_header" :customLabel="targetListLabel" 
                                    usedFor="list" :bindWithElement="targetListId" @perform-serach="performSearch"></edc-toolbar>

                                    <v-list dense :id="targetListId" class="EDC-list overflow-y-auto style-2scroll" max-height="400" data-parent-type="listItem">
                                        <draggable v-model="targetItemList" :sort="sortableList" @start="startDrag($event,'list')" 
                                        @end="targetDragEnd" :options="dragOptions" :move="onMove" 
                                        :group="{name:'edc',put:true,filter:'hr'}" id="toDrag">
                                            <template v-for="(item, i) in targetItemList">
                                                <v-list-item dense selectable :key="i+'selctedcolumn'" class="custom-list-item" data-parent-type="listItem">
                                                    <template v-for="(col,colIndex) in targetColDimention">
                                                        <v-col class="EDC-Col" :class="col.class || 'EDC-Wrap-List-Item'" :cols="col.cols" :key="colIndex+'targetlist'+i" 
                                                        :align="col.align || 'left'" :title="col.columnfor || 'Item'">
                                                            <span v-if="col.useIcon">
                                                                <template v-for="(icon,iconIndex) in col.icons">
                                                                    <svgicon class="svg-icon-mini svg-fill-mini" :name="icon.iconName"  
                                                                    :original="true" :title="icon.iconFor" @click="iconEvent(icon.emitEvent,i,item)" :key="colIndex+'targetcol'+i+'icon'+iconIndex"></svgicon>
                                                                </template>
                                                            </span>
                                                            
                                                            <span v-else>{{item[col.itemKey]}}</span>
                                                        </v-col>
                                                    </template>
                                                </v-list-item>
                                            </template>
                                        </draggable>
                                    </v-list>
                                </v-col>
                            </v-row>
                            <v-row class="EDC-Row">
                                <v-col class="EDC-Col list-Footer" cols="12" align="end"> 
                                    Total Selected Items:{{targetItemList.length}}
                                </v-col>
                            </v-row>
						</v-card>
                    </v-col>
                     <v-col cols="1" class="EDC-Col"></v-col>
                </v-row>
            </v-col>
        </v-row>
    </v-container>
</template>
<style scoped>
/* radio label styling */
.radioClass >>> label{
    top: 5px !important;
	/* margin: 7px !important; */
} 

.EDC-VCards {
  margin: 0px !important;
  height: 55vh !important;
}

.EDC-Custom-Toolbar {
    padding:0px !important;
}

.EDC-list {
	max-height:45vh !important;
	min-height:45vh !important;
    border:1px solid #dcdcdc !important;
}

.EDC-Toolbar >>> .v-sheet {
  border-radius: 0px !important;
}

.EDC-Wrap-List-Item {
    overflow:hidden;
    white-space:nowrap;
    text-overflow:ellipsis;
}

.custom-list-item {
	margin-bottom: 0px !important;
    padding:4px !important;
	cursor: pointer !important;
}

.list-Footer{
    padding:4px !important;
}
</style>
<script>
import draggable from 'vuedraggable'
import edctoolbar from './edctoolbar.vue'
export default {
    name:"DraggableList",
    components:{
        draggable,
        'edc-toolbar':edctoolbar
    },
    props:{
        sourceListLabel:{
            type:String,
            default:'Select From'
        },
        sourceItemInputList:{
            type:Array,
            default:()=>{
                return []
            }
        },
        targetItemInputList:{
            type:Array,
            default:()=>{
                return []
            }
        },
        sourceColDimention:{
            type:Array,
            default:()=>{
                return [
                    {cols:"6","itemKey":"name", "columnFor":"name"},
                    {cols:"5","itemKey":"value", "columnFor":"value"}
                ]
            }
        },
        targetListLabel:{
            type:String,
            default:'Select To'
        },
        targetColDimention:{
            type:Array,
            default:()=>{
                return [
                    {cols:"6","itemKey":"name", "columnFor":"name"},
                    {cols:"5","itemKey":"value", "columnFor":"value"},
                    {cols:"1",useIcon:true, 'icons':[
                        {'iconName':"delete_v2","iconFor":"Delete",
                     "emitEvent":"deleteEntry"}]}
                ]
            }
        },
        sourceListId:{
            type:String,
            default:'sourceItemList'
        },
        targetListId:{
            type:String,
            default:'targetItemList'
        },
        defaultTargetSelection:{
            type:Array,
            default:()=>{
                return []
            }
        },
        keyForSort:{
            type:String,
            default:''
        },
        sortableList:{
            type:Boolean,
            default:false
        },
        keyForUnique:{
            type:String,
            default:''
        }
    },
    data(){
        return {
            sourceItemList:[],
            targetItemList:[]
        }
    },
    computed:{
        dragOptions(){
		    return  {
		      animation: 0,
		      group: 'description',
		    };
		},
    },
    watch:{
        'sourceItemInputList':{
            handler(newValue){
                this.manageSourceItemList()
            }
        },
        'targetItemInputList':{
            handler(newValue){
                this.manageTargetItemList()
            }
        },
        'sourceListId':{
            handler(newValue){
                
            }
        },
        'targetListId':{
            handler(newValue){
                
            }
        },
        'targetItemList':{
            handler(newValue){
                this.$emit('sendOutputList',_.cloneDeep(newValue))
            }
        },
        'defaultTargetSelection':{
            handler(newValue){
                this.targetItemList = _.cloneDeep(newValue)
            }
        }
    },
    mounted(){
        
    },
    methods:{
        startDrag(event,source){
		

		},
        sourceDragEnd(){
            var _this =this
            if(_this.keyForSort){
                // on source drag end we need to sort target item list
                _this.targetItemList = _this.targetItemList.sort((one, two) => {
                    return one[_this.keyForSort] - two[_this.keyForSort];
                });
            }
            if(_this.keyForUnique){
                _this.targetItemList = _.uniqBy(_this.targetItemList,(obj)=>{
                    return obj[_this.keyForUnique]
                })
            }
        },
        targetDragEnd(){
            var _this =this
            if(_this.keyForSort){
                // on target drag end we need to sort target item list
                _this.sourceItemList = _this.sourceItemList.sort((one, two) => {
                    return one[_this.keyForSort] - two[_this.keyForSort];
                });
            }

            if(_this.keyForUnique){
                _this.sourceItemList = _.uniqBy(_this.sourceItemList,(obj)=>{
                    return obj[_this.keyForUnique]
                })
            }

        },
        onMove({ relatedContext, draggedContext }) {
	  		const relatedElement = relatedContext.element;
	  		const draggedElement = draggedContext.element;
	  		return (
	    	(!relatedElement || !relatedElement.fixed) && !draggedElement.fixed
	    	);
		},
        performSearch(searchText,tagId,emitSearch=true){
            // emitSearch to avoid looping
	        var columnList = document.getElementById(tagId)
	        var list_items = columnList.getElementsByClassName('custom-list-item')
	        var filter = searchText?searchText.toUpperCase():''
	        for(var i = 0; i < list_items.length; i++){
	            if (list_items[i].innerText.trim().toUpperCase().indexOf(filter) > -1)
	              list_items[i].style.display = "";
	            else
	              list_items[i].style.display = "none";
	        }
            if(tagId === this.sourceListId && emitSearch){
                this.sourceSearchText = searchText
                this.$emit('searchInSource',searchText)
            }
	    },
        iconEvent(){

        },
        manageSourceItemList(){
            var _this = this
            if(!this.sourceItemInputList)
                this.sourceItemList = []
            else
                this.sourceItemList = _.cloneDeep(this.sourceItemInputList)
            setTimeout(()=>{
                _this.performSearch(this.sourceSearchText,this.sourceListId, false)
            },100)
            
        },
        manageTargetItemList(){
            if(!this.targetItemInputList)
                this.targetItemList = []
            else
                this.targetItemList = _.cloneDeep(this.targetItemInputList)
        }
    }
}
</script>