<template lang="html">
  <!-- <v-flex xs12 style="padding:0px;"> -->
    <v-menu ref="menu2" :close-on-content-click="false" :disabled="disabled" v-model="menu2" 
      :return-value.sync="date" :absolute="true"  :nudge-left="100"
      lazy transition="scale-transition" offset-y full-width min-width="290px">
      <template v-slot:activator="{ on }">
      <v-text-field slot="activator" :disabled="disabled" v-model="calenderDate" label="Select Date" append-icon="event" @focus="OnDateFieldFocus" @keypress="OnDateKeyPress" @blur="DateInputEvent" hide-details="auto" :dense="dense" v-on="on"></v-text-field>
    </template>
      <v-date-picker v-model="date" :disabled="disabled" @input="emitChange" :min="mindate" no-title></v-date-picker>
    </v-menu>
  <!-- </v-flex> -->
</template>

<script>
import moment from 'moment'

export default {
  name: 'Calender',
  data() {
    return {
        menu2: false,
        date: null,
        calenderDate:this.input,
        customFormat:null,
        dateLabel:'Date (YYYY-MM-DD)',
        date_format:'(YYYY-MM-DD)',
        anyKeyPressed:false, // this variable used to handle blur event properly. If blur event is active and user going to select date from date picker then we no need to fire blur. blur event close date popup.
        isFocused:false, // this variable to select date in date picker if date already there. Focus event get called continuesly. So this variable will get used to fire event only once,
        mindate:'',
    }
  },
  props: ['input','format','disabled','label','isMinDate','dense'],
  watch:{
    dense(newValue){

    },
    input(newValue) {  
            this.calenderDate = newValue;
    },
     date (val) {
        this.calenderDate = this.parseDate(this.date)
      },
      format(newValue) {  
            this.customFormat = newValue;
    },
    label(newValue){
      // if(newValue)
      //   this.label = newValue + date_format
    },
    isMinDate(newValue){
      if(newValue)
        this.mindate = moment().format("YYYY-MM-DD")
    }
  },
  mounted(){
    if(this.isMinDate)
      this.mindate = moment().format("YYYY-MM-DD")
  },
  methods: {
    emitChange: function () {
      this.calenderDate = this.parseDate(this.date);
      this.$refs.menu2.save(this.calenderDate);
      this.anyKeyPressed = false;
      this.isFocused = false;
      this.$emit('update', this.calenderDate);
    },
    parseDate (date) {
       if (!date) return null

       if(!this.customFormat || this.customFormat == 'yyyy-mm-dd') return date

        const [year, month, day] = date.split('-')
        return `${month}-${day}-${year}`
    },
    OnDateKeyPress(e){
      e.preventDefault()
      return
      this.anyKeyPressed = true
      if(e.keyCode === 13){
        this.DateInputEvent()
      }
    },
    DateInputEvent(){
      if(this.anyKeyPressed){
      this.date = this.parseDate(this.calenderDate)
      this.emitChange()
      }
    },
    OnDateFieldFocus(){
      if(!this.isFocused){
        this.isFocused = true
        this.date = this.parseDate(this.calenderDate)
      }
    }
  }
}
</script>