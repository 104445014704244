<template> 
  <div id="environmentlist">
    <v-row class="EDC-Row">
      <v-col cols="8" class="EDC-Col">
        <v-row class="EDC-Row breadcrumbRow">
          <v-col cols="4" class="EDC-Col">
            <ul class="breadcrumb breadcrumbUL">
              <li>Change Password</li>
            </ul>
          </v-col>
          <v-col cols="8"></v-col>
        </v-row>
      </v-col>
      <v-col cols="4"></v-col>
    </v-row>

    <v-container>
      <v-form v-model="valid" ref="form">
        <v-card elevation-1>
          <v-row no-gutters class="EDC-Row">
            <v-col cols="3"></v-col>
            <v-col cols="6" class="EDC-Col">
              <v-row no-gutters class="EDC-Row" style="margin-top:20px !important">          
                <v-col cols="12" class="EDC-Col">
                  <v-row no-gutters class="EDC-Row EDC-RowsSpacing">
                    <v-text-field label="Current Password" v-model="current_password" :append-icon="show1 ? 'visibility_off' : 'visibility'" :type="show1 ? 'text' : 'password'" class="input-group--focused" required @click:append="show1 = !show1" dense></v-text-field>
                  </v-row>
                  <v-row no-gutters class="EDC-Row EDC-RowsSpacing">
                    <v-text-field label="New Password" v-model="password" :append-icon="show1 ? 'visibility_off' : 'visibility'" :type="show1 ? 'text' : 'password'" class="input-group--focused" required :rules="passwordRules" ref='password' @click:append="show1 = !show1" @keyup="checkwithcurrent_password" dense></v-text-field>
                  </v-row>
                  <v-row no-gutters class="EDC-Row EDC-RowsSpacing">
                    <v-text-field label="Confirm New Password" v-model="confirmpassword" :rules="confirmPasswordRules" :append-icon="show1 ? 'visibility_off' : 'visibility'" :type="show1 ? 'text' : 'password'" class="input-group--focused" required @click:append="show1 = !show1" ref='confirmpassword' @keyup="checkwithpassword"></v-text-field>
                  </v-row>
                  <v-row no-gutters class="EDC-Row EDC-RowsSpacing" justify="end">
                    <vc-button itemText="Reset your Password" @click.native="submit"></vc-button>
                    <!-- <v-btn :color="outlineColor"@click.native="loader = 'loading'" @click="submit" outline style="border-radius:10px">Reset your Password </v-btn> -->
                    <vc-button itemText="Back" @click.native="back"></vc-button>
                  </v-row>
                </v-col>
              </v-row>
            </v-col>
            <v-col cols="3"></v-col>
          </v-row>
        </v-card>
      </v-form>

    <v-snackbar v-model="snackbar" :color="colorValue" :timeout="snackbartimeout" id="edcmessage" top right>{{ snackbartext }}</v-snackbar>

    </v-container> 
  </div>
</template>

<script>
import { post as postToServer } from './../../methods/serverCall.js';
import { get as getToServer } from './../../methods/serverCall.js';
import config from '../../config.json'
import {SERVER_ERROR} from '../../data/client_message.js'
import {CHANGE_PASSWORD} from '../../data/url_constants.js'
import {COLOR_CODE, BTN_COLOR} from '../../data/macros.js'
import {Snackbar_Default_Timeout} from '@/constants/constants.js'

export default {
  data () {
    return { 
      outlineColor:BTN_COLOR,
      colorCode:COLOR_CODE,
      valid: true,
      snackbar:false,
      snackbartext:'',
      snackbartimeout: Snackbar_Default_Timeout,
      colorValue:'error',
      loader: "loading",
      loading: false,
      show1: false,
      show2: false,
      password: '',
      current_password:'',
      confirmpassword: '',
      change_password_input:'',
      password_policy: {},
      passwordRules: [v => !!v || 'Password is required.',
      v=> v != this.current_password || 'Current Password and New Password should not be same.'],
      confirmPasswordRules: [v => !!v || 'Confirm Password is required.',
      v=> v == this.password || 'New Password and Confirm Password should be same.'],
    }
  },
  mounted (){
    this.getClient()
  },
  methods:{
    checkwithcurrent_password(){
      this.$refs.password.validate()
    },
    back () {
     this.$router.back();
  
      },
    checkwithpassword(){
      this.$refs.confirmpassword.validate()
    },
    submit () {
      if (this.$refs.form.validate()) {
        this.validatePassword()
      }
    },

    change_password(){
      const l = this.loader
      this[l] = !this[l]
      var _this = this
      var change_password_endpoint = config.USER_PROVISION_URL + CHANGE_PASSWORD
      var change_password_input = {
        'username': this.$session.get('email'),
        'old_password':this.current_password,
        'new_password': this.password
      }
      postToServer(this, change_password_endpoint, change_password_input).then(user_provisioning_response  => {
        this.snackbar = true
        this.colorValue = 'info'
        this.snackbartext = user_provisioning_response
        setTimeout(function() {
          _this.$router.go(-1)
        }, 1000);
        this[l]  = false
        this.loader = null
      }).catch(error_response => {
        
        if(error_response){            
          this.snackbartext = error_response
        }
        else {
          this.snackbartext = SERVER_ERROR
        } 
        this.snackbar = true
        this.colorValue = 'error'
        this[l]  = false
        this.loader = null
      });
    },

    getClient() {
      this.Organization = this.$session.get('client_id')
      var client_id = this.$session.get('client_id')
      getToServer(this, config.ACCOUNT_MANAGEMENT_URL + '/client_account_preferences/' + client_id).then(response => {
        this.password_policy = response
        }).catch(error_response => {
          if(error_response){
              this.loader = null 
              this.snackbar = true
              this.colorValue = 'error'
              this.snackbartext = error_response;
            }
            else {
              this.snackbar = true
              this.colorValue = 'error'
              this.snackbartext = 'Something went wrong. Try Again';
            }
        })
    },

    validatePassword(){
      if(this.password.length > this.password_policy.max_password_length){
        this.snackbar = true
        this.colorValue = 'error'
        this.snackbartext = 'Maximum ' + this.password_policy.max_password_length + ' characters';
      }
      else if(this.password.length < this.password_policy.min_password_length){
        this.snackbar = true
        this.colorValue = 'error'
        this.snackbartext = 'Minimum ' + this.password_policy.min_password_length + ' characters';
      }
      else if(this.password_policy.password_allow_numeric = 1 && !this.password.match(/[0-9]/g)){
        this.snackbar = true
        this.colorValue = 'error'
        this.snackbartext = 'Numeric digit is missing';
      }
      else if(this.password_policy.password_must_contain_alphabates = 1 && !this.password.match(/[a-z]/i)){
        this.snackbar = true
        this.colorValue = 'error'
        this.snackbartext = 'Alphabate is missing';
      }
      else if(this.password_policy.password_must_contain_uppercase = 1 && !this.password.match(/[A-Z]/g)){
        this.snackbar = true
        this.colorValue = 'error'
        this.snackbartext = 'Uppercase Character is missing';
      }
      else if(this.password_policy.password_must_contain_lowercase = 1 && !this.password.match(/[a-z]/g)){
        this.snackbar = true
        this.colorValue = 'error'
        this.snackbartext = 'Lowercase Character is missing';
      }
      else if(this.password_policy.password_must_contain_special_character = 1 && !this.password.match(/[!@#$%^&*]/g)){
        this.snackbar = true
        this.colorValue = 'error'
        this.snackbartext = "Special Character is missing. Use '!', '@', '#', '$', '%', '^', '&' or '*'";
      }
      else{
        this.change_password()
      }
    }
  }
}
</script>

<style>


</style>
