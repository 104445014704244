<template>
	<div id="edcprocessdesigner">
		<v-container class="EDC-Row breadcrumbBackground">
			<v-row class="EDC-Row">
				<v-col class="EDC-Col text-truncate" align="left" cols="11">
					<ul class="breadcrumb" style="display:inline-flex;padding-left:0px !important;">
						<li>{{env_name}}</li>
						<li><input type="text" v-model='definitionname' :title="definitionname" v-on:blur="OnDefinationNameChange()" :readonly="isReadOnly" :size="nameTextboxSize"/></li>
					</ul>
					<span style="color:rgba(0,0,0,0.90);font-weight:400;font-size:13px;margin-left:5px;" v-if="process_def_id">
						<span style="cursor: pointer;" @click="ShowObjDetails">
							Ver. {{object_revision}}
						</span>
					</span>

					<span v-if="!isReadOnly" style="font-weight:400;font-size:13px;margin-left:5px;" v-bind:class="{errorcls:is_error_occured , successcls:!is_error_occured}">{{'('+savedstatus + ')'}}</span>

					<span v-else style="color:rgba(0,0,0,0.54);font-weight:400;font-size:13px;margin-left:5px;">
					(Readonly Mode)</span>

					<span v-if="!isReadOnly" style="padding-left:4px !important;">
						<toggle-button class="saveTypeToggle" :labels="{checked: 'Auto Save', unchecked: 'Auto Save'}" v-model="isAutoSave" style="margin-top:0px !important;margin-bottom:0px !important;border-radius:20px !important;padding-right:2px !important;" :sync="true" :width="96" :color="{checked: '#75C791', unchecked: '#859AA4', disabled: '#CCCCCC'}" @change="setUserPreferences"/>
						<!-- <vc-button itemText="Save" :xSmall='true' :disabled="!saveType"></vc-button> -->
					</span>
					<span style="font-weight:400;font-size:13px;margin-left:5px;" :style="{'color': colorCode}">{{dataSourceError||redoundoMsg}}</span>

				</v-col>

				<v-col class="EDC-Col">
					<v-row class="EDC-Row">
						<v-col class="EDC-Col" cols="4">
							<v-progress-circular :width="3" v-if="validateProgress" :size="20" color="blue" indeterminate></v-progress-circular>
							<svgicon v-if="!validateProgress" class="svg-icon-grid svg-fill-grid" name="validate"  style="float:right;"  title="Validate" @click="Validateflowchart"></svgicon>
						</v-col>
						<v-col class="EDC-Col" cols="4">
							<v-chip small :color="chipcolor" style="margin-top:2px !important;height:20px !important;">{{errorListCount}}</v-chip>
						</v-col>
						<v-col class="EDC-Col" cols="4">
							<svgicon class="svg-icon-grid svg-fill-grid" name="cancel_v2"  @click="showClosePopUp()" style="float:right;"  title="Close Tab"></svgicon>
						</v-col>
					</v-row>
				</v-col>
			</v-row>
			<v-row class="EDC-Row">
				<v-col class="EDC-Col" align="left">
					<edc-graph-toolbar :redoCount="undoStack.length" :undoCount="redoStack.length" @performUndoRedo="keyupHandler" 
					@showDescription="showDescription()" key="pd-desinger" @createNewVersion="createNewVersion"
					 @onSave="toolbarSaveClicked" @templateOptionChanged="templateOptionChanged" 
					:isTemplate="isTemplate" :erpCode="erp_code"></edc-graph-toolbar>
				</v-col>
			</v-row>
		</v-container>
		<v-layout raw wrap>
				<div role="tablist" style="width:25%;" id="tablist" ref="tablistref">
					<b-card no-body class="mb-1">
						<b-card-header header-tag="header" class="p-1" role="tab">
							<b-btn block href="#" v-b-toggle.accordion1><font color='white'>Basic Steps </font> </b-btn>
						</b-card-header>
						<b-collapse id="accordion1" visible accordion="my-accordion" role="tabpanel"
						style="overflow-y: auto; height: 450px !important;">
							<b-card-body>
								<div id="stencil" style="width:100%"></div>
							</b-card-body>
						</b-collapse>
					</b-card>
					<b-card no-body class="mb-1">
						<b-card-header header-tag="header" class="p-1" role="tab">
							<b-btn block href="#" v-b-toggle.jdecomponents><font color='white'>JDE Components </font> </b-btn>
						</b-card-header>
						<b-collapse id="jdecomponents" visible accordion="my-accordion" role="tabpanel"
						style="overflow-y: auto; height: 450px !important;">
							<b-card-body>
								<div id="jdecomponentsstencil" style="width:100%"></div>
							</b-card-body>
						</b-collapse>
					</b-card>
					<b-card no-body class="mb-1">
						<b-card-header header-tag="header" class="p-1" role="tab" @click="setBusinessStencil()">
							<b-btn block href="#" v-b-toggle.accordion2 > <font color='white'>Business Objects</font></b-btn>
						</b-card-header>
						<b-collapse id="accordion2" visible accordion="my-accordion" role="tabpanel"
						style="overflow-y: auto; height: 300px;">
							<b-card-body class="cardbody">
								<div id="bussinesspaper" ref="refbusinessobj"></div>
							</b-card-body>
						</b-collapse>
					</b-card>
			</div>
			<div id="collasible" style="width:1%;">
				<v-icon id="close_tablelist_icon" @click.stop="perform_collapse('close')" title="Close Control Panel" class="glyphicon" ref="closetablelisticon">chevron_left</v-icon>

				<v-icon id="open_tablelist_icon" @click.stop="perform_collapse('open')" title="Open Control Panel" style="display:none;position: relative;font-size:25px;" ref="opentablelisticon">chevron_right</v-icon>
			</div>
			<div style="width:74%;" id="main_container" ref="main_container" :style="leftColStyles">
				<edc-graph :mainPaperDimentions="paperDimentions" :newObject="newCell" @sendElementEvent="receiveElementEvent" @sendLinkEvent="receiveLinkEvent" @sendGraphEvent="receiveGraphEvent" :graphFor="graphFor" :graphLoader="tableListLoader":cellToRemove="cellToRemove" :dataForGraphRedesign="dataForGraphRedesign" :validationGraphObj="validationGraphObj" :isReadOnly="isReadOnly" :updatedStepData="updatedStepData" key="process_designer" :changedBOForPolicy="changedBOForPolicy" :updatedReferenceDetails="updatedReferenceDetails" @sendNonGraphEvent="receiveNonGraphEvent" :openCell="openCell" :closeCell="closeCell"></edc-graph>
			</div>
		</v-layout>
		<v-layout>
			<v-flex xs12>
			<v-dialog v-model="showSelectionDialog" persistent eager width="1210px">
				<edc-dataselection :parentdigramobj='selectionStepDataObject' :environmentDetails="environmentDetails" @update-object="saveGraphData" :tableListLoader="tableListLoader" :savedatastatus="savedstatus" :iserroroccured="is_error_occured" :policies="policyList" :datasourceerror='dataSourceError' @update-step-selection="updatePDJSON" :acceleratorObj="acceleratorObj" :readonly="isReadOnly" key="selection" @sendNonGraphEvent="receiveNonGraphEvent" :redoundoCommandObj="redoundoCommandObj" :redoundoMsg="redoundoMsg" @validatePD="validateProcessDefinitionOnUserAction" :undoStack="undoStack" :redoStack="redoStack" @keyupHandler="keyupHandler"  @saveProcessDesigner="toolbarSaveClicked"></edc-dataselection>
			</v-dialog>

			<v-dialog v-model="showMergeDialog" persistent eager width="1210px">
				<edc-data-merge :parentdigramobj='mergeStepData' @update-object="saveGraphData" :tableListLoader="tableListLoader" :savedatastatus="savedstatus" :iserroroccured="is_error_occured" :datasourceerror='dataSourceError' @update-step-selection="updatePDJSON" :acceleratorObj="acceleratorObj" :readonly="isReadOnly" key="datamerge" @sendNonGraphEvent="receiveNonGraphEvent" :redoundoCommandObj="redoundoCommandObj" :redoundoMsg="redoundoMsg" @validatePD="validateProcessDefinitionOnUserAction"  :undoStack="undoStack" :redoStack="redoStack" @keyupHandler="keyupHandler"  @saveProcessDesigner="toolbarSaveClicked"></edc-data-merge>
			</v-dialog>

			<v-dialog v-model="showBOActivityDialog" persistent eager width="1210px">
				<edc-boactivity :archivalobj='BOActivityStepDataObject' :bussinessObject="bussinessObject" :bussinessObjectList="business_obj_list" @update-object="saveGraphData" :savedatastatus="savedstatus" :iserroroccured="is_error_occured" :policies="policyList" @update-policy-list="get_policy_details_of_selected_bus_obj" :datasourceerror='dataSourceError' :tblsprimarykeys="tableWithPrimaryKeys"  @update-tblsprimarykeys="updatetblsprimarykeys" :acceleratorObj="acceleratorObj" :readonly="isReadOnly" :redoundoCommandObj="redoundoCommandObj" @sendNonGraphEvent="receiveNonGraphEvent" :redoundoMsg="redoundoMsg" @validatePD="validateProcessDefinitionOnUserAction"  :undoStack="undoStack" :redoStack="redoStack" @keyupHandler="keyupHandler"  @saveProcessDesigner="toolbarSaveClicked"></edc-boactivity>
			</v-dialog>

			<v-dialog v-model="showProcedureDialog" persistent eager width="1210px">
				<edc-storedprocedure :storedproceduredigramobj='procedureStepDataObject' :savedatastatus="savedstatus" :iserroroccured="is_error_occured" :policies="policyList" @update-object="saveGraphData" :datasourceerror='dataSourceError' :acceleratorObj="acceleratorObj" :readonly="isReadOnly" @validatePD="validateProcessDefinitionOnUserAction" :redoundoCommandObj="redoundoCommandObj" @sendNonGraphEvent="receiveNonGraphEvent" :redoundoMsg="redoundoMsg" :undoStack="undoStack" :redoStack="redoStack" @keyupHandler="keyupHandler"  @saveProcessDesigner="toolbarSaveClicked"></edc-storedprocedure>
			</v-dialog>

			<v-dialog v-model="showDuplicateDialog" persistent eager width="1210px">
				<edc-duplicate :duplicateobj='duplicateStepDataObject' :bussinessObject="bussinessObject" :bussinessObjectList="business_obj_list" @update-object="saveGraphData" :savedatastatus="savedstatus" :iserroroccured="is_error_occured" :policies="policyList" @update-policy-list="get_policy_details_of_selected_bus_obj" :datasourceerror='dataSourceError' :tblsprimarykeys="tableWithPrimaryKeys"  @update-tblsprimarykeys="updatetblsprimarykeys" :acceleratorObj="acceleratorObj" :readonly="isReadOnly" @validatePD="validateProcessDefinitionOnUserAction"  :undoStack="undoStack" :redoStack="redoStack" @keyupHandler="keyupHandler"  @saveProcessDesigner="toolbarSaveClicked"></edc-duplicate>
			</v-dialog>

			<v-dialog v-model="showJdeSummarizationDialog" persistent eager width="1210px">
				<edc-jde-summarization :parentdigramobj="jdeSummarizationObj" @update-object="saveGraphData" :savedatastatus="savedstatus" :iserroroccured="is_error_occured"
				:policies="policyList" @update-policy-list="get_policy_details_of_selected_bus_obj"
				:datasourceerror='dataSourceError' :tblsprimarykeys="tableWithPrimaryKeys"
				 @update-tblsprimarykeys="updatetblsprimarykeys" :acceleratorObj="acceleratorObj"
				 :readonly="isReadOnly" @validatePD="validateProcessDefinitionOnUserAction">
				</edc-jde-summarization>
			</v-dialog>

			<v-dialog v-model="showDataItemSearch" persistent eager width="1210px">
				<edc-data-item-search @update-object="saveGraphData" :environmentDetails="environmentDetails"
				 :parentdigramobj="dataItemSearchObj" :acceleratorObj="acceleratorObj" :tableList="dataItemTableList"
				 @GetTables="Get_Tables" :businessObjectList="business_obj_list"
				 @validatePD="validateProcessDefinitionOnUserAction" :policies="policyList"></edc-data-item-search>
			</v-dialog>

			<v-dialog v-model="showCreateViewStep" persistent eager width="1210px">
				<edc-create-view @update-object="saveGraphData" :environmentDetails="environmentDetails"
				 :parentdigramobj="createViewStepDataObject" :acceleratorObj="acceleratorObj" 
				 @validatePD="validateProcessDefinitionOnUserAction" :policies="policyList"></edc-create-view>
			</v-dialog>

			<v-dialog v-model="showObjectDetails" width="400">
			<ObjectDetailpopUp :objectdetails="reqInputData" @closeDetailPopup="closeDetailPopup"></ObjectDetailpopUp>
		</v-dialog>

		</v-flex>
		<simplert :useRadius="true"
            :useIcon="true"
            ref="simplert">
          </simplert>
		</v-layout>
		<v-dialog v-model="descriptionPanel" width="1200" eager>
			<v-card>
					<rich-text-box style="backgroud:white" @DescriptionChange="DescriptionChange" :vueEditorObj="vueEditorObj"></rich-text-box>
					<v-card-actions>
						<v-spacer></v-spacer>
						<vc-button  itemText= "Close" @click.native="descriptionPanel = false">

			            </vc-button>
					</v-card-actions>
				</v-card>
			</v-dialog>

	</div>
</template>
<style scoped>
	.saveTypeToggle >>> .v-switch-core{
		border-radius: 20px !important;
	}
</style>
<script>
	import {mixinEdcGraph} from '../../mixins/edcGraph.js'
	import { shapes, dia,layout } from 'jointjs'
	import config from '../../config.json'
	import { post as postToServer } from '@/methods/serverCall.js';
import { get as getToServer } from '@/methods/serverCall.js';
	import edcDataSelection from './edcSelectionDesignerRaw.vue'
	import edcDataMerge from './edcDataMerge.vue'
	import edcStoredProcedure from './store_procedure.vue'
	import edcBOActivity from './newarchival.vue'
	import edcDuplicate from './duplicate.vue'
	import edcJdeSummarization from './edcJdeSummarization.vue'
	import edcDataItemSearch from './edcDataItemSearch.vue'
	import edcCreateView from './edcCreateView.vue'
	import Simplert from 'vue2-simplert'
	import ObjectDetailpopUp from './object_details_popup.vue'
	import {getEnvironmentDetails} from '@/methods/EnvironmentList.js'
	import {validate_flowchart} from "../../methods/call_validation.js"
	import RichTextBox from "@/views/richTextEditor/index.vue";

	import {getDefinedBusinessObjectList} from "../../methods/flowcharts/shapes.js"
	import {getTableList, getTableColumns, getStoredProcedureList,getAccelerationDetails} from "../../methods/agent_api_call.js"
	import { ADD_GRAPH_DATA, GET_PROCESS_GRAPH_DATA, ALL_PUBLISHED_BUSINESS_OBJ_LIST,GET_POLICY_BY_ID_LIST,GET_PRIMARY_KEYS } from '../../data/url_constants.js'
	import {SERVER_ERROR} from '../../data/client_message.js'

	import {COLOR_CODE,BTN_COLOR} from '@/data/macros.js'
	import * as paperObj from './../../methods/edcGraph/papers.js'
	import * as shapesObj from './../../methods/edcGraph/shapes.js'
	import * as commonObj from './../../methods/edcGraph/commonFunctions.js'
	import * as anonymousObj from './../../methods/edcGraph/anonymousFunctions.js'
	import * as edcGraphConstants from './../../methods/edcGraph/constants.js'
	import * as edcGraphObj from './../../methods/edcGraph/graph.js'
	import * as edcConstantObj from './../../methods/edcGraph/constants.js'
	import {mapActions} from 'vuex'

	export default {
		name:'ProcessDesigner',
		mixins:[mixinEdcGraph],
		components:{
			'edc-dataselection':edcDataSelection,
			'edc-boactivity':edcBOActivity,
			'edc-storedprocedure':edcStoredProcedure,
			'edc-duplicate':edcDuplicate,
			'simplert':Simplert,
			ObjectDetailpopUp,
			'rich-text-box':RichTextBox,
			'edc-data-merge':edcDataMerge,
			'edc-jde-summarization':edcJdeSummarization,
			'edc-data-item-search':edcDataItemSearch,
			'edc-create-view':edcCreateView
		},
		data(){
			return {
				productName:this.$route.params.product_name,
				datasource_id:this.$route.params.datasource_id,
				datasourceName:this.$route.params.datasourceName,
				nameTextboxSize:20,
				graphFor:'process_designer',
				is_error_occured:false,
				paperDimentions:{
					'width':1600,
					'height':1600
				},
				savedstatus:'edited',
				newCell:{},
				removeCell:{},
				graphData:{},
				env_id: this.$session.get("selected_env").id,
				env_name: this.$session.get("selected_env").name,
				main_filterUI:[],
				secondary_filterUI:{groupList:[]},
				minimapTop:'370px',
				graphHeight:'450px',
				cellToRemove:{},
				dataForGraphRedesign:{},
				validationGraphObj:{},
				isReadOnly:false,
				tableListLoader:false,
				leftColStyles: { },
				definitionname:'Untitled Process Definition',
				object_revision:'',
				process_def_id:'',
				outlineColor:BTN_COLOR,
        		colorCode:COLOR_CODE,
        		validateProgress:false,
				chipcolor:'green',
				dataSourceError:'',
				errorListCount:0,
				selectionStepDataObject:{},
				mergeStepData:{},
				BOActivityStepDataObject:{},
				procedureStepDataObject:{},
				duplicateStepDataObject:{},
				policyList:[],
				acceleratorObj:{},
				showSelectionDialog:false,
				showMergeDialog:false,
				showBOActivityDialog:false,
				showProcedureDialog:false,
				showDuplicateDialog:false,
				currentActiveStep:{},
				dataSourceTimer:'',
				datasourceTimerSec:0,
				table_list:[],
				connection_str:"",
				schema:"",
				db_type:"",
				datasource_id:this.$route.params.datasource_id,
				target_connection_str:"",
				target_schema:"",
				target_datasource_id:"",
				default_table_list:"",
				store_procedure_list: '',
				default_store_procedure_list: '',
				updatedStepData:{},
				edcGraph:new dia.Graph({},{ cellNamespace: shapes }),
				BussinessGraph:new dia.Graph({},{ cellNamespace: shapes }),
				tableWithPrimaryKeys:[],
				business_obj_list:[],
				bussinessObject:{},
				is_request_in_process:false,
				oldPDName:'',
				showObjectDetails:false,
				reqInputData:{},
	      		erp_code:this.$route.params.erpCode,
			    erp_version:this.$route.params.erpVersion,
				isObjectFullyLoaded:false, // call getting send to backend before object get loaded fully.
				vueEditorObj:{},
				descriptionPanel:false,
			    pd_description:'',
			    valid:false,
			    changedBOForPolicy:{},
			    updatedReferenceDetails:{},
			    undoStack:[],
			    redoStack:[],
			    openCell:{},
			    redoundoCommandObj:{},
			    redoundoMsg:'',
			    redoundoTimer:null,
			    closeCell:{},
			    isBusinessObjectNameChanged:false, // we need to reflect business object name change in the policy reference.For that purpose we are using this variable.
			    showJdeSummarizationDialog:false,
				showDataItemSearch:false,
				jdeSummarizationObj:{},
				dataItemSearchObj:{},
				environmentDetails:{},
				dataItemTableList:[],
				autoValidateTimer:0,
				allStepsDescription:[],
				isTemplate:false,
				showCreateViewStep:false,
				createViewStepDataObject:{}
			}
		},
		beforeDestroy() {
			clearInterval(this.timer)
			clearInterval(this.dataSourceTimer)
		},
		watch:{
			business_obj_list(newvalue){
				this.setBusinessStencil()
			},
			definitionname(newvalue,oldvalue){
				if(newvalue){
					this.nameTextboxSize=Math.max(20,newvalue.length>60?60:newvalue.length)
				}
			},
			isObjectFullyLoaded(newvalue){

			}
		},
		mounted() {
			this.setDesignerFor({"designerFor":'Process Definition Designer'})
			let _this = this
			document.addEventListener('keyup',  _this.keyupHandler)

			$('#edcprocessdesigner').on('keydown',function(event){
		      if (event.ctrlKey && (event.code === 'KeyZ'||event.code === 'KeyY'))
		          event.preventDefault()
		    });

			if(!this.$route.params.process_def_id){
				_this.startInitialSaveTimer(5000)
			}


			var env_id = this.$session.get("selected_env").id
			var client_id = this.$session.get("client_id")
			// Canvas where sape are dropped
			let height = window.innerHeight-110 + 'px';
			this.minimapTop = (window.innerHeight-210)+'px';//60 internal minimap height + 140 top navigation height
			getAccelerationDetails(_this,env_id,client_id)
			this.$set(this.leftColStyles, 'height', height);
			var stencilGraph = new dia.Graph({},{ cellNamespace: shapes }),
			stencilPaper = paperObj.getStencilPaper('#stencil',stencilGraph)
			stencilGraph.addCells(shapesObj.getDesignerShapes())

			var jdeItemGraph = new dia.Graph({},{ cellNamespace: shapes })
			var jdeObjectPaper = paperObj.getStencilPaper('#jdecomponentsstencil',jdeItemGraph)
			jdeItemGraph.addCells(shapesObj.getJdeDesignerSteps())

			var businessPaper = paperObj.getStencilPaper('#bussinesspaper',_this.BussinessGraph);
			_this.getBusinessObjectNameList()

			stencilPaper.on('cell:pointerdown', function(cellView, e, x, y) {
				if(!_this.isReadOnly)
					_this.newCell = {
						'element':cellView.model,
						'edc_type':_this.graphFor,
						'e':e,
						'x':x,
						'y':y,
						'is_element':true,
						'is_dragged':true
					}
			});


			jdeObjectPaper.on('cell:pointerdown', function(cellView, e, x, y) {
				if(!_this.isReadOnly)
					_this.newCell = {
						'element':cellView.model,
						'edc_type':_this.graphFor,
						'e':e,
						'x':x,
						'y':y,
						'is_element':true,
						'is_dragged':true
					}
			});


			businessPaper.on('cell:pointerdown', function(cellView, e, x, y) {
			if(!_this.isReadOnly)
				_this.newCell = {
					'element':cellView.model,
					'edc_type':_this.graphFor,
					'e':e,
					'x':x,
					'y':y,
					'is_element':true,
					'is_dragged':true
				}
			});

			var env_has_allow_create = _this.$session.get('selected_env').allow_create
			if(env_has_allow_create === false || env_has_allow_create === 'false'){
				_this.isReadOnly = true
				_this.RedesignGraph('No');
			}
			else
			{
				if(this.$route.params.process_def_id){
					if(this.$route.params.copy){
						_this.isReadOnly = false
						_this.CopyGraph()
					}
					else{
						_this.RedesignGraph()
						if(this.$route.params.is_read_only){
							this.isReadOnly = true

						}
						else{
							var data = {
				     		"object_id": this.$route.params.process_def_id,
				     		"object_revision": this.$route.params.object_revision,
				     		"object_type_code": this.$route.params.object_type_code,
				     		"env_id": this.$route.params.env_id,
				     		"env_name": this.$route.params.env_name
				     	}
						_this.process_def_id = _this.$route.params.process_def_id
						_this.object_revision =_this.$route.params.object_revision
						if(!_this.$route.params.create_new_version)
							_this.checkobjectversion(data)
						else
							_this.isReadOnly = false
						}
					}
				}
				else{
					_this.isReadOnly = false
					// if(!this.$session.get('selected_env').id){
					// 	this.$router.push('/processdefinitionlist');
					// }
					// _this.getProcedureList()
					_this.Get_Tables()
				}
			}
			// this.setDesignerFor({"designerFor":'Process Definition Designer'})
			getEnvironmentDetails(_this, _this.env_id, _this.client_id, _this.environmentDetails)
			_this.getAllStepsDescription(stencilGraph,jdeItemGraph)
		},
		destroyed(){
	  		document.removeEventListener('keyup', this.keyupHandler)
		},
		methods:{
			...mapActions(['setDesignerFor']),
			validateProcessDefinitionOnUserAction(){
				// we are going to call validate object after curtain time after action get performed because graph take sometime to update its json.
				var _this = this
				clearInterval(_this.autoValidateTimer)
				_this.autoValidateTimer = setTimeout(()=>{
					_this.Validateflowchart()
				},edcGraphConstants.AUTO_VALIDATOR_TIMER)
			},
			OnDefinationNameChange(){
			// code check for if user didnt specify any name to object then we need to give old name
				if(!this.definitionname)
					this.definitionname = this.oldPDName
				if(this.definitionname != this.oldPDName)
					this.receiveNonGraphEvent('definition_name_change',{"user_action":'definition_name_change','new_name':this.definitionname,'old_name':this.oldPDName,'action_to_take':'definition_name_change'})
				this.changeSaveStatus()

			},
			receiveElementEvent(eventName,data){
				let element = data.element
				if(!element || !element.prop('is_embed'))
					return
				let _this = this
				_this.currentActiveStep = element

				if(eventName === 'doubleclick'){
					_this.cellDoubleClicked(element,data.is_redo_undo)
				}
				if(data.stackObj)
					_this.undoStack.push(data.stackObj)
				if(eventName === 'add_element' || eventName === 'remove_element'){
					_this.validateProcessDefinitionOnUserAction()
				}
			},
			receiveLinkEvent(eventName,data){
				let link = data.link
				if(data.stackObj)
					this.undoStack.push(data.stackObj)
				if(eventName === 'connect' || eventName ==='remove'){
					this.validateProcessDefinitionOnUserAction()
				}
			},
			receiveGraphEvent(eventName,data){
				let _this = this
				_this.graphData = data.graphData
				_this.edcGraph = data.edcGraph
				if(edcGraphConstants.GRAPH_EVENTS_FOR_REDESIGN.indexOf(eventName)>-1){
					_this.changeSaveStatus()
				}
			},
			receiveNonGraphEvent(eventName,data){
				if(eventName === 'step_name_changed'||eventName === 'step_name_changed_from_inside'){
					this.reverseStepChangeData(data,eventName)
				}
				else if(eventName === 'definition_name_change')
					this.reverseDefinitionName(data)
				else if(eventName === 'dragged_table'){
					data['user_action'] = 'add_table'
					data['action_to_take'] = 'remove_table'
				}
				else if(eventName === 'remove_table'){
					data['user_action'] = eventName
					data['action_to_take'] = 'add_table'
				}
				else if(eventName === 'join_link_deleted'){
					data['user_action'] = eventName
					data['action_to_take'] = 'add_join_link'
				}
				else if(eventName === 'add_column'){
					data['user_action'] = eventName
					data['action_to_take'] = 'remove_column'
				} else if(eventName === 'remove_column'){
					data['user_action'] = eventName
					data['action_to_take'] = 'add_column'
					// if eventName is remove_column, then we need to make sure for step id,for add_column, make sure same column name is not present in the stack.We have add this code because there is no clear option available to indicate is that column deleted by the user or the column get removed due to Undo Redo
					let isExistAlready = _.filter(this.undoStack,(obj)=>{return obj.step_id === data.step_id && obj.action_to_take==='add_column' && obj.column.column_name === data.column.column_name && obj.column.id === data.column.id})
					if(isExistAlready.length)
						return
				}
				// else if(eventName === 'selection_filter_change'){
				// 	data['user_action'] = eventName
				// 	data['action_to_take']=eventName
				// }
				else if(eventName ==='step_limit_change'){
					data['user_action'] = eventName
					data['action_to_take']=eventName
				} else if(eventName === 'run_on_target_change'){
					data['user_action'] = eventName
					data['action_to_take']=eventName
				} else if(eventName === 'driver_table_change'||eventName === 'business_obj_change'||eventName==='new_table_dragged' || eventName ==='table_replaced' || eventName ==='selection_filter_change'){
					data['user_action'] = eventName
					data['action_to_take']=eventName
					this.reverseOldNew(data)
				}
				else
					return

				this.undoStack.push(data)
			},
			reverseStepChangeData(kwargs,eventName){
				kwargs['user_action'] =eventName
				kwargs['action_to_take'] =eventName
				let dataToUpdate = _.cloneDeep(kwargs.update_details)
				dataToUpdate['new_step_name'] = kwargs.update_details['step_name']
				dataToUpdate['step_name'] = kwargs.update_details['new_step_name']
				kwargs['update_details'] = dataToUpdate
			},
			reverseDefinitionName(data){
				let dataCopy =_.cloneDeep(data)
				data['old_name'] = dataCopy['new_name']
				data['new_name'] = dataCopy['old_name']
			},
			reverseOldNew(data){
				let oldValue = data['old_value']
				data['old_value']=data['new_value']
				data['new_value'] = oldValue
			},
			Get_Tables(connection_str='', schema ='', db_type='',
    		table_search_string='', tbl_object_type='', isCallFromDataItem=false,mapped_agent_id=''){
				var _this = this;
				_this.env_id = _this.$session.get('selected_env').id
				if(!_this.dataSourceTimer)
					_this.tableListLoader = true;
				_this.dataSourceError = 'Connecting to business datasource'
				clearInterval(_this.dataSourceTimer)
				var search_types = ["table","view","alias"]
	      		var kwargs = {"description_needed":true,
	                    "check_in_description":isCallFromDataItem,
	                    "object_type":search_types,"mapped_agent_id":mapped_agent_id}
				getTableList(_this,_this.env_id, connection_str, schema, db_type,
				table_search_string, tbl_object_type, kwargs).then(tableResponse =>{
					_this.dataSourceError=''
					_this.tableListLoader = false;
					var latest_table_list = tableResponse.table_view_list;
					if(isCallFromDataItem){
						_this.dataItemTableList = latest_table_list
					} else {
						_this.table_list = latest_table_list
						_this.default_table_list=_.cloneDeep(latest_table_list);
						_this.connection_str = tableResponse.conn_str
						_this.schema = tableResponse.schema
						_this.db_type = tableResponse.db_type
						_this.getProcedureList()
					}

				}).catch(errorResponse => {
					_this.tableListLoader = false;
					_this.dataSourceError = 'Enabled to connect business datasource.Retry in '+_this.datasourceTimerSec/1000+' seconds'
					_this.dataSourceTimer = setInterval(_this.Get_Tables,_this.datasourceTimerSec)
					_this.datasourceTimerSec = _this.datasourceTimerSec * 2
				});
			},
			getProcedureList(){
				var _this = this
				_this.env_id = _this.$session.get('selected_env').id
				getStoredProcedureList(_this, _this.env_id).then(tableResponse =>{
					const latest_store_procedure_list = tableResponse.result.map(x => x.trim());
					_this.store_procedure_list = latest_store_procedure_list
					_this.default_store_procedure_list=_.cloneDeep(latest_store_procedure_list)
					_this.connection_str = tableResponse.connstr
				}).catch(errorResponse => {});
			},
			cellDoubleClicked(cell,is_redo_undo,calledTime = 0){
				var _this = this
				this.env_id = this.$session.get('selected_env').id
				var source_cells_detail = commonObj.getAllIncomingLinksSourceDetails(this.edcGraph,cell,[])
				if(!source_cells_detail.length && this.edcGraph.getLinks().length && calledTime<=1){
					// some time graph not get immidiately updated after connecting link.for that purpose we have recall this function after one 1.And this case for link, target id is undefined.We will do this only once thats why used calledTime variable (Avoid infine loop in case of unhandled scenario).
					// alert('found 0')
					let undefinedLinks = this.edcGraph.getLinks().filter((obj)=>{return !obj.target().id})
					if(undefinedLinks.length){
						// alert('found undefied')
						calledTime = calledTime+1
						setTimeout(function(){
							_this.cellDoubleClicked(cell,is_redo_undo,calledTime)
						},800)
						return
					}
				}
				// alert('found '+source_cells_detail.length)
				this.get_policy_details_of_selected_bus_obj()
				var tables_detail = {
					"table_list":this.table_list,
					"connection_str":this.connection_str,
					"schema":this.schema,
					"db_type":this.db_type,
					"default_table_list":this.default_table_list,
					"store_procedure_list": this.store_procedure_list,
					"default_store_procedure_list": this.default_store_procedure_list,
					"datasource_id":this.datasource_id,
					"target_schema":this.target_schema,
					"target_connection_str":this.target_connection_str,
					"target_datasource_id":this.target_datasource_id
				}
				var data_to_pass = {
					"diagram_name":this.definitionname,
					"cell_name":cell.prop('stepname'),
					"step_data":cell.prop('step_data'),
					"source_cells_detail":source_cells_detail,
					"env_id": this.env_id,
					"tables_detail":tables_detail,
					"business_obj_id":"",
					"steptype":cell.prop('steptype'),
					"stepId":cell.id,
					"erp_code": this.erp_code,
					"erp_version": this.erp_version,
					"stepSeqNo":cell.prop('seqNo')
				}
				if(cell.prop('steptype') === 'Selection'  || cell.prop('steptype') === 'Minus'){
					// other step data only applicable in case of data selection process
					this.selectionStepDataObject = _.cloneDeep(data_to_pass)
					this.showSelectionDialog = true
				}
				else if(cell.prop('steptype') === 'Merge'){
					this.mergeStepData = _.cloneDeep(data_to_pass)
					this.showMergeDialog = true
				}

				else if(cell.prop('steptype') === 'Archival'){
					if(cell.prop('bus_id')){
						// this.getBussinessObject(cell.attr('bussinessObjectName'));
						data_to_pass.business_obj_id = cell.prop('bus_id');
					}
					this.BOActivityStepDataObject = _.cloneDeep(data_to_pass);
					this.BOActivityStepDataObject.run_on_target_label ="Archive from target to source"
					this.showBOActivityDialog = true;
				}
				else if(cell.prop('steptype') === 'Copy'){
					if(cell.prop('bus_id')){
						// this.getBussinessObject(cell.attr('bussinessObjectName'));
						data_to_pass.business_obj_id = cell.prop('bus_id');
					}
					this.BOActivityStepDataObject = _.cloneDeep(data_to_pass);
					this.BOActivityStepDataObject.run_on_target_label ="Copy from target to source"
					this.showBOActivityDialog = true;
				}
				else if(cell.prop('steptype') === 'Purge'){
					if(cell.prop('bus_id')){
						// this.getBussinessObject(cell.attr('bussinessObjectName'));
						data_to_pass.business_obj_id = cell.prop('bus_id');
					}
					this.BOActivityStepDataObject = _.cloneDeep(data_to_pass);
					this.BOActivityStepDataObject.run_on_target_label ="Purge from target"
					this.showBOActivityDialog = true;
				}
				else if(cell.prop('steptype') === 'Duplicate'){
					if(cell.prop('bus_id')){
						// this.getBussinessObject(cell.attr('bussinessObjectName'));
						data_to_pass.business_obj_id = cell.prop('bus_id');
					}
					this.duplicateStepDataObject = _.cloneDeep(data_to_pass);
					this.showDuplicateDialog = true;
				}
				else if(cell.prop('steptype') === 'Procedure'){
					this.procedureStepDataObject = {}
					var cells_list = this.edcGraph.toJSON()['cells']
					var cells_name_list = []
					for (var i = 0; i < cells_list.length; i++) {
						if(cells_list[i].steptype == 'Merge'){
							cells_name_list.push(cells_list[i].stepname)
						}
						if(cells_list[i].steptype == 'Minus'){
							cells_name_list.push(cells_list[i].stepname)
						}
						if(cells_list[i].steptype == 'Selection'){
							cells_name_list.push(cells_list[i].stepname)
						}
					}
					data_to_pass['cells_name_list'] = cells_name_list
					data_to_pass['cell_id'] = cell.id
					this.procedureStepDataObject = _.cloneDeep(data_to_pass)
					this.showProcedureDialog = true
				}

				else if(cell.prop('steptype') === 'Jde_summ_f0911' || cell.prop('steptype') === 'Jde_summ_f4111'){
					data_to_pass['cell_id'] = cell.id
					this.jdeSummarizationObj = _.cloneDeep(data_to_pass)
					this.showJdeSummarizationDialog = true
				}
				else if(cell.prop('steptype') === edcConstantObj.DATA_ITEM_SEARCH){
					data_to_pass['environmentDetails'] = _this.environmentDetails
					this.dataItemSearchObj = _.cloneDeep(data_to_pass)
					this.showDataItemSearch = true
				}
				else if(cell.prop('steptype') === edcConstantObj.CREATE_VIEW){
					data_to_pass['environmentDetails'] = _this.environmentDetails
					this.createViewStepDataObject = _.cloneDeep(data_to_pass)
					this.showCreateViewStep = true
				}
				// if(!is_redo_undo)
				// 	this.undoStack.push({"user_action":"open_step","cell_id":cell.id,'action_to_take':'close_step'})
			},
			get_policy_details_of_selected_bus_obj(data_from_step_inside,oldSelectionObject){
				this.env_id = this.$session.get('selected_env').id
				this.policyList=[]
				var selected_business_obj_list =[]
				var bo_from_inside_id =''
				var bo_from_inside_name = ''
				if(data_from_step_inside && data_from_step_inside['id']){
					bo_from_inside_id =data_from_step_inside['id']
					bo_from_inside_name = data_from_step_inside['name']
					// add that business object into businessobjectlist because user might created business object at runtime
					this.addNewBusinessObjectInList(data_from_step_inside)
				}
				if(!_.isEmpty(oldSelectionObject)){
					this.changedBOForPolicy = {"new_bo":data_from_step_inside,"old_bo":oldSelectionObject}
				}

				selected_business_obj_list = commonObj.getAllSelectedBusinessObjsDetails(this.edcGraph)
				if(bo_from_inside_id)
					selected_business_obj_list.push({"bus_id":bo_from_inside_id,"bus_name":bo_from_inside_name})

				if(!selected_business_obj_list)
					return false

				for(let i=0;i<selected_business_obj_list.length;i++){
					let bo_obj = _.find(this.business_obj_list,['object_id',selected_business_obj_list[i].bus_id])
					if(bo_obj)
						this.policyList.push({"bus_id":bo_obj.object_id,"bus_name":bo_obj.object_name})
					else
						this.policyList.push(selected_business_obj_list[i])
				}
			},
			addNewBusinessObjectInList(data_from_step){
				var is_bo_exists = _.find(this.business_obj_list,{"id":data_from_step["id"]})
				if (is_bo_exists)
					return false
				this.business_obj_list.push(data_from_step)
				this.setBusinessStencil()
			},
			setBusinessStencil(){
				this.BussinessGraph.clear()
				this.BussinessGraph.addCell(shapesObj.getEdcBOsForFlowchart(this.business_obj_list))
			},
			saveGraphData(data_from_selection,is_close_call){
				if(!this.currentActiveStep.id)
					return
				let cell = anonymousObj.getEmbeddedCell(this.currentActiveStep)
				var uniqueName = commonObj.getUniqueName(data_from_selection.stepname,this.currentActiveStep,this.edcGraph)

				this.currentActiveStep.prop('stepname',uniqueName)
				this.currentActiveStep.prop('step_data',data_from_selection.step_data)

				if(this.currentActiveStep.prop('steptype') === 'Archival' ||this.currentActiveStep.prop('steptype') === 'Copy' || this.currentActiveStep.prop('steptype') === 'Purge' ||this.currentActiveStep.prop('steptype') === 'Duplicate'){
					//write code for set business object properties to archival
					this.currentActiveStep.prop('bus_id', data_from_selection.step_data.business_obj_id)
					if(this.currentActiveStep.prop('bus_id')){

						let businessObjectName = data_from_selection.stepname
						let businessObj = _.find(this.business_obj_list,['object_id',this.currentActiveStep.prop('bus_id')])
						if(businessObj)
							businessObjectName = businessObj.name
						this.currentActiveStep.prop('bussinessObjectName', businessObjectName)
					}
					this.currentActiveStep.prop('policy_id',data_from_selection.step_data.business_object_policy_id)
				}

				this.updatedStepData = {
					"step_id":this.currentActiveStep.id,
					"stepname": this.currentActiveStep.prop('stepname'),
					"step_data":this.currentActiveStep.prop('step_data'),
					"bus_id":this.currentActiveStep.prop('bus_id'),
					"bussinessObjectName":this.currentActiveStep.prop('bussinessObjectName'),
					"policy_id":this.currentActiveStep.prop('policy_id')
				}

				if(is_close_call){
					// this is only purpose for numbering the each step on process definition
					this.closeCell = {"action":"closed","step_id":this.currentActiveStep.id}
					this.closeAllDialog()
					this.validateProcessDefinitionOnUserAction()
				}
				this.changeSaveStatus()
			},
			closeAllDialog(){
				this.showSelectionDialog = false;
				this.showMergeDialog = false;
				this.showBOActivityDialog = false;
				this.showDuplicateDialog = false;
				this.showProcedureDialog = false;
				this.showJdeSummarizationDialog = false;
				this.showDataItemSearch = false;
				this.showCreateViewStep = false;
				this.dataSourceError = ''
				this.currentActiveStep = {}
				this.openCell = {}
			},
			changeSaveStatus(){
				this.is_error_occured = false
				this.savedstatus = 'edited'
				// logic to save document only if user stop editing
				clearInterval(this.timer)
				this.timer = setInterval(this.AutoSaveProcessDesignerGraph,3000)
			},
			updatetblsprimarykeys(table_name,table_type,run_of_target){
		      let _this = this
		      this.dataSourceError = ''
		      var table_type = table_type
		      var env_id = _this.env_id
		      var env_name = this.$session.get('selected_env').name
		      var connection_str = this.connection_str
		      var mapped_list = []
		      if(_this.$session.get('mapped_agent_details'))
		          mapped_list = _this.$session.get('mapped_agent_details')
		      var mapped_agent_obj= _.find(mapped_list,['conn_str',connection_str])
		      var mapped_agent_id = ''
		      if(mapped_agent_obj)
		          mapped_agent_id = mapped_agent_obj.mapped_agent_id
		      var schema = _this.schema
		      var table_name =table_name
		      var db_type =_this.db_type
		      var client_id = this.$session.get('client_id')
		      var added_by = this.$session.get('email')
		      var data_to_pass = {"table_type":table_type,"env_id":env_id,"schema":schema,"conn_str":connection_str,"table_name":table_name,"db_type":db_type,"client_id":client_id,"env_name":env_name,"added_by":added_by,"object_type_code": "BO","mapped_agent_id":mapped_agent_id,"ds_id":this.datasource_id}

		      if(run_of_target){
		      	// load keys from target datasource
		      	data_to_pass["conn_str"] = this.target_connection_str
		      	data_to_pass["schema"] = this.target_schema
		      	data_to_pass["ds_id"] = this.target_datasource_id
		      }
		      postToServer(this, config.AGENT_API_URL + GET_PRIMARY_KEYS, data_to_pass).then(response => {
		        if(response.unique_identifier_keys){
		        	_this.tableWithPrimaryKeys.push({"table_name":table_name,"table_type":table_type,"keys":response.unique_identifier_keys})
		        }
		      }).catch(CurrentJobError => {
		        this.generateboclick = false
		        if(CurrentJobError){
		          this.dataSourceError = CurrentJobError
		        }
		        else {

		        }
		      })
		    },
		    getBusinessObjectNameList(){
				var _this = this
				var client_id = this.$session.get('client_id')
				var data = {'client_id': this.$session.get('client_id'), 'env_id': _this.$session.get('selected_env').id}
				postToServer(this, config.PUBLISHER_URL + ALL_PUBLISHED_BUSINESS_OBJ_LIST, data).then(response => {
					var res = response
					// CHANGE THIS IN FUTURE TEMPORARY CHANGE FOR FAST DEVELOPMENT
					// REPLACE ID WITH OBJECT_ID AND NAME WITH OBJECT_NAME IN newarchival.vue page
					res.map(function(obj){
						obj.id = obj.object_id
						obj.name = obj.object_name
						obj.display_name = obj.object_name + ' ('+obj.object_id+') '
					})
					res = _.sortBy(res,(obj)=>{
						return obj.display_name
					})
					this.business_obj_list = res
					paperObj.resetPaper(_this.$refs.refbusinessobj,80,_this.business_obj_list.length,60)

				}).catch(error_response => {
					if(error_response){
						this.snackbar = true
						this.colorValue = 'error'
						this.snackbartext = error_response;
						this.loader = null
					}
					else {
						this.snackbar = true
						this.colorValue = 'error'
						this.snackbartext = SERVER_ERROR;
					}
				})
			},
			Validateflowchart(){
				if(this.isReadOnly ||this.validateProgress)
					return
				var data = this.graphData;
				var selected_business_obj_list = commonObj.getAllSelectedBusinessObjsDetails(this.edcGraph)
				var selected_policy_details = commonObj.getselectedPolicies(this.edcGraph)
				data['selected_policy_details']= selected_policy_details
				data['selected_business_obj_list'] = selected_business_obj_list
				let _this = this;
				this.validateProgress = true;
				data['env_id'] = this.env_id;
				validate_flowchart(this, data).then(
				validation_response => {
					_this.validateProgress = false;
					if(!_.isEmpty(validation_response)){
						let stepIdArray = Object.keys(validation_response);

						if(validation_response.orphan_steps){
							stepIdArray = validation_response.orphan_steps
						}
						_this.errorListCount = stepIdArray.length;
						_this.chipcolor = 'red';
						_this.validationGraphObj = {
							"cellList":stepIdArray
						}
						// HighlightUnHighlightInvalidCell(_this.graph, _this.main_paper, stepIdArray, 'error');
					}else{
						let idArray = [];
						_this.errorListCount = 0;
						_this.chipcolor = 'green';
						_this.validationGraphObj = {
							"cellList":idArray
						}
					}
					_this.$store.state.errorList = validation_response;
				},error=>{
					_this.validateProgress = false;
				})
			},
			AutoSaveProcessDesignerGraph(isSaveClicked,closeTabAfterSave){
				// If autoSave is off, then save only when user click on save button
				if(!isSaveClicked && !this.isAutoSave)
					return
				if(!this.isObjectFullyLoaded)
					return

				if(this.isReadOnly)
					return false
				var _this = this
				if(this.savedstatus !== 'saved' && !this.is_request_in_process){
					let data_send_backend = []
					var selected_business_obj_list = commonObj.getAllSelectedBusinessObjsDetails(this.edcGraph)
					var selected_policy_details = commonObj.getselectedPolicies(this.edcGraph,selected_business_obj_list)
					var client_id = this.$session.get('client_id')
					var user = this.$session.get('email')
					let data = this.graphData;
					if(selected_policy_details.length){
						// we have to make sure policy referenced in the filter is correct
						data = commonObj.managePolicyFilters(_.cloneDeep(this.graphData),selected_business_obj_list)
					}
					data['selected_business_objects'] = selected_business_obj_list
					data['selected_policy_details'] = selected_policy_details
					data['isAug2020ReleasedUI'] = true
					if(!this.isBusinessObjectNameChanged && selected_policy_details && selected_policy_details.length){
						// here we have to check the business object name changed or not.
						for(let i=0;i<selected_policy_details.length;i++){
							// fetch object from the business object list
							let bo_obj = _.find(this.business_obj_list,['object_id',selected_policy_details[i].bo_id])
							if(bo_obj && bo_obj.object_name != selected_policy_details[i].bo_name){
								_this.isBusinessObjectNameChanged = true
								let old_obj = _.cloneDeep(bo_obj)
								old_obj.object_name = old_obj.name = selected_policy_details[i].bo_name
								this.changedBOForPolicy = {"new_bo":bo_obj,"old_bo":old_obj,"totalSelectedBO":selected_business_obj_list.length}
								setTimeout(()=>{
									// in case of multiple business object in same pd.At one time we are considering only one bo. So for next bo we will come after 10 seconds. We are giving enough time to change the bo
									_this.isBusinessObjectNameChanged = false
								},10000)
								break;
							}
						}
					}
					// data['is_revised_merge_minus'] = true
					var graphData = {"object_json": data,
					"erp_code": this.erp_code,
					"erp_version": this.erp_version,
					"object_type_code": "PD",
					"object_desc": "",
					"description": this.pd_description,
					"object_name": this.definitionname,
					"object_revision": this.object_revision,
					"client_id": client_id,
					"added_by": user,
					"env_id": this.$session.get('selected_env').id,
					"env_name":this.$session.get('selected_env').name,
					"object_id": this.process_def_id,
					"template":this.isTemplate
				}
					if(!this.process_def_id && this.definitionname === "Untitled Process Definition" && data && (!data.cells || !data.cells.length)){
						/* This condition for IN CASE OF NEW OBJECT dont save unless user start creating the object. Means there has to be some different name of the business object than default one or atleast single table should be dragged */
						return
					}
					// while doing the undo redo testing, somehow blank object name get saved.
					if(!this.definitionname)
						this.definitionname = "Untitled Process Definition"
					this.oldPDName = this.definitionname
		      		this.is_request_in_process = true // To avoid call to server unless get response of previous request.
		      		postToServer(this, config.REPOSITORY_API_URL + ADD_GRAPH_DATA, graphData).then(response  => {
		      			this.is_request_in_process = false
		      			if(response){
		      				var res = response
		      				this.process_def_id = res.object_id
		      				// this.definitionname = res.object_name
		      				this.oldPDName = res.object_name
		      				this.object_revision = res.object_revision
		      				this.is_error_occured = false
		      				this.savedstatus = 'saved'
		      			}
		      			if(closeTabAfterSave)
		      				_this.closeTab()
		      		}).catch(error_response => {
		      			this.is_request_in_process = false
		      			this.is_error_occured = true
		      			this.savedstatus='Error in the saving data into the databse.'

		      			if(error_response){
							// this.snackbar = true
							// this.colorValue = 'error'
							// this.snackbartext = error_response;
							// this.savedstatus='Error in the saving data into the databse.'
						}
						else {
							// this.snackbar = true
							// this.snackbartext = 'Something went wrong.Try Again';
							// this.colorValue = 'error'
							// this.savedstatus='Error in the saving data into the databse.'

						}
					})
				}
			},
			RedesignGraph(env_has_allow_create,create_new_version){
		     	if (this.$route.params.create_new_version || create_new_version) {
				var create_new_version = true;
			} else {
				var create_new_version = false;
			}

		     	// from object management page user click on create new version but env might not have create new version rights. So for that purpose added below check.

		     	if(env_has_allow_create === 'No')
					create_new_version = false

		     	var data = {
		     		"object_id": this.$route.params.process_def_id,
		     		"object_revision": this.$route.params.object_revision,
		     		"object_type_code": this.$route.params.object_type_code,
		     		"create_new_version": create_new_version,
		     		"env_id": this.$route.params.env_id,
		     		"env_name": this.$route.params.env_name
		     	}


		     	var _this = this;
		     	_this.tableListLoader = true;
		     	postToServer(this, config.REPOSITORY_API_URL + GET_PROCESS_GRAPH_DATA, data).then(response => {
	     			var res = response
	     			_this.manageGraphResponse(res)
	     			if(create_new_version)
						this.isReadOnly = false
					// if(!this.timer)
					// 	this.timer = setInterval(this.AutoSaveProcessDesignerGraph,3000)
				}).catch(error_response => {
					_this.is_request_in_process = true
					_this.tableListLoader = false
					if(error_response){
						this.snackbar = true
						this.colorValue = 'error'
						this.snackbartext = error_response;
						this.loader = null
					}
					else {
						this.snackbar = true
						this.colorValue = 'error'
						this.snackbartext = SERVER_ERROR;
					}
				})
			},
			checkobjectversion(record){
            let _this = this;
            // if(_this.isReadOnly)
            // 	return
            if(record.object_version){
                var object_revision = record.object_version
            }else{
                var object_revision =  record.object_revision
            }
            var inputJson =  {"object_id": record.object_id, "object_revision": object_revision, "env_id": this.$session.get('selected_env').id}
            postToServer(this, config.PUBLISHER_URL + '/object_published_in_multiple_env', inputJson).then(response => {
                    if(response){
                        if(response.status == 'CREATE_NEW_VERSION'){
                        	this.isReadOnly = true
                            this.ShowConfirm(record)
                        } else if (response.status == 'PENDING_APPROVAL'){
                        	this.isReadOnly = true
                        	alert('Object is in Approval pending state, can not edit object')
                        	return
                        } else {
                            _this.isReadOnly = false
                        }
                    } else {
                       this.errorMsg = response
                    }
                }).catch(ProcessDocError => {
                    if(ProcessDocError){
                        this.loader = null
                        this.snackbar = true
                        this.colorValue = 'error'
                        this.tableList.rows = [];
                        this.tableList.total_count =0;
                        this.snackbartext = ProcessDocError;
                    }
                    else {
                        this.snackbar = true
                        this.colorValue = 'error'
                        this.snackbartext = SERVER_ERROR;
                    }
                });
        },
        ShowConfirm(object){
	        let confirmFn = function () {
	        this.$parent.SendObjectForNewVerion(object)
	        }
	        let obj = {
	          title: 'Published Object',
	          message: 'This Object has been published. You can create a new version or open it in read only mode',
	          type: 'info',
	          useConfirmBtn: true,
	          onConfirm: confirmFn,
	          customCloseBtnText:'Read Only',
	          customConfirmBtnText:'New Version'
	        }
	        this.$refs.simplert.openSimplert(obj)
	      },
			CopyGraph(){
				var data = {
					"object_id": this.$route.params.process_def_id,
					"object_revision": this.$route.params.object_revision,
					"object_type_code": this.$route.params.object_type_code
				}
				this.process_def_id = ''
				var _this = this;
				_this.tableListLoader = true;
				postToServer(this, config.REPOSITORY_API_URL + GET_PROCESS_GRAPH_DATA, data).then(response => {
					var res = response
					_this.manageGraphResponse(res,true)
				}).catch(error_response => {
					_this.tableListLoader = false
					if(error_response){
						this.snackbar = true
						this.colorValue = 'error'
						this.snackbartext = error_response;
						this.loader = null
					}
					else {
						this.snackbar = true
						this.colorValue = 'error'
						this.snackbartext = SERVER_ERROR;
					}
				})

			},
			manageGraphResponse(res,is_copy=false){
				if(is_copy){
					this.definitionname = 'Copy of ' +res.object_name
				}
				else{
					this.process_def_id = res.object_id
					// this.process_def_id = process_doc_id
					this.definitionname = res.object_name
					this.oldPDName = res.object_name
				}

				this.object_revision = res.object_revision
				var graphdata = {"cells": res.object_json.cells}
				this.dataForGraphRedesign = {
					"cells":res.object_json.cells,
					"isAug2020ReleasedUI":res.object_json.isAug2020ReleasedUI
				}
				this.pd_description = res.description
				if(this.$route.params.is_read_only){
		          this.isReadOnly = true
		        }
		        else{
					this.startInitialSaveTimer(4000)
		        }
				this.Get_Tables()
				if(res.erp_code){
					this.erp_code = res.erp_code
					this.erp_version = res.erp_version
				}
				this.isTemplate = res.template
			},

			createNewVersion:function(record){
	            let _this = this;
	            if(_this.isReadOnly)
	            	return
	            var inputJson =  {"object_id": this.process_def_id, "object_revision": this.object_revision, "env_id": this.$session.get('selected_env').id}

            	postToServer(this, config.PUBLISHER_URL + '/object_published_in_multiple_env', inputJson).then(response => {
                    if(response){
                        if(response.status == 'PENDING_APPROVAL'){
                        	// this.snackbar = true
                        	// this.colorValue = 'error'
                        	// this.snackbartext = 'Object is in Approval pending state, Can not create New version';
                        	alert('Object is in Approval pending state, Can not create New version')
                        	return
                        } else {
                            this.ShowConfirmNewVersion(inputJson)
                        }
                    } else {
                       this.errorMsg = response
                    }
                }).catch(ProcessDocError => {
                    if(ProcessDocError){
                        this.loader = null
                        this.snackbar = true
                        this.colorValue = 'error'
                        this.tableList.rows = [];
                        this.tableList.total_count =0;
                        this.snackbartext = ProcessDocError;
                    }
                    else {
                        this.snackbar = true
                        this.colorValue = 'error'
                        this.snackbartext = SERVER_ERROR;
                    }
                });
	        },

	        ShowConfirmNewVersion(object){
		        let confirmFn = function () {
		        this.$parent.SendObjectForNewVerion(object)
		        }
		        let obj = {
		          title: 'Create New Version',
		          message: 'This will create new version of Object and get replaced with Current version of Object, Proceed?',
		          type: 'info',
		          useConfirmBtn: true,
		          onConfirm: confirmFn
		        }
		        this.$refs.simplert.openSimplert(obj)
	      	},
	      	SendObjectForNewVerion(object){
		      	// let routeData = this.$router.resolve({ name: 'editprocessdef',
		       //              query: { 'process_def_id': object.object_id,
		       //              'object_revision': object.object_revision,
		       //              'env_id': this.$session.get('selected_env').id,
		       //              'env_name': this.$session.get('selected_env').name,
		       //              'create_new_version': true,
		       //              'object_type_code': 'PD'}});
		       //  window.open(routeData.href, '_self');
       			this.RedesignGraph('Yes',true)
		    },
			updatePDJSON(action_type,update_details){
		      	this.updatedReferenceDetails = {
		      		'action_type':action_type,
		      		'update_details':update_details
		      	}
		    },
		    ShowObjDetails(){
	      	this.reqInputData = {}
	      	var data = {"object_id": this.process_def_id, "object_revision": this.object_revision}
	      	this.reqInputData = data
	      	this.showObjectDetails = true
	      },
	      closeDetailPopup(){
	      	this.showObjectDetails = false
	      },
	      startInitialSaveTimer(timing){
	      	let _this = this
	      	// over come issue with load object before loadding anything, I am writing set timeout for 8 seconds
			setTimeout(()=>{
				_this.isObjectFullyLoaded = true
			},timing)
	      },
	    showDescription(){
	    	this.vueEditorObj = {}
	    	this.vueEditorObj['description'] = _.cloneDeep(this.pd_description)
	    	this.descriptionPanel = true
	    },
	    DescriptionChange(pd_description){
	    	this.pd_description = pd_description
	    	this.savedstatus = 'edited'
	    },
		keyupHandler(event){
			if (event.ctrlKey && event.code === 'KeyZ') {
      			this.undoHandler()
    		}
    		else if (event.ctrlKey && event.code === 'KeyY') {
      			this.redoHandler()
    		}
		},
		undoHandler(){
			let _this = this
			if(!_this.undoStack.length)
				return
			let undoData = _this.undoStack.pop()
			let redoData = _.cloneDeep(undoData)
			redoData['action_to_take'] = undoData.user_action
			redoData['user_action'] = undoData.action_to_take
			if(redoData.action_to_take === 'step_name_changed' || redoData.action_to_take ==='step_name_changed_from_inside'){
				this.reverseStepChangeData(redoData,redoData.action_to_take)
			}
			else if(redoData.action_to_take === 'definition_name_change')
				this.reverseDefinitionName(redoData)
			else if(redoData.action_to_take === 'driver_table_change' || redoData.action_to_take ==='business_obj_change'||redoData.action_to_take==='new_table_dragged' || redoData.action_to_take === 'table_replaced' || redoData.action_to_take ==='selection_filter_change')
				this.reverseOldNew(redoData)
			if(_this.redoStack.indexOf(redoData)===-1)
				_this.redoStack.push(redoData)
			_this.handlerUndoRedo(undoData,'undo')
		},
		redoHandler(){
			let _this = this
			let redoData = _this.redoStack.pop()
			let undoData =  _.cloneDeep(redoData)
			undoData['action_to_take'] = redoData.user_action
			undoData['user_action'] = redoData.action_to_take
			if(undoData.action_to_take === 'step_name_changed' || undoData.action_to_take ==='step_name_changed_from_inside'){
				this.reverseStepChangeData(undoData,undoData.action_to_take)
			}
			else if(undoData.action_to_take === 'definition_name_change')
				this.reverseDefinitionName(undoData)

			else if (undoData.action_to_take === 'driver_table_change' || undoData.action_to_take ==='business_obj_change'||undoData.action_to_take==='new_table_dragged' || undoData.action_to_take === 'table_replaced' || undoData.action_to_take ==='selection_filter_change')
				this.reverseOldNew(undoData)

			if(_this.undoStack.indexOf(undoData)===-1)
				_this.undoStack.push(undoData)

			_this.handlerUndoRedo(redoData,'redo')
		},
		handlerUndoRedo(data,action){
			/*
				TO DO: step open / close event have to handle.
				on desinger event we need to close all steps
				on step event we need to open the step if that not already opened.
			*/
			if(!data ||!data.action_to_take)
				return
			var _this = this

			let desingerEvents = ['add_element','remove_element','add_link','remove_link','step_name_changed','definition_name_change']

			let stepEvents = ['step_name_changed_from_inside','remove_table','add_table','add_join_link','remove_column','add_column','selection_filter_change','step_limit_change','run_on_target_change','driver_table_change','business_obj_change','new_table_dragged','table_replaced']
			if(desingerEvents.indexOf(data.action_to_take)>-1){
				this.closeAllDialog()
			}
			else if(stepEvents.indexOf(data.action_to_take)>-1){
				let step_id = data.step_id
				if(!step_id && data.update_details)
					step_id = data.update_details.step_id
				if(!step_id){
					alert('step id not found for the undo-redo operation')
					this.closeAllDialog()
					return
				}

				if(_this.currentActiveStep && step_id!=_this.currentActiveStep.id){
					_this.closeAllDialog()
					_this.openCell = {"cell_id":step_id}
				}
			}
			else{
				alert(data.action_to_take + " is not managed.")
				return
			}
 			let undoredoMsg = ''
 			if(data.action_to_take === 'definition_name_change'){
 				undoredoMsg = action+': Process definition name changed from "'+data.old_name+'" to "'+data.new_name+'"'
 				this.definitionname = data.new_name
 				this.oldPDName = data.old_name
 			}
			else if(data.action_to_take === 'add_element'){
				// designer event
				undoredoMsg = action +':'+data.element.stepname+' step added'
				_this.newCell = {'element':data.element,'edc_type':_this.graphFor,'is_element':true,'is_redo_undo':true,'relatedLinks':data.relatedLinks}
			}
			else if(data.action_to_take === 'remove_element'){
				// designer event
				undoredoMsg = action +':'+data.element.stepname+' step removed'
				_this.cellToRemove = {'element':data.element,'edc_type':_this.graphFor,'is_element':true,'cell_id':data.element.id,'is_redo_undo':true}
			}
			else if(data.action_to_take === 'add_link'){
				// designer event
				undoredoMsg = action +': link added'
				_this.newCell = {'link':data.link,'edc_type':_this.graphFor,'is_link':true,'is_redo_undo':true}
			}
			else if(data.action_to_take === 'remove_link'){
				// designer event
				undoredoMsg = action +': link removed'
				_this.cellToRemove = {'link':data.link,'edc_type':_this.graphFor,'is_link':true,'link_id':data.link.id,'is_redo_undo':true}
			}
			else if(data.action_to_take === 'step_name_changed'){
				// designer event
				undoredoMsg = action+': Step name changed from "'+data.update_details.step_name+'" to "'+data.update_details.new_step_name+'"'
				_this.updatePDJSON('step_name_changed',data.update_details)
				data['step_id'] = data.update_details.step_id
				_this.redoundoCommandObj =_.cloneDeep(data)
			}
			else if(data.action_to_take === 'step_name_changed_from_inside'){
				// designer event
				undoredoMsg = action+': Step name changed from "'+data.update_details.step_name+'" to "'+data.update_details.new_step_name+'"'
				_this.updatePDJSON('step_name_changed',data.update_details)
				data['step_id'] = data.update_details.step_id
				_this.redoundoCommandObj =_.cloneDeep(data)
			}
			else if(data.action_to_take === 'remove_table'){
				// step event
				undoredoMsg = action +':'+data.element.table_name+' table removed'
				_this.redoundoCommandObj = _.cloneDeep(data)
			}
			else if(data.action_to_take === 'add_table'){
				// step event
				undoredoMsg = action +':'+data.element.table_name+' table added'
				_this.redoundoCommandObj = _.cloneDeep(data)
			}
			else if(data.action_to_take === 'add_join_link'){
				// step event
				undoredoMsg = action +':Join link aaded'
				_this.redoundoCommandObj = _.cloneDeep(data)
			}
			else if(data.action_to_take === 'remove_column'){
				// step event
				undoredoMsg = action +':Column '+ data.column.column_name+' removed'
				_this.redoundoCommandObj = _.cloneDeep(data)
			}
			else if(data.action_to_take === 'add_column'){
				// step event
				undoredoMsg = action +':Column '+ data.column.column_name+' added'
				_this.redoundoCommandObj = _.cloneDeep(data)
				if(data.allReferences && data.allReferences.length){
					_this.updatePDJSON('update_column_references',data.allReferences)
				}
			} else if(data.action_to_take === 'selection_filter_change'){
				// step event
				undoredoMsg = action +':Step filter changed'
				_this.redoundoCommandObj = _.cloneDeep(data)
			} else if(data.action_to_take === 'step_limit_change'){
				let old_limit = data.old_limit
				data.old_limit = data.new_limit
				data.new_limit = old_limit
				undoredoMsg = action +':Step limit changed from '+data.old_limit+' to '+data.new_limit
				_this.redoundoCommandObj = _.cloneDeep(data)
			}else if(data.action_to_take === 'run_on_target_change'){
				// step event
				undoredoMsg = action +':Run on target option changed'
				_this.redoundoCommandObj = _.cloneDeep(data)
			}else if(data.action_to_take === 'driver_table_change'){
				// step event
				undoredoMsg = action +':Driver table changed'
				_this.redoundoCommandObj = _.cloneDeep(data)
			} else if(data.action_to_take === 'business_obj_change'){
				undoredoMsg = action +':Business object changed'
				_this.redoundoCommandObj = _.cloneDeep(data)
			} else if(data.action_to_take === 'new_table_dragged'){
				undoredoMsg = action+": " + data.tableIndex + " table changed"
				_this.redoundoCommandObj = _.cloneDeep(data)
			} else if(data.action_to_take === 'table_replaced'){
				undoredoMsg = action +': Table replaced from "'+data.old_value.table_name +'" to "'+data.new_value.table_name+'"'
				_this.redoundoCommandObj = _.cloneDeep(data)
			}
			if(undoredoMsg)
 				this.setRedoUndoMsgTimeout(undoredoMsg)
 			_this.validateProcessDefinitionOnUserAction()
 			_this.changeSaveStatus()
		},
		setRedoUndoMsgTimeout(message){
			var _this = this
			_this.redoundoMsg = message
			_this.redoundoTimer = null
			_this.redoundoTimer = setTimeout(()=>{
				_this.redoundoMsg = ''
			},8000)
		},
		toolbarSaveClicked(){
			let confirmFn = function () {
	        	this.$parent.AutoSaveProcessDesignerGraph(true)
	        }
	        let obj = {
	          title: 'Save',
	          message: 'All changes will get saved, Proceed?',
	          type: 'info',
	          useConfirmBtn: true,
	          customConfirmBtnText:'Save',
	          customCloseBtnText:'Cancel',
	          onConfirm: confirmFn,
	          showXclose:true
	        }
	        this.$refs.simplert.openSimplert(obj)
		},
		showClosePopUp(){
			if(this.isReadOnly){
				this.closeTab()
				return
			}
			if(this.savedstatus === 'edited'){
				let confirmFn = function () {
		        	this.$parent.AutoSaveProcessDesignerGraph(true,true)
		        }
		        let closeFn = function () {
		        	this.$parent.closeTab()
		        }
		        let obj = {
		          title: 'Close Tab',
		          message: 'Some changes are not saved, Proceed?',
		          type: 'info',
		          useConfirmBtn: true,
		          customConfirmBtnText:'Save & Close',
		          customCloseBtnText:'Close Anyway',
		          onConfirm: confirmFn,
		          onClose:closeFn,
		          showXclose:true
		        }
		        this.$refs.simplert.openSimplert(obj)
			}
			else{
				let confirmFn = function () {
		        	this.$parent.closeTab()
		        }
		        let obj = {
		          title: 'Close Tab',
		          message: 'Tab will get close, Proceed?',
		          type: 'info',
		          useConfirmBtn: true,
		          customConfirmBtnText:'Close',
		          customCloseBtnText:'Cancel',
		          onConfirm: confirmFn,
		          showXclose:true
		        }
		        this.$refs.simplert.openSimplert(obj)
			}
		},
		closeTab(){
			window.top.close()
		},
		getAllStepsDescription(stencilGraph,jdeItemGraph){
        var _this = this
        var data = {}
		if(!jdeItemGraph)
			return
        postToServer(_this, config.REPOSITORY_API_URL +'/get_process_designer_steps_description',data).then(response=>{
			_this.allStepsDescription =  response
			let allCommonElements =stencilGraph.getElements()
			for(let i=0;i<allCommonElements.length;i++){
				let currentCell = allCommonElements[i]
				let currentDescription = _.find(response,["step_type",currentCell.prop('steptype').toLowerCase()])
				if (currentDescription){
					currentCell.attr('image/title',currentDescription.step_description)
					currentCell.attr('label/title',currentDescription.step_description)
				}
			}
			if(!jdeItemGraph)
				return
			let allJDEElemements =jdeItemGraph.getElements()
			for(let i=0;i<allJDEElemements.length;i++){
				let currentCell = allJDEElemements[i]
				let currentDescription = _.find(response,["step_type",currentCell.prop('steptype').toLowerCase()])
				if (currentDescription){
					currentCell.attr('image/title',currentDescription.step_description)
					currentCell.attr('label/title',currentDescription.step_description)
				}
			}
        }).catch(error=>{

        })
      },
	  templateOptionChanged(value){
		this.isTemplate = value
		this.changeSaveStatus()
	}
	}
}
</script>
