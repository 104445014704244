<template>
  <div id="table_list_panel" class="upperrow" style="width:20%;overflow: hidden;" :style="{'height':containerHeight}" ref="table_list_panel_ref">

    <edc-toolbar class="EDC-Custom-Toolbar" key="selected_columns_toolbar" customLabel="Selected Columns" usedFor="list" bindWithElement="list_of_tables" @perform-serach="perform_tablelist_serach"></edc-toolbar>

<div style="height:93%;overflow:auto;justify:left !important;">
  <v-progress-linear v-if="indeterminate" :indeterminate="indeterminate" style="margin:0px;"></v-progress-linear>
  <v-list :disabled="readonly" id="list_of_tables" ref="list_of_tables_ref"  dense class="EDC-list-for-columns overflow-y-auto style-2scroll">
    <template  v-for="(item,indx) in revisedColumnList">
    <draggable v-if="!tableListLoader" :key="indx+'_'+item.title" v-model="revisedColumnList" @start="startDrag($event,item)" :options="dragOptions" :move="onMove">
      <v-list-item dense selectable :class="{'list_items':true, 'custom-list-item':true, 'selected-list-item':item.isSelected, 'deselected-list-item':!item.isSelected}"
      ref="list_items_ref" style="height:24px !important;" @click="listItemClicked(item)" >
      <div style="width:20%;">
      <v-list-item-content >
        <v-list-item-title  class="tabletitle EDC-ListItem" v-text="item.column_name" :title="item.column_name"></v-list-item-title>
      </v-list-item-content>
    </div>
    <div style="width:15%;">
      <v-list-item-content>
        <v-list-item-title class="tabletitle EDC-ListItem" v-text="item.table_name" :title="item.table_name"></v-list-item-title>
      </v-list-item-content>
    </div>
    <div style="width:25%;padding-right:4px !important;" v-if="item.datasource_index >=0">
      <v-list-item-content>
        <v-list-item-title class="tabletitle EDC-ListItem" v-text="getDatasourceName(item.datasource_index)" :title="getDatasourceName(item.datasource_index)"></v-list-item-title>
      </v-list-item-content>
    </div>
      <v-list-item-content  style="text-align:left;" v-if="showDescription">
        <v-list-item-title class="EDC-ListItem" v-text="getTrimDescription(item.description)" :title="item.description"></v-list-item-title>
      </v-list-item-content>
    </v-list-item>
  </draggable>
    </template>
  <v-progress-circular v-if="tableListLoader"  :width="3" :size="20" color="blue" indeterminate></v-progress-circular>

</v-list>

</div>
</div> 
</template>
<script>
const cloneDeep = require('clone-deep');
import draggable from 'vuedraggable'
import _ from 'lodash'
import '../compiled-icons';
import vcButton from '@/views/button.vue'
import edctoolbar from '@/views/edctoolbar.vue'

export default {
 components:{
  draggable,
  'vc-button':vcButton,
  'edc-toolbar':edctoolbar
},
computed:{
  dragOptions () {
    return  {
      animation: 0,
      group: 'description',
    };
  },
},
name: 'ColumnList',
props: ['columnList','containerHeight','tableListLoader','steptablelist','readonly', 'hidecloslisticon','datasourceList'],
data () {
  return {
    isDragging:false,
    defaultColumnList:[],
    indeterminate:false,
    height:"",
    table_search:'',
    env_id:'',
    schema:'',
    connection_str:'',
    db_type: '',
    tbl_object_type:[],
    showMoreActions:false,
    showDescription:true,
    checkDesciption:false,
    searchForTable:true,
    searchForView:true,
    searchForAlias:true,
    searchForWorkTable:true,
    hidecloslisticon:false,
    defaultListOffsetWidth:0,
    resizeListWidth:"500px",
    revisedColumnList:[], // we need to avoid mutation of prop
  } 
},
watch: {
  tableheight(newValue, o){
      // debugger
      height = newValue;
    },
    columnList(newvalue,o){

      this.revisedColumnList = _.cloneDeep(newvalue)
      _.forEach(this.revisedColumnList,(obj)=>{
        obj.isSelected = false
      })
        this.defaultColumnList = this.revisedColumnList
    },
    hidecloslisticon(newvalue){
      this.hidecloslisticon = newvalue
    },
    readonly(newvalue){

    },
    datasourceList(newvalue){

    }
  },
  mounted() {
    if(this.$refs.table_list_panel_ref.$el)
      var table_list_panel = this.$refs.table_list_panel_ref.$el
    else 
      var table_list_panel = this.$refs.table_list_panel_ref
    table_list_panel.addEventListener('scroll',function(e){});
    this.defaultListOffsetWidth = "300px"
    // alert(this.defaultListOffsetWidth)
    this.managetableListWidth(this.resizeListWidth)
  },
  methods:{
    managetableListWidth(width){
      if(this.$refs.list_of_tables_ref.$el){
      this.$refs.list_of_tables_ref.$el.style.width=width;
      // this.$refs.list_of_tables_ref.$el.style.clientWidth = width
      }
    else 
      {
        this.$refs.list_of_tables_ref.style.width=width;
        // this.$refs.list_of_tables_ref.style.clientWidth = width;
      }
    },
    showDescriptionChange(show){
      // if(show)
        this.managetableListWidth(this.resizeListWidth)
      // else
        // this.managetableListWidth(this.defaultListOffsetWidth)
    },
    getTrimDescription(description=""){
      var trimSize = 30
      if(!description)
        return ""
      if(description.length <=trimSize)
          return description
      var sub_description = description.substr(0,trimSize-3)+"..."
      return sub_description
    },
    startDrag(event,column){      
      // debugger;
      if(this.readonly)
        return
      // let title = column.column_name

      // if(title){
      //   // title = title.split("\n")[0]
      //   title = title.trim()
      // }
      // let tableName = column.table_name
      // let _this = this;

      // let obj = _.cloneDeep(_.find(_this.revisedColumnList,function(o){
      //   return o.column_name === title && o.table_name === tableName
      // }));
      // if(!obj)
      //   return
     this.$emit('dragged-object', column);
   },
   onMove ({relatedContext, draggedContext}) {
      // this.$emit('dragged-object',draggedContext.element);
    },
      perform_tablelist_serach(searchText){
      var input = searchText
      if(this.$refs.list_of_tables_ref.$el)
        var table_list = this.$refs.list_of_tables_ref.$el
      else 
        var table_list = this.$refs.list_of_tables_ref
      var list_items = table_list.getElementsByClassName('list_items')
      var filter = input.toUpperCase();
      var key_found = false
      for (var i = 0; i < list_items.length; i++) {
        if (list_items[i].innerText.trim().toUpperCase().indexOf(filter) > -1) 
        {
          list_items[i].style.display = "";
          key_found = true
        }
        else
          list_items[i].style.display = "none";
      }
      if(input.length == 0){

        this.revisedColumnList = cloneDeep(this.defaultColumnList)
      }
      
    },
    dragitem(event){
      var innerhtml = event.target.innerHTML
      var parser = new DOMParser()
      var doc = parser.parseFromString(innerhtml, "text/xml");
      var table_type = doc.getElementsByTagName('i')[0].getAttribute("title")
      event.dataTransfer.setData("table_name", event.target.innerText);
      event.dataTransfer.setData("table_type", table_type);
    },
    listItemClicked(item){
        // item.isSelected = !item.isSelected
    },
    getDatasourceName(index){
      if(!this.datasourceList || !this.datasourceList.length||!this.datasourceList[index]||!this.datasourceList[index].business_data)
        return ''
      return this.datasourceList[index].business_data.datasource_name 
    }
  }
}
</script>
<style scoped>

.customCheckbox >>> label{
  top: 5px !important;
}

.v-input--selection-controls {
  margin-top:0px;
  padding-top:0px;
}

.tabletitle{
  
}
.minwidth{
  min-width:700px !important;
}

.minwidthwithoutstep{
  min-width:500px !important;
}

.EDC-ListItemMargin{
  margin-right:10px !important;
}


.EDC-list-for-columns {
  max-height:412px !important;
  min-height:412px !important;
}

.v-list-item--dense, .v-list--dense .v-list-item {
    min-height: 16px !important;
}

.custom-list-item {
  margin-bottom: 0px !important;
  color:#000000;
}


.selected-list-item {
  background-color:var(--v-secondary-base) !important;
}

/* Here we need apply css for all non selected items. Work around logic is here. This logic will force 
apply css to the items which are not deselected-list-item.In short we are applying css forcefully on user action.(reverse css formatting)
*/
.theme--light.v-list-item:not(.v-list-item--active):not(.v-list-item--disabled):not(.deselected-list-item) {
    color:white !important;
}

.EDC-Toolbar >>> .v-sheet {
  border-radius: 0px !important;
}
</style>