<template>
    <v-container class="EDC-Row">
        <v-row class="EDC-Row">
            <v-col class="EDC-Col" v-if="header.show_select_all">
                <v-autocomplete :multiple="header.useMultiple || false" :return-object="header.returnObject || false" 
                hide-details="auto" v-model="selectedItems"  :items="header.option" dense :item-text="header.item_text"
                 :label="header.label || 'select'" :item-value="header.item_value" 
                 @input="changeAutoCompleteSelection" :search-input.sync="searchText">
                <template v-slot:prepend-item>
                    <v-list-item dense hide-details ripple @mousedown.prevent @click="toggleAllSelect()">
                        <v-list-item-action>
                            <v-icon>
                            {{ icon }}
                            </v-icon>
                        </v-list-item-action>
                        <v-list-item-content>
                            <v-list-item-title>
                            {{  'Select All ' + header.headerFor}}
                            </v-list-item-title>
                        </v-list-item-content>
                    </v-list-item>
                    <v-divider class="mt-2"></v-divider>
                </template>
                <template v-slot:selection="{ item, index }">
                    <span v-if="selectAll && index ===0" class="labelCls"> All {{header.headerFor}} Selected </span>
                    <span v-if ="!selectAll">
                        <span v-if="index === 0" class="labelCls"> {{ item[itemText] || item }} </span>
                        <span v-if="index === 1" class="labelCls">(+{{ selectedLength - 1 }} {{header.headerFor}})</span>
                    </span>
                </template>
                </v-autocomplete>
            </v-col>
            <v-col class="EDC-Col" v-else>
                <v-autocomplete :multiple="header.useMultiple || false" :return-object="header.returnObject || false" 
                hide-details="auto" v-model="selectedItems"  :items="header.option" dense :item-text="header.item_text"
                 :label="header.label || 'select'" :item-value="header.item_value" 
                 @input="changeAutoCompleteSelection" :search-input.sync="searchText">
                    <template v-slot:selection="{ item, index }">
                        <span v-if="index === 0" class="labelCls"> {{ item[itemText] }} </span>
                        <span v-if="index === 1" class="labelCls">(+{{ selectedLength - 1 }} Tables)</span>
                    </template>
                </v-autocomplete>
            </v-col>
        </v-row>
    </v-container>
</template>
<style scoped>
    .labelCls{
        font-size: 12px !important;
    }
</style>
<script>
    export default {
        name:'EdcTableSearchComponent',
        props:{
            header:{
                type:Object,
                default:()=>{
                    return {
                        useMultiple:false,
                        returnObject:false,
                        value:"",
                        option:[],
                        item_text:"display_text",
                        label:"",
                        item_value:"value",
                        show_select_all:false,
                        headerFor:'',
                        gridDisplayTextKey:'', // this field to show compancted list name. will work with useMultiple only
                        gridDisplayTextTitleKey:'' //this field to show all items on mouseover which are compacted.will work with useMultiple only
                    }
                }
            },
            selectedValue:{

            },
            isAllSelected:{
                type:Boolean,
                default:false
            }
        },
        data(){
            return{
                searchText:"",
                timerForSearch:'',
                selectAll:false,
                selectedItems:[]
            }
        },
        computed:{
            selectedLength(){
                return this.selectedItems.length
            },
            icon (){
                if (this.selectAll) 
                    return 'mdi-checkbox-marked'
                return 'mdi-checkbox-blank-outline'
            },
            itemText(){
                return this.header.item_text || 'name'
            },
            gridDisplayText(){
                if(this.selectAll){
                    return  'All ' + this.header.headerFor
                }
                else{
                    let displayText = ''
                    if(this.selectedItems.length){
                        for(let i=0;i<this.selectedItems.length;i++){
                            if(i=== 0)
                                displayText = this.selectedItems[0][this.itemText] || this.selectedItems[0]
                            if(i===1)
                                displayText  = displayText + " (+" + (this.selectedItems.length-1) + this.header.headerFor+")"
                        }
                    }
                    return displayText
                }
            },
            gridDisplayTextTitle(){
                if(this.selectAll){
                    return  'All ' + this.header.headerFor
                }
                else{
                    let displayTextTitle = ''
                    if(this.selectedItems.length){
                        for(let i=0;i<this.selectedItems.length;i++){
                            if(displayTextTitle)
                                displayTextTitle = displayTextTitle+", " 
                            displayTextTitle = displayTextTitle + (this.selectedItems[i][this.itemText] || this.selectedItems[i])
                        }
                    }
                    return displayTextTitle
                }
            },
        },
        watch:{
            headers:{
                handler(newvalue){

                },
                deep:true
            },
            // 'header.option':{
            //     handler(newvalue){
            //         this.selectAll = false
            //     }
            // },
            selectedValue:{
                handler(newvalue){
                    if(newvalue){
                        this.selectedItems = _.cloneDeep(newvalue)
                    }
                },
                deep:true
            },
            
            searchText:{
                handler(newvalue){
                    // if(newvalue && (newvalue!=this.item[this.header.value]))
                    this.callSearch(newvalue)
                }
            },
            isAllSelected:{
                handler(newvalue){
                    this.toggleAllSelect(this.isAllSelected)
                }
            }
        },
        mounted(){
            if(this.selectedValue && this.selectedValue.length){
                this.selectedItems = _.cloneDeep(this.selectedValue)
            }
            this.toggleAllSelect(this.isAllSelected)
        },  
        methods:{
            changeAutoCompleteSelection(event){
                let valueObj={}
                valueObj[this.header.value] = this.selectedItems
                if(this.header.useMultiple){
                    if(this.header.gridDisplayTextKey)
                        valueObj[this.header.gridDisplayTextKey] = this.gridDisplayText
                    if(this.header.gridDisplayTextTitleKey)
                        valueObj[this.header.gridDisplayTextTitleKey] = this.gridDisplayTextTitle

                    if(this.header.show_select_all){
                        valueObj[this.header.selectAllKey] = this.selectAll
                    }
                }
                this.$emit("changeAutoCompleteSelection",valueObj)
            },
            callSearch(searchText){
                var _this = this
                clearInterval(_this.timerForSearch)
                _this.timerForSearch = setTimeout(()=>{
                    _this.performSearch(searchText)
                },1000)
            },
            performSearch(searchText){
                this.$emit("performSearch",searchText)
            },
            toggleAllSelect(defaultValue){
                if(!this.header.show_select_all)
                    return
                if(defaultValue===true || defaultValue === false){
                    this.selectAll =  defaultValue
                    if(defaultValue)
                        this.selectedItems = this.header.option
                    return
                }
                
                this.selectAll = !this.selectAll
                if(this.selectAll)
                    this.selectedItems = this.header.option
                else
                    this.selectedItems = []

                this.changeAutoCompleteSelection()
            }
        }
    }
</script>