import * as edcAnonymousObj from  "./anonymousFunctions.js"
import * as edcLinkObj from './links.js'
import * as graphObj from './graph.js'


export function mouseOverEvent(that,item,conditionCheck){
	if(conditionCheck && ((conditionCheck === 'selection' && !that.isCellSelected) 
					  ||(conditionCheck === 'copied' && !that.elementCutCopied)))
		return false
	item.target.style.backgroundColor = "grey";
}

export function mouseOutEvent(that,item){
		item.target.style.backgroundColor = "white";
}

export function contextMenuItemClick(that,action_type,graph,graphFor,otherParam1){
	let embeddedCell = edcAnonymousObj.getEmbeddedCell(that.selectedElementForContextMenu)
	let relatedLinks = edcLinkObj.getRelatedLinks(embeddedCell,graph,graphFor,true)
	if((action_type === 'cut' || action_type === 'copy') && that.selectedElementForContextMenu.id){
		that.elementWrapperCutCopied = that.selectedElementForContextMenu.clone()
		that.elementCutCopied = embeddedCell
		if(action_type === 'cut'){
			that.selectedElementForContextMenu.remove()
		}
	}else if(action_type === 'paste'){
		let element = that.elementCutCopied.clone()
		let elmentWrapper = that.elementWrapperCutCopied.clone()
		element.position(50, 50)
		elmentWrapper.position(50,50)
		elmentWrapper.embed(element)
		that.edcGraph.addCells([element,elmentWrapper])
	}else if(action_type === 'delete'){
		// now we are showing magnet on the tranferent border. If user press 
		// delete button there then it only delete child. So we are finding parent and delete it.
		let parentCell = that.selectedElementForContextMenu.getParentCell()
		if(parentCell)
			parentCell.remove()
		else
			that.selectedElementForContextMenu.remove()

		let relatedJoins = []
		if(that.copyJoinJson && that.copyJoinJson.length){
			for(let i=0;i<relatedLinks.length;i++){
				let reletedJoin = _.find(that.copyJoinJson,relatedLinks[i].id)
				if(reletedJoin)
					relatedJoins.push(reletedJoin)
			}
		}
		if(graphFor === 'data_selection' ||graphFor === 'business_object'){
			let zippedElement = graphObj.zipElement(embeddedCell,graphFor) 
			that.$emit('sendNonGraphEvent','remove_table',{"element":zippedElement,'relatedLinks':relatedLinks, 
				'step_id':that.parentdigramobj?that.parentdigramobj.stepId:"",'relatedJoins':relatedJoins})
		}
		else
			that.sendElementEvent('remove_element',{"element":embeddedCell,'relatedLinks':relatedLinks})
		 
	} else if(action_type === 'unmerge'){
		that.$emit('sendNonGraphEvent','unmerge_table',{"element":embeddedCell,'relatedLinks':relatedLinks,"table_to_unmerge":otherParam1})
	}

	else {

	}
		
}