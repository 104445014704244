<template>
  <v-flex id="mainflex" style="overflow-y:hidden;">
    <v-card class="rounded-card EDC-Designer-Row">
      <v-container class="EDC-Container">
        <v-row class="EDC-Row" justify="start">
          <v-col class="EDC-Col text-truncate" cols="10" align="start">
            <ul class="breadcrumb" style="display:inline-flex;">
              <li><a @click="savedata(true)">{{stepType}} </a></li>
              <li>[{{stepSeqNo}}] <input type="text" v-model="stepname" :title="stepname" @blur="StepNameChanged()" :readonly="readonly" @keydown="preventKeyEvent" :size="nameTextboxSize"/></li>
            </ul>
            <span v-if='!readonly' style="font-weight:400;font-size:13px;margin-left:5px;" v-bind:class="{errorcls:is_error_occured , successcls:!is_error_occured}">{{'('+savedstatus + ')'}}</span>

            <span v-else style="color:rgba(0,0,0,0.54);font-weight:400;font-size:13px;margin-left:5px;">
            (Readonly Mode)</span>

            <span style="font-weight:400;font-size:13px;margin-left:5px;" :style="{'color': colorCode}">{{datasource_error||redoundoMsg}}</span>
          </v-col>
          <v-col cols="2" class="EDC-Col">

            <v-icon style="font-size:20px;float:right;padding-left:12px !important;" class="v-align" @click="stepper=1;savedata(true)" title='close'>far fa-times-circle</v-icon>

             <span class="" style="font-size:13px;float:right;margin-right:5px;" :style="{'color': colorCode}">
              <v-progress-circular :width="3" v-if="validateProgress" :size="24" color="blue" indeterminate></v-progress-circular>
              <showerrorlisttemplate :validation_response="validation_response" :stepType="stepType"
                @set-error="setError" @totalCount="totalCount" :errorListCount="errorListCount" :showprocess="validateProgress ? 'Y':'N'" style="margin-top:2px !important;margin-left:4px !important;" :showErrorPopUp="showErrorPopUp"></showerrorlisttemplate></span>

                <span style="float:right;" class="v-align" :style="{'color': colorCode}">
              <svgicon class="svg-icon-grid svg-fill-grid" name="validate"  style="float:right;margin-top:-20%;"  title="Validate Step" @click="validatestepdata" ></svgicon>
            </span>
            <svgicon class="svg-icon-grid svg-fill-grid" name="object_notes"  style="float:right;"  title="Add Step Description" @click="descriptionPanel = true"></svgicon>
          </v-col>
        </v-row>
      </v-container>
    <v-container class="EDC-Container">
      <v-row class="EDC-Row">
        <v-col cols="12" class="EDC-Col">
          <v-stepper v-model="stepper" class="EDC-Stepper" non-linear @change="changeStepper">
              <v-stepper-header class="EDC-StepperHeader">
                <v-stepper-step step="1" class="EDC-StepperStep" editable>
                  Options
                </v-stepper-step>
                <v-divider class="EDC-StepperDivider"></v-divider>
                <v-stepper-step step="2" class="EDC-StepperStep" editable>
                  Grouping
                </v-stepper-step>
                <v-divider class="EDC-StepperDivider"></v-divider>
                <v-stepper-step step="3" class="EDC-StepperStep" editable>
                  Filters
                </v-stepper-step>
                <!-- <v-divider class="EDC-StepperDivider"></v-divider>
                 <v-stepper-step step="4" class="EDC-StepperStep" editable>
                  Order By
                </v-stepper-step> -->

              </v-stepper-header>
              <v-stepper-items>
                <v-stepper-content class="EDC-Stepper-Content" step="1" elevation="0">
                  <v-row class="EDC-Row">
                    <v-col cols="8" class="EDC-Col">
                      <v-row class="EDC-Row">
                        <v-col cols="3">
                          <v-row class="EDC-Row" style="padding-top:8px !important;">
                          <v-card style="width:100%;">
                            <v-row class="EDC-Row">
                              <v-col class="EDC-Col" cols="12">
                                <v-text-field dense :readonly="readonly" label="Commit Size Balance Forward" style="margin-top: 20px;margin-left:5px;margin-right:5px;padding-bottom:8px !important;" v-model="limit" v-on:blur="savedata()" hide-details @keypress="validateLimit" @blur="onLimitBlur" autocomplete="off"></v-text-field>
                              </v-col>
                            </v-row>
                          </v-card>
                        </v-row>
                        </v-col>
                        <v-col cols="4" v-if="isF4111Summarization">
                          <v-row class="EDC-Row" style="padding-top:8px !important;" >
                            <v-col class="EDC-Col" cols="12">
                              <v-card>
                                <v-row class="EDC-Row">
                                  <!-- <v-col class="EDC-Col" cols="1" align="left"></v-col> -->
                                  <v-col cols="8" align="left">
                                    Summarize By
                                  </v-col>
                                </v-row>
                                <v-row class="EDC-Row">
                                  <v-col class="EDC-Col" cols="2" align="left"></v-col>
                                  <v-col class="EDC-Col">
                                    <v-radio-group hide-details v-model="f4111SummarizationType" row dense>
                                      <template v-for="(options,index) in f4111SummarizationOptions">
                                        <v-radio :disabled="readonly" class="customCheckbox" :color="colorCode" :label="options.name"  :value="options.value">
                                        </v-radio>
                                      </template>

                                    </v-radio-group>
                                  </v-col>
                                </v-row>
                              </v-card>
                            </v-col>
                          </v-row>
                        </v-col>
                        <v-col cols="5">
                          <v-row class="EDC-Row" style="padding-top:8px !important;" >
                            <v-col class="EDC-Col" cols="12">
                              <v-card>
                                <v-row class="EDC-Row" v-if="isF4111Summarization">
                                  <v-col cols="12" id="bfquantityoption">
                                    <v-checkbox  :disabled="readonly" :color="colorCode" label="Write BF record If Summarize Transaction Quantity & Extended Cost Is 0" v-model="write_bf_record_if_quantity_0"
                                    class="customCheckbox"  hide-details>
                                    </v-checkbox>
                                  </v-col>
                                </v-row>
                                <!-- <v-row class="EDC-Row" v-if="!isF4111Summarization">
                                  <v-col cols="12">
                                    <v-checkbox :disabled="readonly" :color="colorCode" label="Don't summarize if F0902 is archived" v-model="dont_summarize_if_f0902_archived"
                                    class="customCheckbox"  hide-details>
                                    </v-checkbox>
                                  </v-col>
                                </v-row>
                                <v-row class="EDC-Row" v-if="!isF4111Summarization">
                                  <v-col cols="12">
                                    <v-checkbox :disabled="readonly" :color="colorCode" label="Write summarization details in catalog" v-model="write_summarization_details_in_catalog"
                                    class="customCheckbox"  hide-details>
                                    </v-checkbox>
                                  </v-col>
                                </v-row> -->
                              </v-card>
                            </v-col>
                          </v-row>
                        </v-col>
                      </v-row>
                    </v-col>
                  </v-row>
                </v-stepper-content>

                <v-stepper-content class="EDC-Stepper-Content EDC-CriteriaStepper" step="2" elevation="0">
                  <v-row class="EDC-Row">
                    <v-col  class="EDC-Col" cols="5">
                      <edc-column-list :tableListLoader="tableListLoader" :columnList="columnPanelList"
                      @dragged-object="columnDraggedObject" :readonly='readonly' style="width:98% !important;height:64vh !important;">
                      </edc-column-list>
                    </v-col>
                    <v-col  class="EDC-Col style-2scroll" cols="7" style="max-height:64vh !important;overflow-y:auto;" ref="colDragPanel">
                      <table dense class="table table-striped table-bordered draggable_table" id="select_column_table" >
                        <thead>
                          <tr>
                            <th>#</th>
                            <th>Column</th>
                            <th>Column Description</th>
                            <!-- <th>Aggregate</th> -->
                            <th>Action</th>
                          </tr>
                        </thead>
                        <draggable v-model="columns_for_selection" :element="'tbody'"
                        >
                          <tr v-for="(item, idx) in columns_for_selection" :key="idx" v-show="!item.hide">
                            <td class="handle" style="max-width: 28px;max-height:30px;">::</td>
                            <td class="text-xs-left" v-model="item.column_name" align="left">
                              {{item.column_name}}
                            </td>
                            <td class="text-xs-left" v-model="item.column_name" align="left">
                              {{item.report_alias_name}}
                            </td>
                            <!-- <td class="text-xs-left" style="width:160px;padding:0px;"> <v-autocomplete style="width:160px;padding:0px;" :items="aggregate_types" placeholder="Aggregate" item-text="name" item-value="id" v-model="item.aggregate" clearable dense hide-details :disabled='readonly' dense></v-autocomplete></td> -->

                            <td>
                              <svgicon class="svg-icon-mini svg-fill-mini" name="delete_v2" :original="true" title="Remove" v-if="!item.isCompulsory" @click="delete_selection_row(idx)"></svgicon>
                            </td>
                          </tr>
                        </draggable>
                      </table>
                    </v-col>
                  </v-row>
                </v-stepper-content>

                <v-stepper-content class="EDC-Stepper-Content EDC-CriteriaStepper" step="3" elevation="0">
                  <v-row class="EDC-Row">
                    <new-edc-criteria key="criteria" :policy_list="summarizationPolicyList" :showpolicies="showpolicies" :parentsteptype="stepType" :columns_for_filter="columns_for_filter" :column_name_list="column_name_list"
                    @savedata="saveCriteria" @new="add_new_filter_row" @delete="delete_filter_row" :acceleratorObj="acceleratorObj" :new_columns_for_filter="main_filterUI" :secondaryfilter="secondary_filterUI" :readonly='readonly' :restrictedColumnsForFilter="isF4111Summarization?restrictedColumnsForFilter:[]"></new-edc-criteria>
                  </v-row>
                </v-stepper-content>

                <!-- <v-stepper-content class="EDC-Stepper-Content EDC-CriteriaStepper" step="4" elevation="0" >
                  <v-row class="EDC-Row">
                    <v-col class="EDC-Col">
                      <edc-order-by :availableColumns="columns_for_selection" :columnsForOrderBy="order_by"></edc-order-by>
                    </v-col>
                  </v-row>
                </v-stepper-content> -->

              </v-stepper-items>
            </v-stepper>
        </v-col>
      </v-row>
    </v-container>
</v-card>
  	<v-dialog v-model="descriptionPanel" width="1000" eager>
    	<v-card>
      		<rich-text-box style="backgroud:white" key="selectionRichTextBox" @DescriptionChange="DescriptionChange" :vueEditorObj="vueEditorObj">

      		</rich-text-box>
      		<v-card-actions>
        		<v-spacer></v-spacer>
        		<vc-button  itemText= "Close" @click.native="descriptionPanel = false">

              	</vc-button>
      		</v-card-actions>
    	</v-card>
  	</v-dialog>

  <simplert :useRadius="true"
    :useIcon="true"
    ref="simplert" key="selectionSimplert">
  </simplert>
</v-flex>
</template>
<script>
import {COLOR_CODE,BTN_COLOR} from '@/data/macros.js'
import draggable from 'vuedraggable'

import moment from 'moment'
import Simplert from 'vue2-simplert'
import showerrorlisttemplate from './../../views/showerrorlisttemplate.vue'
import orderByPanel from './../../views/orderByPanel.vue'
import columnlistpanel from './../../views/columnlistpanel.vue'
import {getTableList, getBulkTablesColumns,getColumnsMoreInfo,getRevisedColumnsWithDescription,getTableDescriptions} from "../../methods/agent_api_call.js"
import * as constantObj from '../../methods/edcGraph/constants.js'
import * as jdeSummObj from '../../methods/edcGraph/jdesummarization.js'
import {validate_process_def_json} from "../../methods/call_validation.js"
import Criteria from '@/views/newcriteria.vue';
import {GRAPH_GRID_SIZE,GRID_COLOR,AGGREGATE_FUNCTIONS} from "../../methods/flowcharts/constants.js"
import _ from 'lodash';
import {
  VALUE_TO_TEXT,VALIDATION_IN_RPOCESS_MESSAGE
} from "../../methods/flowcharts/constants.js";
import {PerformStepNameValidation} from "../../methods/flowcharts/graph.js"
import {
        CLIENT_SIDE,
        SERVER_SIDE
    } from '@/data/macros.js'
import {DEFAULT_DESCRIPTION,getColDescription} from "../../methods/flowcharts/commonFunc.js"
import RichTextBox from "@/views/richTextEditor/index.vue";

export default {
  components:{
    showerrorlisttemplate,
    'new-edc-criteria':Criteria,
    'rich-text-box':RichTextBox,
    'simplert':Simplert,
    'edc-order-by':orderByPanel,
    draggable,
    'edc-column-list':columnlistpanel
  },
  data () {
    return {
      move_data_back_to_source:true,
      dont_summarize_if_f0902_archived:true,
      nameTextboxSize:20,
      main_filterUI:[],
      secondary_filterUI:{groupList:[]},
      stepper:1,
      columnPanelList:[],
      changeText:VALUE_TO_TEXT,
      errorListCount:0,
      colorCode:COLOR_CODE,
      outlineColor:BTN_COLOR,
      selectedColumns:[],
      validateProgress:false,
      errorList:{},
      distinct:true,
      stepname:this.parentdigramobj.cell_name,
      columns_for_filter: [],
      column_name_list: [],
      filter_column_tbl_obj: {
        's_parentheses': '',
        'column_name': '',
        'operator': '',
        'v_type1': 'Value',
        'v_type2': 'Value',
        'value1': '',
        'value2':'',
        'e_parentheses': '',
        'is_drv_table': false,
        'ref_row_id':'',
        'ref_step_id':''

      },
      columns_for_selection:[],
      dragged_tbl_list:[],
      dragged_tbl_list_with_type:[],
      env_id: '',
      savedstatus:'edited',
      is_error_occured:false,
      auto_increment_id:0,
      combined_error_list:{},
      validation_response:{},
      policy_list:[],
      filter_popup_index:0,
      showpolicies:true,
      datasource_error:'',
      env_name:this.$session.get('selected_env').name,
      stepType:constantObj.JDE_SUMM_F0911,
      stepTypeF0911:constantObj.JDE_SUMM_F0911,
      stepTypeF4111:constantObj.JDE_SUMM_F4111,
      vueEditorObj:{},
      descriptionPanel:false,
      pd_description:'',
      stepSeqNo:1,
      showErrorPopUp:true,
      order_by:[],
      table_column_list:[],
      summarizationFor:'F0911',
      orderComponentKey:0,
      primaryKeysForTable:[],
      limit:"1000",
      old_limit:'',
      summarizationPolicyList:[],
      columnListForHaving:[],
      aggregate_types:AGGREGATE_FUNCTIONS,
      currentColumnObject:{},
      f4111SummarizationOptions:[{"name":"Summarization Policy","value":"summarize_by_policy"},
                                  {"name":"Fiscal Period","value":"summarize_by_fiscal_period"},
                                  {"name":"Fiscal Year","value":"summarize_by_fiscal_year"}
                                  // {"name":"Transaction Date","value":"summarize_bb_transaction_date"}
                                  ],
      f4111SummarizationType:"summarize_by_policy",
      write_bf_record_if_quantity_0:false,
      restrictedColumnsForSelection:['ILTRDJ','ILTRQT','ILPAID','ILDGL'], // some of the columns are not allowed for the grouping. Suggested By Nirav - Date: 12th March 2022
      write_summarization_details_in_catalog:true,
      restrictedColumnsForFilter:['ILTRDJ','ILDGL'], // some of the columns are not allowed for the filtering. This columns should get added on the fly.Suggested By Nirav - Date 17th March 2022
    }
  },
  props:{
    parentdigramobj:{
      type:Object,
      default:{"name":"f0911sumarization"}
    },
    savedatastatus:{
      type:String,
      default:''
    },
    iserroroccured:{
      type:Boolean,
      default:false
    },
    policies:{
      type:Array,
      default:[]
    },
    tableListLoader:{
      type:Boolean,
      default:false
    },
    datasourceerror:{
      type:String,
      default:''
    },
    acceleratorObj:{
      type:Object,
      default:{}
    },
    readonly:{
      type:Boolean,
      default:false
    },
    redoundoCommandObj:{
      type:Object
    },
    redoundoMsg:{
      type:String,
    },
    tblsprimarykeys:{
      type:Array
    }
  },
  computed:{
    isF4111Summarization(){
      return this.stepType === this.stepTypeF4111
    }
  },
  watch: {
    '$store.state.errorList':{
      handler(newValue){
        if(!_.isEmpty(newValue)){
          // let idArray = Object.keys(newValue);
          if(this.parentdigramobj && newValue[this.parentdigramobj.stepId]){
            this.validation_response = newValue[this.parentdigramobj.stepId];
          }
        }
      },
      deep:true
    },
    columns_for_selection(newvalue){

    },

    acceleratorObj(newvalue){

    },
    datasourceerror(newvalue){
      this.datasource_error =newvalue
    },
    policies(newvalue){
      this.policy_list = newvalue;
    },
    savedatastatus(newvalue,oldvalue){
      this.savedstatus = newvalue
    },
    iserroroccured(newvalue,oldvalue){
      this.is_error_occured =newvalue
    },
    readonly(newvalue){

    },
    parentdigramobj(newvalue,o){
      // debugger;
      // this.errorListCount = 0
      var _this = this
      this.stepname = newvalue.cell_name
      this.schema = newvalue.tables_detail.target_schema
      this.connection_str = newvalue.tables_detail.target_connection_str
      this.datasource_id = newvalue.tables_detail.target_datasource_id
      this.stepType = newvalue.steptype
      this.column_name_list=[]
      this.columnPanelList = []
      this.columnListForHaving = []
      this.move_data_back_to_source = true
      this.dont_summarize_if_f0902_archived = true
      this.write_summarization_details_in_catalog = true
      this.limit = "1000"
      this.old_limit = ''
      this.write_bf_record_if_quantity_0 = false
      this.$nextTick(()=>{
        this.stepper = 1
      })

      this.summarizationPolicyList = [{"bus_id":"jdesumm001","bus_name":this.parentdigramobj.diagram_name}]
      if (newvalue.env_id){
        this.env_id = newvalue.env_id
      }

      let step_data = newvalue.step_data
      if(_.isEmpty(step_data)){
        if(this.stepType === this.stepTypeF0911)
          step_data = jdeSummObj.getF0911SummarizationStepData(this.parentdigramobj.diagram_name)
        else
          step_data = jdeSummObj.getF4111SummarizationStepData(this.parentdigramobj.diagram_name)
      }
      if(step_data.description){
        this.pd_description = step_data.description
        this.vueEditorObj = {'description':step_data.description}
      }

      if(step_data.limit){
        this.limit = newvalue.step_data.limit
        this.old_limit = newvalue.step_data.limit
      }

      if(step_data.move_data_back_to_source === false)
        this.move_data_back_to_source = false

      if(step_data.dont_summarize_if_f0902_archived === false)
        this.dont_summarize_if_f0902_archived = false

      if(step_data.write_summarization_details_in_catalog === false)
        this.write_summarization_details_in_catalog = false

      if(step_data.write_bf_record_if_quantity_0)
        this.write_bf_record_if_quantity_0 = step_data.write_bf_record_if_quantity_0

      this.columns_for_selection = step_data.selection
      this.columns_for_filter = step_data.filter
      this.main_filterUI = step_data.main_filterUI
      this.secondary_filterUI = step_data.secondary_filterUI
      this.dragged_tbl_list = step_data.select_table_list
      this.dragged_tbl_list_with_type = step_data.new_select_table_list
      this.summarizationFor = step_data.summarizationFor
      this.columnListForHaving =  step_data.having
      if(step_data.summarize_by)
        this.f4111SummarizationType = step_data.summarize_by

      this.getTableColumns(this.summarizationFor)
      this.order_by = step_data.order_by
      this.primaryKeysForTable = step_data.primaryKeys
      if(!this.primaryKeysForTable){
        this.$emit('update-tblsprimarykeys',this.summarizationFor,"table",true)
      }
      // setTimeout(()=>{
      // },500)
    },
    columns_for_filter(newvalue){
      this.savedata(false)
    },
    column_name_list(newvalue){
      // this.savedata(false)
    },
    redoundoCommandObj(newvalue){
      if(!newvalue || !newvalue.action_to_take || newvalue.step_id != this.parentdigramobj.stepId)
        return
      this.manageRedoUndoActions(_.cloneDeep(newvalue))
    },
    redoundoMsg(newvalue){

    },
    stepname(newvalue){
      if(newvalue){
        this.nameTextboxSize=Math.max(20,newvalue.length>60?60:newvalue.length)
      }
    },
    tblsprimarykeys(newvalue){
      if(newvalue)
        this.primaryKeysForTable = newvalue[0].keys
    }
  },
  mounted() {
    let _this = this


    var col_flex = this.$refs.colDragPanel
    if(col_flex){
      col_flex.addEventListener('drop',drop_on_col_panel)
      col_flex.addEventListener('dragover',allowDrop_on_col_panel)
    }

    function drop_on_col_panel(event){
      event.preventDefault();
      if(!_this.currentColumnObject || !_this.currentColumnObject.column_name)
      return
      _this.performselection(_.cloneDeep(_this.currentColumnObject))
      _this.currentColumnObject = {}
    }

    function allowDrop_on_col_panel(event) {
      event.preventDefault();
    }
  },

  methods:{
    changeStepper(){

    },
    columnDraggedObject(object){
      this.currentColumnObject = object;
    },
    performselection(columnObj){
      if(!this.isF4111Summarization || _.find(this.columns_for_selection,['column_name',columnObj.column_name])){
        alert('column already selected for grouping')
        return
      }

      if(this.restrictedColumnsForSelection.indexOf(columnObj.column_name)>=0){
        alert('column is not allowed for grouping')
        return
      }
      columnObj.report_alias_name = columnObj.description || columnObj.column_name
      this.columns_for_selection.push(columnObj)
      this.$emit('sendNonGraphEvent','add_column',{"step_id":this.parentdigramobj.stepId,"column":columnObj})
    },
    validateStepOnUserAction(){
      // we are going to call validate object after curtain time after action get performed because graph take sometime to update its json.
        var _this = this
        _this.showErrorPopUp = false
        setTimeout(()=>{
          _this.showErrorPopUp = true
          _this.validatestepdata()
          _this.$emit('validatePD')
        },edcGraphConstantsObj.AUTO_VALIDATOR_TIMER)
    },
    getTableColumns(table_name,table_type='table'){
      var _this = this
      var kwargs = {"table_column_list":this.table_column_list, "env_id":this.env_id,
      "conn_str":this.connection_str,"schema":this.schema, "table_name":table_name, "table_type": table_type,"need_column_db_details":true,"description_needed":true}
      getRevisedColumnsWithDescription(_this,kwargs).then(tableColumnResponse => {
        var column_response = tableColumnResponse
        var all_col_description = []
        let columnDataTypeList  = tableColumnResponse.column_db_details?tableColumnResponse.column_db_details:[]
        if(tableColumnResponse.column_list){
          column_response = tableColumnResponse.column_list
        }
        var substringIndex = 0
        if(_this.acceleratorObj.acceleration_type === 'jde_ed_e1')
          substringIndex = 2
        if(tableColumnResponse.description)
          all_col_description = tableColumnResponse.description

        for(let i=0;i<column_response.length;i++){
          var _data_dict = {"forselection": false,
          "forfilter":false,
          "table_name": table_name,
          "column_name": column_response[i],
          "is_drv_table":false,
          "description":getColDescription(substringIndex,column_response[i],all_col_description,false),
          "data_type_details":_.find(columnDataTypeList,["name",column_response[i]])}
          this.columnPanelList.push(_data_dict)


          let filter_dict = {}
          filter_dict['column_name'] =table_name+'.'+column_response[i]
          filter_dict[column_response[i]+'.'+table_name] = false
          filter_dict['data_type_details'] = _.find(columnDataTypeList,["name",column_response[i]])
          _this.column_name_list.push(filter_dict)

        }
      }).catch(error=>{
        alert(error)
      })
    },
    manageRedoUndoActions(redoundoCommandObj){
      var _this = this
      var graphElements = this.selection_graph.getElements()
      if(redoundoCommandObj.action_to_take === 'step_name_changed_from_inside'){
        if(!redoundoCommandObj.update_details)
          return
        this.old_stepname = this.stepname
        this.stepname = redoundoCommandObj.update_details.new_step_name
      }
      else if(redoundoCommandObj.action_to_take === 'remove_table'){
        this.stepper = 1
        this.removeCell(redoundoCommandObj.element.id)
      }
      else if(redoundoCommandObj.action_to_take === 'add_table'){
        this.stepper = 1
        _this.addTableInSelection(redoundoCommandObj.element,false)
        if(redoundoCommandObj.relatedLinks)
          _this.addLinksInJoin(redoundoCommandObj.relatedLinks,redoundoCommandObj.relatedJoins)
      }
      else if(redoundoCommandObj.action_to_take === 'add_join_link'){
        this.stepper = 1
        _this.addLinksInJoin([redoundoCommandObj.link],[redoundoCommandObj.reletedJoin])
      }
      else if(redoundoCommandObj.action_to_take === 'remove_column'){
        this.stepper = 2
        // here we need to check index of column exist in the data, if yes then remove from that index or remove from last
        let remove_index = this.columns_for_selection.length-1
        if(redoundoCommandObj.removedColumnIndex>-1)
          remove_index = redoundoCommandObj.removedColumnIndex
        this.columns_for_selection.splice(remove_index,1)
      }
      else if(redoundoCommandObj.action_to_take === 'add_column'){
        this.stepper = 2
        // console.log('redoundoCommandObj',redoundoCommandObj)
        redoundoCommandObj.column['columnDeletionPopUp'] = false
        let add_index = this.columns_for_selection.length
        if(redoundoCommandObj.removedColumnIndex>-1)
          add_index = redoundoCommandObj.removedColumnIndex
        this.columns_for_selection.splice(add_index,0,redoundoCommandObj.column)
      } else if(redoundoCommandObj.action_to_take === 'selection_filter_change'){
        this.stepper = 3
        this.columns_for_filter = redoundoCommandObj.columns_for_filter
        this.main_filterUI = redoundoCommandObj.main_filterUI
        this.secondary_filterUI = redoundoCommandObj.secondary_filterUI
      } else if(redoundoCommandObj.action_to_take === 'table_replaced'){
        redoundoCommandObj['newObject'] = redoundoCommandObj.new_value
        redoundoCommandObj['oldObject'] = redoundoCommandObj.old_value
        let table_name = redoundoCommandObj.newObject.table_name
        let table_type = redoundoCommandObj.newObject.table_type
        let old_table_name = redoundoCommandObj.oldObject.table_name
        let oldtableIndex = this.dragged_tbl_list_with_type.findIndex(obj=>obj.table_name === old_table_name)

        this.$set(this.dragged_tbl_list_with_type,oldtableIndex,{"table_name":table_name,"table_type":table_type})

        oldtableIndex =  this.dragged_tbl_list.indexOf(old_table_name)
        this.$set(this.dragged_tbl_list,oldtableIndex,table_name)

        let is_drv_table = false
        if(table_type === 'step')
          is_drv_table = true
        this.showTableColumns(table_name,is_drv_table,table_type,false,redoundoCommandObj,true)
      }
      this.savedata()
    },
    totalCount(count){
      this.errorListCount = count;
    },
    setError(errorList){
      let _this = this;
      // _this.Remove_Error_List();
      debugger;
      _this.Perform_Validation(errorList);
    },
    saveCriteria(columns_for_filter,main_filter,secondary_filter){
      // debugger;
      let oldFilter = _.cloneDeep(this.columns_for_filter)
      let oldMain_filterUI = _.cloneDeep(this.main_filterUI)
      let oldSecondary_filterUI = _.cloneDeep(this.secondary_filterUI)
      this.columns_for_filter = columns_for_filter;
      this.main_filterUI = main_filter
      this.secondary_filterUI=secondary_filter
      this.savedata();
      if(oldFilter.length === columns_for_filter.length){
          return
      }
      this.$emit('sendNonGraphEvent','selection_filter_change',{"columns_for_filter":oldFilter,"main_filterUI":oldMain_filterUI,"secondary_filterUI":oldSecondary_filterUI,'step_id':this.parentdigramobj.stepId})

    },
    get_new_id(){
      return ++this.auto_increment_id;
    },
    savedata(is_close){
      let _this = this
      this.savedstatus = "edited"
      this.datasource_error = ''
      if(!_this.stepname)
        return
      var data = this.getdata()
      // console.log("data",data)

      _this.$emit('update-object',data,is_close)
    },
    getdata(){
      this.env_name = this.$session.get('selected_env').name
      let _this = this
      this.is_error_occured =false
      var filter_data = _.cloneDeep(this.columns_for_filter)
      if(filter_data.length === 1 && (!filter_data[0].column_name && !filter_data[0].operator))
        filter_data = []
      var data = {'stepname':_this.stepname.trim(),
      'step_data':{'selection':_.cloneDeep(this.columns_for_selection),'filter':filter_data,'main_filterUI':this.main_filterUI,'secondary_filterUI':this.secondary_filterUI,'filter_column_list':['abc'], 'select_table_list':_.cloneDeep(this.dragged_tbl_list),'new_select_table_list':_.cloneDeep(this.dragged_tbl_list_with_type),'auto_id':this.auto_increment_id,'is_revised':true,'isAug2020ReleasedUI':true,'description':this.pd_description,"summarizationFor":this.summarizationFor,'order_by':this.order_by,'run_on_target':true,"primaryKeys":this.primaryKeysForTable,"limit":this.limit,'move_data_back_to_source':this.move_data_back_to_source,"dont_summarize_if_f0902_archived":this.dont_summarize_if_f0902_archived,'write_summarization_details_in_catalog':this.write_summarization_details_in_catalog}}
      if(this.isF4111Summarization){
        data['step_data']['having'] = this.columnListForHaving
        data['step_data']['summarize_by'] = this.f4111SummarizationType
        data['step_data']['write_bf_record_if_quantity_0'] = this.write_bf_record_if_quantity_0
      }

      data['step_type'] =this.stepType
    return data
  },
  validatestepdata(){
    var _this = this
    if(this.readonly)
        return
    if(_this.validateProgress){
      return false
    }
    _this.validateProgress=true;
    var data = this.getdata()
    data['previous_steps_data'] = this.source_cells_detail
    data['env_id'] = this.env_id
    _this.selection_error = ''
    _this.combined_error_list = {}
    _this.validation_response={}
    _this.Remove_Error_List();
    validate_process_def_json(this, data).then(validation_response => {
      _this.validateProgress=false;
      var error_data = validation_response ;
      _this.validation_response = validation_response
      _this.$store.state.errorList[this.parentdigramobj.stepId] = validation_response
        //first clear all errors list
        // _this.Perform_Validation(_this.validation_response)
      }).catch(error=>{
         _this.validateProgress = false;
       })
  },
  Perform_Validation(error_data){
    var _this = this

  },

  Perform_Validation_On_Filter(filter_error_data){
   var _this = this;
    var rows = Object.keys(filter_error_data)
    rows.map(row=>{ // it will provide rowindex like 0,1,2
      let columnArray = Object.keys(filter_error_data[row]);
      columnArray.map(column=>{ // it will provide key in that object like alies_name, column_name
        _this.columns_for_filter[row].error_list[column] = filter_error_data[row][column];
      })
    })
  },

  Remove_Error_List(){
    this.Remove_From_Normal_List(this.columns_for_filter)

  },
  Remove_From_Normal_List(list){
    for(var i=0;i<list.length;i++){
      list[i].error_list = {}
    }
  },
  StepNameChanged(){
    this.stepname = this.stepname.trim()
    if(this.old_stepname.trim() === this.stepname)
      return false
      if(this.stepname.length ===0)
        this.stepname = this.old_stepname.trim()
      else{
      var validated_name = PerformStepNameValidation(this.stepname,this.existingsteplist)
      this.stepname = validated_name
        this.UpdateReferences(this.old_stepname,this.stepname
          ,true)
        this.old_stepname = this.stepname.trim()
        this.savedata(false)
      }
  },
    UpdateReferences(old_data,new_data,is_name_change){

      var update_data ={"step_id":this.parentdigramobj.stepId,"step_name":this.old_stepname}
      var action = ''
      // need to reflect name change.
      if(is_name_change) {
        // if user didnt change any name
        if(old_data === new_data)
          return
        update_data['new_step_name'] = new_data
        action = 'step_name_changed'
      }

      if(action){
        this.$emit('update-step-selection',action,update_data)
        let nonGraphEvent = action
        if(action === 'step_name_changed')
          nonGraphEvent = 'step_name_changed_from_inside'

        this.$emit('sendNonGraphEvent',nonGraphEvent,{'action_type':nonGraphEvent,'update_details':update_data})
      }
    },
    callgetColumnsMoreInfo(type_of_request){
      getColumnsMoreInfo(this,this.env_id,this.$session.get("client_id"),this.columns_for_selection,type_of_request,this.sourceCellColumnReferences)
    },
    DescriptionChange(pd_description){
      this.pd_description = pd_description
      this.savedata()
    },
    preventKeyEvent(event){
        if (event.ctrlKey && (event.code === 'KeyZ'||event.code === 'KeyY'))
          event.preventDefault()
    },
    add_new_filter_row(){
      var current_filter_length = this.columns_for_filter.length
      if(current_filter_length > 0){
        if(!this.columns_for_filter[current_filter_length-1]['operation'])
          this.columns_for_filter[current_filter_length-1]['operation'] = false
      }
      var obj = _.cloneDeep(this.filter_column_tbl_obj);
      obj['id'] = this.get_new_id()
      obj['error_list'] = ''
      this.columns_for_filter.push(obj);
    },
    delete_filter_row(idx){
      var current_filter_length = this.columns_for_filter.length
      if(current_filter_length > 1 && current_filter_length-1 ===  idx)
        delete this.columns_for_filter[idx-1]['operation']
      this.columns_for_filter.splice(idx, 1)
      if(this.columns_for_filter.length === 0){
        var obj = _.cloneDeep(this.filter_column_tbl_obj);
        this.columns_for_filter.push(obj);
      }
    },
    validateLimit(e){
      if(["0","1","2","3","4","5","6","7","8","9"].indexOf(e.key) < 0)
        e.preventDefault()
    },
    onLimitBlur(e,fromRedoUndo){
      if(this.old_limit!=this.limit && !fromRedoUndo){
        this.$emit('sendNonGraphEvent','step_limit_change',{"step_id":this.parentdigramobj.stepId,"old_limit":this.old_limit,"new_limit":this.limit})
      }
      if(this.limit.trim()==="")
        return
      // to deal with 0000 or 00111 kind of values in limit

      this.limit = parseInt(this.limit)
      // to deal with 0. 0 cause the error in engine library
      if(this.limit === 0)
        this.limit = ""
      this.old_limit = this.limit
      this.limit = this.limit.toString()
    },
    delete_selection_row(index){
      if(this.readonly)
        return
      this.columns_for_selection.splice(index,1)
      this.savedata()
    },
  }
}
</script>
<style scoped>
.radioClass >>> label{
    top: 4px !important;
}

#bfquantityoption >>> .v-input--selection-controls__input {
  bottom:16px !important;
}


.customCheckbox >>> label{
  top: 5px !important;
}
.v-autocomplete.v-input{
  margin:0px;
}
.upperrow{
  display:inline-block;
  height:350px;
  overflow: auto;
  border:1px solid grey;
}

.lowerrow{
  display:block;
  height:200px;
  overflow: auto;
  /* border:1px solid grey; */

}

#mainflex{
  background-color:grey;
}

.list_items {
  height:30px;
  draggable:true;
}

.handle {
  cursor: move !important;
  cursor: -webkit-grabbing !important;
}
table.v-table thead tr, table.v-table tbody tr{
  height: 38px;
}
.sticky {
  position: absolute;
}
#filter_column_table .v-input__slot{
  margin-bottom: -10px;
  margin-top: -5px;
}
#select_column_table .v-select__slot{
  margin-bottom: -6px;
}
#filter_column_table .v-input__slot input, .v-select-list .v-list__tile__content{
  font-size: 15px;
}
#select_column_table td{
  font-size: 13px;
}
#select_column_table .v-input__slot{
  font-size: 14px;
  margin-bottom: -8px;
  margin-top: -5px;
}
#select_column_table .v-input__append-inner i{
  /*font-size: 15px;*/
}
#select_column_table .v-text-field__slot{
  width: 65px;
}
#select_column_table .v-text-field__slot{
  margin-top: -5px;
}
.handle {
  cursor: move !important;
  cursor: -webkit-grabbing !important;
}
.v-radio label{
  font-size: 15px;
}
.v-radio i{
  font-size: 20px;
}
.v-dialog__activator{
  margin-top: -10px;
}

#switch_button {
  margin-bottom: -1rem !important;
  vertical-align: top !important;
}

.v-input--selection-controls {
     margin-top: 4px !important;
     padding-top: 0px !important;
}

</style>
