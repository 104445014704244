<template>
  <v-flex id="mainflex">
    <v-card class="rounded-card EDC-Designer-Row">
      <v-container class="EDC-Container">
        <v-row class="EDC-Row" justify="start">
          <v-col class="EDC-Col" cols="9" align="start">
            <ul class="breadcrumb" style="display:inline-flex;">
            <!-- <li><a @click="savedata(true)">{{env_name}} </a></li> -->
            <li><a @click="savedata(true)">{{duplicateobj.steptype}} </a></li>
            <li>[{{stepSeqNo}}] <input type="text" v-model="stepname" :title="stepname" :readonly="readonly"/></li>
          </ul>
          <span v-if="!readonly" style="font-weight:400;font-size:13px;margin-left:5px;" v-bind:class="{errorcls:is_error_occured , successcls:!is_error_occured}">{{'('+savedstatus + ')'}}</span>
            <span v-else style="color:rgba(0,0,0,0.54);font-weight:400;font-size:13px;margin-left:5px;">
              (Readonly Mode)</span>
          <span style="font-weight:400;font-size:13px;margin-left:5px;" :style="{'color': colorCode}">{{datasource_error}}</span>
          </v-col>
          <v-col cols="3" class="EDC-Col">
            <v-icon class="v-align" style="font-size:20px;float:right;padding-left:12px !important;" @click="savedata(true)" title='close'>far fa-times-circle</v-icon>
        <span style="font-size:13px;float:right;margin-right:5px;" :style="{'color': colorCode}">
          <v-progress-circular :width="3" v-if="validateProgress" :size="20" color="blue" indeterminate></v-progress-circular>
          <showerrorlisttemplate :validation_response="validation_response" stepType="Duplicate"
          @set-error="setError" @totalCount="totalCount" :errorListCount="errorListCount" :showprocess="validateProgress ? 'Y':'N'" style="margin-top:2px !important;margin-left:4px !important;" :showErrorPopUp="showErrorPopUp"></showerrorlisttemplate></span>
          <span style="float:right;" class="v-align" :style="{'color': colorCode}">
            <svgicon class="svg-icon-grid svg-fill-grid" name="validate"  style="float:right;margin-top:-20%;"  title="Validate Step" @click="validatestepdata" ></svgicon>
          </span>
          <!-- <svgicon class="svg-icon-grid svg-fill-grid" name="object_notes"  style="float:right;"  title="Add Step Description" @click="descriptionPanel = true"></svgicon> -->
          </v-col>
        </v-row>
        <v-row class="EDC-Row">
          <v-col class="EDC-Col" align="left">
            <edc-graph-toolbar style="padding-left:4px !important;" :redoCount="undoStackCount" :undoCount="redoStackCount" key="selection-desinger" @performUndoRedo="$emit('keyupHandler',$event)" @showDescription="descriptionPanel = true" @onSave="$emit('saveProcessDesigner')" :showNewVersion="false"></edc-graph-toolbar>
          </v-col>
        </v-row>
      </v-container>
      <v-container class="EDC-Container">
        <v-row class="EDC-Row">
          <v-col cols="12" class="EDC-Col">
            <v-stepper v-model="stepper" class="EDC-Stepper" non-linear>
              <v-stepper-header class="EDC-StepperHeader">
                <v-stepper-step step="1" class="EDC-StepperStep" editable>
                  Object Selection
                </v-stepper-step>
                <v-divider class="EDC-StepperDivider"></v-divider>
                <v-stepper-step step="2" class="EDC-StepperStep" editable>
                  Apply Filter
                </v-stepper-step>
              </v-stepper-header>
              <v-stepper-items>
                <v-stepper-content class="EDC-Stepper-Content" step="1" elevation="0">
                  <v-row class="EDC-Row">
                    <v-col cols="1" class="EDC-Col">
                      <label class="v-label" style="float:left;margin-top:5px;">Check For</label>
                    </v-col>
                    <v-col cols="6" class="EDC-Col">
                      <v-radio-group row v-model="bywhom" @change="ChangeByWhom" dense>
                      <v-radio class="radioClass" :disabled="readonly" :color="checkboxColor" label="Single Table" value="table"></v-radio>
                      <v-radio class="radioClass" :disabled="readonly" :color="checkboxColor" label="Entire Business Object" value="bo"></v-radio>
                    </v-radio-group>
                    </v-col>
                  </v-row>
                  <v-row class="EDC-Row">
                    <v-col cols="5" class="EDC-Col" style="padding-left:5px !important;padding-right:10px !important;">
                      <v-autocomplete v-model="join_from_table" :disabled="readonly" label="Search Driver Table Here" cache-items hide-no-data
                      :loading="loading" :items="table_list" :search-input.sync="search" item-text="title" @input="setFilterColumn()" return-object item-value="title"
                     ></v-autocomplete>
                    </v-col>
                    <v-col cols="5" v-if="bywhom === 'bo'" class="EDC-Col" style="padding-left:20px !important;">
                      <v-row class="EDC-row">
                        <v-col cols="8" class="EDC-Col">
                          <v-autocomplete :disabled="readonly" v-model="selected_bus_obj" label="Search Business object Here" 
                          :items="bussinessObjectList" @input="setBussinessObject(selected_bus_obj)" item-text="display_name"  item-value="id"  clearable></v-autocomplete>
                        </v-col>
                        <v-col class="EDC-Col">         
                          <v-btn :color="outlineColor"  outline style="border-radius:10px;"  @click="GenerateBO" :loading="generateboclick"
                            :disabled="generateboclick || !is_driver_is_from_db || (selectedBussinessObject && selectedBussinessObject.id) || readonly">
                              Generate BO
                          </v-btn>
                        </v-col>
                      </v-row>
                    </v-col>  
                  </v-row>
                  <v-row class="EDC-Row">
                    <v-col cols="5" class="EDC-Col">
                      <table class="table table-striped table-bordered draggable_table"  id="select_column_table" style="margin-left:5px;margin-right:5px;width:97%;">
                        <thead>
                          <tr>
                            <th>#</th>
                            <th>Column</th>
                            <th>Report Heading</th>
                            <th>Order Type</th>
                          </tr>
                        </thead>
                        <draggable v-model="columns_for_order" :element="'tbody'">
                          <tr v-for="(item, idx) in columns_for_order" :key="idx">
                            <td class="handle" style="max-width: 28px;">::</td>
                            <td class="text-xs-left" v-model="item.column_name">
                            {{item.column_name}}
                            </td>
                            <td>
                              <v-text-field v-model="item.report_alias_name" style="width: 110px;" placeholder="Report Alias" solo @blur="savedata()" :title="item.report_alias_name" hide-details :readonly="readonly"></v-text-field>
                            </td>
                            <td>
                              <toggle-button :disabled="readonly" id="switch_button" v-model="item.type" :labels="{checked: 'DESC', unchecked: 'ASC'}":sync="true" :width="60"/>
                            </td>
                          </tr>
                        </draggable>
                      </table>
                    </v-col>
                    <v-col cols="5" class="EDC-Col" v-if="bywhom === 'bo'">
                      <table class="table table-striped table-bordered draggable_table"  id="select_column_table" style="margin-left:5px;margin-right:5px;width:97%;">
                      <thead>
                        <tr>
                          <th>Business Object Keys</th>
                        </tr>
                      </thead>
                      <tr v-for="(item, idx) in business_object_keys" :key="idx" >
                        <td class="text-xs-left">
                          {{item}}
                        </td>
                      </tr>
                    </table>
                    </v-col>
                  </v-row>
                </v-stepper-content>
                <v-stepper-content class="EDC-Stepper-Content" step="2" elevation="0">
                  <new-edc-criteria :policy_list="policy_list" :showpolicies="showpolicies" :columns_for_filter="columns_for_filter" :column_name_list="column_name_list" 
                  @savedata="saveCriteria" @new="add_new_filter_row" @delete="delete_filter_row" parentsteptype="duplicate" :acceleratorObj="acceleratorObj":readonly="readonly" :new_columns_for_filter="main_filterUI" :secondaryfilter="secondary_filterUI" :oldFilterObjDetails="oldFilterObjDetails" :redoUndoFilterObj="redoUndoFilterObj" @redoUndoFilterUpdate="redoUndoFilterUpdate"></new-edc-criteria>
                </v-stepper-content>
              </v-stepper-items>
            </v-stepper>
          </v-col>
        </v-row>
      </v-container> 
      
</div> 
</div>
</div>   
<v-layout raw wrap style="margin-left:1%;margin-right:1%">
 <!-- Embed criteria table here -->
      
</v-layout>
</v-card>
<v-dialog v-model="descriptionPanel" width="1000" eager>
    <v-card>
      <rich-text-box style="backgroud:white" key="duplicateRichTextBox" @DescriptionChange="DescriptionChange" :vueEditorObj="vueEditorObj">
        
      </rich-text-box>
      <v-card-actions>
        <v-spacer></v-spacer>
        <vc-button  itemText= "Close" @click.native="descriptionPanel = false">
                
              </vc-button>
      </v-card-actions>
    </v-card>
  </v-dialog>
</v-flex>        
</template>
<script>
const joint = require('jointjs')
  // const cloneDeep = require('clone-deep');
  import {COLOR_CODE,BTN_COLOR} from '@/data/macros.js'
  import draggable from 'vuedraggable'
  import moment from 'moment'
  import {getRectangle} from "../../methods/flowcharts/shapes.js"
  import {validateConnection,validateMagnet,get_port_group,get_port_items,reset_port_markup} from "../../methods/flowcharts/ports.js"
  import {getDefaultLinkProperies,RestrictCyclicConnection,ValidateSourceTarget,ValidateOutgoingLink} from "../../methods/flowcharts/links.js"
  import {PerformSmartRouting} from "../../methods/flowcharts/graph.js" 
  import {JOIN_OPERATOR_ARRAY_JSON,JOIN_TYPE,AGGREGATE_FUNCTIONS, FLITER_JOIN_OPERATOR_ARRAY_JSON,VALIDATION_IN_RPOCESS_MESSAGE} from '../../methods/flowcharts/constants.js'
  import Sortable from 'sortablejs'
  import {getTableList, getTableColumns,getColumnsMoreInfo,getColumnsWithDescription} from "../../methods/agent_api_call.js"
  import {handleKeyevents} from "../../methods/flowcharts/papers.js"
  export const DATE_TYPE_ARRAY = {'Database Date':'MM/DD/YYYY', 'JDE Julien': '', 'CCYYMMDD': 'CCYYMMDD', 'Century Only': 'CC', 'Year Only': 'YY', 'Month Only':'MM', 'YYDDD': 'YYDDD'};
  import dateformat from './../../views/dateformat.vue'
  import { get as getToServer } from './../../methods/serverCall.js';
  import { GET_BUSINESS_DATA, GET_BUSINESS_GRAPH_DATA,ADD_SINGLE_TABLE_BO } from '../../data/url_constants.js'
  import config from '../../config.json'
  import find from 'lodash/find'
  import cloneDeep from 'lodash/cloneDeep'
  import isEmpty from 'lodash/isEmpty'
  import { post as postToServer } from './../../methods/serverCall.js';
  import {getPanandZoomComponent,performZoomActions,performPanningActions} from "../../methods/flowcharts/paningandzoooming.js"
  import {validate_process_def_json} from "../../methods/call_validation.js"
  import Criteria from "@/views/newcriteria.vue"
  import showerrorlisttemplate from './../../views/showerrorlisttemplate.vue'
  import {CHECKBOX_COLOR} from '../../data/macros.js'
  import _ from 'lodash'
  import {DEFAULT_DESCRIPTION,getColDescription} from "../../methods/flowcharts/commonFunc.js"
  import RichTextBox from "@/views/richTextEditor/index.vue";
  import * as edcConstantObj from '../../methods/edcGraph/constants.js'
  import edcGraphToolbar from '@/views/edcGraph/edcGraphToolbar.vue'
  export default {
    components:{
      draggable,
      dateformat,
      "new-edc-criteria":Criteria,
      showerrorlisttemplate,
      'rich-text-box':RichTextBox,
      'edc-graph-toolbar':edcGraphToolbar,

    },
    data () {
      return {
        main_filterUI:[],
        secondary_filterUI:{groupList:[]},
        stepper:1,
        errorListCount:0,
        colorCode:COLOR_CODE,
        outlineColor:BTN_COLOR,
        validateProgress:false,
        validation_response:{},
        search: null,
        loading:false,
        flattening_allowed:true,
        run_on_target:false,
        limit:"100",
        stepname:this.duplicateobj.cell_name,
        join_from_table:{},
        join_to_table:'',
        dateformatobj:'',
        jto_column_list:[],
        jfrom_column_list:[],
        filter_operators:FLITER_JOIN_OPERATOR_ARRAY_JSON,
        jfrom_drv_table: "false",
        // table_column_list:[],
        table_list:[],
        connection_str:"",
        schema:"",
        db_type:"",
        datasource_id:"",
        target_connection_str:"",
        target_schema:"",
        target_datasource_id:"",
        default_table_list:"",
        columns_for_filter: [],
        value_type_list: ['Value', 'Date', 'Column'],
        start_parentheses_list: [{'value':'('}, {'value':'(('}, {'value':'((('}, {'value':'(((('}],
        end_parentheses_list: [')', '))', ')))', '))))'],
        column_name_list: [],
        filter_column_tbl_obj: {
          's_parentheses': '',
          'column_name': '',
          'operator': '',
          'v_type': 'Value',
          'value': '',
          'e_parentheses': '',
          'is_drv_table': false,
          'table_name':'',
          error_list:{}
        },
        dateformat_dialog: false,
        selectedBussinessObject:{},
        env_id:"",
        savedstatus:'edited',
        is_error_occured:false,
        auto_increment_id:0,
        source_cells_detail:{},
        selected_bus_obj:"",
        policy_list:[],
        filter_popup_index:0,
        datasource_error:'',
        is_driver_is_from_db:false,
        generateboclick:false,
        showpolicies:true,
        env_name:this.$session.get('selected_env').name,
        columns_for_order:[],
        business_object_keys:[],
        checkboxColor:CHECKBOX_COLOR,
        bywhom:'table',
        duplicate_table_list:[],
        Table_Selection_Header:'Tables Details',
        search_tbl_lbl:'Search Table Here',
        sourceCellColumnReferences:[],
        vueEditorObj:{},
        descriptionPanel:false,
        pd_description:'',
        stepSeqNo:1,
        showErrorPopUp:true,
        oldFilterObjDetails:{},
        redoUndoFilterObj:{},
        autoValidateTimer:0
      }
    },
    props:['duplicateobj','bussinessObject','savedatastatus','iserroroccured','policies','bussinessObjectList','datasourceerror','tblsprimarykeys','acceleratorObj','readonly','undoStack','redoStack'],
    computed: {
      table_column_list(){
        let data = this.$store.state.cacheData[this.env_id+'_table_column_list'];
        return data ? data : [];
      },
      undoStackCount(){
      if(!this.parentdigramobj || !this.parentdigramobj.stepId)
        return 0
      return _.filter(this.undoStack,(obj)=>{
        return obj.step_id === this.parentdigramobj.stepId
      }).length
    },
    redoStackCount(){
      if(!this.parentdigramobj || !this.parentdigramobj.stepId)
        return 0
      return _.filter(this.redoStack,(obj)=>{
        return obj.step_id === this.parentdigramobj.stepId
      }).length
    }
    },
    watch: {
      '$store.state.errorList':{
        handler(newValue){
          if(!_.isEmpty(newValue)){
            // let idArray = Object.keys(newValue);
            if(this.duplicateobj && newValue[this.duplicateobj.stepId]){
              this.validation_response = newValue[this.duplicateobj.stepId];
            }          
          }
        },
        deep:true
      },
      search (val) {
        val && val !== this.join_from_table.title && this.querySelections(val)
      },
      datasourceerror(newvalue){
        this.datasource_error =newvalue
      },
      policies(newvalue){
        this.policy_list = newvalue
      },
      savedatastatus(newvalue,oldvalue){
        this.savedstatus = newvalue
      },
      iserroroccured(newvalue,oldvalue){
        this.is_error_occured =newvalue
      },
      tblsprimarykeys(newvalue,oldvalue){
        this.tblsprimarykeys =newvalue
        this.getDriverKeys(true)
      },
      readonly(newValue){

      },
      duplicateobj(newvalue,oldvalue){
        // debugger;
        this.resetDuplicateStep()
        this.stepSeqNo = newvalue.stepSeqNo
        this.validation_response ={}
        //Code to bind error message if user perform validation at flowchart level
        var flowchart_errors = this.$store.state.errorList
        if(flowchart_errors && this.duplicateobj && flowchart_errors[newvalue.stepId]){
          this.validation_response = flowchart_errors[newvalue.stepId];
        }

        if(newvalue.business_obj_id){
          this.selected_bus_obj = newvalue.business_obj_id
          this.setBussinessObject(this.selected_bus_obj)
        }
        if(newvalue.step_data){

          if(newvalue.step_data.description){
            this.pd_description = newvalue.step_data.description
            this.vueEditorObj = {'description':newvalue.step_data.description}
          }

          if(newvalue.step_data.main_filterUI)
          this.main_filterUI = newvalue.step_data.main_filterUI
        if(newvalue.step_data.secondary_filterUI)
          this.secondary_filterUI = newvalue.step_data.secondary_filterUI


          if(newvalue.step_data.bywhom)
            this.bywhom = newvalue.step_data.bywhom

          if(newvalue.step_data.drv_table && newvalue.step_data.drv_table.select_table_list && newvalue.step_data.drv_table.select_table_list.length){
            this.join_from_table.title = newvalue.step_data.drv_table.select_table_list[0];
            this.join_from_table.type = 'T'
            if(newvalue.step_data.drv_table.driver_table_type){
              this.join_from_table.type = newvalue.step_data.drv_table.driver_table_type
            }
            this.jfrom_drv_table=false
            if(newvalue.step_data.drv_table.is_drv_table){
              this.join_from_table.type = 'S'
              this.jfrom_drv_table=true
            }
          }

          this.is_driver_is_from_db = false
          if(newvalue.step_data.isDriverFromDb)
            this.is_driver_is_from_db = true

          if(newvalue.step_data.selected_table_data){
            this.join_from_table = newvalue.step_data.selected_table_data 
          }

          if(newvalue.step_data.drv_table && newvalue.step_data.drv_table.selection)
            this.column_name_list = newvalue.step_data.drv_table.selection;

          // this.designOrderBy(this.column_name_list)
          if(newvalue.step_data.drv_table && newvalue.step_data.drv_table.order_by && newvalue.step_data.drv_table.order_by.length > 0)
            this.columns_for_order = newvalue.step_data.drv_table.order_by

          this.columns_for_filter=[];
          this.add_new_filter_row()
          if(newvalue.step_data.drv_table && newvalue.step_data.drv_table.filter && newvalue.step_data.drv_table.filter.length)
            this.columns_for_filter=newvalue.step_data.drv_table.filter;

          this.flattening_allowed = false
          if(newvalue.step_data.flattening_allowed)
            this.flattening_allowed = newvalue.step_data.flattening_allowed


          this.run_on_target = false
          if(newvalue.step_data.run_on_target)
            this.run_on_target = newvalue.step_data.run_on_target
        }
        if(newvalue.cell_name)
          this.stepname = newvalue.cell_name
        this.validateStepOnUserAction()
        if(newvalue.tables_detail){
          this.table_list = newvalue.tables_detail.table_list
          this.connection_str= newvalue.tables_detail.connection_str
          this.schema= newvalue.tables_detail.schema
          this.db_type= newvalue.tables_detail.db_type
          this.default_table_list = newvalue.tables_detail.default_table_list
          this.datasource_id = newvalue.tables_detail.datasource_id
          this.target_schema = newvalue.tables_detail.target_schema
          this.target_connection_str = newvalue.tables_detail.target_connection_str
          this.target_datasource_id = newvalue.tables_detail.target_datasource_id
          if(this.join_from_table.type && this.join_from_table.type != "S"){
            /* add driver table in to table list if driver tbl not present in table list.
               issue is if selected table is not from default table list then its not get bind to
               the driver drop down list
               */
               var obj = _.find(this.default_table_list, ["table_name",this.join_from_table.title])
               if(!obj){
                var new_tbl ={"title":this.join_from_table.title,"type":this.join_from_table.type}
                this.default_table_list.push(new_tbl)
                this.table_list.push(new_tbl)
              }
            }
          }
          if (newvalue.env_id){
            this.env_id = newvalue.env_id
          }
          this.source_cells_detail=[]
          if(newvalue.source_cells_detail && newvalue.source_cells_detail.length > 0){
            this.source_cells_detail = newvalue.source_cells_detail
            this.table_list = cloneDeep(this.default_table_list)
            var step_list = newvalue.source_cells_detail
            for(var i=0;i<step_list.length;i++){

              this.sourceCellColumnReferences = _.concat(this.sourceCellColumnReferences,step_list[i].column_list_references)

              let key = this.env_id + '_table_column_list';

              if (!this.$store.state.cacheData[key]) {
                this.$store.state.cacheData[key] = [];
              }
              // this.table_column_list.push({"table_name":step_list[i].source_name, "table_column_list":step_list[i].columns})
              let cacheIndex = -1;
              cacheIndex = _.findIndex(this.$store.state.cacheData[key], function(o) { return o.table_name == step_list[i].source_name; });

              if(cacheIndex >= 0){
                this.$store.state.cacheData[key].splice(cacheIndex, 1); 
              }

              this.$store.state.cacheData[key].push({"table_name":step_list[i].source_name, "table_column_list":step_list[i].columns,'table_column_descriptions':step_list[i].cols_description})
              

              //auto selection of driver table is case of driver table not selected 
              // for archival, purge and copy. BUG ISSUE NO. 115
              if(!this.join_from_table.title && this.bywhom==='bo'){
                this.join_from_table.title = step_list[i].source_name
                this.column_name_list = step_list[i].columns
                this.jfrom_drv_table = true
                this.designOrderBy(this.column_name_list)
              }
              
              for(var each_table_count=0;each_table_count<this.table_list.length;each_table_count++){
                if (this.table_list[each_table_count].title == step_list[i].source_name){
                  this.table_list.splice(each_table_count, 1)
                  break;
                }
              }
              this.table_list.push({"type":"S","title":step_list[i].source_name})
              for(var tbl_col_count=0;tbl_col_count<this.table_column_list.length; tbl_col_count++){
                if (this.table_column_list[tbl_col_count].table_name == step_list[i].source_name){
                  this.table_column_list.splice(tbl_col_count, 1)
                  break
                }
              }

              // DONT REMOVE THIS CODE. This code is duplicate. Same code we have above. But above code not storing step_data into this.$store.state.cacheData[key] once this code block get executed. But here it stored that.
              cacheIndex = -1;
              cacheIndex = _.findIndex(this.$store.state.cacheData[key], function(o) { return o.table_name == step_list[i].source_name; });

              if(cacheIndex >= 0){
                this.$store.state.cacheData[key].splice(cacheIndex, 1); 
              }

              this.$store.state.cacheData[key].push({"table_name":step_list[i].source_name, "table_column_list":step_list[i].columns, 'table_column_descriptions':step_list[i].cols_description})
              
              
            }
          }
          else{
            this.table_list = cloneDeep(this.default_table_list)
          }

          this.ChangeByWhom('internal')

        },
        limit(newvalue){
          this.savedata(false)
        },
        filter_data(newvalue){
          this.savedata(false)
        },
        jfrom_drv_table(newvalue){
          this.savedata(false)
        },
        stepname(newvalue){
          this.savedata(false)
        },
        join_from_table(newvalue){
          this.savedata(false)
        },
        run_on_target(newvalue){
          this.savedata(false)
        },
        acceleratorObj(newvalue){

        },
        undoStack(newvalue){
          if(newvalue){

          }
        },
        redoStack(newvalue){
          if(newvalue){

          }
        }
      },
      methods:{
        validateStepOnUserAction(){
          // we are going to call validate object after curtain time after action get performed because graph take sometime to update its json.
            var _this = this
            _this.showErrorPopUp = false
            clearInterval(_this.autoValidateTimer)
				    _this.autoValidateTimer=setTimeout(()=>{
              _this.showErrorPopUp = true
              _this.validatestepdata()
              _this.$emit('validatePD')
            },edcConstantObj.AUTO_VALIDATOR_TIMER)
        },
        totalCount(count){
          this.errorListCount = count;
        },
        setError(errorList){
          let _this = this;
          // _this.Remove_Error_List();
          _this.Perform_Validation(errorList);
        },
        Perform_Validation(error_data) {
          var _this = this
          _this.Remove_Error_List()
          if (typeof error_data === 'object') {
            if (error_data.step_data) {
              var step_data_errors = error_data.step_data
              if (step_data_errors.drv_table && step_data_errors.drv_table.filter) {
                _this.Perform_Validation_On_Filter(step_data_errors.drv_table.filter)
              }
            }
          }
          this.$forceUpdate();
            // console.log(JSON.stringify(_this.columns_for_filter));
          },
          Perform_Validation_On_Filter(filter_error_data){
            var _this = this;
            var rows = Object.keys(filter_error_data);
              // debugger;
              rows.map(row=>{ // it will provide rowindex like 0,1,2
                let columnArray = Object.keys(filter_error_data[row]); 
                columnArray.map(column=>{ // it will provide key in that object like alies_name, column_name
                  _this.columns_for_filter[row].error_list[column] = filter_error_data[row][column]; 
                })
              })
            // console.log("Column for filter:"+JSON.stringify(_this.columns_for_filter));  
          },
          Remove_Error_List(){  
            this.Remove_From_Normal_List(this.columns_for_filter)
          },
          Remove_From_Normal_List(list){
            for(var i=0;i<list.length;i++){
              list[i].error_list = {}
            }
          },
          saveCriteria(columns_for_filter,main_filter,secondary_filter){
          // debugger;
          this.columns_for_filter = columns_for_filter;
          this.main_filterUI = main_filter
          this.secondary_filterUI=secondary_filter 
          this.savedata();
        },

        redoUndoFilterUpdate(filterObj){
          filterObj['step_id'] = this.parentdigramobj.stepId
          this.$emit('sendNonGraphEvent','selection_filter_change',filterObj)
        },
        delete_filter_row(idx){
          var current_filter_length = this.columns_for_filter.length
          if(current_filter_length > 1 && current_filter_length-1 ===  idx)
            delete this.columns_for_filter[idx-1]['operation']
          this.columns_for_filter.splice(idx, 1)
          if(this.columns_for_filter.length === 0){
            var obj = cloneDeep(this.filter_column_tbl_obj);
            this.columns_for_filter.push(obj);
          }
        },
        querySelections (v) {
          this.loading = true
          this.Get_Tables(v);
        },
        Get_Tables(table_search_string){
          var _this = this;
          getTableList(_this,_this.env_id, _this.connection_str, _this.schema, _this.db_type, table_search_string).then(tableResponse =>{
            var latest_table_list = tableResponse.table_view_list;
            _this.table_list = latest_table_list;
            _this.ChangeByWhom('internal');
            this.loading = false;
          }).catch(errorResponse => {});
        }, 
        savedata(is_close){
          let data = this.getDuplicateStepData()
          this.$emit('update-object',data,is_close);
        },
        resetValue(idx){
        // this.columns_for_filter[idx].value = '';
        this.savedata()
      },
      getdate(index,data){
        index = this.filter_popup_index
        var date_data = cloneDeep(data)
        this.columns_for_filter[index].value = date_data["value"]
        this.columns_for_filter[index].datedetails = date_data
        this.dateformat_dialog = false
      },
      validatestepdata(){
        var _this = this;
        _this.Remove_Error_List();
        if(this.readonly)
          return
        if(_this.validateProgress){
          alert(VALIDATION_IN_RPOCESS_MESSAGE)
          return false
        }
        _this.validateProgress = true;
        var data = this.getDuplicateStepData();
        data['env_id'] = this.env_id
        data['previous_steps_data'] = this.source_cells_detail
        // validate_graph_data_for_selection(this.merge_graph,this.paper,this.dragged_tbl_list,this.join_json)
        // debugger
        _this.selection_error =''
        // if(isEmpty(_this.join_from_table)){
        //   // this.$toasted.error('No Driver Table found');
        //   this.colorValue = 'error'
        //   this.snackbartext = 'No Driver Table found';
        //   this.snackbar = true;
        //   _this.validateProgress = false;
        //   return;
        // }if(!_this.selected_bus_obj){
        //   // this.$toasted.error('No Business Object found');
        //   this.colorValue = 'error'
        //   this.snackbartext = 'No Business Object found';
        //   this.snackbar = true;
        //   _this.validateProgress = false;
        //   return;
        // }

        validate_process_def_json(this, data).then(validation_response => {
          _this.validateProgress = false;
          _this.validation_response = validation_response;
          _this.$store.state.errorList[_this.duplicateobj.stepId] = validation_response
        },error=>{
         _this.validateProgress = false;
       })
      },
      getDuplicateStepData(){
        this.env_name = this.$session.get('selected_env').name
        let _this = this
      // debugger;
      this.is_error_occured =false
      this.savedstatus = "edited"
      if(this.join_from_table.type === 'S')
        this.is_driver_is_from_db = false
      var filter_data = cloneDeep(this.columns_for_filter)
      if(filter_data.length === 1 && !filter_data[0].column_name)
        filter_data = []
      for (var i = filter_data.length - 1; i >= 0; i--) {
        filter_data[i].is_drv_table = this.jfrom_drv_table
        filter_data[i].table_name = this.join_from_table.title
      }

      var selection_list = []
      for (var i = 0; i < this.columns_for_order.length; i++) {
        if(!this.columns_for_order[i].id)
          this.columns_for_order[i]["id"] = _this.get_new_id()
        if(!this.columns_for_order[i].report_alias_name)
          this.columns_for_order[i].report_alias_name = ""
        // validating duplicate report alias
        for (var j = 0; j <i; j++) {
          if(this.columns_for_order[i].report_alias_name && this.columns_for_order[i].report_alias_name.toLowerCase() === this.columns_for_order[j].report_alias_name.toLowerCase()){
            this.columns_for_order[i].report_alias_name = this.columns_for_order[i].report_alias_name + "_" + this.columns_for_order[i]["id"]
            break
          }
        }
        // now make selection list
        var current_order_item = cloneDeep(this.columns_for_order[i])
        current_order_item["is_drv_table"] = this.jfrom_drv_table
        current_order_item["table_name"] = this.join_from_table.title
        selection_list.push(current_order_item)
      }

      
      var data = {'stepname':_this.stepname,'step_type':'Duplicate',
      'step_data':{"type":"Duplicate","isDriverFromDb":this.is_driver_is_from_db,'bywhom':this.bywhom,'main_filterUI':this.main_filterUI,'secondary_filterUI':this.secondary_filterUI,
      "selected_table_data":this.join_from_table,"limit":"0","flattening_allowed":false,"run_on_target":false,
      "drv_table":{'is_drv_table':this.jfrom_drv_table,"select_table_list":[this.join_from_table.title],'selection':_.map(this.columns_for_order,"column_name"),'select_columns':selection_list,
      'join':[],'filter':filter_data,'order_by':this.columns_for_order,"driver_table_type":_this.join_from_table.type
    },
    "business_obj_id":isEmpty(this.selectedBussinessObject) ? "" :this.selectedBussinessObject.id,
    "business_object_policy_id":isEmpty(this.selectedBussinessObject) ? "" :this.selectedBussinessObject.policy_name,
    "business_object_version":isEmpty(this.selectedBussinessObject) ? "" :this.selectedBussinessObject.object_version,
    "auto_id":this.auto_increment_id,'description':this.pd_description
  }}
  return data;
},
get_new_id(){
  return ++this.auto_increment_id;
},
resetDuplicateStep(){
  this.selectedBussinessObject={};
  this.join_from_table={};
  this.selected_bus_obj='';
  this.column_name_list=[];
  this.columns_for_filter=[];
  this.columns_for_order=[];
  this.business_object_keys=[];
  this.is_driver_is_from_db=false
  this.search = null
  this.sourceCellColumnReferences = []
  this.main_filterUI = []
  this.secondary_filterUI = {groupList:[]}
  this.pd_description = ''
  this.vueEditorObj = {'description':''}
},
add_new_filter_row(){
  var current_filter_length = this.columns_for_filter.length
  if(current_filter_length > 0){
    if(!this.columns_for_filter[current_filter_length-1]['operation'])
      this.columns_for_filter[current_filter_length-1]['operation'] = false   
  }
  var obj = cloneDeep(this.filter_column_tbl_obj);
  obj['id'] = this.get_new_id()
  obj.table_name = this.join_from_table.title
  this.columns_for_filter.push(obj);
},
resetIsDrvTable(c_name, idx){
    // for(var i=0;i< this.column_name_list.length;i++){
    //   if(this.column_name_list[i][c_name]){
    //     this.columns_for_filter[idx].is_drv_table = this.column_name_list[i][c_name] 
    //     break;
    //   }else{
    //     this.columns_for_filter[idx].is_drv_table = false
    //   }
    // }
  },
  setBussinessObject(id){
    this.business_object_keys =[]
    if(!id){
      this.selectedBussinessObject = {}
      return false
    }
    let _this = this;
    _this.selectedBussinessObject = find(_this.bussinessObjectList, ['id', id]);
    this.stepname = _this.selectedBussinessObject.name;
    this.business_object_keys = _this.selectedBussinessObject.unique_identifier_keys
    this.$emit('update-policy-list',_this.selectedBussinessObject)
    this.validateStepOnUserAction()
  },
  setFilterColumn(){
    this.is_driver_is_from_db = true
    let _this = this;
    var table = _this.join_from_table.title
    var table_type = 'table'
    if(this.join_from_table.type === 'T')
      table_type = "table"
    else if (this.join_from_table.type === 'V')
      table_type = "view"
    else if(this.join_from_table.type === 'A')
      table_type = "alias"
    else{
      table_type = 'step'
      this.is_driver_is_from_db = false
    }
      // let stepTable = find(_this.duplicateobj.source_cells_detail, ['source_name', table]);
      if(_this.join_from_table.type === 'S'){
        this.jfrom_drv_table=true;
        this.is_driver_is_from_db = false
      }else{
        this.is_driver_is_from_db = true
        this.jfrom_drv_table=false;
        this.getDriverKeys()
      }
      table && getTableColumns(_this,_this.table_column_list,_this.env_id,_this.connection_str,_this.schema, table, table_type).then(tableColumnResponse=> {
        if(tableColumnResponse){
          _this.column_name_list = tableColumnResponse  
          if(_this.join_from_table.type === 'S')    
            _this.designOrderBy(_this.column_name_list)          
          if(_this.columns_for_filter.length === 0){
            _this.add_new_filter_row()
          }
          // this.designOrderBy(_this.column_name_list)              
        }
      }).catch();
      this.validateStepOnUserAction()
    },
    designOrderBy(columnlist){
      let that = this
      this.columns_for_order = []
      var is_drv_table = this.jfrom_drv_table
      for (var i = 0; i < columnlist.length; i++) {
        this.columns_for_order.push({'column_name':columnlist[i], 'is_drv_table': is_drv_table, 'type':false,"report_alias_name":columnlist[i]})
      } 
      var table_type = 'table'
      if(this.join_from_table.type === 'T')
        table_type = "table"
      else if (this.join_from_table.type === 'V')
        table_type = "view"
      else if(this.join_from_table.type === 'A')
        table_type = "alias"
      else
        table_type = 'step'

      var kwargs = {"table_column_list":this.table_column_list, "env_id":this.env_id,
      "connection_str":this.connection_str,"schema":this.schema, "table_name":this.join_from_table.title, "table_type": table_type}
      getColumnsWithDescription(this,kwargs).then(tableColumnResponse => {
        var substringIndex = 0
        if(that.acceleratorObj.acceleration_type === 'jde_ed_e1')
          substringIndex = 2

        if(this.jfrom_drv_table === true || this.jfrom_drv_table === 'true')
          substringIndex = 0

        if(tableColumnResponse.description){
          for(var i=0;i<this.columns_for_order.length;i++){
            this.columns_for_order[i].report_alias_name = getColDescription(substringIndex,
              this.columns_for_order[i].column_name,tableColumnResponse.description,is_drv_table)
          }
        }
        }).catch({});
    },

    // getColDescription(substringIndex,column_name,all_col_description,is_drv_table)
    // {
    //   if (all_col_description.length == 0)
    //     return 'N/A'

    //   // incase of drv table we will match the whole column name. 
    //   // In case of drv table we storing whle column name
    //   if (is_drv_table == true || is_drv_table === 'true')
    //     substringIndex = 0
    //   let col_alias = column_name.substring(substringIndex)
    //   var description_obj = _.find(all_col_description,["column_name",col_alias])
    //   if (description_obj && description_obj.description)
    //     return description_obj.description
    //   return 'N/A'
    // },
    dateHandler(idx){
      this.filter_popup_index = idx
      var date_details = this.columns_for_filter[idx].datedetails
      if(date_details)
        this.dateformatobj = cloneDeep(date_details)
      else
        this.dateformatobj ={}
      this.dateformat_dialog = true
    },
    close_date_format_popup(){
      this.dateformat_dialog = false
    },
    getDriverKeys(from_watch){
      this.datasource_error = ""
      this.columns_for_order = []
      // from_watch variable is only for remove cyclic call to agent api
      var table_name = this.join_from_table.title
      var drv_obj = _.find(this.tblsprimarykeys,['table_name',table_name])
      if(drv_obj)
        this.designOrderBy(drv_obj.keys)
      else if(!from_watch){
        this.$emit('update-tblsprimarykeys',this.join_from_table.title,this.join_from_table.type)
      }
    },
    GenerateBO(){
      let _this = this
      _this.selectedBussinessObject = {}
      if(!_this.join_from_table || !_this.join_from_table.type)
      {
        alert('Table for archival not selected')
        return false
      }
      if(_this.join_from_table.type == 'S'){
        alert('You cannot perform archival on driver table')
        return false
      }
      this.datasource_error = ''
      this.generateboclick =true
      var table_type = _this.join_from_table.type
      var env_id = _this.env_id
      var env_name = this.$session.get('selected_env').name
      var schema = _this.schema
      var connection_str = this.connection_str
      var mapped_list = []
      if(_this.$session.get('mapped_agent_details'))
        mapped_list = _this.$session.get('mapped_agent_details')
      var mapped_agent_obj= _.find(mapped_list,['conn_str',connection_str])
      var mapped_agent_id = ''
      if(mapped_agent_obj)
        mapped_agent_id = mapped_agent_obj.mapped_agent_id
      var table_name =_this.join_from_table.title
      var db_type =_this.db_type
      var client_id = this.$session.get('client_id')
      var added_by = this.$session.get('email')
      var data_to_pass = {"table_type":table_type,"env_id":env_id,"schema":schema,"conn_str":connection_str,"table_name":table_name,"db_type":db_type,"client_id":client_id,"env_name":env_name,"added_by":added_by,"object_type_code": "BO","mapped_agent_id":mapped_agent_id}
      postToServer(this, config.REPOSITORY_API_URL + ADD_SINGLE_TABLE_BO, data_to_pass).then(response => {
        _this.selectedBussinessObject['id'] = response.object_id
        _this.selectedBussinessObject['name'] = response.object_name
        _this.selectedBussinessObject['object_id'] = response.object_id
        _this.selectedBussinessObject['object_name'] = response.object_name
        _this.selectedBussinessObject["object_version"] = response.object_revision
        _this.selectedBussinessObject["unique_identifier_keys"] = response.unique_identifier_keys
        _this.selected_bus_obj = _this.selectedBussinessObject['id']
        _this.selectedBussinessObject["display_name"] = response.object_name + ' ('+response.object_id+')'
        _this.datasource_error = 'Business Object created successfully'
        _this.bussinessObjectList.push(_this.selectedBussinessObject)
        _this.setBussinessObject(_this.selected_bus_obj)
        _this.$emit('update-policy-list',_this.selectedBussinessObject)
        _this.generateboclick =false
      }).catch(CurrentJobError => {
        this.generateboclick = false
        if(CurrentJobError){
          this.datasource_error = CurrentJobError
        }
        else {

        }
      })
    },
    ChangeByWhom(internal_call){
      if(internal_call === 'bo' || internal_call === 'table')
        this.resetDuplicateStep()
      
      this.duplicate_table_list = []
      var table_list = cloneDeep(this.table_list)
      this.duplicate_table_list = table_list

      // we have removed cache-item property of driver table selection combo. So each time we need to add steps details
      if(this.bywhom === 'bo'){
        this.search_tbl_lbl = 'Search Driver Table Here'
        this.Table_Selection_Header = 'Drivers Detail'
        var step_list = this.duplicateobj.source_cells_detail
        for(var i=0;i<step_list.length;i++){
          
          for(var each_table_count=0;each_table_count<this.duplicate_table_list.length;each_table_count++){
            if (this.duplicate_table_list[each_table_count].title == step_list[i].source_name){
              this.duplicate_table_list.splice(each_table_count, 1)
              break;
            }
          }
          this.duplicate_table_list.push({"type":"S","title":step_list[i].source_name})
        }
      }
      else
      {
        this.Table_Selection_Header = 'Tables Detail'
        this.search_tbl_lbl = 'Search Table Here'
        // remove all tables object which type = 'S'
        var list_of_steps = _.filter(table_list,['type','S'])
        if(list_of_steps.length > 0){
          this.duplicate_table_list = _.difference(table_list,list_of_steps)
        }
      }
    },
    callgetColumnsMoreInfo(type_of_request){
      getColumnsMoreInfo(this,this.env_id,this.$session.get("client_id"),this.columns_for_order,type_of_request,this.sourceCellColumnReferences)
    },
    DescriptionChange(pd_description){
      this.pd_description = pd_description
      this.savedata()
    },
  }
}
</script>
<style scoped>

.v-input--selection-controls{
  margin-top:0px !important;
  padding-top:0px !important;
}
.customCheckbox >>> label{
  top: 5px !important;
}
.v-autocomplete.v-input{
  margin:0px;
}
.upperrow{
  display:inline-block;
  height:350px;
  overflow: auto;
  border:1px solid grey;
}

.lowerrow{
  display:block;
  height:200px;
  overflow: auto;
  border:1px solid grey;

}

#mainflex{
  background-color:grey;
}

.list_items {
  height:30px;
  draggable:true;
}

.handle {
  cursor: move !important;
  cursor: -webkit-grabbing !important;
}
table.v-table thead tr, table.v-table tbody tr{
  height: 38px;
}
.sticky {
  position: absolute;
}
#filter_column_table .v-input__slot{
  margin-bottom: -10px;
  margin-top: -5px;
}
#select_column_table .v-select__slot{
  margin-bottom: -6px;
}
#filter_column_table .v-input__slot input, .v-select-list .v-list__tile__content{
  font-size: 15px;
}
#select_column_table td{
  font-size: 13px;
}
#select_column_table .v-input__slot{
  font-size: 14px;
  margin-bottom: -8px;
  margin-top: -5px;
}
#select_column_table .v-input__append-inner i{
  /*font-size: 15px;*/
}
#select_column_table .v-text-field__slot{
  width: 65px;
}
#select_column_table .v-text-field__slot{
  margin-top: -5px;
}
.handle {
  cursor: move !important;
  cursor: -webkit-grabbing !important;
}
.v-radio label{
  font-size: 15px;
}
.v-radio i{
  font-size: 20px;
}
.v-dialog__activator{
  margin-top: -10px;
}
#switch_button {
  margin-bottom: -1rem !important;
  vertical-align: top !important;
}



</style>