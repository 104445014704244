<template>
  <v-flex id="mainflex">
    <v-card class="rounded-card EDC-Designer-Row">
      <v-container class="EDC-Container">
        <v-row class="EDC-Row" justify="start">
          <v-col class="EDC-Col text-truncate" cols="10" align="start">
            <ul class="breadcrumb" style="display:inline-flex;">
              <!-- <li><a @click="savedata(true)">{{env_name}} </a></li>
              <li><a @click="savedata(true)"> {{parentdigramobj.diagram_name}} </a></li> -->
              <li><a @click="savedata(true)">{{stepType}} </a></li>
              <li>[{{stepSeqNo}}] <input type="text" v-model="stepname" :title="stepname" @blur="StepNameChanged()" :readonly="readonly" :size="nameTextboxSize"/></li>
            </ul>
          
            <span v-if='!readonly' style="font-weight:400;font-size:13px;margin-left:5px;" v-bind:class="{errorcls:is_error_occured , successcls:!is_error_occured}">{{'('+savedstatus + ')'}}</span>

            <span v-else style="color:rgba(0,0,0,0.54);font-weight:400;font-size:13px;margin-left:5px;">
            (Readonly Mode)</span>

            <span style="font-weight:400;font-size:13px;margin-left:5px;" :style="{'color': colorCode}">{{datasource_error||redoundoMsg}}</span>
          </v-col>
          <v-col cols="2" class="EDC-Col">
            
            <v-icon style="font-size:20px;float:right;padding-left:12px !important;" class="v-align" @click="savedata(true)" title='close'>far fa-times-circle</v-icon>

             <span class="" style="font-size:13px;float:right;margin-right:5px;" :style="{'color': colorCode}">
              <v-progress-circular :width="3" v-if="validateProgress" :size="20" color="blue" indeterminate></v-progress-circular>
              <showerrorlisttemplate :validation_response="validation_response" :stepType="stepType"
                @set-error="setError" @totalCount="totalCount" :errorListCount="errorListCount" :showprocess="validateProgress ? 'Y':'N'" style="margin-top:2px !important;margin-left:4px !important;" :showErrorPopUp="showErrorPopUp"></showerrorlisttemplate></span>

                <span style="float:right;" class="v-align" :style="{'color': colorCode}">
              <svgicon class="svg-icon-grid svg-fill-grid" name="validate"  style="float:right;margin-top:-20%;"  title="Validate Step" @click="validatestepdata" ></svgicon>
            </span>
            <!-- <svgicon class="svg-icon-grid svg-fill-grid" name="object_notes"  style="float:right;"  title="Add Step Description" @click="descriptionPanel = true"></svgicon> -->
          </v-col>
        </v-row>
        <v-row class="EDC-Row">
          <v-col class="EDC-Col" align="left">
            <edc-graph-toolbar style="padding-left:4px !important;" :redoCount="undoStackCount" :undoCount="redoStackCount" key="selection-desinger" @performUndoRedo="$emit('keyupHandler',$event)" @showDescription="descriptionPanel = true" @onSave="$emit('saveProcessDesigner')" :showNewVersion="false"></edc-graph-toolbar>
          </v-col>
        </v-row>
      </v-container>
    <v-container class="EDC-Container" style="padding-top:16px !important;">
      <v-row class="EDC-Row">
        <v-col class="EDC-Col" cols="3">
          <edc-table-list :tableListLoader="tableListLoader" :tablelistobj="tablelistpanelproperties"
          @dragged-object="draggedObject" @performcollapse="perform_collapse" :steptablelist="steps_table_list" :readonly='readonly' style="width:100% !important;">
          </edc-table-list>
        </v-col>
        <v-col :class="{'EDC-Row':true,'commondiv':true, 'dragdiv':!firstDraggedObj.table_name}" cols="3" id="firstTblObj">
          <svgicon class="svg-icon-grid svg-fill-grid EDC-GridIcon fixed-position-el" align="left" name="add_v2"></svgicon>
          <v-list dense v-if="firstDraggedObj && firstDraggedObj.table_name" class="style-2scroll col-list table-striped">
            <v-subheader align="left">{{firstDraggedObj.table_name}} <v-spacer></v-spacer> </v-subheader>
            <draggable v-model="firstDraggedObjCols" @change="onFirstDragChange">
              <v-list-item dense v-for="(item, i) in firstDraggedObjCols" :key="i+'f'" style="height:32px !important;min-height: 32px !important;">
                <v-list-item-avatar style="width:16px !important;align:left;min-width:16px !important;">::</v-list-item-avatar>
                <v-list-item-content style="height:16px !important;padding:0px !important;">
                  <v-list-item-title v-text="item"></v-list-item-title>
                </v-list-item-content>
              </v-list-item>
            </draggable>
          </v-list>
          <span v-else>
            <h6>Drag your first table here.....</h6>
          </span>
        </v-col>
        <v-col :class="{'EDC-Row':true, 'commondiv':true, 'dragdiv':!secondDraggedObj.table_name}" cols="3" id="secondTblObj">
          <svgicon class="svg-icon-grid svg-fill-grid EDC-GridIcon fixed-position-el" align="left" name="job_suspend_v2"></svgicon>
          <v-list dense v-if="secondDraggedObj && secondDraggedObj.table_name" class="style-2scroll col-list">
            <v-subheader dense>{{secondDraggedObj.table_name}}<v-spacer></v-spacer> </v-subheader>
            <draggable v-model="secondDraggedObjCols">
              <v-list-item v-for="(item, i) in secondDraggedObjCols" :key="i+'s'" style="height:32px !important;min-height: 32px !important;">
                <v-list-item-avatar style="width:16px !important;align:left;min-width:16px !important;">::</v-list-item-avatar>
                <v-list-item-content>
                  <v-list-item-title v-text="item"></v-list-item-title>
                </v-list-item-content>
              </v-list-item>
          </draggable>
            </v-list>
          <span v-else>
            <h6>Drag your second table here.....</h6>
          </span>
        </v-col>
        <v-col class="EDC-Col commondiv" cols="2">
          <v-list dense class="style-2scroll col-list" v-if="isDraggedTablesAreValidForMerge">
            <v-subheader>Resulting Worktable Columns</v-subheader>
            <v-list-item dense v-for="(item, i) in columnAliasList" :key="i+'alias'" style="height:32px !important;min-height: 32px !important;">
              <v-list-item-content>
                <v-text-field autocomplete="off" dense hide-details v-model="item.column_alias" @blur="validateAlias(i)"></v-text-field>
              </v-list-item-content>
            </v-list-item>
          </v-list>
          <span v-else>
            Resulting Worktable Columns
          </span>
          <label v-if="isDraggedTablesAreValidForMerge" style="float:right !imortant;"><input style="float:right !imortant;" type="checkbox" v-model="distinct" :disabled="readonly"/>&nbsp;&nbsp;<strong>Remove Duplicates Rows</strong></label></span>
        </v-col>
        </v-col>
      </v-row>
    </v-container>

  </v-card>
      
  <v-dialog v-model="descriptionPanel" width="1000" eager>
    <v-card>
      <rich-text-box style="backgroud:white" key="selectionRichTextBox" @DescriptionChange="DescriptionChange" :vueEditorObj="vueEditorObj">
        
      </rich-text-box>
      <v-card-actions>
        <v-spacer></v-spacer>
        <vc-button  itemText= "Close" @click.native="descriptionPanel = false">
                
              </vc-button>
      </v-card-actions>
    </v-card>
  </v-dialog>
</v-flex>
</template>
<script>
import {COLOR_CODE,BTN_COLOR} from '@/data/macros.js'
import tablelistpanel from './../../views/tablelistpanel.vue'

import draggable from 'vuedraggable'
import moment from 'moment'
import showerrorlisttemplate from './../../views/showerrorlisttemplate.vue'

import Sortable from 'sortablejs'
import {getBulkTablesColumns,getColumnsMoreInfo,getColumnsWithDescription} from "../../methods/agent_api_call.js"
import {validate_process_def_json} from "../../methods/call_validation.js"
import _ from 'lodash';
import {
  VALUE_TO_TEXT,VALIDATION_IN_RPOCESS_MESSAGE
} from "../../methods/flowcharts/constants.js";
import {PerformStepNameValidation} from "../../methods/flowcharts/graph.js"
import {
        CLIENT_SIDE,
        SERVER_SIDE
    } from '@/data/macros.js'
import {DEFAULT_DESCRIPTION,getColDescription} from "../../methods/flowcharts/commonFunc.js"
import * as edcConstantObj from '../../methods/edcGraph/constants.js'
import RichTextBox from "@/views/richTextEditor/index.vue";
import edcGraphToolbar from '@/views/edcGraph/edcGraphToolbar.vue'


export default {
  components:{
    draggable,
    'edc-table-list':tablelistpanel,
    showerrorlisttemplate,
    'rich-text-box':RichTextBox,
    'edc-graph-toolbar':edcGraphToolbar
  },
  data () {
    return {
      nameTextboxSize:20,
      firstDraggedObj:{},
      secondDraggedObj:{},
      firstDraggedObjCols:[],
      secondDraggedObjCols:[],
      columnAliasList:[],
      main_filterUI:[],
      secondary_filterUI:{groupList:[]},
      errorListCount:0,
      colorCode:COLOR_CODE,
      outlineColor:BTN_COLOR,
      currentTableObject:{},
      currentColumnObject:{},
      validateProgress:false,
      errorList:{},
      tableheight:'500px',
      distinct:true,
      search: '',
      table_search:'',
      stepname:this.parentdigramobj.cell_name,
      join_json: [],
      columns_for_selection:[],
      // table_column_list:[],
      selected_table:'',
      table_list:[],
      connection_str:"",
      schema:"",
      db_type:"",
      datasource_id:"",
      target_connection_str:"",
      target_schema:"",
      target_datasource_id:"",
      default_table_list:"",
      dragged_tbl_list:[],
      dragged_tbl_list_with_type:[],
      env_id: '',
      savedstatus:'edited',
      is_error_occured:false,
      tablelistpanelproperties:'',
      auto_increment_id:0,
      selection_error:'',
      source_cells_detail:[],
      combined_error_list:{},
      validation_response:{},
      datasource_error:'',
      env_name:this.$session.get('selected_env').name,
      steps_table_list:[], //this variable is for pass step table list to tablelist panel when user perform server search.
      old_stepname:'',
      stepType:"Merge",
      sourceCellColumnReferences:[], // this variable used to save the source cells references.Which we will use to add refernce detail in rows while performing selection,merge,minus,
      previous_columns_for_selection:[], //use for the reference updatation
      existingsteplist:[],
      vueEditorObj:{},
      descriptionPanel:false,
      pd_description:'',
      stepSeqNo:1,
      showErrorPopUp:true,
      autoValidateTimer:0
    }
  },
  props:{
    parentdigramobj:{
      type:Object,
      default:{"name":"dataselection"}
    },
    savedatastatus:{
      type:String,
      default:''
    },
    iserroroccured:{
      type:Boolean,
      default:false
    },
    tableListLoader:{
      type:Boolean,
      default:false
    },
    datasourceerror:{
      type:String,
      default:''
    },
    acceleratorObj:{
      type:Object,
      default:{}
    },
    readonly:{
      type:Boolean,
      default:false
    },
    redoundoCommandObj:{
      type:Object
    },
    redoundoMsg:{
      type:String,
    },
    undoStack:{
      type:Array
    },
    redoStack:{
      type:Array
    }
  },
  watch: {
    '$store.state.errorList':{
      handler(newValue){
        if(!_.isEmpty(newValue)){
          // let idArray = Object.keys(newValue);
          if(this.parentdigramobj && newValue[this.parentdigramobj.stepId]){
            this.validation_response = newValue[this.parentdigramobj.stepId];
          }          
        }
      },
      deep:true
    },
    acceleratorObj(newvalue){

    },
    datasourceerror(newvalue){
      this.datasource_error =newvalue
    },
    savedatastatus(newvalue,oldvalue){
      this.savedstatus = newvalue
    },
    iserroroccured(newvalue,oldvalue){
      this.is_error_occured =newvalue
    },
    readonly(newvalue){

    },
    parentdigramobj(newvalue,o){
      // TO DO -> updateReference for column deletion,validation
      var details_for_table_panel = {}
      this.source_cells_detail = []
      this.dragged_tbl_list = []
      this.dragged_tbl_list_with_type = []
      this.columnAliasList = []
      this.stepname = newvalue.cell_name
      this.old_stepname = newvalue.cell_name
      this.steps_table_list = []
      this.validation_response ={}
      this.stepType = newvalue.steptype
      this.existingsteplist = []
      this.pd_description = ''
      this.vueEditorObj = {'description':''}
      this.stepSeqNo = newvalue.stepSeqNo
      this.firstDraggedObj={}
      this.secondDraggedObj={}
      this.firstDraggedObjCols=[]
      this.secondDraggedObjCols=[]
      this.columnAliasList=[]
      if(newvalue.existingsteplist)
        this.existingsteplist = newvalue.existingsteplist
      this.validateStepOnUserAction()
      if(newvalue.tables_detail){
        details_for_table_panel.tables_detail = newvalue.tables_detail
        this.table_list = newvalue.tables_detail.table_list
        this.connection_str= newvalue.tables_detail.connection_str
        this.schema= newvalue.tables_detail.schema
        this.db_type= newvalue.tables_detail.db_type
        this.default_table_list = newvalue.tables_detail.default_table_list
        this.datasource_id = newvalue.tables_detail.datasource_id
        this.target_schema = newvalue.tables_detail.target_schema
        this.target_connection_str = newvalue.tables_detail.target_connection_str
        this.target_datasource_id = newvalue.tables_detail.target_datasource_id
        details_for_table_panel.table_list = this.table_list
        details_for_table_panel.connection_str = this.connection_str
        details_for_table_panel.schema = this.schema
        details_for_table_panel.db_type = this.db_type
        details_for_table_panel.default_table_list = this.default_table_list
      }
      //Code to bind error message if user perform validation at flowchart level
      var flowchart_errors = this.$store.state.errorList
      if(flowchart_errors && this.parentdigramobj && flowchart_errors[newvalue.stepId]){
          this.validation_response = flowchart_errors[newvalue.stepId];
        }

      if (newvalue.env_id){
        this.env_id = newvalue.env_id
      }
      if(newvalue.step_data){
         if(newvalue.step_data.description){
          this.pd_description = newvalue.step_data.description
          this.vueEditorObj = {'description':newvalue.step_data.description}
        }

        if(newvalue.step_data.join)
          this.join_json = newvalue.step_data.join
        else{
          this.join_json = [] 
        }
        
        /*
          distinct for ui
          True => remove duplicate rows, union
          False => keep duplicate entries, union all 

          distinct for backend
          True => union all
          False => union
          */
        this.distinct = true
        if(newvalue.step_data.distinct === true)
          this.distinct = false
        
        if(newvalue.step_data.firstDraggedObj){
          this.firstDraggedObj = newvalue.step_data.firstDraggedObj
        }
        if(newvalue.step_data.secondDraggedObj){
          this.secondDraggedObj = newvalue.step_data.secondDraggedObj
        }
        if(newvalue.step_data.firstDraggedObjCols){
          this.firstDraggedObjCols = newvalue.step_data.firstDraggedObjCols
        }

        if(newvalue.step_data.secondDraggedObjCols){
          this.secondDraggedObjCols = newvalue.step_data.secondDraggedObjCols
        }

        if(newvalue.step_data.selection){
          this.previous_columns_for_selection = _.cloneDeep(newvalue.step_data.selection)
          this.columns_for_selection = newvalue.step_data.selection
          this.callgetColumnsMoreInfo("col_format")
        }
        else{
          this.previous_columns_for_selection = []
          this.columns_for_selection = []
        }


        if(newvalue.step_data.new_select_table_list){
          this.dragged_tbl_list_with_type = newvalue.step_data.new_select_table_list
        }
        // after join,selection and dragged table list we have to create first,second DraggedTblObj & its columns
        this.createDraggedObjectDetails()
        if(newvalue.step_data.select_table_list)
          this.dragged_tbl_list = newvalue.step_data.select_table_list
        /* if(newvalue.step_data.validation_response){
          this.validation_response = newvalue.step_data.validation_response
        } */
        // this.Perform_Validation(this.validation_response)
        if(this.dragged_tbl_list_with_type){
          getBulkTablesColumns(this,this.table_column_list,this.env_id,this.connection_str,this.schema,_.cloneDeep(this.dragged_tbl_list_with_type)).then(tableColumnResponse=>{

          })
        }
        else{
          this.dragged_tbl_list_with_type =[]
        }

        if(newvalue.step_data.auto_id)
          this.auto_increment_id = newvalue.step_data.auto_id
        else
          this.auto_increment_id = 0

        // if(newvalue.step_data.validation_response)
        //   this.validation_response = newvalue.step_data.validation_response
      }
      this.sourceCellColumnReferences = []
      if(newvalue.source_cells_detail && newvalue.source_cells_detail.length > 0){
        this.source_cells_detail = newvalue.source_cells_detail
        this.table_list = _.cloneDeep(this.default_table_list)
        var step_list = newvalue.source_cells_detail
        for(var i=0;i<step_list.length;i++){

          // Add into sourceCellReferences
          this.sourceCellColumnReferences = _.concat(this.sourceCellColumnReferences,step_list[i].column_list_references)

          for(var each_table_count=0;each_table_count<this.table_list.length;each_table_count++){
            if (this.table_list[each_table_count].title == step_list[i].source_name){
              this.table_list.splice(each_table_count, 1)
              break;
            }
          }
          this.table_list.unshift({"type":"S","title":step_list[i].source_name,"seqNo":step_list[i].seqNo})
          this.steps_table_list.push({"type":"S","title":step_list[i].source_name,"seqNo":step_list[i].seqNo})
          for(var tbl_col_count=0;tbl_col_count<this.table_column_list.length; tbl_col_count++){
            if (this.table_column_list[tbl_col_count].table_name == step_list[i].source_name){
              this.table_column_list.splice(tbl_col_count, 1)
              break
            }
          }
          // this.table_column_list.push({"table_name":step_list[i].source_name, "table_column_list":step_list[i].columns})
          let key = this.env_id + '_table_column_list';

          if (!this.$store.state.cacheData[key]) {
            this.$store.state.cacheData[key] = [];
          }
              // this.table_column_list.push({"table_name":step_list[i].source_name, "table_column_list":step_list[i].columns})

              var table_name = step_list[i].source_name
              var table_columns = step_list[i].columns
              var cols_description = step_list[i].cols_description
              let cacheIndex = -1;
              cacheIndex = _.findIndex(this.$store.state.cacheData[key], function(o) { return o.table_name == table_name; });

              if(cacheIndex >= 0){
                this.$store.state.cacheData[key].splice(cacheIndex, 1); 
              }
               
              this.$store.state.cacheData[key].push({"table_name":table_name, "table_column_list":table_columns,'table_column_descriptions':cols_description})
            }
          }
          else{
            this.table_list = _.cloneDeep(this.default_table_list)
          }



      // as we required step tables also.so need to copy table list to default
      this.default_table_list = _.cloneDeep(this.table_list)
      details_for_table_panel.table_list = this.table_list
      details_for_table_panel.default_table_list = this.default_table_list
      details_for_table_panel.env_id = this.env_id
      this.tablelistpanelproperties = details_for_table_panel
    },
    distinct(newValue){
      this.savedata(false)
    },
    redoundoCommandObj(newvalue){
      if(!newvalue || !newvalue.action_to_take || newvalue.step_id != this.parentdigramobj.stepId)
        return
      this.manageRedoUndoActions(_.cloneDeep(newvalue))
    },
    redoundoMsg(newvalue){

    },
    stepname(newvalue){
      if(newvalue){
        this.nameTextboxSize=Math.max(20,newvalue.length>60?60:newvalue.length)
      }
    },
    undoStack(newvalue){
      if(newvalue){

      }
    },
    redoStack(newvalue){
      if(newvalue){

      }
    }
  },
  mounted() {
    let _this = this
      // this.tableheight='530px';
      var firstTblObj = document.getElementById('firstTblObj')

      console.log("join_flex",firstTblObj)
      firstTblObj.addEventListener('drop',drop_on_first_tbl)
      firstTblObj.addEventListener('dragover',allowDrop_on_first_tbl)

      var secondTblObj= document.getElementById('secondTblObj') 
      secondTblObj.addEventListener('drop',drop_on_second_tbl)
      secondTblObj.addEventListener('dragover',allowDrop_on_second_tbl)

      function drop_on_first_tbl(event) {
        event.preventDefault();
        // debugger
        if(!_this.currentTableObject || !_this.currentTableObject.title)
          return
        _this.firstDraggedObjCols = []

        var table_name = _this.currentTableObject.title;
        var table_type = _this.currentTableObject.type;
        var table_description = _this.currentTableObject.table_description
        let is_drv_table = false

        if(table_type === 'step')
          is_drv_table = true
        let oldFirstDraggedObj = _.cloneDeep(_this.firstDraggedObj)
        _this.firstDraggedObj ={"table_name":table_name,"table_description":table_description,"table_type":table_type,"is_first":true,"is_drv_table":is_drv_table}

        // _this.dragged_tbl_list.push(table_name)
        // _this.dragged_tbl_list_with_type.push({"table_name":table_name,"table_type":table_type})
        
        _this.removeTablesFromDraggedList(true,oldFirstDraggedObj)

        _this.showTableColumns(table_name,is_drv_table, table_type,true)
        _this.currentTableObject = {}
    }
    function allowDrop_on_first_tbl(event) {
      event.preventDefault();
    }

    function drop_on_second_tbl(event) {
        event.preventDefault();
        // debugger
        if(!_this.currentTableObject || !_this.currentTableObject.title)
          return
        _this.secondDraggedObjCols = []
        // let old_table_name = _this.secondDraggedObj.table_name
        var table_name = _this.currentTableObject.title;
        var table_type = _this.currentTableObject.type;
        var table_description = _this.currentTableObject.table_description
        let is_drv_table = false
        if(table_type === 'step')
          is_drv_table = true
        let oldSecondDraggedObj = _.cloneDeep(_this.secondDraggedObj)
        _this.secondDraggedObj ={"table_name":table_name,"table_description":table_description,"table_type":table_type,"is_first":true,"is_drv_table":is_drv_table}

        // _this.dragged_tbl_list.push(table_name)
        // _this.dragged_tbl_list_with_type.push({"table_name":table_name,"table_type":table_type})
        
        _this.removeTablesFromDraggedList(false,oldSecondDraggedObj)

        _this.showTableColumns(table_name,is_drv_table, table_type,false)
        _this.currentTableObject = {}
    }
    function allowDrop_on_second_tbl(event) {
      event.preventDefault();
    }
    $('#mainflex').on('keydown',function(event){
      if (event.ctrlKey && (event.code === 'KeyZ'||event.code === 'KeyY'))
          event.preventDefault()
    });
  },
  computed: {
    table_column_list(){
      let data = this.$store.state.cacheData[this.env_id+'_table_column_list'];
      return data ? data : [];
    },
    isDraggedTablesAreValidForMerge(){
      return this.firstDraggedObjCols.length>0 && this.firstDraggedObjCols.length ===  this.secondDraggedObjCols.length
    },
    undoStackCount(){
      if(!this.parentdigramobj || !this.parentdigramobj.stepId)
        return 0
      return _.filter(this.undoStack,(obj)=>{
        return obj.step_id === this.parentdigramobj.stepId
      }).length
    },
    redoStackCount(){
      if(!this.parentdigramobj || !this.parentdigramobj.stepId)
        return 0
      return _.filter(this.redoStack,(obj)=>{
        return obj.step_id === this.parentdigramobj.stepId
      }).length
    }
  },
  methods:{
    validateStepOnUserAction(){
      // we are going to call validate object after curtain time after action get performed because graph take sometime to update its json.
        var _this = this
        _this.showErrorPopUp = false
        clearInterval(_this.autoValidateTimer)
				_this.autoValidateTimer= setTimeout(()=>{
          _this.showErrorPopUp = true
          _this.validatestepdata()
          _this.$emit('validatePD')
        },edcConstantObj.AUTO_VALIDATOR_TIMER)
    },
    manageRedoUndoActions(redoundoCommandObj){
      if(redoundoCommandObj.action_to_take === 'step_name_changed_from_inside'){
        if(!redoundoCommandObj.update_details)
          return
        this.old_stepname = this.stepname
        this.stepname = redoundoCommandObj.update_details.new_step_name
      }
      else if(redoundoCommandObj.action_to_take === 'new_table_dragged'){
        if(redoundoCommandObj.tableIndex === 'First')
          this.firstDraggedObj = redoundoCommandObj.new_value
        else
          this.secondDraggedObj = redoundoCommandObj.new_value

        this.removeTablesFromDraggedList(false,{},true)
      }
    },
    removeTablesFromDraggedList(isFirst,oldObjDetails,isFromRedoUndo){
      var _this = this
      _this.dragged_tbl_list = []
      _this.dragged_tbl_list_with_type = []
      if(_this.firstDraggedObj && _this.firstDraggedObj.table_name){
        _this.dragged_tbl_list.push(_this.firstDraggedObj.table_name)
        _this.dragged_tbl_list_with_type.push({"table_name":_this.firstDraggedObj.table_name,"table_type":_this.firstDraggedObj.table_type})
      }

      if(_this.secondDraggedObj && _this.secondDraggedObj.table_name){
        _this.dragged_tbl_list.push(_this.secondDraggedObj.table_name)
        _this.dragged_tbl_list_with_type.push({"table_name":_this.secondDraggedObj.table_name,"table_type":_this.secondDraggedObj.table_type})
      }
      if(!isFromRedoUndo){
        let newlyDraggedObject = _.cloneDeep(_this.firstDraggedObj)
        let tableIndex = "First"
        if(!isFirst){
          newlyDraggedObject = _.cloneDeep(_this.secondDraggedObj)
          tableIndex = "Second"
        }
        this.$emit('sendNonGraphEvent','new_table_dragged',{"new_value":newlyDraggedObject,"old_value":oldObjDetails,"tableIndex":tableIndex,"step_id":this.parentdigramobj.stepId})
      }
      else
        this.createDraggedObjectDetails()

      this.validateStepOnUserAction()
    },
    createDraggedObjectDetails(){
      let _this = this
      if(!this.columns_for_selection.length)
        return
      this.firstDraggedObj = {}
      this.secondDraggedObj = {}
      this.firstDraggedObjCols = []
      this.secondDraggedObjCols = []
      let joinObj = this.join_json[0][Object.keys(this.join_json[0])]

      let tableType = _.find(_this.dragged_tbl_list_with_type,["table_name",joinObj.jfrom])
      if(tableType)
        tableType = tableType.table_type
      _this.firstDraggedObj ={"table_name":joinObj.jfrom,"table_description":"","table_type":tableType,"is_first":true,"is_drv_table":joinObj.jfrom_drv_table}

      _this.firstDraggedObjCols = _.map(joinObj.condition,"from_column")

      tableType = _.find(_this.dragged_tbl_list_with_type,["table_name",joinObj.jto])
      if(tableType)
        tableType = tableType.table_type
      _this.secondDraggedObj ={"table_name":joinObj.jto,"table_description":"","table_type":tableType,"is_first":true,"is_drv_table":joinObj.jto_drv_table}

      _this.secondDraggedObjCols = _.map(joinObj.condition,"to_column")
      let aliasList = _.map(_this.columns_for_selection,"alias_name")
      _this.createAliasList(aliasList)

    },
    totalCount(count){
      this.errorListCount = count;
    },
    draggedObject(object){
      this.currentTableObject = object;
    },
    columnDraggedObject(object){
      this.currentColumnObject = object;
    },
    setError(errorList){
      let _this = this;
      // _this.Remove_Error_List();
      debugger;
      _this.Perform_Validation(errorList);
    },
    get_new_id(){
      return ++this.auto_increment_id;
    },
    showTableColumns(table_name,is_drv_table, table_type,isFirst){
      var _this = this

      var kwargs = {"table_column_list":this.table_column_list, "env_id":this.env_id,
      "connection_str":this.connection_str,"schema":this.schema, "table_name":table_name, "table_type": table_type}
      getColumnsWithDescription(this,kwargs).then(tableColumnResponse => {
        var column_response = tableColumnResponse
        var all_col_description = []
        if(tableColumnResponse.column_list){
          column_response = tableColumnResponse.column_list
        }
        if(isFirst){
          _this.firstDraggedObjCols = column_response
        }
        else
          _this.secondDraggedObjCols = column_response

        _this.createAliasList(_this.firstDraggedObjCols)
      }).catch(error=>{
        console.log("error occurred")
      });
    },
    createAliasList(aliasList){
      //aliasList =  firstDraggedObjCols when new table dragged & aliasList=columns_for_selection alias list 
      // when its come from redesign
      let _this = this
      _this.columnAliasList = []
      if(_this.isDraggedTablesAreValidForMerge){
        for(let i=0;i<_this.firstDraggedObjCols.length;i++){
          _this.columnAliasList.push({"column_name":_this.firstDraggedObjCols[i],"column_alias":aliasList[i]})
        }
      }
    },
    validateAlias(index){
      if(!this.columnAliasList[index].column_alias)
        this.columnAliasList[index].column_alias = this.columnAliasList[index].column_name

      // TO DO: emit alias change event.
      this.validateStepOnUserAction()
    },
    
    savedata(is_close){
      let _this = this
      this.savedstatus = "edited"
      this.datasource_error = ''
      if(!_this.stepname)
        return
      var data = this.getdata()
      this.Remove_From_Normal_List(data.step_data.selection)
      console.log("data",data)
      _this.$emit('update-object',data,is_close)
    },
    getdata(){
      let _this = this
      this.env_name = this.$session.get('selected_env').name
      _this.createJoinJson()
      this.is_error_occured =false
      var data = {'stepname':_this.stepname.trim(),
      'step_data':{'graph':{},'join':_.cloneDeep(this.join_json),'selection':_.cloneDeep(this.columns_for_selection),'filter':[],'main_filterUI':[],'secondary_filterUI':{},'filter_column_list':['abc'], 'select_table_list':_.cloneDeep(this.dragged_tbl_list),'new_select_table_list':_.cloneDeep(this.dragged_tbl_list_with_type),'auto_id':this.auto_increment_id,'validation_response':this.validation_response,'distinct':!this.distinct, 'is_revised':true,'isAug2020ReleasedUI':true,'description':this.pd_description,'firstDraggedObj':_this.firstDraggedObj,'firstDraggedObjCols':this.firstDraggedObjCols,'secondDraggedObj':this.secondDraggedObj,'secondDraggedObjCols':this.secondDraggedObjCols}}
      data['step_type'] =this.stepType
      return data
    },
    validatestepdata(){
      if(this.readonly)
        return
      var _this = this
      if(_this.validateProgress){
        // alert(VALIDATION_IN_RPOCESS_MESSAGE)
        return false
      }
      _this.validateProgress=true;
      var data = this.getdata()
      data['previous_steps_data'] = this.source_cells_detail
      data['env_id'] = this.env_id
      _this.selection_error = ''
      _this.combined_error_list = {}
      _this.validation_response={}
      _this.Remove_Error_List();
      validate_process_def_json(this, data).then(validation_response => {
        _this.validateProgress=false;
        var error_data = validation_response ;
        _this.validation_response = validation_response
        _this.$store.state.errorList[this.parentdigramobj.stepId] = validation_response
          //first clear all errors list
          // _this.Perform_Validation(_this.validation_response)
        }).catch(error=>{
           _this.validateProgress = false;
         })
    },
    Perform_Validation(error_data){
      var _this = this
      _this.Remove_Error_List();
      if(this.dragged_tbl_list.length === "0"){
        _this.combined_error_list.Required = []
        _this.combined_error_list.Required.push('Drag atleast one table on the graph')
      }
      if (typeof error_data === 'object'){
        if(error_data.step_data){
          var step_data_errors = error_data.step_data
          if(step_data_errors.selection){
            if(_this.columns_for_selection.length ===0){
              _this.selection_error = 'Atleast one column for selection required'
              if(!_this.combined_error_list.Required)
                _this.combined_error_list.Required = []
              _this.combined_error_list.Required.push(_this.selection_error)
            }
            else{
              _this.Perform_Validation_On_Selection(step_data_errors.selection)
            }
          }
          if(step_data_errors.filter){
            _this.Perform_Validation_On_Filter(step_data_errors.filter)
          }
          if(step_data_errors.join){
            _this.Perform_Validation_On_Join(step_data_errors.join)
          }
        }
      }
    },
    Perform_Validation_On_Selection(selection_error_data){
      var _this = this;
      var rows = Object.keys(selection_error_data)
      rows.map(row=>{ // it will provide rowindex like 0,1,2
        let columnArray = Object.keys(selection_error_data[row]); 
        columnArray.map(column=>{ // it will provide key in that object like alies_name, column_name
          _this.columns_for_selection[row].error_list[column] = selection_error_data[row][column]; 
        })
      })
    },
    Remove_Error_List(){
      this.Remove_From_Normal_List(this.columns_for_selection)    
    },
    Remove_From_Normal_List(list){
      for(var i=0;i<list.length;i++){
        list[i].error_list = {}
      }
    },
    perform_collapse(action){
      if(action === 'close'){
        if(this.$refs.selection_panel_ref.$el)
          this.$refs.selection_panel_ref.$el.style.width="58%";
        else this.$refs.selection_panel_ref.style.width="58%";
      }
      else{
        if(this.$refs.selection_panel_ref.$el)
          this.$refs.selection_panel_ref.$el.style.width="40%";
        else this.$refs.selection_panel_ref.style.width="40%";
      }
    },
    StepNameChanged(){
      this.stepname = this.stepname.trim()
      if(this.old_stepname.trim() === this.stepname)
        return false
        if(this.stepname.length ===0)
          this.stepname = this.old_stepname.trim()
        else{
        var validated_name = PerformStepNameValidation(this.stepname,this.existingsteplist)
        this.stepname = validated_name
          this.UpdateReferences(this.old_stepname,this.stepname
            ,true)
          this.old_stepname = this.stepname.trim()
          this.savedata(false)    
        }
    },
    createJoinJson(isColMoved){
      var _this = this
      _this.join_json = []
      let old_columns_for_selection = _.cloneDeep(_this.columns_for_selection)
      _this.columns_for_selection = []
      if(!_this.isDraggedTablesAreValidForMerge){
        // need to test the flow
        if(old_columns_for_selection.length){
          let update_data = {"step_id":this.parentdigramobj.stepId,"step_name":this.stepname,"columnDeletionType":"delete_direct_reference"}
          for(let i=0;i<old_columns_for_selection.length;i++){
            let new_data = _.cloneDeep(update_data)
            new_data['removedColumnIndex'] =i
            new_data['columns_removed'] = [old_columns_for_selection[i]]
            _this.$emit('update-step-selection',"columns_removed",new_data)
          }
        }
        return
      }
      let joinObj = {
        "jfrom":_this.firstDraggedObj.table_name,
        "jfrom_drv_table":_this.firstDraggedObj.is_drv_table,
        "jto":_this.secondDraggedObj.table_name,
        "jto_drv_table":_this.secondDraggedObj.is_drv_table,
        "type":"Merge",
        "condition":[]
      }

      var jfrom_step_ref_id = ''
      var jto_step_ref_id = ''

      // in few time true or "true" comes as a value. So checking both the values
      if(joinObj.jfrom_drv_table === true || joinObj.jfrom_drv_table === 'true'){
        var from_ref_tab_name = _.find(this.sourceCellColumnReferences,["step_name",this.join_from_table])
        if(from_ref_tab_name){
          jfrom_step_ref_id = from_ref_tab_name.step_id
        }
      }
      
      if(joinObj.jto_drv_table === true || joinObj.jto_drv_table === 'true'){
        var to_ref_tab_name = _.find(this.sourceCellColumnReferences,["step_name",this.join_to_table])
        if(to_ref_tab_name){
          jto_step_ref_id = to_ref_tab_name.step_id
        }
      }

      // Now go for column references.
      var jfrom_col_ref_id = ''
      var jto_col_ref_id = ''
      if(jfrom_step_ref_id){
        var jfrom_col_obj = _.find(this.sourceCellColumnReferences,["column_name",this.jfrom])
        if(jfrom_col_obj)
          jfrom_col_ref_id = jfrom_col_obj.row_id
      }

      if(jto_step_ref_id){
        var jto_col_obj = _.find(this.sourceCellColumnReferences,["column_name",this.to])
        if(jto_col_obj)
          jto_col_ref_id = jto_col_obj.row_id
      }

      joinObj['jfrom_step_ref_id'] = jfrom_step_ref_id
      joinObj['jto_step_ref_id'] = jto_step_ref_id

      
      let condition = []
      for(let i=0;i<_this.firstDraggedObjCols.length;i++){
        condition.push({"id":_this.get_new_id(),"from_column":_this.firstDraggedObjCols[i],
        "operator":"_eq_",'to_column':_this.secondDraggedObjCols[i],"jfrom":_this.firstDraggedObj.table_name,"jto":_this.secondDraggedObj.table_name,"jfrom_col_ref_id":jfrom_col_ref_id,"jto_col_ref_id":jto_col_ref_id})
      }
      joinObj['condition'] = condition
      let joinkey = "merge-"+new Date().getDate()+'-'+new Date().getMonth()+'-'+new Date().getFullYear()+'-' + new Date().getTime().toString()

      _this.join_json[0] = {}
      _this.join_json[0][joinkey] = joinObj
      _this.createSelectionJson(joinObj,isColMoved)

    },
    createSelectionJson(joinObj,isColMoved){
      var _this = this
      _this.columns_for_selection = []
      for(let i=0;i<joinObj.condition.length;i++){
        let alias_name = _this.columnAliasList[i].column_alias
        
        if(!alias_name || isColMoved)
          alias_name = joinObj.condition[i].from_column

        let report_alias = _this.getMergeColDescription(alias_name,joinObj.jfrom,joinObj.jfrom_drv_table)
        // _this.columns_for_selection.push()
        _this.$set(_this.columns_for_selection,i,{'id':_this.get_new_id(),'column_name': joinObj.condition[i].from_column, 'table_name': joinObj.jfrom, 'alias_name': joinObj.condition[i].from_column,'is_drv_table':joinObj.jfrom_drv_table,'error_list':'','ref_row_id':joinObj.condition[i].jfrom_col_ref_id,'ref_step_id':joinObj.jfrom_step_ref_id,"report_alias_name":report_alias,"columnDeletionPopUp":false})
      }
      _this.callgetColumnsMoreInfo("col_format")
    },
    getMergeColDescription(alias_name,table_name,is_drv_table){
      let key = this.env_id + '_table_column_list';
      var sub_string_index = 0
      if (this.acceleratorObj.acceleration_type === 'jde_ed_e1')
        sub_string_index = 2

      let table_obj = _.find(this.$store.state.cacheData[key],["table_name",table_name])
      var descriptionlist = []
      if(table_obj && table_obj.table_column_descriptions)
        descriptionlist = table_obj.table_column_descriptions

      var description = getColDescription(sub_string_index,alias_name,descriptionlist,is_drv_table)
      if(description && description != DEFAULT_DESCRIPTION)
        return description
      return alias_name

    },
    UpdateReferences(old_data,new_data,is_name_change){
      var update_data ={"step_id":this.parentdigramobj.stepId,"step_name":this.old_stepname}
      var action = ''
      // need to reflect name change.
      if(is_name_change) {
        // if user didnt change any name
        if(old_data === new_data)
          return
        update_data['new_step_name'] = new_data
        action = 'step_name_changed'
      }
      let nonGraphEvent = action
      if(action === 'step_name_changed')
          nonGraphEvent = 'step_name_changed_from_inside'

        this.$emit('sendNonGraphEvent',nonGraphEvent,{'action_type':nonGraphEvent,'update_details':update_data})
      this.$emit('update-step-selection',action,update_data)
    },
    
    callgetColumnsMoreInfo(type_of_request){
      getColumnsMoreInfo(this,this.env_id,this.$session.get("client_id"),this.columns_for_selection,type_of_request,this.sourceCellColumnReferences)
    },
    DescriptionChange(pd_description){
      this.pd_description = pd_description
      this.savedata()
    },
    clearTableSelection(isFirst){
      var _this = this
      if(isFirst){
        _this.firstDraggedObjCols = []
        _this.firstDraggedObj = {}
      }
      else{
        _this.secondDraggedObjCols = []
        _this.secondDraggedObj = {}
      }
      _this.columnAliasList =[]
    },
    onFirstDragChange(event){
      if(event.moved){
        // this.manageFirstDraggedColumnMoved(event.moved.element,event.moved.newIndex,event.moved.oldIndex)
        this.createJoinJson(true)
      }
    },
    manageFirstDraggedColumnMoved(element,newIndex,oldIndex){
      // we have to manage join + column selection index based on the new index selection
      let _this = this
      console.log(_this.firstDraggedObjCols)
      // first remove the element from the dragged list
      // let removedElement = _this.firstDraggedObjCols.splice(newIndex,1)
      // now add that element into the 
    }
  }
}
</script>
<style scoped>
  .dragdiv {
    min-height:200px !important;
    border:1px solid #000000 !important;
    border-style: dotted !important;
    opacity:0.5;
    margin-right:4px !important;
  }
  .commondiv{
    margin-left:16px !important;
    margin-bottom:4px !important;
    margin-right:16px !important;

  }
  .col-list{
    height:400px !important;
    overflow-y: auto !important;
    padding-right:4px !important;
    opacity:0.8;
  }
  .v-subheader {
    height:24px !important;
  }
  .v-list--dense .v-list-item, .v-list-item--dense {
    height: 16px !important;
}

.commondiv .v-list-item:nth-of-type(odd) {
    background-color: rgba(0, 0, 0, 0.05);
}

.commondiv .v-list-item:nth-of-type(even) {
    background-color: var(--v-datatablealternate-base) !important;
}

.fixed-position-el {
  position:absolute;
  left:98%;
  top:0%;
  opacity:0.3;
  z-index:0 !important;
  width:32px !important;
  height:32px !important;
  transform: rotate(90deg);
}


.table th, .table td {
padding:8px !important;
}

.joinGrid{
  max-height: 112px;
  overflow-y: auto;
}
.v-autocomplete.v-input{
  margin:0px;
}


/* .lowerrow{
  display:block;
  height:200px;
  overflow: auto;
  border:1px solid grey;

} */

#mainflex{
  background-color:grey;
}

.list_items {
  draggable:true;
  height:30px;
}

.handle {
  cursor: move !important;
  cursor: -webkit-grabbing !important;
}
table.v-table thead tr, table.v-table tbody tr{
  height: 24px;
}
.sticky {
  position: absolute;
}
#filter_column_table .v-input__slot{
  margin-bottom: -10px;
  margin-top: -5px;
}
#select_column_table .v-select__slot{
  margin-bottom: -6px;
}
#filter_column_table .v-input__slot input, .v-select-list .v-list__tile__content{
  font-size: 12px;
}

.v-input__slot input, .v-select-list .v-list__tile__content{
  font-size: 12px;
}

#select_column_table td{
  font-size: 12px;
}


#select_column_table .v-input__slot{
  font-size: 14px;
  margin-bottom: -8px;
  margin-top: -5px;
}
#select_column_table .v-input__append-inner i{
  /*font-size: 15px;*/
}
#select_column_table .v-text-field__slot{
  width: 65px;
}
#select_column_table .v-text-field__slot{
  margin-top: -5px;
}
.handle {
  cursor: move !important;
  cursor: -webkit-grabbing !important;
}
.v-radio label{
  font-size: 15px;
}
.v-radio i{
  font-size: 20px;
}
.v-dialog__activator{
  margin-top: -10px;
}
.scrollit {
    overflow:scroll !important;
    height:100px;
}

#togglebtn label{
  margin-bottom:0px !important;
}
/* .highlight_tbl{
  border:3px solid #FF0000 !important;
} */
</style>
