<template>
  <div class="text-xs-center" v-if="showchip">
    <v-menu v-model="menu" :close-on-content-click="false" :nudge-width="400" offset-x left :absolute="true">
      <template v-slot:activator="{ on }">
        <v-chip class="text-xs-left" small slot="activator" v-on="on" :color="chipcolor" >{{totalCount}}</v-chip>
        
      </template>
      <v-card>
        <v-toolbar color="#262525;" height="35" dark>
          <v-icon color="red">warning</v-icon>
          <v-toolbar-title>Errors</v-toolbar-title>
        </v-toolbar>
        <!-- <v-list> -->
          <v-checkbox @change="showErrorMessage('all', highlightAll)" v-model="highlightAll" color="red" 
            label="Highlight All" class="customCheckbox" style="height: 32px !important;" v-if="errorsource !='bo'"> </v-checkbox>
          <v-flex height="200" style="overflow-x:scroll !important;overflow-y:auto !important">
          <table border="0" class="table table-striped table-bordered table-fixed" v-if="errorsource ==='bo'">
            <thead>
              <tr>
                <th> Type </th>
                <th> Message </th>
              </tr>
            </thead>
            <tbody >
              <tr v-for="(item, index) in boErrorList" :key="index">
                <td style="cursor:pointer;text-align:left;">{{item.type}}</td>                
                <td style="cursor:pointer;padding:0px">
                  <table border="0" class="table table-striped table-bordered table-fixed" style="margin:0px">
                    <tr v-for="(row, key) in item.errors" :key="key">
                      <td><v-checkbox @change="showErrorMessage(item.type, row.isChecked, row)" v-model="row.isChecked" color="red" 
                          :label="row.message" :disabled="!row.cell_id" class="customCheckbox" style="height: 20px !important;"> </v-checkbox></td>
                      <!-- <td v-else>{{row.message}}</td> -->
                    </tr>
                  </table>
                </td>
              </tr>
            </tbody>
          </table>

          <table border="0" class="table table-striped table-bordered table-fixed" v-else>
            <thead>
              <tr>
                <th> Type </th>
                <th> Message </th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="(item, index) in inputResponse" :key="index">
                <td style="cursor:pointer" v-if="item.type === 'graph'"> Orphan Table/Link Without Join</td>
                <td style="cursor:pointer;text-align:left;" v-else>{{item.type}}</td>                
                <td style="cursor:pointer;padding:0px">
                  <table border="0" class="table table-striped table-bordered table-fixed" style="margin:0px">
                    <tr v-for="(row, key) in item.rowArray" :key="key">
                      <td><v-checkbox @change="showErrorMessage(item.type, row.isChecked, row)" v-model="row.isChecked" color="red" 
                          :label="row.message" class="customCheckbox" style="height: 20px !important;"> </v-checkbox></td>
                    </tr>
                  </table>
                </td>
              </tr>
            </tbody>
          </table>
        </v-flex>
        <!-- </v-list> -->

        <v-card-actions>
          <v-spacer></v-spacer>
          <vc-button  @click.native="menu = false" itemText="Close"></vc-button>
        </v-card-actions>
      </v-card>
    </v-menu>
  </div>
</template>
<script>
import _ from 'lodash'
import { BTN_COLOR} from '../data/macros.js'
import * as edcConstantObj from '../methods/edcGraph/constants.js'
  export default {
    data: () => ({
      outlineColor:BTN_COLOR,
      totalCount:0,
      highlightAll:false,
      showchip:true,
      fav: true,
      message: false,
      menu:false,
      hints: true,
      items: [],
      inputResponse:[],
      errorList:{step_data:{}},
      chipcolor:'green',
      def:{
        'Selection':{
          'filter':"setFilterSelError",
          'selection':"setFilterSelError",
          'graph':"setGraphError",
          'join':"setJoinError"
        },
        'Merge':{
          'selection':"setMergeStepSelectionError",
          'filter':"setMergeStepCriteriaError"
        },
        'Minus':{
          'selection':"setMergeStepSelectionError",
          'filter':"setMergeStepCriteriaError"
        },
        "Archival":{
          'filter':"setArchivalStepCriteriaError"
        }
      },
      boErrorList:[],
    }),
    props:{
      validation_response: Object,
      stepType: String,
      errorListCount:Number,
      showprocess:String,
      errorsource:String,
      bovalidationresponse:Object,
      bographdata:Object,
      showErrorPopUp:{
        type:Boolean,
        default:false,
      }
    },
    watch:{
      errorListCount(newVal){

      },
      validation_response:{
        handler(newVal){
          // debugger;
          this.totalCount =0
          this.chipcolor ='green'
          if(_.isObject(newVal) && !_.isEmpty(newVal))
            this.setInputResponse(newVal.step_data);
        },
        deep:true
      },

      showprocess(newVal){
        // alert(newVal)
        this.showchip = true ? newVal === 'N' || newVal === false :false
      },
      errorsource(newVal){

      },
      bovalidationresponse(newVal){
        this.totalCount =0
        this.chipcolor ='green'
        if(_.isObject(newVal) && !_.isEmpty(newVal))
            this.setBoInputResponse(newVal);
      },
      bographdata(newVal){

      },
      showErrorPopUp:{
        handler(newVal){
          if(!newVal)
            this.menu = false
        }
      }
    },
    methods:{
      /**
        @param key will represent 'all, selection, graph'
        @param value will represent that checkbox value true,false
        @param rowObj will represent row object of that module(selection, criteria, graph) 
       */
      showErrorMessage(key, value, rowObj){
        if(this.errorsource === "bo"){
          if(key === "all"){
            this.setAllError(value);
          }
          else{
            this.setErrorOnBO(value,rowObj.cell_id,rowObj.message)
          }
        }
        // debugger;
        else{
          if(key == 'all'){
            this.setAllError(value);
          }else{
            this[this.def[this.stepType][key]](key, value, rowObj);
          }
        }
        
      },
      setMergeStepSelectionError(key, value, rowObj){
            if(value){
              this.errorList.step_data[key] = this.errorList.step_data[key] ? this.errorList.step_data[key]:{};
            this.errorList.step_data[key][rowObj.rowIndex] = this.errorList.step_data[key][rowObj.rowIndex] ? 
                                              this.errorList.step_data[key][rowObj.rowIndex]:{};
            this.errorList.step_data[key][rowObj.rowIndex][rowObj.column] = rowObj.message;             
            }else{
              this.errorList.step_data[key][rowObj.rowIndex][rowObj.column] = undefined; 
            }
             this.$emit('set-error',this.errorList); 
      },
      setMergeStepCriteriaError(key, value, rowObj){
        if(value){
            this.errorList.step_data[key] = this.errorList.step_data[key] ? this.errorList.step_data[key]:{};
            this.errorList.step_data[key][rowObj.table_name] = this.errorList.step_data[key][rowObj.table_name] ? 
                                              this.errorList.step_data[key][rowObj.table_name]:{};
            this.errorList.step_data[key][rowObj.table_name][rowObj.rowIndex] = this.errorList.step_data[key][rowObj.table_name][rowObj.rowIndex] ? 
                                              this.errorList.step_data[key][rowObj.table_name][rowObj.rowIndex]:{};
            this.errorList.step_data[key][rowObj.table_name][rowObj.rowIndex][rowObj.column] = this.errorList.step_data[key][rowObj.table_name][rowObj.rowIndex][rowObj.column] ? 
                                              this.errorList.step_data[key][rowObj.table_name][rowObj.rowIndex][rowObj.column]:[];                                                                      
            this.errorList.step_data[key][rowObj.table_name][rowObj.rowIndex][rowObj.column].push(rowObj.message);                                  
        }else{
            this.errorList.step_data[key][rowObj.table_name][rowObj.rowIndex][rowObj.column] = undefined;       
        }
         this.$emit('set-error',this.errorList);
      },
      setJoinError(key, value, rowObj){
        if(value){
         this.errorList.step_data[key] = this.errorList.step_data[key] ? this.errorList.step_data[key]:{};
         this.errorList.step_data[key][rowObj.rowIndex] = this.errorList.step_data[key][rowObj.rowIndex] ? 
                                              this.errorList.step_data[key][rowObj.rowIndex]:{};
            this.errorList.step_data[key][rowObj.rowIndex][rowObj.column] = this.errorList.step_data[key][rowObj.rowIndex][rowObj.column] ?
                                                                     this.errorList.step_data[key][rowObj.rowIndex][rowObj.column]:{};
            
            this.errorList.step_data[key][rowObj.rowIndex][rowObj.column]=
                  this.validation_response['step_data'][key][rowObj.rowIndex][rowObj.column];
            if(this.validation_response['step_data']['link'])
                this.errorList.step_data['link'] = this.validation_response['step_data']['link'];
            
        }else{
           this.errorList.step_data[key][rowObj.rowIndex][rowObj.column] =  undefined;
           this.errorList.step_data['link'] = undefined;
        }
        this.$emit('set-error',this.errorList);
      },
      setGraphError(key, value, rowObj){
        if(value){
          this.errorList.step_data[key] = this.errorList.step_data[key] ? this.errorList.step_data[key]:{};
          this.errorList.step_data[key][rowObj.rowIndex] = this.errorList.step_data[key][rowObj.rowIndex] ? 
                                              this.errorList.step_data[key][rowObj.rowIndex]:[];
          this.errorList.step_data[key][rowObj.rowIndex].push(rowObj.message);                                    
        }else{
          let index = -1;
          this.errorList.step_data[key][rowObj.rowIndex].map((value, valueIndex)=>{
            if(value == rowObj.message){
              index = valueIndex
            }
          });
          if(index >= 0) 
            this.errorList.step_data[key][rowObj.rowIndex].splice(index, 1);
        }
        this.$emit('set-error',this.errorList);
      },
      setFilterSelError(key, value, rowObj){
         if(value){            
            this.errorList.step_data[key] = this.errorList.step_data[key] ? this.errorList.step_data[key]:{};
            this.errorList.step_data[key][rowObj.rowIndex] = this.errorList.step_data[key][rowObj.rowIndex] ? 
                                              this.errorList.step_data[key][rowObj.rowIndex]:{};
            this.errorList.step_data[key][rowObj.rowIndex][rowObj.column] = this.errorList.step_data[key][rowObj.rowIndex][rowObj.column] ?
                                                                     this.errorList.step_data[key][rowObj.rowIndex][rowObj.column]:{};
            this.errorList.step_data[key][rowObj.rowIndex][rowObj.column] =  rowObj.message;                                                      
          }else{
            this.errorList.step_data[key][rowObj.rowIndex][rowObj.column] =  undefined;
          }
          this.$emit('set-error',this.errorList);
      },
      setArchivalStepCriteriaError(key, value, rowObj){
        if(value){
            this.errorList.step_data.drv_table = this.errorList.step_data.drv_table ? this.errorList.step_data.drv_table:{};          
            this.errorList.step_data.drv_table[key] = this.errorList.step_data.drv_table[key] ? this.errorList.step_data.drv_table[key]:{};
            this.errorList.step_data.drv_table[key][rowObj.rowIndex] = this.errorList.step_data.drv_table[key][rowObj.rowIndex] ? 
                                              this.errorList.step_data.drv_table[key][rowObj.rowIndex]:{};
            this.errorList.step_data.drv_table[key][rowObj.rowIndex][rowObj.column] = this.errorList.step_data.drv_table[key][rowObj.rowIndex][rowObj.column] ?
                                                                     this.errorList.step_data.drv_table[key][rowObj.rowIndex][rowObj.column]:{};
            this.errorList.step_data.drv_table[key][rowObj.rowIndex][rowObj.column] =  rowObj.message;                                                      
          }else{
            this.errorList.step_data.drv_table[key][rowObj.rowIndex][rowObj.column] =  undefined;
          }
          // debugger;
          this.$emit('set-error',this.errorList);
      },
      setAllError(value){
        // debugger;
        this.resetAllCheckbox(value);
        if(this.errorsource != "bo"){
          if(value){
              this.errorList = this.validation_response;
            }else{
              this.errorList = {step_data:{}};
            }
            this.$emit('set-error',this.errorList);
        }
      },
      resetAllCheckbox(value){
        if(this.errorsource === "bo"){
          this.boErrorList.map(module=>{
            module.errors.map(row=>{
              if(row.cell_id)
                row.isChecked = value ? true : false;
            });
          })
        }
        else{
          this.inputResponse.map(module=>{
            module.rowArray.map(row=>{
              row.isChecked = value ? true : false;
            });
          })
        }
                 
      },
      setInputResponse(obj){
        this.showchip = true
        let _this = this;
        _this.totalCount = 0;
        // alert(this.stepType);
        if(this.stepType == 'Selection' || this.stepType == 'Merge' || this.stepType == 'Minus'){
          this.setSelectionResponse(obj)
        }
        else if (this.stepType == "Archival"|| this.stepType == 'Purge' || this.stepType == 'Copy' ||this.stepType == "Duplicate"){
          this.setArchivalResponse(obj);  
        }
        // else if(this.stepType == 'Merge' || this.stepType == 'Minus'){
        //   this.setMergeResponse(obj);  
        // }
        else if(this.stepType == 'Procedure'){
          this.setProcedureResponse(obj);
        }
        else if(this.stepType === edcConstantObj.DATA_ITEM_SEARCH){
          this.setDataItemSearchResponse(obj)
        }
        else if(this.stepType === edcConstantObj.CREATE_VIEW){
          this.setCreateViewError(obj)
        }
          /**
           * count total error in a list to show a bedge 
           */
          
          this.inputResponse.map(obj=>{
            obj.rowArray.map(row=>{
              _this.totalCount += 1;
            })
            _this.chipcolor = 'green'
            if(_this.totalCount > 0)
              _this.chipcolor = 'red'
          })
          this.$emit('totalCount', _this.totalCount); 
        // console.log("Input Response:....."+JSON.stringify(_this.inputResponse));
      },
      setDataItemSearchResponse(obj){
        let _this = this;
        _this.inputResponse=[];
        let tempObject = {type:'Selection',rowArray:[]};
        if(obj.search_alias)
          tempObject.rowArray.push({"message":obj.search_alias})
        if(obj.run_on)
          tempObject.rowArray.push({"message":obj.run_on})
        if(obj.include_list)
          tempObject.rowArray.push({"message":obj.include_list})
        _this.inputResponse.push(_.cloneDeep(tempObject));
      },
      setCreateViewError(obj){
        debugger;
        let _this = this;
        _this.inputResponse=[];
        let tempObject = {type:'Selection',rowArray:[]};
        if(obj && obj.all_rules_list && obj.all_rules_list.duplicate_row){

          let message = ""
          let dupRowIndexes = _.keys(obj.all_rules_list.duplicate_row)
          for(let i=0;i<dupRowIndexes.length;i++){
            tempObject.rowArray.push({"message":obj.all_rules_list.duplicate_row[dupRowIndexes[i]]})
          }
          
        } else if (obj && obj.all_rules_list && typeof(obj.all_rules_list)== 'string')
          tempObject.rowArray.push({"message":obj.all_rules_list})

        this.inputResponse.push(_.cloneDeep(tempObject));
      },
      setProcedureResponse(obj){
        let _this = this;
        _this.inputResponse=[];
        if(!obj.procedure_name)
          return false
        let tempObject = {type:'Selection',rowArray:[]};
          let colObject = { 'message':'Select Stored Procedure to execute','isChecked':false};
          tempObject.rowArray.push(_.cloneDeep(colObject));
          _this.inputResponse.push(_.cloneDeep(tempObject));
      },
      setArchivalResponse(obj){
        let _this = this;
        _this.inputResponse=[];
        if(obj.business_obj_id){
          let tempObject = {type:'Business Object',rowArray:[]};
          if(obj.business_obj_id.keysMistmatch){
            let colObject = { 'message':'Driver columns and business object keys are not matching','isChecked':false};
            tempObject.rowArray.push(_.cloneDeep(colObject));
          }
          else{

            let colObject = { 'message':'Select Business Object','isChecked':false};
            tempObject.rowArray.push(_.cloneDeep(colObject));
          }
          _this.inputResponse.push(_.cloneDeep(tempObject));
        }
        if(!obj.drv_table)
          return false
        let moduleArray = Object.keys(obj.drv_table);//Array of selection, join, graph, criteria key
        moduleArray && moduleArray.map(modString=>{ //modObj will ref key like selection
        if(modString == 'filter'){
          let rowArray = Object.keys(obj.drv_table[modString]);
          let tempObject = {type:modString,rowArray:[]};
            rowArray.map(row=>{
              if(!(typeof obj.drv_table[modString][row] == 'string')){ //sometime in graph getting key 'tree':'multiple tree found' on row level
                  let ColArray = Object.keys(obj.drv_table[modString][row]);
                    ColArray.map(column=>{
                      let message = "";
                      if(obj.drv_table[modString][row][column] instanceof Array){
                          message = obj.drv_table[modString][row][column].join("");
                      }else{
                        message = obj.drv_table[modString][row][column];
                      }
                      let colObject = {'rowIndex':row, 'column':column, 'message':message,'isChecked':false};
                        tempObject.rowArray.push(_.cloneDeep(colObject));
                    })
              } 
            })
          _this.inputResponse.push(_.cloneDeep(tempObject));
        }
        else if (modString === 'selection'){
          let colObject = { 'message':'Driver columns and business object keys are not matching','isChecked':false};
          tempObject.rowArray.push(_.cloneDeep(colObject));
           _this.inputResponse.push(_.cloneDeep(tempObject));
        }
        else if (modString == 'select_table_list'){
          let tempObject = {type:'Driver table',rowArray:[]};
          let colObject = { 'message':'Select Driver table','isChecked':false};
          tempObject.rowArray.push(_.cloneDeep(colObject));
          _this.inputResponse.push(_.cloneDeep(tempObject));
        }
        });
      },
      setSelectionResponse(obj){
        let moduleArray = Object.keys(obj);//Array of selection, join, graph, criteria key
        let _this = this;
        _this.inputResponse=[];
        moduleArray && moduleArray.map(modString=>{ //modObj will ref key like selection
          let rowArray = Object.keys(obj[modString]);
          let tempObject = {type:modString,rowArray:[]};
            rowArray.map(row=>{
              if(!(typeof obj[modString][row] == 'string')){ //sometime in graph getting key 'tree':'multiple tree found' on row level
                  let ColArray = Object.keys(obj[modString][row]);
                    ColArray.map(column=>{
                      let message = "";
                      if(obj[modString][row][column] instanceof Array){
                          message = obj[modString][row][column].join("");
                      }else{
                        message = obj[modString][row][column];
                      }
                      let colObject = {'rowIndex':row, 'column':column, 'message':message,'isChecked':false};
                      if(tempObject.type=='join'){
                        this.setJoinRsponse(tempObject, colObject);
                      }else{
                        tempObject.rowArray.push(_.cloneDeep(colObject));
                      }
                    })
              }
              else if (modString == 'selection')
              {
                let colObject = {'message':'Select atleast one column for data selection','isChecked':false};
                 tempObject.rowArray.push(_.cloneDeep(colObject));
              }
            })
            if(modString == 'select_table_list'){
              let colObject = {'column':'table select', 'message':obj[modString],'isChecked':false}
              if(tempObject.rowArray.indexOf(colObject)<0)
                tempObject.rowArray.push(_.cloneDeep(colObject)); 
            }
            if(tempObject.rowArray.length > 0)
              _this.inputResponse.push(_.cloneDeep(tempObject));
        });
      },
      setJoinRsponse(tempObject, colObject){
        let joinObj = _.cloneDeep(colObject);
        if(colObject.message.jfrom){
          joinObj.message = colObject.message.jfrom;
          joinObj['jfrom'] = true;
          tempObject.rowArray.push(_.cloneDeep(joinObj));
        }
        if(colObject.message.jto){
           joinObj.message = colObject.message.jfrom;
          joinObj['jto'] = true;
          tempObject.rowArray.push(_.cloneDeep(joinObj));
        }
        if(colObject.message.from_column){
           joinObj.message = colObject.message.from_column;
          joinObj['jfrom'] = true;
          tempObject.rowArray.push(_.cloneDeep(joinObj));
        }
        if(colObject.message.to_column){
           joinObj.message = colObject.message.to_column;
          joinObj['jto'] = true;
          tempObject.rowArray.push(_.cloneDeep(joinObj));
        }
        if(colObject.message.condition){
          joinObj.message = colObject.message.condition[0]
          joinObj['condition'] = true;
          tempObject.rowArray.push(_.cloneDeep(joinObj));
        }
      },
      setBoInputResponse(validation_response){
        var _this = this
        _this.boErrorList = []
        if(!validation_response.business_data && !validation_response.unique_identifier_keys)
          return false

        var cells = this.bographdata.cells
        if(cells.length === 0){
          _this.boErrorList.push({"type":"Business Object Keys","errors":[{"message":"Select atleast one table with primary kys","cell_id":"","isChecked":false}]})
          this.totalCount = 1
          return
        }
        if(validation_response.unique_identifier_keys){
          var bo_key_cell = _.find(cells,"table_name","Business Object Keys")
          _this.boErrorList.push({"type":"Business Object Keys","errors":[{"message":"Select atleast one business object key","cell_id":bo_key_cell.id,"isChecked":false}]})
          this.totalCount = 1
        }
        if(!validation_response.business_data)
          return false

        var error_response = validation_response.business_data
        var bo_error_json = []
        
        if(error_response.graph && error_response.graph.tree){
          _this.boErrorList.push({"type":"Multiple Hierarchy","errors":[{"message":"Multiple hierarchy found","cell_id":'',"isChecked":false}]})
          this.totalCount = this.totalCount + 1
        }

        if(error_response.graph && error_response.graph.links_without_join){
          var links_without_join = error_response.graph.links_without_join
          var error_list = []
          _.forEach(links_without_join,function(link){
            var link_obj = _.find(cells,['id',link])
            var link_source = _.find(cells,["id",link_obj.source.id])
            var link_target = _.find(cells,["id",link_obj.target.id])
            var message = "Specify join information between table "+link_source.table_name +" & "+link_target.table_name
            error_list.push({"message":message,"cell_id":link,"isChecked":false})
          })
            _this.boErrorList.push({"type":"Join","errors":error_list})
          this.totalCount = this.totalCount + links_without_join.length
        }
        
        if(error_response.graph && error_response.graph.orphan_steps){
          var orphan_steps = error_response.graph.orphan_steps
          var error_list = []
          _.forEach(orphan_steps,function(obj){
            var tbl_obj = _.find(cells,['id',obj])
            var message = "Table "+tbl_obj.table_name+" is an orphan"
            error_list.push({"message":message,"cell_id":obj,"isChecked":false})
          })
            _this.boErrorList.push({"type":"Table","errors":error_list})
          this.totalCount = this.totalCount + orphan_steps.length
        }


        if(error_response.link_filters){
          var link_filters = error_response.link_filters
          var keys = _.keys(link_filters)
          var error_list = []
          _.forEach(keys,function(key){
              var link_obj = _.find(cells,['id',key])
              var link_source = _.find(cells,["id",link_obj.source.id])
              var link_target = _.find(cells,["id",link_obj.target.id])
              var message = "Invalid filter specify on link between table "+link_source.table_name +" & "+link_target.table_name
              error_list.push({"message":message,"cell_id":key,"isChecked":false})
          })
          _this.boErrorList.push({"type":"Join Filter","errors":error_list})
          this.totalCount = this.totalCount + keys.length
        }

        if(error_response.filters){
          var filters = error_response.filters
          var keys = _.keys(filters)
          var error_list = []
          _.forEach(keys,function(key){
            var tbl_obj = _.find(cells,['id',key])
            var message = "Invalid filter specified for Table "+tbl_obj.table_name
            error_list.push({"message":message,"cell_id":key,"isChecked":false})
          })
            _this.boErrorList.push({"type":"Table Filter","errors":error_list})
          this.totalCount = this.totalCount + keys.length
        }

        if(error_response.joins){
          var joins = error_response.joins
          let joinkeys =  _.keys(joins)
          var error_list = []
          _.forEach(joinkeys,function(key){
            var link_obj = _.find(cells,['id',key])
            var link_source = _.find(cells,["id",link_obj.source.id])
            var link_target = _.find(cells,["id",link_obj.target.id])
            var message = "Duplicate relation found between table "+link_source.table_name +" & "+link_target.table_name
            error_list.push({"message":message,"cell_id":key,"isChecked":false})
          })
            _this.boErrorList.push({"type":"Duplicate Relation","errors":error_list})
          this.totalCount = this.totalCount + joinkeys.length
        }
        _this.chipcolor = 'green'
            if(_this.totalCount > 0)
              _this.chipcolor = 'red'
      },
      setErrorOnBO(isHeighlight,cell_id,message){
        this.$emit("setErrorOnBO",isHeighlight,cell_id,message)
      },
    }
  }
</script>
<style scoped>
.customCheckbox >>> label{
    top: 1px !important;
    font-size:14px !important;
}
.v-input--selection-controls{
  margin-top:2px;
}
.tableScroll{
    width: 100%;
    overflow-x: auto;
    overflow-y: auto;
}

.tbodyScroll {
    width: 100%;
    height: 300px;
    overflow-y: auto;
    display: block;
    overflow-x: scroll;
}

.v-chip.v-size--small{
  height:20px !important;
}

</style>
